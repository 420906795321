import * as actions from './actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

const INITIAL_STATE = {};

export const knownFeatures = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CHANGE_OPENED_ONCE:
      if (action.openedOnce) {
        return ReduxUtils.replace(state, `${action.name}.openedOnce`, true);
      }
      return !state[action.name] ? state : ReduxUtils.replace(state, `${action.name}`, undefined);
    default:
      return state;
  }
};
