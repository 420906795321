import * as amplitude from '../../analytics/amplitude.js';

let __childWindow;
export const trelloOauth2Login = (apiKey, appName, expiration) => {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const oauthUrl = `https://trello.com/1/authorize?expiration=${expiration}&name=${encodeURIComponent(appName)}&scope=read&scope=account&key=${apiKey}&callback_method=fragment&return_url=${encodeURIComponent(getRedirectUrl())}`;
  const width = window.innerWidth > 767 ? 650 : window.innerWidth;
  const height = window.innerWidth > 767 ? window.innerHeight > 700 ? 700: window.innerHeight : window.innerHeight;
  const left = window.innerWidth > 767 ? ((screen.width - width) / 2) : 0;
  const top = window.innerWidth > 767 ? ((screen.height - height) / 2) : 0;
  __childWindow && __childWindow.close && __childWindow.close();
  __childWindow = window.open(oauthUrl, "", "titlebar=no, width=" + width + ", height=" + height + ", left=" + left + ", top=" + top + ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, directories=no, status=no");

  const timer = setInterval(checkChild, 1000);
  function checkChild() {
    if (!__childWindow || __childWindow.closed) {
      reject({code: 'CANCELED'});
      clearInterval(timer);
    }
  }

  /**
   * Listens for TRELLO_AUTH_SUCCESSFULLY_COMPLETED, TRELLO_AUTH_LOGIN_FAILED
   * @param {Object} e Event details.
   */
   const listener = (e) => {
    const type = e.data && e.data.type;
    if (type === 'TRELLO_AUTH_LOGIN_SUCCESSFULLY_COMPLETED' || type === 'TRELLO_AUTH_LOGIN_FAILED') {
      const code = type === 'TRELLO_AUTH_LOGIN_FAILED' ? e.data && e.data.error === 'Token request rejected' ? 'CANCELED': e.data.error || 'UNKNOWN': 'SUCCESS';
      const data = {...e.data, ...{code}};
      clearInterval(timer);
      window.removeEventListener('message', listener);
      type === 'TRELLO_AUTH_LOGIN_SUCCESSFULLY_COMPLETED' ? resolve(data) : reject(data);
    }
  };
  window.addEventListener('message', listener);
  return promise;
};

/**
 * @returns {String} Redirect fallback page url.
 */
const getRedirectUrl = () => {
  return `${window.location.protocol}//${window.location.host}/trello-auth-identity-fallback.html`;
}