import { isAccountPreferencesPage  } from './validate-url';
import URI from '@dw/urijs-esm';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If page url is `{account-id}/preferences` and user has at least permission on account then resolve a promise.
 *                    Otherwise reject a promise.
 */
export const accountPreferencesRouter = (data) => {
  return new Promise((resolve, reject) => {
    if (!data.user || !data.user.id) {
      reject(data);
      return;
    }

    if(isAccountPreferencesPage(data.path)) {
      let uri = new URI(data.path);
      data.accountId = uri.segment()[0];
      let params = new URI().query(true);
      data.page = {
        name: 'ACCOUNT_PREFERENCES',
        params: {
          tab: uri.segment()[2] || 'general',
          ...params
        }
      };
      resolve(data);
      return;
    }

    reject(data);
  });
}
