export const LOAD = 'SUBSCRIPTION_DETAILS_LOAD';
export const DISCONNECT = 'SUBSCRIPTION_DETAILS_DISCONNECT';

export const INVOICES_LOAD = 'SUBSCRIPTION_INVOICES_LOAD';
export const INVOICES_DISCONNECT = 'SUBSCRIPTION_INVOICES_DISCONNECT';

export const TRANSACTIONS_LOAD = 'SUBSCRIPTION_TRANSACTIONS_LOAD';
export const TRANSACTIONS_DISCONNECT = 'SUBSCRIPTION_TRANSACTIONS_DISCONNECT';

export const BILLING_ADDRESS_LOAD = 'SUBSCRIPTION_BILLING_ADDRESS_LOAD';
export const BILLING_ADDRESS_DISCONNECT = 'SUBSCRIPTION_BILLING_ADDRESS_DISCONNECT';

export const REFUND_REQUEST = 'SUBSCRIPTION_REFUND_REQUEST';
export const REFUND_REQUEST_SUCCESS = 'SUBSCRIPTION_REFUND_REQUEST_SUCCESS';
export const REFUND_REQUEST_FAILED = 'SUBSCRIPTION_REFUND_REQUEST_FAILED';

export const UPDATE_PENDING_INVOICES_COUNT = 'SUBSCRIPTION_UPDATE_PENDING_INVOICES_COUNT';

export const UPDATE_PUBLIC_DOMAIN = 'SUBSCRIPTION_UPDATE_PUBLIC_DOMAIN';

export const RESEND_INVOICE = 'SUBSCRIPTION_RESEND_INVOICE';
export const RESEND_INVOICE_SUCCESS = 'SUBSCRIPTION_RESEND_INVOICE_SUCCESS';
export const RESEND_INVOICE_FAILED = 'SUBSCRIPTION_RESEND_INVOICE_FAILED';

export const PAY_ONLINE_INVOICE = 'SUBSCRIPTION_PAY_ONLINE_INVOICE';
export const PAY_ONLINE_INVOICE_SUCCESS = 'SUBSCRIPTION_PAY_ONLINE_INVOICE_SUCCESS';
export const PAY_ONLINE_INVOICE_FAILED = 'SUBSCRIPTION_PAY_ONLINE_INVOICE_FAILED';

export const UPDATE_BILLING_ADDRESS = 'SUBSCRIPTION_UPDATE_BILLING_ADDRESS';
export const UPDATE_BILLING_ADDRESS_SUCCESS = 'SUBSCRIPTION_UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAILED = 'SUBSCRIPTION_UPDATE_BILLING_ADDRESS_FAILED';

export const CHANGE_PLAN = 'SUBSCRIPTION_CHANGE_PLAN';
export const CHANGE_PLAN_SUCCESS = 'SUBSCRIPTION_CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_FAILED = 'SUBSCRIPTION_CHANGE_PLAN_FAILED';
export const RESET_CHANGE_PLAN_DATA = 'SUBSCRIPTION_RESET_CHANGE_PLAN_DATA';

export const ADD_SUBSCRIPTIONS = 'SUBSCRIPTION_ADD_SUBSCRIPTIONS';
export const ADD_SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTION_ADD_SUBSCRIPTIONS_SUCCESS';
export const ADD_SUBSCRIPTIONS_FAILED = 'SUBSCRIPTION_ADD_SUBSCRIPTIONS_FAILED';
export const RESET_CHANGE_SUBSCRIPTIONS_DATA = 'SUBSCRIPTION_RESET_CHANGE_SUBSCRIPTIONS_DATA';

export const REMOVE_SUBSCRIPTIONS = 'SUBSCRIPTION_REMOVE_SUBSCRIPTIONS';
export const REMOVE_SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTION_REMOVE_SUBSCRIPTIONS_SUCCESS';
export const REMOVE_SUBSCRIPTIONS_FAILED = 'SUBSCRIPTION_REMOVE_SUBSCRIPTIONS_FAILED';

export const RENEW = 'SUBSCRIPTION_RENEW';
export const RENEW_SUCCESS = 'SUBSCRIPTION_RENEW_SUCCESS';
export const RENEW_FAILED = 'SUBSCRIPTION_RENEW_FAILED';

export const REQUEST_NON_PROFIT_PLAN = 'SUBSCRIPTION_REQUEST_NON_PROFIT_PLAN';
export const REQUEST_NON_PROFIT_PLAN_SUCCESS = 'SUBSCRIPTION_REQUEST_NON_PROFIT_PLAN_SUCCESS';
export const REQUEST_NON_PROFIT_PLAN_FAILED = 'SUBSCRIPTION_REQUEST_NON_PROFIT_PLAN_FAILED';
export const RESET_REQUEST_NON_PROFIT_PLAN_DATA = 'SUBSCRIPTION_RESET_REQUEST_NON_PROFIT_PLAN_DATA';

export const COMPUTE_CHARGES = 'SUBSCRIPTION_COMPUTE_CHARGES';
export const COMPUTE_CHARGES_SUCCESS = 'SUBSCRIPTION_COMPUTE_CHARGES_SUCCESS';
export const COMPUTE_CHARGES_FAILED = 'SUBSCRIPTION_COMPUTE_CHARGES_FAILED';
export const RESET_COMPUTE_CHARGES_DATA = 'SUBSCRIPTION_RESET_COMPUTE_CHARGES_DATA';

export const CHANGE_AUTO_RENEW = 'SUBSCRIPTION_CHANGE_AUTO_RENEW';
export const CHANGE_AUTO_RENEW_SUCCESS = 'SUBSCRIPTION_CHANGE_AUTO_RENEW_SUCCESS';
export const CHANGE_AUTO_RENEW_FAILED = 'SUBSCRIPTION_CHANGE_AUTO_RENEW_FAILED';

export const RESET_MANAGE_TEAM_PENDING_ACTION_DATA = 'SUBSCRIPTION_RESET_MANAGE_TEAM_PENDING_ACTION_DATA';
export const UPDATE_MANAGE_TEAM_PENDING_ACTION_DATA =  'SUBSCRIPTION_UPDATE_MANAGE_TEAM_PENDING_ACTION_DATA';

export const MESSAGE_MARK_AS_KNOWN = 'SUBSCRIPTION_MESSAGE_MARK_AS_KNOWN';
/**
 * Dispatch action to load current account subscription details.
 */
export const load = (requesterId) => {
  return {
    type: LOAD,
    requesterId
  }
}

/**
 * Dispatch action to stop live listening subscription details for given `requestId`.
 */
export const disconnect = (requesterId) => {
  return {
    type: DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to load current account invoices details.
 */
 export const loadInvoices = (requesterId) => {
  return {
    type: INVOICES_LOAD,
    requesterId
  }
}

/**
 * Dispatch action to stop live listening invoices details for given `requestId`.
 */
export const disconnectInvoices = (requesterId) => {
  return {
    type: INVOICES_DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to load current account transactions details.
 */
export const loadTransactions = (requesterId) => {
  return {
    type: TRANSACTIONS_LOAD,
    requesterId
  }
}

/**
 * Dispatch action to stop live listening transactions details for given `requestId`.
 */
export const disconnectTransactions = (requesterId) => {
  return {
    type: TRANSACTIONS_DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to load current account billing address details.
 */
 export const loadBillingAddress = (requesterId) => {
  return {
    type: BILLING_ADDRESS_LOAD,
    requesterId
  }
}

/**
 * Dispatch action to stop live listening billing address for given `requestId`.
 */
export const disconnectBillingAddress = (requesterId) => {
  return {
    type: BILLING_ADDRESS_DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to intiate a refund request.
 */
export const refundRequest = () => {
  return {
    type: REFUND_REQUEST
  }
}

export const _refundRequestSuccess = () => {
  return {
    type: REFUND_REQUEST_SUCCESS
  }
}

export const _refundRequestFailed = (error) => {
  return {
    type: REFUND_REQUEST_FAILED,
    error
  }
}

/**
 * Dispatch action to update pending invoices count.
 */
export const updatePendingInvoicesCount = (count) => {
  return {
    type: UPDATE_PENDING_INVOICES_COUNT,
    count
  }
}

/**
 * Dispatch action to update current user domain is public or not.
 */
export const updatePublicDomain = (value) => {
  return {
    type: UPDATE_PUBLIC_DOMAIN,
    value
  }
}

/**
 * Resend given invoice to current account billing contacts.
 * @param {String} invoiceId
 */
export const resendInvoice = (invoiceId) => {
  return {
    type: RESEND_INVOICE,
    invoiceId
  }
}

export const _resendInvoiceSuccess = (invoiceId) => {
  return {
    type: RESEND_INVOICE_SUCCESS,
    invoiceId
  }
}

export const _resendInvoiceFailed = (invoiceId, error) => {
  return {
    type: RESEND_INVOICE_FAILED,
    invoiceId,
    error
  }
}

/**
 * Pay online given invoiceId using give token
 * @param {Number} invoiceId
 * @param {String} token payment getway token
 * @param {Number} amount invoice amount.
 */
export const payOnlineInvoice = (invoiceId, token, amount) => {
  return {
    type: PAY_ONLINE_INVOICE,
    invoiceId,
    token,
    amount
  }
}

export const _payOnlineInvoiceSuccess = (invoiceId) => {
  return {
    type: PAY_ONLINE_INVOICE_SUCCESS,
    invoiceId
  }
}

export const _payOnlineInvoiceFailed = (invoiceId, error) => {
  return {
    type: PAY_ONLINE_INVOICE_FAILED,
    invoiceId,
    error
  }
}

/**
 * Dispatch action to update billing address of current account.
 * @param {Object} billingAddress
 *  - @property {Number} accountId Id of account.
 *  - @property {String} organization
 *  - @property {String} phone
 *  - @property {String} country
 *  - @property {String} state
 *  - @property {String} address
 *  - @property {String} city
 *  - @property {String} zip
 *  - @property {Boolean} washingtonUser
 *  - @property {Array} billingEmails Lists of billing address.
 */
export const updateBillingAddress = (billingAddress) => {
  return {
    type: UPDATE_BILLING_ADDRESS,
    billingAddress
  }
}

export const _updateBillingAddressSuccess = () => {
  return {
    type: UPDATE_BILLING_ADDRESS_SUCCESS
  }
}

export const _updateBillingAddressFailed = (error) => {
  return {
    type: UPDATE_BILLING_ADDRESS_FAILED,
    error
  }
}

/**
 * Dispatch action to change a plan for current account.
 * @param {Enum} plan give a new plan name. Possible values: 'SOLO', 'PROFESSIONAL'.
 * @param {Number} subscriptions give a subscriptions.
 * @param {String} token give a online payement token.
 * @param {Boolean} requestInvoice give true when change plan thorugh offline invoice.
 * @param {Boolean} autoRenew New plan is automatically renew or not.
 */
export const changePlan = ({plan, subscriptions, token, requestInvoice, autoRenew, amount}) => {
  return {
    type: CHANGE_PLAN,
    plan,
    subscriptions,
    token,
    requestInvoice,
    autoRenew,
    amount
  }
}

export const _changePlanFailed = ({plan, error, message}) => {
  return {
    type: CHANGE_PLAN_FAILED,
    plan,
    error,
    message
  }
}

export const _changePlanSuccess = ({plan, offlineInvoicePaymentDuedate, oldPlan, accountId}) => {
  return {
    type: CHANGE_PLAN_SUCCESS,
    plan,
    offlineInvoicePaymentDuedate,
    oldPlan,
    accountId
  }
}

/**
 * Dispatch this action to reset change plan data.
 */
export const resetChangePlan = () => {
  return {
    type: RESET_CHANGE_PLAN_DATA
  }
}

/**
 * Dispatch action to request a non profit plan.
 */
 export const requestNonprofitPlan = () => {
  return {
    type: REQUEST_NON_PROFIT_PLAN
  }
}

export const _requestNonprofitPlanSuccess = ({oldPlan, plan, accountId}) => {
  return {
    type: REQUEST_NON_PROFIT_PLAN_SUCCESS,
    oldPlan,
    plan,
    accountId
  }
}

export const _requestNonprofitPlanFailed = (error) => {
  return {
    type: REQUEST_NON_PROFIT_PLAN_FAILED,
    error
  }
}

/**
 * Dispatch this action to reset non-profit plan data.
 */
export const resetRequestNonprofitPlanData = () => {
  return {
    type: RESET_REQUEST_NON_PROFIT_PLAN_DATA
  }
}

/**
 * Dispatch actions to compute a charges for given subscriptions and given type.
 */
export const computeCharges = (subscriptionsType, subscriptions, downgrade, renew) => {
  return {
    type: COMPUTE_CHARGES,
    subscriptionsType,
    subscriptions,
    downgrade,
    renew
  }
}

export const _computeChargesSuccess = ({subscriptionsType, subscriptions, taxAmount, paymentAmount, expirationDate}) => {
  return {
    type: COMPUTE_CHARGES_SUCCESS,
    subscriptionsType,
    subscriptions,
    taxAmount,
    paymentAmount,
    expirationDate
  }
}

export const _computeChargesFailed = ({subscriptionsType, subscriptions, error}) => {
  return {
    type: COMPUTE_CHARGES_FAILED,
    subscriptionsType,
    subscriptions,
    error
  }
}

/**
 * Dispatch this action to reset compute charges data.
 */
export const resetComputeChargesData = () => {
  return {
    type: RESET_COMPUTE_CHARGES_DATA
  }
}

/**
 * Dispatch actionn to change auto renew for current account.
 */
export const changeAutoRenew = (autoRenew) => {
  return {
    type: CHANGE_AUTO_RENEW,
    autoRenew
  }
}

export const _changeAutoRenewSuccess = (autoRenew) => {
  return {
    type: CHANGE_AUTO_RENEW_SUCCESS,
    autoRenew
  }
}

export const _changeAutoRenewFailed = (autoRenew, code) => {
  return {
    type: CHANGE_AUTO_RENEW_FAILED,
    autoRenew
  }
}

/**
 * Dispatch this action to reset pending action data.
 */
export const resetPendingActionData = () => {
  return {
    type: RESET_MANAGE_TEAM_PENDING_ACTION_DATA
  }
}

/**
 * Dispatch thi action to update pending action data.
 */
export const updatePendingActionData = (data) => {
  return {
    type: UPDATE_MANAGE_TEAM_PENDING_ACTION_DATA,
    data
  }
}

/**
 * Dispatch this action to buy additional subscriptions.
 */
export const addSubscriptions = ({subscriptions, token, requestInvoice, autoRenew, amount}) => {
  return {
    type: ADD_SUBSCRIPTIONS,
    subscriptions,
    token,
    requestInvoice,
    autoRenew,
    amount
  }
}

export const _addSubscriptionsFailed = ({error, message, subscriptions}) => {
  return {
    type: ADD_SUBSCRIPTIONS_FAILED,
    error,
    message,
    subscriptions
  }
}

export const _addSubscriptionsSuccess = (offlineInvoicePaymentDuedate) => {
  return {
    type: ADD_SUBSCRIPTIONS_SUCCESS,
    offlineInvoicePaymentDuedate
  }
}

/**
 * Dispatch this action to reset change subscriptions data.
 */
 export const resetChangeSubscriptionsData = () => {
  return {
    type: RESET_CHANGE_SUBSCRIPTIONS_DATA
  }
}


/**
 * Dispatch this action to buy additional subscriptions.
 */
 export const removeSubscriptions = ({subscriptions, autoRenew}) => {
  return {
    type: REMOVE_SUBSCRIPTIONS,
    subscriptions,
    autoRenew
  }
}

export const _removeSubscriptionsFailed = (error) => {
  return {
    type: REMOVE_SUBSCRIPTIONS_FAILED,
    error
  }
}

export const _removeSubscriptionsSuccess = () => {
  return {
    type: REMOVE_SUBSCRIPTIONS_SUCCESS
  }
}

/**
 * Dispatch this action to renew expired plan subscriptions.
 */
export const renew = ({subscriptions, token, requestInvoice, autoRenew, amount}) => {
  return {
    type: RENEW,
    subscriptions,
    token,
    requestInvoice,
    autoRenew,
    amount
  }
}

export const _renewFailed = ({error, message, subscriptions}) => {
  return {
    type: RENEW_FAILED,
    error,
    message,
    subscriptions
  }
}

export const _renewSuccess = (offlineInvoicePaymentDuedate) => {
  return {
    type: RENEW_SUCCESS,
    offlineInvoicePaymentDuedate
  }
}

/**
 * Dispatch action when user given subscription message is known.
 */
export const messageMarkAsKnown = (name, accountId) => {
  return {
    type: MESSAGE_MARK_AS_KNOWN,
    name,
    accountId
  }
}