import isEmpty from "lodash-es/isEmpty.js";
import forEach from "lodash-es/forEach.js";
import getNewIds from '../../../get-new-ids.js';
import {
  isLeaveAppDonePage,
  isBlankPage,
  isAuthorizeFileStorePage,
  isApplicationRootPage,
  isDesktopHomePage,
  isViewPage,
  isPreferredStoreSelectionPage,
  isNotFoundPage,
  isNotSupportedPage,
  isTestPage
} from './validate-url.js';

/**
 * This promise function is reject with parse v4 url.
 */
export const convertV2V3ToV4 = (data) => {
  return new Promise(async function(resolve, reject) {
    const isV2Url = data.path.startsWith('/app');
    if(isV2Url) {
      data.convertV2V3ToV4 = true;
      data.redirect = data.path.replace('/app', '');;
      resolve(data);
      return;
    }

    if (isLeaveAppDonePage(data.path) || isBlankPage(data.path) ||
        isAuthorizeFileStorePage(data.path) || isPreferredStoreSelectionPage(data.path) ||
        isApplicationRootPage(data.path) || isViewPage(data.path) || isDesktopHomePage(data.path) ||
        isNotFoundPage(data.path) || isNotSupportedPage(data.path) || isTestPage(data.path)) {
          reject(data);
          return;
    }

    const ignorePathRegex = new RegExp(/^\/(acc_[a-zA-Z0-9]+)\/.*/);
    if(ignorePathRegex.test(data.path)) {
      reject(data);
      return;
    }

    const path  = data.path && data.path.startsWith('/') ? data.path.replace('/', '') : data.path || '';
    const ids = path ? path.split("/"): [];
    if(isEmpty(ids)) {
      reject(data);
      return;
    }

    const newIdsMap = await getNewIds(ids);
    if(isEmpty(newIdsMap)) {
      reject(data);
      return;
    }

    const newIds = [];
    forEach(ids, (id)=> {
      if(id) {
        newIds.push(newIdsMap && newIdsMap[id] ? newIdsMap[id]: id);
      }
    });

    const newPath = newIds.join("/");
    if(newPath == data.path) {
      reject(data);
      return;
    }

    data.convertV2V3ToV4 = true;
    data.redirect = newPath;
    resolve(data);
  });
}