import { takeEvery, call, select, all, take } from 'redux-saga/effects';
import * as actions from './actions';
import * as auth from '../auth/index.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../utils';

/**
 * Marks dowaload dialog is presented to user.
 */
function* markAsKnown() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  try {
    firestoreRedux.save(`users/${userId}/user-ui`, {
      id: `dad_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      type: 'download-app-dialog',
      userId,
      presented: true,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    console.error("download-app > markAsKnown > failed due to this: ", error);
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([takeEvery(actions.MARK_AS_KNOWN, markAsKnown)]);
}

export default saga;
