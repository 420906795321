import { takeEvery, select } from 'redux-saga/effects'
/**
 * Load redux module based on pageName.
 */
function* loadModule() {
  const state = yield select();
  if (state.router.page.name === 'BOARD_EXPLORER' || state.router.page.name === 'SIGNUP') {
    import('../board-explorer');
  }

  if (state.router.page.name === 'SIGNUP') {
    import('../signup');
  }

  if (state.router.page.name === 'MANAGE_ACCOUNT') {
    import('../manage-account');
  }

  if (state.router.page.name === 'ACCOUNT_PREFERENCES') {
    import('../account-preferences');
  }

  if (state.router.page.name === 'VIEWS') {
    import('../views');
  }
}

/*
  Starts moduleLoader on each dispatched `UPDATE_ROUTER` action.
*/
function* moduleLoader() {
  yield takeEvery("UPDATE_ROUTER", loadModule);
}

export default moduleLoader;