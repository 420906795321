import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import uniqWith from 'lodash-es/uniqWith';
import isEqual from 'lodash-es/isEqual';
import findIndex from 'lodash-es/findIndex';
import find from 'lodash-es/find';
import without from 'lodash-es/without';

import * as app from '../../app';
import * as auth from '../../auth';
import * as router from '../../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as multipleLanguage from '../../multiple-language';
import * as selectorCreator from '../../selector-creator.js';
import * as boardExplorerSelectors from '../selectors.js';

import { getLanguage } from '../../../components/i18next.js';
import { getIdWoPrefix } from '../../../utils';

/**
 * @param {Number} acId - Given account id data is loaded or not.
 */
export const dataLoaded = selectorCreator.reselct.createSelector(
  (state, accountId) => templates({state, accountId, currentLangFilter: false}),
  (templates) => {
    return !isEmpty(templates);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * Currently any account data loading process is running or not.
 */
export const dataLoading = selectorCreator.reselct.createSelector(
  dataLoaded,
  (state, accountId) => get(state, 'board-explorer.createBoard.dataLoading'),
  (dataLoaded, loading) => {
    return loading && !dataLoaded;
  }
);

/**
 * @returns {Array} sorted template list by it's type.
 *  e.g. [{id, name, accountId, ownerId, category, description, type }, ...]
 */
export const templates = selectorCreator.default({maxSize: 300})(
  ({ state, accountId, type, currentLangFilter }) => accountId,
  ({ state, accountId, type, currentLangFilter }) => type || 'TASKBOARD',
  ({ state, accountId, type, currentLangFilter }) => currentLangFilter,
  ({ state, accountId, type, currentLangFilter }) => boardExplorerSelectors.currentLangFilterAvailable(state),
  ({ state, accountId, type, currentLangFilter }) => app.selectors.templateAcId(state),
  ({ state, accountId, type, currentLangFilter }) => firestoreRedux.selectors.collection(state, 'boards'),
  ({ state, accountId, type, currentLangFilter }) => multipleLanguage.selectors.defaultLanguage(state),
  ({ state, accountId, type, currentLangFilter }) => multipleLanguage.selectors.currentLanguage(state),
  ({ state, accountId, type, currentLangFilter }) => boardExplorerSelectors.templateFilterLangList(state),
  ({ state, accountId, type, currentLangFilter }) => auth.selectors.currentUserTemplateAcOwner(state),
  (accountId, type, currentLangFilter, currentLangFilterAvailable, templateAccId, allBoards, defaultLanguage, currentLanguage, templateFilterLangList, templateAcOwner) => {
    type = type || 'TASKBOARD';
    defaultLanguage = defaultLanguage || 'en';
    currentLanguage = currentLanguage || defaultLanguage;
    const removeLanguage = templateAcOwner || currentLanguage === defaultLanguage ? '': currentLangFilterAvailable ? defaultLanguage: '';
    const langList = currentLangFilter ? without(templateFilterLangList, removeLanguage): [...templateFilterLangList, ...[defaultLanguage]];

    const allTemplates = [];
    forEach(allBoards, (board) => {
      if (board && board.type === type && board.template === true && (board.accountId === accountId || board.accountId === templateAccId)) {
        const boardLang = board.lang || defaultLanguage;
        if(langList.includes(boardLang)) {
          allTemplates.push(board);
        }
      }
    });

    return sortBy(allTemplates, ['name']);
  }
);

/**
 * Last used template id for a create a new board.
 */
export const templateId = (state, accountId) => {
  const templateId = router.selectors.templateId(state);
  const type = defaultBoardType(state, accountId);
  const userId = auth.selectors.currentUserId(state);
  const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
  const createBoardPreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-board-preferences' }) || {};
  const lastUsedTemplateId = get(createBoardPreference, `lastUsedTemplate.${type}`);
  const defaultTemplateId =
    type === 'WHITEBOARD' ? app.selectors.defaultTemplateWhiteBoardId(state) : app.selectors.defaultTemplateTaskBoardId(state);
  const defaultTemplateTaskBoardId = app.selectors.defaultTemplateTaskBoardId(state);
  return templateId || lastUsedTemplateId || defaultTemplateId || defaultTemplateTaskBoardId;
};

export const createInProgress = (state) => get(state, 'board-explorer.createBoard.createInProgress');

/**
 * @returns {Boolean} `true` when user can change the template in create board dialog.
 */
export const canChangeTemplate = (state) => !router.selectors.templateId(state);

/**
 *
 * @returns {Boolean} `true` when user can change the account in create board dialog.
 */
export const canChangeAccount = (state) => {
  if (app.selectors.wideLayout(state) && canChangeTemplate(state)) {
    const writableAccessibleAccounts = auth.selectors.writableAccessibleAccounts(state);
    return writableAccessibleAccounts && writableAccessibleAccounts.length > 1;
  }

  if (!app.selectors.wideLayout(state) && canChangeTemplate(state)) {
    return false;
  }

  let templateId = router.selectors.templateId(state);
  let templateDetail = firestoreRedux.selectors.doc(state, 'boards', templateId);

  if (isEmpty(templateDetail)) {
    return false;
  }

  if (templateDetail.privacy === 'PUBLIC') {
    return true;
  }

  return false;
};

export const createBoardAccountId = (state) => get(state, 'board-explorer.createBoard.lastLoadedAc');

/**
 *
 * @param {Object} state redux state
 * @returns {Number} last used accountId for create board.
 */
export const createBoardLastUsedAccountId = (state) => {
  const userId = auth.selectors.currentUserId(state);
  const userUi = firestoreRedux.selectors.doc(state, 'user-ui', `ucbp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`);
  const routerAccountId = router.selectors.accountId(state);
  const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
  const accessibleAccounts = auth.selectors.writableAccessibleAccounts(state);
  return get(userUi, `lastUsedAccount`) || routerAccountId || get(ownedAccounts, '0', '') || get(accessibleAccounts, '0', '') || '';
};

/**
 * @returns {String} given account default privacy value.
 */
export const boardPrivacy = (state, accountId) => {
  accountId = accountId || router.selectors.accountId(state);
  const accSettings = firestoreRedux.selectors.doc(state, 'account-settings', `as_${accountId.replace('acc_', '')}`);
  const defaultAccSettings = firestoreRedux.selectors.doc(state, 'account-settings', `default`);
  return get(accSettings, `boardPrivacy`, get(defaultAccSettings, `boardPrivacy`, 'PRIVATE'));
};

/**
 * @param {Object} state redux state.
 * @returns {String} last used boardType by this user, or TASKBOARD as fallback.
 */
export const defaultBoardType = (state, accountId) => {
  accountId = accountId || router.selectors.accountId(state);
  const userId = auth.selectors.currentUserId(state);
  let actionType = get(state, 'router.page.params.action-type');
  actionType = actionType === 'W' ? 'WHITEBOARD': actionType === 'T' ? 'TASKBOARD': '';
  const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
  const createBoardPreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-board-preferences' }) || {};
  const lastUsedType = get(createBoardPreference, `lastUsedType`, 'TASKBOARD');
  return actionType || lastUsedType || "TASKBOARD"
};

/**
 * @param {Number} accountId
 * @param {String} type
 * @returns {Array} categories list for given account and only for given type.
 *  e.g. ['Software development', 'other]
 */
export const categories = selectorCreator.default( {maxSize: 50})(
  ({ state, accountId, type, language, currentLangFilter }) => templates({ state, accountId, type, currentLangFilter }),
  ({ state, accountId, type, language, currentLangFilter }) => app.selectors.templateCategory(),
  (allTemplates, templateCategoryOrder) => {
    let categories = [];
    forEach(allTemplates, (value) => {
      forEach(templateCategoryOrder, (item) => {
        if (item.name === value.templateCategory) {
          categories.push({ name: get(item, 'name'), value: get(item, `title`) || get(item, 'name') });
        }
      });
    });

    return sortBy(uniqWith(categories, isEqual), (item) => {
      return findIndex(templateCategoryOrder, function (o) {
        return o.name === item.name;
      });
    });
  }
);

/**
 * @returns {Array} templates list based on given accountId and category and board-type.
 */
export const categoryWiseTemplates = selectorCreator.default({maxSize: 50})(
  ({ state, accountId, category, type, currentLangFilter }) => category,
  ({ state, accountId, category, type, currentLangFilter }) => templates({ state, accountId, type, currentLangFilter }),
  (category, allTemplates) => {
    let templateModels = [];
    forEach(allTemplates, (value) => {
      if (value.templateCategory === category) {
        templateModels.push(value);
      }
    });

    return sortBy(templateModels, ['name']);
  }
);

/**
 * Currently any account data loading process is running or not.
 */
 export const templateDataLoading = (state) => {
  return get(state, 'board-explorer.createTemplate.dataLoading', true);
 };

 /**
 * @param {Object} state redux state
 * @returns {String} last used accountId for create template.
 */
  export const createTemplateLastUsedAccountId = (state) => {
    const userId = auth.selectors.currentUserId(state);
    const userUiTemplate = firestoreRedux.selectors.doc(state, 'user-ui', `uctp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`);
    const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
    const accessibleAccounts = auth.selectors.writableAccessibleAccounts(state);
    return get(userUiTemplate, `lastUsedAccount`, '') || get(ownedAccounts, `0`, '') || get(accessibleAccounts, '0', '');
  };

  /**
   * @param {Object} state redux state.
   * @param {String} accountId Account Id.
   * @returns {String} last used category for create template. Default is `Design`.
   */
  export const createTemplateCategory = (state, accountId) => {
    const userId = auth.selectors.currentUserId(state);
    const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
    const createTemplatePreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-template-preferences' }) || {};
    return get(createTemplatePreference, `lastUsedCategory`, 'Design');
  };

  /**
   * @param {Object} state redux state.
   * @param {String} accountId Account Id.
   * @returns {String} last used type for create template. Default is `TASKBOARD`.
   */
  export const createTemplateType = (state, accountId) =>  {
    let actionType = get(state, 'router.page.params.action-type');
    actionType = actionType === 'W' ? 'WHITEBOARD' : actionType === 'T' ? 'TASKBOARD' : '';

    const userId = auth.selectors.currentUserId(state);
    const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
    const createTemplatePreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-template-preferences' }) || {};
    return  actionType || get(createTemplatePreference, `lastUsedType`, 'TASKBOARD');
  };

  /**
   * @param {Object} state redux state.
   * @returns {Boolean} create template is in progess or not.
   */
  export const createTemplateInProgress = (state) => get(state, 'board-explorer.createTemplateInProgress');
