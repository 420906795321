import * as app from '../app';
import * as router from '../router';
import get from 'lodash-es/get';
import { requestApi } from '../../request-api';
import { isTouchDevice } from '@dreamworld/web-util';
import i18next from '@dw/i18next-esm';

export const UPDATE_LATEST_VERSION = 'UPDATE_LATEST_VERSION';
export const UPDATE_APP_CONNECTION_STATUS = 'UPDATE_APPLICATION_CONNECTION_STATUS';
export const UPDATE_LAST_CONNECTION_FAIL_TIME = 'UPDATE_LAST_CONNECTION_FAIL_TIME';
export const UPDATE_WIDE_LAYOUT = 'UPDATE_WIDE_LAYOUT';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const UPDATE_TOUCH_DEVICE = 'UPDATE_TOUCH_DEVICE';
export const UPDATE_SERVICE_WORKER_UPDATE_STATUS =
  'UPDATE_SERVICE_WORKER_UPDATE_STATUS';
export const ORIENTATION_CHANGED = 'ORIENTATION_CHANGED';
export const LOAD_APP_DATA = 'APP_LOAD_APP_DATA';
export const LOAD_USE_CASE_BOARDS = 'APP_LOAD_USE_CASE_BOARDS';

/**
 * Internal action. (In installed app)
 * Dispatched when cordova plugin is ready.
 */
export const DEVICE_READY = 'APP_DEVICE_READY';

export const updateLatestVersion = (latestVersion) => {
  return {
    type: UPDATE_LATEST_VERSION,
    latestVersion,
  };
};

/**
 * @param {Boolean} status
 * Update status of application is connected to server or not based on given `status`.
 */
export const updateAppConnectionStatus = (status) => {
  return {
    type: UPDATE_APP_CONNECTION_STATUS,
    status,
  };
};

/**
 * Updates last connection failure time.
 */
export const updateLastConnectionFailTime = () => {
  return {
    type: UPDATE_LAST_CONNECTION_FAIL_TIME,
    value: Date.now(),
  };
};

/**
 * Dispatch this action when device orientation is changed.
 */
export const orientationChanged = (orientation) => {
  return {
    type: ORIENTATION_CHANGED,
    orientation,
  };
};

export const setPageTitle = (title) => {
  return {
    type: SET_PAGE_TITLE,
    title,
  };
};

export const updateLayout = (wide) => (dispatch) => {
  dispatch({
    type: UPDATE_WIDE_LAYOUT,
    wide,
  });
};

/**
 * Detect that current device is touch or non touch.
 */
export const detectTouchDevice = () => {
  return {
    type: UPDATE_TOUCH_DEVICE,
    value: isTouchDevice(),
  };
};

/**
 * Loads app level data.
 */
export const loadAppData = () => {
  return {
    type: LOAD_APP_DATA
  }
}

/**
 * Loads the use case boards.
 */
export const loadUseCaseBoards = () => {
  return {
    type: LOAD_USE_CASE_BOARDS
  }
}

/**
 * Reload page.
 */
export const reload = () => {
  window.location.reload();
};

/**
 * Called server API to inform system admin to the user some help for the application.
 * Also send extra detail to system admin like accountId, browser name, device, device os, page-name and url.
 * @param {String} message Give a message to send a system admin.
 * @returns {Promise}
 */
export const getHelp = (message) => (dispatch, getState) => {
  const state = getState();
  const bowser = get(state, 'app.bowser');
  const accountId = router.selectors.accountId(state) || undefined;
  const boardId = router.selectors.pageBoardId(state);
  const config =  app.selectors.config(state);
  const pwaVersion = config && config.version || '';
  const isInstalledApp = app.selectors.isInstalledApp(state);
  const installedAppVersion = app.installedApp.selectors.version(state);
  const body = {
    message,
    accountId,
    boardId,
    pageName: document.title,
    pageUrl: window.location.href,
    browser: `${bowser.browser.name} ${bowser.browser.version}`,
    os: `${bowser.os.name} ${bowser.os.version}`,
    device: bowser.platform.type,
    pwaVersion
  };

  if(isInstalledApp) {
    body.installedAppVersion = installedAppVersion;
  }

  return requestApi('/user/contact-us', {
    method: 'POST',
    body
  });
};

/**
 * This can be used when text resources required in redux mulule on page load.
 * e.g Link additional auth for Box + Installed app
 * @returns {Promise} resoves when i18next is initialized.
 */
export const initI18n = () => {
  if (i18next.isInitialized) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    i18next.on('initialized', () => {
      resolve();
    });
  });
};

export const deviceReady = () => {
  return {
    type: DEVICE_READY
  };
}
