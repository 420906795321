import { UPDATE_ROUTER, UPDATE_PRIVATE_BOARD } from './actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';

/**
 * Page reducer
 * Handle `UPDATE_ROUTER` action to update `router` data on redux store.
 */
let initialState = {
  page: {
    name: 'LOADING',
    params: {}
  },
  dialog: {
    name: '',
    params: {}
  },
  accountId: 0
}
export const routerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROUTER:
      return {
        ...state,
        accountId: action.router.accountId,
        page: {
          ...state.page,
          name: action.router.page.name,
          params: {
            ...action.router.page.params
          }
        },
        dialog: {
          ...state.dialog,
          name: action.router.dialog.name,
          params: {
            ...action.router.dialog.params
          }
        }
      }
    case UPDATE_PRIVATE_BOARD:
      return ReduxUtils.replace(state, `page.params.private`, action.value);
    default:
      return state;
  }
};