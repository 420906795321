import { takeEvery, select, all, call, put } from 'redux-saga/effects';
import * as app from '../app/index.js';
import * as auth from '../auth/index.js';
import * as router from '../router/index.js';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as amplitude from '../../analytics/amplitude.js';
import URI from '@dw/urijs-esm/urijs-esm.js';
import { store } from '../../store';
import { requestApi, isNetworkError } from '../../request-api.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import authorizeStore from './authorize-store.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../utils.js';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';
import { show as showToast } from '../../components/kerika-snackbar.js';
import i18next from '@dw/i18next-esm';

/**
 * Opens `file-store-accessed-revoked` dialog.
 */
function* openFileStoreAccessRevokedDialog() {
  router.actions.setQueryParams({'attachment-action': 'file-store-access-revoked'}, true);
}

/**
 * Open `prefferred-store-selection-dialog`.
 */
let preferredStoreSelectionPendingAction;
function* preferredStoreSelectionPending(action) {
  router.actions.setQueryParams({'attachment-action': 'prefferred-store-selection'}, true);
  preferredStoreSelectionPendingAction = action.callback ? action.callback: function(){};
}

/**
 * select a preferred store selection.
 */
function* preferredStoreSelection(action) {
  const state = yield select();
  const pageName = router.selectors.pageName(state);
  const accountId = action.accountId;
  const storage = action.storage;
  const service = storage === 'BOX' ? 'box': storage === 'KERIKA' ? 'kerika': storage === 'MICROSOFT' ? 'microsoft': 'google';
  const token = action.token;
  const inSameWindow = action.inSameWindow;
  const reAuthorizationAlreadyCompleted = action.reAuthorizationAlreadyCompleted;
  let email = action.email;
  let authEmail = action.authEmail;
  const withoutPrefixAccountId = getIdWoPrefix({ id: accountId, prefix: 'acc_' });
  const currentDoc = firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${withoutPrefixAccountId}`) || {};
  const id = currentDoc && currentDoc.id || `acs_${withoutPrefixAccountId}`;
  const newDoc = { ...currentDoc, ...{id, preferredStoreSelectionDone: true} };
  const oauth2LoginUsingNativeFlow = app.selectors.oauth2LoginUsingNativeFlow(state, service);
  try {
    firestoreRedux.save(`accounts/${accountId}/account-cloud-stores`, newDoc, { localWrite: true });
    if(storage !== 'KERIKA') {
      let response = {email, authEmail};
      if(!reAuthorizationAlreadyCompleted) {
        const installedApp = app.selectors.isInstalledApp(state);
        if(oauth2LoginUsingNativeFlow) {
          response = yield call(reauthorizeInstalledApp, {service, token, accountId, email});
        } else {
          response = yield call(authorizeStore, {service, token, accountId, inSameWindow, email, installedApp});
        }

        authEmail = response && response.authEmail;
        email = response && response.email || email;
        if(!inSameWindow) {
          amplitude.logEvent('preferred store selection cloud-store authorization completed', {'account_id': accountId});
        }

        if(!inSameWindow && authEmail && email && email != authEmail) {
          yield put(actions._preferredStoreSelectDone({accountId, storage, status: 'DIFFERENT_EMAIL', email, authEmail}));
          return;
        }
      }
      yield call(waitTillCloudStoresIsAuthorized, accountId, storage, token);
    }

    yield call(requestApi, `/file-store/account-cloud-store/${accountId}/change-preferred-store`, { method: 'PUT', body: { preferredStore: storage, token }});
    if(!inSameWindow && !token && storage !== 'KERIKA') {
      yield call(waitTillChangePreferredStoreCompeleted, accountId, storage);
    }

    //Call pending action
    if(preferredStoreSelectionPendingAction && typeof preferredStoreSelectionPendingAction === 'function') {
      preferredStoreSelectionPendingAction();
    }

    router.actions.setQueryParams(oauth2LoginUsingNativeFlow ? {'authentication-in-same-window': undefined, 'auth-email': undefined, 'status': undefined, 'attachment-action': undefined, 'service': undefined}: {'authentication-in-same-window': undefined, 'auth-email': undefined, 'status': undefined});
    yield put(actions._preferredStoreSelectDone({accountId, storage}));
  } catch (error) {
    id && firestoreRedux.delete(`accounts/${accountId}/account-cloud-stores`, id, { localWrite: true });
    const code =  error && error.code || 'UNKNOWN';

    if(code === 'EMAIL_NOT_AVAILABLE') {
      router.actions.setQueryParams({ 'attachment-sub-action': 'auth-identity-not-found', 'storage': storage, 'attachment-action': undefined }, pageName === 'PREFERRED_STORE_SELECTION');
      return;
    }

    yield put(actions._preferredStoreSelectFailed({accountId, storage, error: code}));
    if(isNetworkError(error)) {
      return;
    }

    if(code === 'CANCELED') {
      amplitude.logEvent('preferred store selection cloud-store authorization cancelled', {'account_id': accountId});
      if(pageName == 'PREFERRED_STORE_SELECTION') {
        showToast({message: `${storage === 'KERIKA' ? 'kerika': storage === 'BOX' ? 'box': storage === 'MICROSOFT' ? 'microsoft': 'google'} authentication process is cancelled`});
      }
      return;
    }

    if(code === 'DRIVE_SETUP_PENDING') {
      router.actions.setQueryParams({'attachment-sub-action': 'drive-setup-pending', 'storage': storage}, true);
      return;
    }

    console.error("Preferred store selection is failed, due to this:", error);
    if(error && error.authFailed) {
      showToast({message: code, type: 'ERROR'});
    }
  }
}

/**
 * Wait till change preferred store is complete.
 */
function waitTillChangePreferredStoreCompeleted(accountId, storage) {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const woPrefixAccountId = getIdWoPrefix({ id: accountId, prefix: 'acc_' });

  const firestorePath = `firestore.docs.account-cloud-stores.acs_${woPrefixAccountId}`;
  const unsubscribe = ReduxUtils.subscribe(store, firestorePath, (doc)=> {
    const preferredStore = get(doc, 'preferredStore');
    const nextPreferredStore = get(doc, 'nextPreferredStore');
    if(preferredStore == storage && !nextPreferredStore) {
      unsubscribe && unsubscribe();
      resolve();
    }
  });
  return promise;
}

/**
 * Wait till change preferred store is complete.
 */
function waitTillCloudStoresIsAuthorized(accountId, service, token) {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  let lastAbortControllerData;
  let intervalId = setInterval(async() => {
    try {
      // lastAbortControllerData && lastAbortControllerData.abort && lastAbortControllerData.abort();
      lastAbortControllerData = new AbortController();
      const apiUrl = token ? `/file-store/cloud-stores/${service}/is-authorized?accountId=${accountId}&token=${token}`: `/file-store/cloud-stores/${service}/is-authorized?accountId=${accountId}`
      const response = await requestApi(apiUrl, {excludeErrors: [504], signal: lastAbortControllerData.signal});
      if(response && response.authorized) {
        clearInterval(intervalId);
        intervalId = null;
        resolve();
      }
    } catch (error) {}
  }, 2000);

  setTimeout(() => {
    if (intervalId) {
      clearInterval(intervalId);
      reject({code: 'CLOUD_STORES_IS_NOT_AUTHORIZED'});
    }
  }, 30000);
  return promise;
}

/**
 * check preferred store selection already made or not.
 */
function* checkPrefferedStoreSelection(action) {
  const accountId = action.accountId;
  const token = action.token;
  const storage = action.storage;
  try {
    const response = yield call(requestApi, `/file-store/account-cloud-store/${accountId}/preferred-store-selection?token=${token}`);
    yield put(actions._checkPrefferedStoreSelectionDone({accountId, token, storage, data: response}));
  } catch (error) {
    yield put(actions._checkPrefferedStoreSelectionFailed({accountId, token, storage, error: error && error.code || 'UNKNOWN'}));
  }
}

/**
 * Change a preferred store.
 */
function* changePrefferedStore(action) {
  const state = yield select();
  const accountId = action.accountId;
  const storage = action.storage;
  const service = storage === 'BOX' ? 'box': storage === 'KERIKA' ? 'kerika': storage === 'MICROSOFT' ? 'microsoft': 'google';
  const token = action.token;
  const inSameWindow = action.inSameWindow;
  const reAuthorizationAlreadyCompleted = action.reAuthorizationAlreadyCompleted;
  let email = action.email;
  let authEmail = action.authEmail;
  const oauth2LoginUsingNativeFlow = app.selectors.oauth2LoginUsingNativeFlow(state, service);
  try {
    if(storage !== 'KERIKA') {
      let response = {email, authEmail};
      if(!reAuthorizationAlreadyCompleted) {
        const installedApp = app.selectors.isInstalledApp(state);
        if(oauth2LoginUsingNativeFlow) {
          response = yield call(reauthorizeInstalledApp, {service, token, accountId, email});
        } else {
          response = yield call(authorizeStore, {service, token, accountId, inSameWindow, email, installedApp});
        }

        authEmail = response && response.authEmail;
        email = response && response.email || email;
      }
      yield call(waitTillCloudStoresIsAuthorized, accountId, storage, token);
    }
    yield call(requestApi, `/file-store/account-cloud-store/${accountId}/change-preferred-store`, { method: 'PUT', body: { preferredStore: storage, token }});
    router.actions.setQueryParams({'authentication-in-same-window': undefined, 'auth-email': undefined, 'status': undefined, 'action': undefined, 'service': undefined, 'storage': undefined});
    yield put(actions._changePrefferedStoreDone({accountId, storage}));
  } catch (error) {
    const code =  error && error.code || 'UNKNOWN';
    yield put(actions._changePrefferedStoreFailed({accountId, storage, error: code}));
    if(code === 'EMAIL_NOT_AVAILABLE') {
      router.actions.setQueryParams({ 'attachment-sub-action': 'auth-identity-not-found', 'storage': storage, 'action': undefined }, false);
      return;
    }
    if(isNetworkError(error) || code === 'CANCELED') {
      return;
    }

    if(code === 'DRIVE_SETUP_PENDING') {
      router.actions.setQueryParams({'attachment-sub-action': 'drive-setup-pending', 'storage': storage}, true);
      return;
    }
    console.error("Change preferred store is failed, due to this:", error);
    if(error && error.authFailed) {
      showToast({message: code, type: 'ERROR'});
    }
  }
}

/**
 * Send email to Account Owner.
 */
function* requestFileStoreAuthorizationToAo() {
  try {
    const state = yield select();
    const accountId = router.selectors.accountId(state);
    const service = selectors.preferredStorage(state);
    yield call(requestApi, `/file-store/send-grant-access-email`, { method: 'POST', body: { service, accountId }});
    yield put({ type: actions.REQUEST_AUTHORIZATON_TO_AO_DONE });
  } catch (error) {
    yield put({ type: actions.REQUEST_AUTHORIZATON_TO_AO_FAILED, error});
  }
}

/**
* When PWA,
*  - Opens /authorize-cloud-store?service={serviceName}&redirect=${redirectURL} in child window.
*  -   redirectUrl is "/authorize-storage-fallback.html"
*  - Listen on postMessage from that widnow. It can be `REAUTHORIZED_CLOUD_STORAGE_SUCCESS` or `REAUTHORIZED_CLOUD_STORAGE_FAILED`
*      - Based on postMessage, show toast message.
* When Installed App,
*  - When preferred store is google then
*   - Triggers cordova's `googleplus` login with scopes=`email profile https://www.googleapis.com/auth/drive.file`.
*   - On successfull login, triggers API /api/authorize-cloud-store with auht-code and service
*  - When preferred store is box or microsoft then authorize cloud store end point in main window.
*/
function* reauthorizeFileStore(action) {
  const service = action.service;
  let state = yield select();
  const installedApp = app.selectors.isInstalledApp(state);
  const pageName = router.selectors.pageName(state);
  try {
    if(app.selectors.oauth2LoginUsingNativeFlow(state, service)) {
      yield call(reauthorizeInstalledApp, {service: action.service, email: action.email});
    } else {
      yield call(authorizeStore, {service: action.service, email: action.email, installedApp});
    }
    yield put({ type: actions.REAUTHORIZE_DONE });
  } catch (error) {
    let errorCode = error && error.code || 'UNKNOWN';
    yield put({ type: actions.REAUTHORIZE_FAILED, error: errorCode});
    if(errorCode === 'EMAIL_NOT_AVAILABLE') {
      router.actions.setQueryParams({ 'attachment-sub-action': 'auth-identity-not-found', 'storage': action.service, 'attachment-action': undefined }, pageName === 'AUTHORIZE_FILE_STORE');
      return;
    }
    
    console.error("re-autorize file-store failed, due to this:", error);
    if(error && error.authFailed) {
      showToast({message: errorCode, type: 'ERROR'});
    }
  }
}

/**
 * Installed app re-authorize.
 */
function* reauthorizeInstalledApp({service, email, token, accountId}) {
  let state = yield select();
  let config = app.selectors.config(state);
  let response;
  if(service == 'microsoft') {
    response = yield call (installedAppMicrosoftReauthorize, {apiBaseUrl: config.apiBaseUrl, webAppBaseUrl: config.webAppBaseUrl, email});
  } else if(service == 'box') {
    response = yield call(installedAppBoxReauthorize, {apiBaseUrl: config.apiBaseUrl, webAppBaseUrl: config.webAppBaseUrl, email});
  } else {
    const authCode = yield call(installedAppGoogleReauthorize, config.auth.googleClientId);
    const uri = new URI(`${config.apiBaseUrl}/user/api/authorize-cloud-store`);
    if (authCode) {
      uri.setSearch('code', authCode);
    }
    if (service) {
      uri.setSearch('service', service);
    }
    if (token) {
      uri.setSearch('token', token);
    }
    if (accountId) {
      uri.setSearch('accountId', accountId);
    }
    response = yield call(requestApi, uri.toString());
  }

  return response;
}

/**
 * Installed app google authorize.
 */
function installedAppGoogleReauthorize(googleClientId) {
  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });

  window.plugins.googleplus.login({
    'scopes': 'email profile https://www.googleapis.com/auth/drive.file',
    'webClientId': googleClientId,
    'offline': true
  },
  function (obj) {
    resolve(obj.serverAuthCode);
  },
  function (error) {
    reject({code: 'CANCELED'});
    console.warn('GooglePlus Authorize Error: ', error);
  });

  return promise;
}

/**
 * Installed app box authorize.
 */
function installedAppBoxReauthorize({apiBaseUrl, email, webAppBaseUrl}) {
  let redirectURL = getRedirectURL(webAppBaseUrl, 'BOX');
  let finalURL = `${apiBaseUrl}/authorize-cloud-store?service=box&redirect=${encodeURIComponent(redirectURL)}`;
  if(email) {
    finalURL = `${apiBaseUrl}/authorize-cloud-store?service=box&email=${email}&redirect=${encodeURIComponent(redirectURL)}`;
  }
  window.location.href = finalURL;

  //return a never resolved Promise, because box reauthorization complete in same window.
  return new Promise(() => { });
}

/**
 * Installed app microsoft authorize.
 */
function installedAppMicrosoftReauthorize({apiBaseUrl, email, webAppBaseUrl}) {
  let redirectURL = getRedirectURL(webAppBaseUrl, 'MICROSOFT');
  let finalURL = `${apiBaseUrl}/authorize-cloud-store?service=microsoft&redirect=${encodeURIComponent(redirectURL)}`;
  if(email) {
    finalURL = `${apiBaseUrl}/authorize-cloud-store?service=microsoft&email=${email}&redirect=${encodeURIComponent(redirectURL)}`;
  }
  window.location.href = finalURL;

  //return a never resolved Promise, because microsoft reauthorization complete in same window.
  return new Promise(() => { });
}


function getRedirectURL(webAppBaseUrl, service) {
  let uri = URI();
  //remove page url from current url.
  if(uri.pathname() === "/reauthorize-cloud-store") {
    uri.pathname('/');
    uri.hash('');
    uri.query('');
  } else {
    uri.setSearch('authentication-in-same-window', true);
    uri.setSearch('service', service);
    uri.removeSearch('status');
    uri.removeSearch('auth-email');
    uri.removeSearch('error');
  }
  return `${webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(uri.toString())}`;
}

function* load({requesterId, accountId}) {
  const state = yield select();
  accountId = accountId || router.selectors.accountId(state);
  const userId = auth.selectors.currentUserId(state);
  const liveQueries = firestoreRedux.selectors.liveQueriesByRequester(state, requesterId);
  if (!isEmpty(liveQueries) || !userId || !accountId) {
    return;
  }

  const woPrefixAccountId = getIdWoPrefix({ id: accountId, prefix: 'acc_' });
  firestoreRedux.getDocById(`accounts/${accountId}/account-cloud-stores`, `acs_${woPrefixAccountId}`, { requesterId });
  firestoreRedux.getDocById(`users/${userId}/cloud-store-emails`, `cse_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId });
  firestoreRedux.query('user-cloud-stores', { where: [['userId', '==', userId]], requesterId });
}

/**
 * Init Saga.
 */
function* fileStoreSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, load),
    takeEvery(actions.ACCESS_REVOKED_DETECTED, openFileStoreAccessRevokedDialog),
    takeEvery(actions.REQUEST_AUTHORIZATON_TO_AO, requestFileStoreAuthorizationToAo),
    takeEvery(actions.REAUTHORIZE, reauthorizeFileStore),
    takeEvery(actions.PREFERRED_STORE_SELECTION_PENDING, preferredStoreSelectionPending),
    takeEvery(actions.PREFERRED_STORE_SELECTION, preferredStoreSelection),
    takeEvery(actions.CHECK_PREFERRED_STORE_SELECTION, checkPrefferedStoreSelection),
    takeEvery(actions.CHANGE_PREFERRED_STORE, changePrefferedStore),
  ]);
}

export default fileStoreSaga;