import URI from '@dw/urijs-esm/index.js';
import { isUnsubscribePage } from './validate-url.js';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If path is /unsubscribe/** then resolve promise with unsubscribe page data.
 *                    Otherwise reject a promise.
 */
export const unsubscribePage = (data) => {
  return new Promise((resolve, reject) => {
    if(isUnsubscribePage(data.path)) {
      let uri = new URI(data.path);
      let params = new URI().query(true);
      let type = uri.segment()[2];
      data.page = {
        name: 'UNSUBSCRIBE',
        type: type === 'tasks-summary' ? 'TASKS_SUMMARY': type,
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}