import * as app from '../app';
import * as routerSelectors from '../router/selectors.js';
import * as auth from '../auth';
import * as signup from '../signup';
import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import omitBy from 'lodash-es/omitBy';
import uniqBy from 'lodash-es/uniqBy';
import isEqual from 'lodash-es/isEqual';
import findIndex from 'lodash-es/findIndex';
import filter from 'lodash-es/filter';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as utils from '../../utils';
import * as selectorCreator from '../selector-creator.js';
import * as multipleLanguage from '../multiple-language';
import * as boardUtils from '../board/utils.js';

/**
 * @returns {Array} Accessible boards for current user.
 *                   When layout is narrow then return accessible boards current user for current account
 *                   Otherwise returns all accessible boards for currrent user. e.g. {$boardId: $boardDetails, $boardId2: $boardDetails2,..}
 */
export const accessibleBoards = selectorCreator.createDeepEqualSelector(
  app.selectors.wideLayout,
  routerSelectors.accountId,
  (state) => firestoreRedux.selectors.docsByQueryResult(state, 'user-accessible-boards', 'user-accessible-boards'),
  (wideLayout, accountId, allBoards) => {
    const result = {};
    for (const board of allBoards) {
      if (wideLayout || board.accountId === accountId) {
        result[board.boardId] = board;
      }
    }
    return result;
  }
);

/**
 * Active and public app templates.
 * @returns {Object} All app templates. e.g. {$boardId: $board, $boardId2: $board2,..}
 */
export const activeAppTemplates = selectorCreator.default({maxSize: 50})(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, 'app-active-public-templates', 'boards'),
  (allBoards) => {
    let appTemplates = {};
    for (const board of allBoards) {
      appTemplates[board.id] = board;
    }
    return appTemplates;
  }
);

/**
 * @returns {Array} List of favorite board ids. e.g. ['brd_2kjldfjlk3j42', 'brd_5j3kg9f0mvmff', ...]
 */
export const favoriteBoards = selectorCreator.createDeepEqualSelector(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, 'favorite-boards', 'favorite-boards'),
  (favBoards) => {
    let list = {};
    for (const board of favBoards) {
      list[board.boardId] = true;
    }
    return list;
  }
);

/**
 * @param {Object} state redux state
 * @param {*} boardId
 * @returns {Boolean} `true` when given board is favorite.
 */
export const favoriteBoard = selectorCreator.reselct.createSelector(
  (state, boardId) => favoriteBoards(state)[boardId] || false,
  (favorite) => {
    return favorite;
  }
);

/**
 * @param {Object} state Redux state.
 * @returns {Array} accounts list based on filters.
 */
export const accountsFiltersList = selectorCreator.default({maxSize: 50})(
  routerSelectors.pageFilters,
  auth.selectors.accessibleAccountsDetails,
  auth.selectors.templateAccountDetails,
  (filters, accessibleAccounts, templateAccountDetails) => {
    if (!filters || !filters.template) {
      return accessibleAccounts;
    }

    let accountsFiltersList = [];
    if (templateAccountDetails) {
      accountsFiltersList.push(templateAccountDetails);
    }
    forEach(accessibleAccounts, (value) => {
      accountsFiltersList.push(value);
    });

    return uniqBy(accountsFiltersList, (account) => account.id);
  }
);

/**
 * @returns {Object} browse filters for desktop layout.
 *                   e.g. {
 *                      status: {
 *                        includeTrash:  true,
 *                        includeArchive: true
 *                      },
 *                      accounts: {
 *                        123141: true
 *                      },
 *                      currentLanguage: true
 *                   }
 */
export const browseFilters = selectorCreator.createDeepEqualSelector(
  (state) => get(state, 'board-explorer.browsefilters.status'),
  (state) => get(state, 'board-explorer.browsefilters.accounts'),
  (state) => get(state, 'board-explorer.browsefilters.currentLanguage'),
  accountsFiltersList,
  (status, accounts, currentLanguage, _accountsFiltersList) => {
    if (isEmpty(status)) {
      status = { includeTrash: false, includeArchive: false };
    }

    if (isEmpty(accounts) || isEmpty(omitBy(accounts))) {
      accounts = {};
      forEach(_accountsFiltersList, (value) => {
        if (value && value.id) {
          accounts[value.id] = true;
        }
      });
    }

    return { status, accounts, currentLanguage: currentLanguage === undefined ? true: currentLanguage };
  }
);

/**
 * @returns {Array} List of filtered accessible boards. e.g. ['brd_j6k3ldk9vfj7k', 'brd_5jfjj4j3j0ff', ...]
 */
export const filteredBoards = selectorCreator.default({maxSize: 10})(
  (state, filters) => filters || routerSelectors.pageFilters,
  app.selectors.wideLayout,
  activeAppTemplates,
  favoriteBoards,
  browseFilters,
  accountsFiltersList,
  accessibleBoards,
  (filters, wideLayout, _activeAppTemplates, _favoriteBoards, _browseFilters, _accountsFiltersList, _accessibleBoards) => {
    if (!_accessibleBoards || !filters || (filters.template && isEmpty(_activeAppTemplates))) {
      return;
    }

    let filteredBoards = [];
    const mergedBoards = Object.assign({}, _activeAppTemplates, _accessibleBoards);
    forEach(mergedBoards, (board, key) => {
      const isFavorite = _favoriteBoards[key] || false;
      if (!wideLayout) {
        if (filters.status === 'ACTIVE') {
          if (
            board.status === filters.status &&
            ((!filters.favorite && !isFavorite) || isFavorite === filters.favorite) &&
            board.template === filters.template
          ) {
            filteredBoards.push(key);
          }
        } else {
          if (
            board.status === filters.status &&
            ((!filters.favorite && !isFavorite) || isFavorite === filters.favorite)
          ) {
            filteredBoards.push(key);
          }
        }
      } else {
        if (
          ((!filters.favorite && !isFavorite) || isFavorite === filters.favorite) &&
          board.template === filters.template
        ) {
          const includeArchive = _browseFilters.status.includeArchive;
          const includeTrash = _browseFilters.status.includeTrash;
          if (!((!includeArchive && board.status === 'ARCHIVED') || (!includeTrash && board.status === 'TRASHED'))) {
            filteredBoards.push(key);
          }
        }
      }
    });

    //If accounts filters is applied.
    if (wideLayout && !isEmpty(_browseFilters) && !isEmpty(_browseFilters.accounts) && !isEmpty(_accountsFiltersList)) {
      let newFilteredBoards = [];
      forEach(filteredBoards, (id) => {
        const details = mergedBoards[id];
        if (_browseFilters.accounts[details.accountId]) {
          newFilteredBoards.push(id);
        }
      });

      return newFilteredBoards;
    }

    return filteredBoards;
  }
);

/**
 * @returns {Object} Hash of given board's team members. Key is the user Id & value is it's role in the board.
 */
const _members = selectorCreator.default({maxSize: 500})(
  (state, boardId) => firestoreRedux.selectors.collection(state, 'board-team-members'),
  (state, boardId) => boardId,
  (allBoardTeamMembers, boardId) => {
    return filter(allBoardTeamMembers, { boardId });
  }
);

export const members = selectorCreator.default({maxSize: 500})(
  (state, boardId) => _members(state, boardId),
  (docs) => {
    let _members = {};
    forEach(docs, (doc, value) => {
      _members[doc.userId] = doc.role;
    });
    return _members;
  }
);

/**
 * Returns actions in progress for board.
 * @param {Number} boardId Board Id
 * @returns {Object} Actions which are in progress. e.g. { archive: true }
 */
export const actionsInProgress = (state, boardId) => get(state, `board-explorer.action-in-progress.${boardId}`);

/**
 * @returns {String} current user role in given account.
 */
export const userAccountRole = selectorCreator.reselct.createSelector(
  (state, accountId) => accountId,
  auth.selectors.currentUser,
  auth.selectors.currentUserOwnedAccounts,
  auth.selectors.currentUserAccessibleAccount,
  (accountId, currentUser, ownedAccounts, otherAccounts) => {
    if (!currentUser || !accountId || (isEmpty(ownedAccounts) && isEmpty(otherAccounts))) {
      return null;
    }
    return ownedAccounts[0] === accountId ? 'OWNER' : get(otherAccounts, `${accountId}`);
  }
);

/**
 * `true` when board's basic details is being loaded.
 * @param {Object} state Redux state
 */
export const boardsLoadInProgress = (state) => get(state, `board-explorer.loading`);

/**
 * @return {String} Last visited Url based on last visited page filters.
 */
export const lastVisitedUrl = selectorCreator.createDeepEqualSelector(
  (state) => get(state, `board-explorer.lastVisitedPageParams`),
  (state) => get(state, `auth.lastAccessibleAccount`),
  auth.selectors.lastUsedAccount,
  (params, lastAccessibleAccount, lastUsedAccount) => {
    if (isEmpty(params)) {
      const accId = lastAccessibleAccount || lastUsedAccount;
      return accId ? `/${accId}/home/favorite/boards` : '/';
    }

    let url = `/${params.accountId}/home`;

    if (params.favorite) {
      url += '/favorite';
    } else {
      url += '/other';
    }

    if (params.status === 'TRASHED') {
      url += '/trash';
    } else if (params.status === 'ARCHIVED') {
      url += '/archive';
    } else if (params.template === true) {
      url += '/templates';
    } else {
      url += '/boards';
    }

    if (params.currentLang) {
      url += '/current-lang';
    }
    
    return url;
  }
);

/**
 * @returns {String} status of given board. e.g. ACTIVE, ARCHIVE, and TRASHED.
 */
export const status = selectorCreator.reselct.createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId),
  (board) => {
    return get(board, `status`);
  }
);

/**
 * @returns {Boolean} `true` when given template is system template. otherwise `false`.
 */
export const isSystemTemplate = selectorCreator.reselct.createSelector(
  (state, templateId) => templateId,
  activeAppTemplates,
  (templateId, systemTemplates) => {
    return (systemTemplates && systemTemplates[templateId]) || false;
  }
);

/**
 * @param {Object} state redux state.
 * @returns {Boolean} `true` when favorites tab is present to current user, `false` otherwise.
 */
export const favoritesTabPresented = (state) => {
  const userId = auth.selectors.currentUserId(state);
  const doc = firestoreRedux.selectors.doc(state, 'user-ui', `uexp_${utils.getIdWoPrefix({ id: userId, prefix: 'usr_' })}`);
  return get(doc, 'favoritesTabPresented');
};

/**
 * @param {Object} state redux state.
 * @returns {Boolean} `true` when all others tab is present to current user, `false` otherwise.
 */
export const allOthersTabPresented = (state) => {
  const userId = auth.selectors.currentUserId(state);
  const doc = firestoreRedux.selectors.doc(state, 'user-ui', `uexp_${utils.getIdWoPrefix({ id: userId, prefix: 'usr_' })}`);
  return get(doc, 'allOthersTabPresented');
};

const explorerPageUserUIDoc = selectorCreator.reselct.createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'user-ui', `uexp_${utils.getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (doc) => {
    return doc || {};
  }
);

/**
 * All other tab categories collapse details for current user.
 * @param {Object} state redux state.
 * @returns {Object} All other tab categories collapse details map.
 */
export const allOthersTemplatesCollapsedCategories = selectorCreator.createDeepEqualSelector(
  (state) => explorerPageUserUIDoc(state),
  (doc) => {
    return get(doc, 'allOthersTemplates.collapsedCategories') || {};
  }
);

/**
 * Favorites tab categories collapse details for current user.
 * @param {Object} state redux state.
 * @returns {Object} Favorites tab categories collapse details map.
 */
export const favoritesTemplatesCollapsedCategories = selectorCreator.createDeepEqualSelector(
  (state) => explorerPageUserUIDoc(state),
  (doc) => {
    return get(doc, 'favoritesTemplates.collapsedCategories') || {};
  }
);

/**
 * @returns {Boolean} `true` when user has any favorite boards.
 */
const hasFavoriteBoards = selectorCreator.reselct.createSelector(
  (state) => filteredBoards(state, { ...get(state, 'router.page.params.filters'), favorite: true }),
  (list) => {
    return (list && list.length > 0) || false;
  }
);

/**
 * @returns {Boolean} `true` when user has non-favorite board.
 */
const hasAllOtherBoards = selectorCreator.reselct.createSelector(
  (state) => filteredBoards(state, { ...get(state, 'router.page.params.filters'), favorite: false }),
  (list) => {
    return (list && list.length > 0) || false;
  }
);

/**
 * @returns {Boolean} `true` explore-page favorites/allOthers tab is shown or not.
 */
export const isFavoritesTabEnabled = selectorCreator.createDeepEqualSelector(
  hasAllOtherBoards,
  hasFavoriteBoards,
  favoritesTabPresented,
  allOthersTabPresented,
  signup.selectors.isNewUser,
  routerSelectors.isFavoritesTab,
  (_hasAllOtherBoards, _hasFavoriteBoards, _favoritesTabPresented, _allOthesTabPresented, _isNewUser, _isFavoritesTab) => {
    if (!_isNewUser) {
      return true;
    }

    if (_favoritesTabPresented && _allOthesTabPresented) {
      return true;
    }

    if (_hasFavoriteBoards && _hasAllOtherBoards) {
      return true;
    }

    if (_isFavoritesTab && !_hasFavoriteBoards && _hasAllOtherBoards) {
      return true;
    }

    if (!_isFavoritesTab && !_hasAllOtherBoards && _hasFavoriteBoards) {
      return true;
    }

    return false;
  }
);


/**
 * Select boards list based on  filters & boards.
 * Sort boards based on it's title in ascending order.
 * @param {Object} state
 * @param {Object} filters filters (e.g {favorite: true, template: false, status: "ACTIVE"})
 * @returns {Array} List of boards based on current filters. e.g. [{id, name, accountId, ownerId, isTemplate, privacy, favorite, category, movingStatus, movingTo, type}, ...]
 */
let previousBoardsListMap = {};
export const boardsList = selectorCreator.default({maxSize: 500})(
  (state, filters) => filters || routerSelectors.pageFilters,
  (state, filters) => multipleLanguage.selectors.defaultLanguage(state),
  (state, filters) => auth.selectors.currentUserId(state),
  (state, filters) => app.selectors.templateAcId(state, filters),
  filteredBoards,
  favoriteBoards,
  accessibleBoards,
  activeAppTemplates,
  (state) => firestoreRedux.selectors.queryStatus(state, 'favorite-boards'),
  (state) => firestoreRedux.selectors.collection(state, 'boards'),
  (state) => firestoreRedux.selectors.collection(state, 'board-move-requests'),
  (state) => firestoreRedux.selectors.collection(state, 'board-unread-user-details'),
  (filters, defaultLanguage, userId, templateAcId, _filteredBoards, _favoriteBoards, _accessibleBoards, _activeAppTemplates, favBoardQueryStatus, boards, boardMoveRequest, boardUnreadUserDetails) => {
    if (!_filteredBoards || !_favoriteBoards || !filters || favBoardQueryStatus === 'PENDING') {
      return undefined;
    }

    if (isEmpty(_filteredBoards)) {
      return [];
    }

    const userIdWithoutPrefix = userId && utils.getIdWoPrefix({id: userId, prefix: 'usr_'});
    defaultLanguage = defaultLanguage || 'en';
    let newValue = [];
    let newValueMap = {};
    for (const key  of _filteredBoards) {
      const _accessibleBoard = _accessibleBoards && _accessibleBoards[key] || {};
      const board = boards && boards[key] || _activeAppTemplates && _activeAppTemplates[key] || {};
      const isDeleted = board.isDeleted || false;
      if (!isDeleted) {
        const attrs = boardUtils.attrs(board, boardMoveRequest && boardMoveRequest[`bmr_${utils.getIdWoPrefix({ id: key, prefix: 'brd_' })}`]) || {};
        const isCreator = userId && attrs.createdBy && attrs.createdBy === userId || false;
        const importBoardDetail = attrs.importBoardDetail || {};
        const importFailed = isCreator ? importBoardDetail && importBoardDetail.status === 'FAILED' && importBoardDetail.statusAck || false: importBoardDetail && (importBoardDetail.status === 'FAILED' || importBoardDetail.status === 'IN_PROGRESS') || false;
        if(!importFailed) {
          const _boardIsActive = boardUtils.isBoardActive(attrs);
          const unreadDoc = boardUnreadUserDetails && boardUnreadUserDetails[`buu_${utils.getIdWoPrefix({ id: key, prefix: 'brd_' })}_${userIdWithoutPrefix}`] || {};
          const unread = _boardIsActive && unreadDoc.anyUnread && unreadDoc.unread && (unreadDoc.unread.name || unreadDoc.unread.columns || unreadDoc.unread.attachments || unreadDoc.unread.chats) || false;
          const isTemplate = attrs.template || _accessibleBoard.template || false;
          const newItem = {
            id: key,
            isCreator,
            name: attrs.name || _accessibleBoard.boardName || '',
            createdAt: attrs.createdAt,
            accountId: attrs.accountId || _accessibleBoard.accountId,
            isAppTemplateBoard: attrs.accountId && attrs.accountId === templateAcId,
            ownerId: attrs.ownerId,
            isTemplate: isTemplate,
            initialized: attrs.initialized,
            privacy: attrs.privacy,
            favorite: _favoriteBoards[key] || false,
            category: attrs.templateCategory || _accessibleBoard.templateCategory || undefined,
            lang: attrs.lang || defaultLanguage,
            movingStatus: attrs.movingStatus,
            movingTo: attrs.movingTo,
            type: attrs.type || _accessibleBoard.type,
            canvasId: attrs.canvasId,
            gettingStarted: attrs.gettingStarted || false,
            importBoardDetail: attrs.importBoardDetail || {},
            unread
          };
  
          const existingItem = previousBoardsListMap[key] || undefined;
          if (existingItem && isEqual(existingItem, newItem)) {
            newValue.push(existingItem);
            newValueMap[key] = existingItem;
          } else {
            newValue.push(newItem);
            newValueMap[key] = newItem;
          }
        }
      }
    };

    previousBoardsListMap = newValueMap;
    return sortBy(newValue, (o) => o.name.toLowerCase());
  }
);

export const currentLanguageFilter = selectorCreator.default({maxSize: 10})(
  (state) => browseFilters(state),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (state) => multipleLanguage.selectors.defaultLanguage(state),
  (state) => currentLangFilterAvailable(state),
  (filters, currentLanguage, defaultLanguage, currentLangFilterAvailable) => {
    defaultLanguage = defaultLanguage || 'en';
    return currentLanguage === defaultLanguage ? true: currentLangFilterAvailable ? filters.currentLanguage: false;
  }
);

export const templateFilterLangList = selectorCreator.default({maxSize: 100})(
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (state) => multipleLanguage.selectors.defaultLanguage(state),
  (state) => auth.selectors.currentUserTemplateAcOwner(state),
  (state) => multipleLanguage.selectors.supportedLanguagesCode(state),
  (state) => currentLanguageFilter(state),
  (currentLanguage, defaultLanguage, templateAcOwner, supportedLangs, currentLanguageFilter) => {
    defaultLanguage = defaultLanguage || 'en';
    const list = currentLanguageFilter && !templateAcOwner ? [currentLanguage]: [defaultLanguage, currentLanguage];
    if(templateAcOwner) {
      return supportedLangs && supportedLangs.length ? supportedLangs: list;
    }
    return list;
  }
);

export const templateList = selectorCreator.default({maxSize: 100})(
  (state, filters) => app.selectors.wideLayout(state),
  (state, filters) => boardsList(state, filters),
  (state, filters) => templateFilterLangList(state),
  (state, filters) => get(filters, 'favorite', false),
  favoritesTemplatesCollapsedCategories,
  allOthersTemplatesCollapsedCategories,
  (wideLayout, list, langList, filtersFavorite, favoritesCollapsedCategories, allOthersCollapsedCategories) => { 
    if(list === undefined) {
      return undefined;
    }

    const filteredList = list.filter(item => langList.includes(item.lang));
    if (!wideLayout) {
      return filteredList;
    }

    const templates = filteredList.filter(item => item.name !== undefined && item.category !== undefined);
    if (templates.length !== filteredList.length) {
      return undefined;
    }

    const collapsedCategories = filtersFavorite ? favoritesCollapsedCategories : allOthersCollapsedCategories;
    const groupedTemplates = groupBy(templates, 'category');

    const categorizedList = Object.entries(groupedTemplates).map(([category, templates]) => ({
      collapsed: collapsedCategories && collapsedCategories[category] !== undefined ? collapsedCategories[category]: filtersFavorite ? false: true,
      category,
      templates
    }));

    return sortBy(categorizedList, item => 
      findIndex(app.selectors.templateCategory(), o => o.name === item.category)
    );
  }
);

/**
 * Template list based on given language and filters.
 */
export const langTemplateList = selectorCreator.default({maxSize: 100})(
  (state, filters, lang) => lang, 
  (state, filters, lang) => boardsList(state, filters),
  (lang, list) => {
    let newList = [];
    //Filter out based on lang
    forEach(list,  (item) => {
      if(lang === item.lang) {
        newList.push(item);
      }
    });

    return newList;
  }
);

export const langActiveAppTemplates =  selectorCreator.default({maxSize: 10})(
  (state, lang) => lang,
  (state, lang) => activeAppTemplates(state),
  (state, lang) => multipleLanguage.selectors.defaultLanguage(state),
  (lang, _activeAppTemplates, defaultLanguage) => {
    let newList = [];
    //Filter out based on lang
    forEach(_activeAppTemplates,  (item) => {
      const _lang = item.lang || defaultLanguage || 'en';
      if(lang === _lang) {
        newList.push(item);
      }
    });

    return newList;
  }
);

/**
 * @returns {Boolean} Current lang filter is available or not.
 */
export const currentLangFilterAvailable = selectorCreator.default({maxSize: 10})(
  (state) => auth.selectors.currentUserTemplateAcOwner(state),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (state) => multipleLanguage.selectors.defaultLanguage(state),
  (state) => langActiveAppTemplates(state, multipleLanguage.selectors.currentLanguage(state)),
  (templateAcOwner, currentLanguage, defaultLanguage, langTemplateList) => {
    if(templateAcOwner) {
      return false;
    }

    if(currentLanguage === defaultLanguage) {
      return false;
    }

    return langTemplateList && langTemplateList.length > 0;
  }
);