import URI from '@dw/urijs-esm/index.js';
import { isLeaveAppDonePage } from './validate-url.js';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If path is /leave-app-done then resolve promise with leave-app-done-page page data.
 *                    Otherwise reject a promise.
 */
export const leaveAppDonePage = (data) => {
  return new Promise((resolve, reject) => {
    if(isLeaveAppDonePage(data.path)) {
      let params = new URI().query(true);
      data.page = {
        name: 'LEAVE_APP',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}