/**
 * Behaviours:
 *  - When 'left-align-text' class is set, align text to left instead of center.
 */

import { css } from "lit-element";
import { DwButton } from "@dreamworld/dw-button/dw-button";
import typographyLiterals from "../../theme/typography-literals.js";

export class KerikaButton extends DwButton {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          border-radius: var(--kerika-button-border-radius, 2px);
          -webkit-border-radius: var(--kerika-button-border-radius, 2px);
          -ms-border-radius: var(--kerika-button-border-radius, 2px);
          ${typographyLiterals.button};
          min-width: var(--kerika-button-min-width, 76px);
          --mdc-button-outline-color: var(
            --kerika-button-outline-color,
            --mdc-theme-primary
          );
        }

        :host([readonly]),
        :host([readonly]) button {
          pointer-events: none;
          cursor: default;
        }

        :host .mdc-button {
          height: var(--kerika-button-height, 32px);
          min-width: var(--kerika-button-min-width, 64px);
          border-width: 1px;
          box-sizing: var(--kerika-button-box-sizing, border-box);
          padding: var(--kerika-button-padding, 0px 16px);
          border-radius: var(--kerika-button-border-radius, 2px);
          -webkit-border-radius: var(--kerika-button-border-radius, 2px);
          -ms-border-radius: var(--kerika-button-border-radius, 2px);
          white-space: var(--kerika-button-white-space, nowrap);
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          letter-spacing: inherit;
          line-height: inherit;
          font-style: inherit;
        }

        :host(.left-align-text) .mdc-button {
          justify-content: start;
        }

        :host(.top-border) .mdc-button {
          border-top: 1px solid var(--divider-color);
        }

        :host([learn-more]) {
          --kerika-button-padding: 8px 16px;
          --kerika-button-box-sizing: content-box;
        }

        :host([large]) .mdc-button {
          height: var(--kerika-button-height, 40px);
        }

        :host([x-large]) .mdc-button {
          font-size: 18px;
          line-height: normal;
          padding: var(--kerika-button-padding, 16px);
          height: var(--kerika-button-height, 56px);
          min-width: var(--kerika-button-x-large-min-width, 400px);
          border-radius: var(--kerika-button-border-radius, 4px);
        }

        :host([outlined]) {
          background-color: var(--mdc-theme-on-primary);
        }

        :host([text-inherit]) {
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          letter-spacing: inherit;
          text-transform: inherit;
        }

        :host([caption]) {
          ${typographyLiterals.caption};
          --mdc-theme-primary: var(--secondary-text-color);
        }

        .slot-container {
          width: var(--kerika-button-slot-container-width, auto);
        }

        :host([caption]) .mdc-button,
        :host([caption]) .slot-container {
          display: flex;
          height: 56px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-transform: inherit;
        }

        :host([text-inherit]) .mdc-button {
          text-transform: inherit;
        }

        :host([danger]) .mdc-button {
          --mdc-theme-primary: var(--danger-text-color);
          --mdc-button-outline-color: var(--danger-text-color);
        }

        .mdc-button__label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :host .mdc-button .mdc-button__icon {
          width: var(--kerika-button-icon-size, 20px);
          height: var(--kerika-button-icon-size, 20px);
          font-size: var(--kerika-button-icon-size, 20px);
          color: var(--kerika-button-icon-color, var(--dw-icon-color));
        }

        :host([filled]:not([disabled])) .mdc-button {
          --mdc-ripple-color: var(--mdc-theme-surface);
          --mdc-ripple-hover-opacity: 0.12;
        }

        :host([auto-width][x-large]) {
          --kerika-button-x-large-min-width: auto;
        }
      `,
    ];
  }
}

window.customElements.define("kerika-button", KerikaButton);
