import { takeEvery, call, select, all, put, delay } from 'redux-saga/effects';
import { requestApi, isNetworkError } from "../../request-api"
import * as actions from './actions';
import * as router from '../router';
import { show as showSnackBar, hide as hideSnackbar } from '../../components/kerika-snackbar';
import i18next from '@dw/i18next-esm';
import { store } from '../../store';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import last from 'lodash-es/last';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import * as board from '../board';
import * as columnsSelectors from '../columns/selectors.js';
import { clipboard } from '../../components/clip-board';
import forEach from 'lodash-es/forEach';
import every from 'lodash-es/every';
import isEmpty from 'lodash-es/isEmpty';
import keys from 'lodash-es/keys';
import find from 'lodash-es/find';
import cloneDeep from 'lodash-es/cloneDeep';
import omitBy from 'lodash-es/omitBy';
import { sortBoardCards } from './sort-cards';
import * as views from '../views';
import * as amplitude from '../../analytics/amplitude.js';
import * as clipboardModule from '../clipboard';
import firestoreRedux from '@dreamworld/firestore-redux';
import entityIdProvider from '../../entity-id-provider';
import * as knownFeatures from '../known-features';
import * as app from '../app';
import * as auth from '../auth';
import * as utils from '../../utils.js';
import * as selectors from './selectors';
import * as lastMoveCards from '../last-move-cards';
import isEqual from 'lodash-es/isEqual';
import { captureMessage } from '@sentry/browser';

/**
 * Loads cards by board.
 * @param {*} param0
 *  @property {String} requesterId Requester Id for firestore query.
 *  @property {String} boardId Board Id of which cards will be loaded.
 */
function* loadBoardCards({boardId}) {
  if (!boardId) {
    throw 'loadBoardCards: boardId is required';
  }

  firestoreRedux.query('cards', { id: `cards_${boardId}`, where: [['boardId', '==', boardId]] });
}

/**
 * Loads cards by column.
 * @param {*} param0
 *  @property {String} requesterId Requester Id for firestore query.
 *  @property {String} columnId Column Id of which cards will be loaded.
 */
function* loadCardsByColumnType({requesterId, boardId, columnType, limit}) {
  if (!requesterId || !columnType || (columnType !== 'DONE' && columnType !== 'TRASH')) {
    throw 'loadCardsByColumnType: requesterId & columnType are required';
  }

  const state = yield select();
  const cardQueryId = columnType === 'DONE' ? `done_cards_${boardId}`: columnType === 'TRASH' ? `trash_cards_${boardId}`: `cards_${boardId}`;
  const queryStatus = firestoreRedux.selectors.queryStatus(state, cardQueryId);
  const cardSummaryQueryId = columnType === 'DONE' ? `done_cards_summary_${boardId}`: columnType === 'TRASH' ? `trash_cards_summary_${boardId}`: `cards_summary_${boardId}`;
  if(queryStatus === 'PENDING') {
    return;
  }
  firestoreRedux.cancelQuery(cardQueryId);
  firestoreRedux.cancelQuery(cardSummaryQueryId);
  firestoreRedux.query('cards', { id: cardQueryId, where: [['boardId', '==', boardId], ['columnType', '==', columnType]], limit: limit || 100, orderBy: [['columnOrder']], requesterId });
  firestoreRedux.query('cards-summary', { id: cardSummaryQueryId, where: [['boardId', '==', boardId], ['columnType', '==', columnType]], limit: limit || 100, orderBy: [['columnOrder']], requesterId });
}

/**
 * Cancels cards by column query by it's requester id.
 * @param {*} param0
 *  @property {String} requesterId Firestore requester ID.
 */
function* disconnectCardsByColumnType({ requesterId }) {
  firestoreRedux.cancelQueryByRequester(requesterId);
}

/**
 * Sends request to create new card.
 * @param {*} param0
 *  @property {String} title Card title.
 *  @property {String} boardId Board ID in which card will be added.
 *  @property {String} columnId Column ID in which card will be added.
 */
function* addNewCard({ title, boardId, columnId }) {

  amplitude.updateCardsAddedUserProperty(1);
  amplitude.logBoardEvent('card added');
  if(!title || !columnId || !boardId) {
    throw new Error('addNewCard: Mandatory paramerted are not defined');
  }

  const state = yield select();
  const id = entityIdProvider.getId();
  const cards = board.selectors.cardsByColumn(state, columnId) || [];
  const lastCard = last(cards);
  const columnOrder = (lastCard) ? lastCard.columnOrder + 1000 : 0;
  //Save local data on redux state to display card instant
  const body = {
    id: `crd_${id}`,
    title,
    boardId,
    columnId,
    columnOrder,
    status: 'NORMAL',
    priority: 'NORMAL'
  };

  firestoreRedux.save(`boards/${boardId}/cards`, {...body, local: true}, { localWrite: true, queryId: `cards_${boardId}` });

  store.dispatch(knownFeatures.actions.markAsKnown('ADD_NEW_CARD'));
  requestApi('/card/cards', {method: 'POST', body});
}

/**
 * Moves card to Done column.
 * @param {Object} action action payload. e.g {cardId}
 */
function* moveToDone({ cardId }) {
  yield call(moveCardsToDone, { selectedCards: [cardId] });
}

/**
 * Moves card to Trash column.
 * @param {Object} action action payload. e.g {cardId}
 */
function* moveToTrash({ cardId }) {
  yield call(moveCardsToTrash, { selectedCards: [cardId] });
}


/**
 * Restore provided or selected cards from TRASH column.
 * @param {Arary} cardIds List of card Ids.
 * @param {Boolean} doNotUndo when `true` doesn't show UNDO in toast.
 */
function* restore({ cardIds, doNotUndo }) {
  const state = yield select();
  const selectedColumnId = board.selectors.selectedColumn(state);
  const boardId = router.selectors.pageBoardId(state);
  const trashColumnId = columnsSelectors.currentBoardTrashColumnId(state);
  if (selectedColumnId == trashColumnId && isEmpty(cardIds)) { // When cardIds not provided, gets selected cards.
    cardIds = board.selectors.selectedCards(state);
  }

  if (isEmpty(cardIds)) {
    console.warn('No cardIds provided or selected for restore.');
    return;
  }

  //Cards with it's order. It's used to UNDO restore.
  let currentCardsDocs = [];
  forEach(cardIds, (id) => {
    const doc = firestoreRedux.selectors.doc(state, 'cards', id);
    currentCardsDocs.push(doc)
  })

  try {
    yield put(board.actions.clearSelection());
    const body = { cardIds };
    const response = yield call(requestApi, `/card/cards/restore`, { method: 'POST', body, excludeErrors: [400, 403, 409] })
    const restoredCardsCount = keys(response.restored).length;
    const skipRestoreCards = response && response.skipRestore || [];

    //Remove skip restore cards.
    currentCardsDocs = omitBy(currentCardsDocs, (card)=>{
      return card && card.id && skipRestoreCards.includes(card.id);
    });

    // Set toast message.
    let message;
    if (isEmpty(response.skipRestore)) {
      if (cardIds.length > 1) {
        message = i18next.t('board-card:restoreToast.multiCardRestored');
      } else {
        message = i18next.t('board-card:restoreToast.singleCardRestored', { columnName: get(response, `restored.${cardIds[0]}.columnName`) });
      }
    } else {
      if (cardIds.length > 1) {
        message = i18next.t('board-card:restoreToast.multiCardRestoredFailed', {restoredCardsCount});
      } else {
        message = i18next.t('board-card:restoreToast.singleCardRestoreFailed');
      }
    }

    const actionAt = Date.now();
    const actionName = 'RESTORE_CARDS';
    const actionId = `${actionName}-${actionAt}`;
    const actionButton = doNotUndo || !restoredCardsCount ? undefined : { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.undoRestore(currentCardsDocs)) } };
    showSnackBar({ id: actionId, message, actionButton });
    if(!isEmpty(currentCardsDocs)){
      yield put(lastMoveCards.actions.update({boardId: boardId, columnId: trashColumnId, cards: currentCardsDocs, actionBy: auth.selectors.currentUserId(state), actionAt: actionAt, actionName}));
    }
  } catch (err) {
    console.error('Failed to restore cards', err);
  }
}

/**
 * Restores all cards from TRASH column.
 */
function* restoreAll() {
  const state = yield select();
  const trashColumnId = columnsSelectors.currentBoardTrashColumnId(state);
  const cardsByColumn = board.selectors.cardsByColumn(state, trashColumnId) || [];
  const cardIds = map(cardsByColumn, 'id');
  yield call(restore, { cardIds });
}

/**
 * Undo the restore action.
 * @param {Object} cards List of cards to be restored. e.g. [{id, title, columnOrder, createdAt, columnType}, ...]
 */
function* undoRestore({ cards }) {
  if (isEmpty(cards)) {
    console.warn('Undo restore: cards is not provided.');
    return;
  }
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const columnId = columnsSelectors.currentBoardTrashColumnId(state);
  firestoreRedux.save(`boards/${boardId}/cards`, cards, { localWrite: true });
  const cardIdWiseOrder = {};
  forEach(cards, (card) => {
    cardIdWiseOrder[card.id] = card.columnOrder
  })

  try {
    yield call(moveCardsApi, { boardId, columnId, cardIdWiseOrder });
  } catch (error) {
    console.error(`Failed to undo restored cards`, error);
  }
}

let lastMoveCardIds = [];
let lastMoveCardIdsTimer;
async function moveCardsApi({ boardId, columnId, cardIdWiseOrder }) {
  try {

    const cardIds = keys(cardIdWiseOrder);
    if(isEqual(lastMoveCardIds, cardIds)) {
      console.log('Last moved card ids are same.', cardIds);
      captureMessage('Last moved card ids are same.', cardIds);
    } else {
      let sameCard = false;
      let sameCardId;
      forEach(cardIds, (id) => {
        if(lastMoveCardIds && lastMoveCardIds.includes(id)) {
          sameCard = true;
          sameCardId = id;
          return false;
        }
      });

      if(sameCard) {
        console.log('Last moved card ids are same.', sameCardId, cardIds);
        captureMessage('Last moved card ids are same.', sameCardId, cardIds);
      }
    }
    
    lastMoveCardIds = cardIds;
    //Reset last moved card ids after 2 minutes.
    lastMoveCardIdsTimer && clearTimeout(lastMoveCardIdsTimer);
    lastMoveCardIdsTimer = setTimeout(async () => {
      lastMoveCardIds = [];
    }, 2 * 60 * 1000);
    return requestApi(`/card/cards/move-with-order`, { excludeErrors: [403, 404], method: 'POST', body: { boardId, columnId, cardIdWiseOrder } });
  } catch (error) {
    console.error(`Failed to move cards`, error);
  }
}

/**
 * Copy link of the given or selected card.
 * @param {*} param0 {cardId}. Card Id is optional. When it's not given, it get link of selected card.
 */
function* getLink({ cardId }) {
  const state = yield select();
  const config = app.selectors.config(state);

  // If cardId not provided, gets selected card's id.
  if (!cardId) {
    const pageName = router.selectors.pageName(state);
    const selectedCards = pageName === 'BOARD' ? board.selectors.selectedCards(state) : views.selectors.selectedCards(state);
    cardId = get(selectedCards, '0');
  }

  const card = firestoreRedux.selectors.doc(state, 'cards', cardId);
  const boardId = get(card, 'boardId');
  const _board = firestoreRedux.selectors.doc(state, 'boards', boardId);
  const accountId = get(_board, 'accountId');

  if (!cardId || !accountId || !boardId) {
    console.error('Get Link: Card Id is not provided or no selected card found.', {cardId, accountId, boardId});
    return;
  }

  const url = `${config.baseUrl}/${accountId}/board/${boardId}/${cardId}`;
  clipboard.copy(url);
  showSnackBar({ message: i18next.t('card-details:copyCardUrl.successMessage') });
}

/**
 * Resets clipboard.
 * Requests server to copy cards.
 * On successfull copy of all cards, selects copied cards & dispatches `COPY_SUCCESS` action so View element can perform appropriate action.
 * @param {Object} action action payload
 */
function* copyCards(action) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const columnId = action.columnId;
  const copiedCards = clipboardModule.selectors.copiedCards(state);

  if (isEmpty(copiedCards)) {
    return;
  }

  const sourceBoard = get(state, `clipboard.data.boardId`);
  const sourceColumn = get(state, `clipboard.data.columnId`);
  if (!sourceColumn || !sourceBoard) {
    console.warn('Source column or board not found in clipboard data.');
    return;
  }

  const cardsQuery = firestoreRedux.query('cards', { where: [['boardId', '==', sourceBoard], ['columnId', '==', sourceColumn]], orderBy: [['columnOrder']], once: true });
  yield cardsQuery.waitTillResultAvailableInState();
  let cardIds = [];
  forEach(copiedCards, (cardId) => {
    const doc = firestoreRedux.selectors.doc(state, 'cards', cardId);
    if (doc && doc.columnId === sourceColumn) {
      cardIds.push(cardId);
    }
  });

  const toastId = Date.now();
  if (isEmpty(cardIds)) {
    yield put(clipboardModule.actions.reset());
    showSnackBar({ id: toastId, message: 'Copied cards moved to another column or deleted permanently' });
    return;
  }

  const body = { cardIds, boardId, columnId };
  const cardCounts = cardIds.length;
  if(cardCounts) {
    amplitude.updateCardsAddedUserProperty(cardCounts);
  }

  try {
    yield put(clipboardModule.actions.reset());
    showSnackBar({ id: toastId, message: i18next.t('board-card:copy.toast.inProgress', {count: cardCounts}) });
    const docs = yield call(requestApi, `/card/cards/copy`, {
      excludeErrors: [400],
      method: 'POST',
      body
    });
    cardIds = map(docs, 'id');
    yield call(onCopySuccess, cardIds);
    yield put({type: board.actions.CARD_SELECTION_UPDATE, cardIds, columnId });
    yield put({ type: actions.COPY_SUCCESS, columnId });
    toastId && hideSnackbar(toastId);
  } catch (err) {
    if (!err || !err.code || err.status == 504) {
      return;
    }

    if (err.code === 'NOT_FROM_SAME_COLUMN') {
      showSnackBar({ message: i18next.t('board-card:copy.toast.notFromSameColumn'), type: 'ERROR' });
      return;
    }

    if (err.code === 'BOARD_NOT_FROM_SAME_ACCOUNT') {
      showSnackBar({ message: i18next.t('board-card:copy.toast.notAllowed'), type: 'ERROR' });
      return;
    }

    console.error(`Failed to copy selected cards:`, err);
    toastId && hideSnackbar(toastId);
  }
}

const CARDS_COPY_TIMEOUT = 120000; // timeout for cards copy operation.
/**
 * @returns Promise resolved when all cards are copied successfully.
 * If after 2 minutes, promise is not resolved, reject it.
 */
const onCopySuccess = (cardIds) => {
  const promise =  new Promise((resolve, reject) => {
    const unsubscribe = ReduxUtils.subscribe(store, `firestore.docs.cards`, () => {
      const cards = firestoreRedux.selectors.collection(store.getState(), 'cards');
      const copied = every(cardIds, (id) => {
        return cards[id] ? true : false;
      });
      if (copied) {
        resolve();
        unsubscribe && unsubscribe();
      }
    });

    setTimeout(() => {
      if (!promise.isFulfilled) {
        reject('Copied cards are not reflected in firebase realtime database yet.');
        unsubscribe && unsubscribe();
      }
    }, CARDS_COPY_TIMEOUT);
  });
  return promise
}

/**
 * Deletes provided card or selected or all cards from the provided column.
 */
function* permanentDelete(action) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  let currentDocs = [];
  try {
    let cardIds = action.cardIds;
    const columnId = action.columnId;
    if (isEmpty(cardIds)) {
      if (!columnId) {
        return;
      }

      cardIds = board.selectors.selectedCards(state); //Selected cards.

      if (isEmpty(cardIds)) {
        const cards = board.selectors.cardsByColumn(state, columnId) || []; //All the cards of column.
        cardIds = map(cards, 'id');
      }
    }

    if (isEmpty(cardIds)) {
      return;
    }

    forEach(cardIds, (id) => {
      const doc = firestoreRedux.selectors.doc(state, 'cards', id);
      currentDocs.push(doc);
    })

    firestoreRedux.delete(`boards/${boardId}/cards`, cardIds, { localWrite: true });
    const body = { cardIds };
    yield call(requestApi, `/card/cards/delete`, { method: 'POST', body, excludeErrors: [404] });
  } catch (error) {
    if(isNetworkError(error) || error.code === 'CARD_NOT_FOUND' || error.code === 'USER_DELETION_IN_PROGRESS' || error.status == 504) {
      return;
    }
    firestoreRedux.save(`boards/${boardId}/cards`, currentDocs, { localWrite: true });
    console.error('Failed to delete the card permanently.', error);
  }
}

/**
 * Sorts cards within column by provided `sortType` option & `selectedCards`.
 * Request server to update order of cards.
 * @param {Object} action action payload
 */
function* sortCards({sortBy, columnId}) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const cardIdWiseOrder = sortBoardCards(sortBy, columnId);

  if (isEmpty(cardIdWiseOrder)) {
    return;
  }

  let currentCards = [];
  let newCards = [];
  forEach(cardIdWiseOrder, (order, cardId) => {
    const doc = firestoreRedux.selectors.doc(state, 'cards', cardId);
    if (!isEmpty(doc)) {
      currentCards.push(doc);
      const newDoc = cloneDeep(doc);
      newDoc.columnOrder = order;
      newCards.push(newDoc);
    }
  })

  if (isEmpty(currentCards) || isEmpty(newCards)) {
    return;
  }

  try {
    firestoreRedux.save(`boards/${boardId}/cards`, newCards, { localWrite: true });
    yield call(moveCardsApi, { boardId, columnId, cardIdWiseOrder });
    const actionAt = Date.now();
    const actionName = 'SORT';
    const actionId = `${actionName}-${actionAt}`;
    showSnackBar({
      id: actionId,
      message: i18next.t(`board-card:sortToast.${sortBy}`, { count: keys(cardIdWiseOrder).length }),
      actionButton: {
        caption: i18next.t('buttons.undo'),
        callback: () => { store.dispatch(actions.undoSort(currentCards, actionId)) }
      }
    });

    yield put(lastMoveCards.actions.update({boardId, columnId, cards: currentCards, actionBy: auth.selectors.currentUserId(state), actionAt, actionName}));
  } catch (error) {
    firestoreRedux.save(`boards/${boardId}/cards`, currentCards, { localWrite: true });
    console.error(`Failed to sort cards of column: ${columnId}`, error);
  }
}

/**
 * Undo previously performed sort actions.
 * @param {Object} action action payload. e.g {cards}
 * @returns
 */
function* undoSort({cards, actionId}) {
  yield call(undoMove, { cards, actionId });
}

/**
 * Moves card within board before given card, When before not given, moves to last of the column.
 * @param {*} param0
 *  @property {Array} cardIds List of moved card's ids.
 *  @property {String} destColumnId Destination column Id.
 *  @property {String} before Card Id, before which cards will be moved.
 */
function* moveCardsWithinBoardBeforeCard({ cardIds, destColumnId,columnId, before }) {
  if (isEmpty(cardIds) || !destColumnId) {
    console.warn('moveCardsWithinBoardBeforeCard: Please provided mandatory fields.', { cardIds, destColumnId });
    return;
  }
  const state = yield select();
  const destBoardId = router.selectors.pageBoardId(state);

  let cards = [];
  forEach(cardIds, (id) => {
    const doc = firestoreRedux.selectors.doc(state, 'cards', id);
    if (!isEmpty(doc) && doc.boardId === destBoardId) {
      cards.push(doc);
    }
  })

  if (isEmpty(cards)) {
    showSnackBar({ message: i18next.t('board-page:dragAndDrop.message')});
    return;
  }

  const confirmResponse = yield call(moveCards, { destBoardId, destColumnId, cards, before, dragAndDrop: true });
  if(confirmResponse) {
    yield put(lastMoveCards.actions.update({boardId: destBoardId, columnId, cards, actionBy: auth.selectors.currentUserId(state), actionAt: Date.now(), actionName: 'MOVE_CARDS_WITHIN_BOARD_BEFORE_CARD'}));
  }
}

/**
 *
 * @param {*} param0
 *  @property {String} destBoardId Destination board Id
 *  @property {String} destColumnId Destination column Id.
 *  @property {Array} cards List of cards to be moved.
 *  @property {String} before before card id. If it's not provided, it considers first card as before.
 *  @property {String} dragAndDrop `true` when cards are moved through drag & drop. When it's `true` & `before` is not given, card(s) will be moved at bottom otherwise at top.
 */
function* moveCards({ destBoardId, destColumnId, cards, before, dragAndDrop }) {
  let state = yield select();
  let newCards = cloneDeep(cards);
  let openTaskCount = 0;
  forEach(cards, (card) => {
    const summary = selectors.summary(state, card.id);
    if(summary && summary.tasks && summary.tasks.open) {
      openTaskCount = openTaskCount + summary.tasks.open;
    }
  });

  //load dest column data.
  let destColumn = columnsSelectors.column(state, destColumnId);
  if(isEmpty(destColumn)) {
    const destColumnQuery = firestoreRedux.getDocById(`boards/${destBoardId}/columns`, destColumnId, {waitTillSucceed: true, once: true});
    destColumn = yield destColumnQuery.result;
  }
  const destColumnType = destColumn && destColumn.type;
  const lastMovedAt = Date.now();
  forEach(newCards, (card) => {
    card.columnId = destColumnId;
    card.previousBoardId = card.boardId;
    card.boardId = destBoardId;
    card.columnType = destColumnType || card.columnType;
    card.lastMovedAt = lastMovedAt;
  });

  const allItems = board.selectors.cardsByColumn(state, destColumnId, destBoardId) || [];
  if(destColumnType === 'DONE' || destColumnType === 'TRASH') {
    before = get(head(allItems), 'id');
  }

  before = before ? before : !dragAndDrop ? get(head(allItems), 'id') : get(last(allItems, 'id'));
  newCards = utils.getNewOrder({ allItems, movedItems: newCards, before, sortByKey: 'columnOrder', ignoreAllItemsMove: (destColumnType === 'DONE' || destColumnType === 'TRASH') });

  let cardIdWiseOrder = {};
  forEach(newCards, (item) => {
    cardIdWiseOrder[item.id] = (destColumnType === 'DONE' || destColumnType === 'TRASH') ? null: item.previousBoardId != destBoardId ? null: item.columnOrder;
  });

  if (isEmpty(cardIdWiseOrder)) {
    return;
  }

  let columnWiseCardOrders = {};
  forEach(cardIdWiseOrder, (order, cardId) => {
    const cardDoc = firestoreRedux.selectors.doc(state, 'cards', cardId);
    const columnId = cardDoc.columnId;
    columnWiseCardOrders[columnId] = columnWiseCardOrders[columnId] || {};
    columnWiseCardOrders[columnId][cardId] = order;
  })

  try {
    const destColumnQueryId = destColumnType === 'DONE' ? `done_cards_${destBoardId}`: destColumnType === 'TRASH' ? `trash_cards_${destBoardId}`: `cards_${destBoardId}`;
    firestoreRedux.save(`boards/${destBoardId}/cards`, newCards, { localWrite: true, queryId: destColumnQueryId });

    //Get confirmation when openTaskCount and destColumnType is DONE.
    let confirmResponse = true;
    if(openTaskCount && destColumnType === 'DONE') {
      const promise = yield call(openCardMoveToDoneConfirmDialog, openTaskCount, cards && cards.length || 0);
      confirmResponse = yield promise;
    }

    if(!confirmResponse) {
      firestoreRedux.save(`boards/${destBoardId}/cards`, cards, { localWrite: true, queryId: destColumnQueryId });
    } else {
      forEach(columnWiseCardOrders, async (cardIdWiseOrder) => {
        if(!isEmpty(cardIdWiseOrder)) {
          await moveCardsApi({ boardId: destBoardId, columnId: destColumnId, cardIdWiseOrder });
        }
      })
    }

    return confirmResponse;
  } catch (error) {
    firestoreRedux.save(`boards/${destBoardId}/cards`, cards, { localWrite: true });
    throw new Error(error);
  }
}

function* openCardMoveToDoneConfirmDialog(openTaskCount, cardCount) {
  try {
    let resolve, reject;
    const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
    yield import('../../components/card-move-to-done-confirm-dialog.js');
    const dialog = kerikaPWA && kerikaPWA.cardMoveToDoneConfirmDialog;
    if(dialog) {
      dialog.openTaskCount = openTaskCount;
      dialog.cardCount = cardCount;
      dialog.opened = true;
      dialog.addEventListener('confirm', ()=> {resolve(true);});
      dialog.addEventListener('decline', ()=> {resolve(false);});
    } else {
      resolve(true);
    }
    return promise;
  } catch (error) {
    console.error("card > saga > openCardMoveToDoneConfirmDialog: failed due to this: ", error);
  }
}

/**
 * Undo moves
 * @param {*} param0
 *  @property {Array}  cards Cards with it's previous orders.
 */
function* undoMove({ cards, actionId }) {
  const state = yield select();
  const isUndoAvailable = lastMoveCards.selectors.isUndoAvailable(state);
  // It considers that cards are from the same column.
  const boardId = get(cards, '0.boardId');
  const columnId = get(cards, '0.columnId');
  let cardIdWiseOrder = {};
  let cardIds = [];
  forEach(cards, (card) => {
    cardIdWiseOrder[card.id] = card.columnOrder;
    cardIds.push(card.id);
  })

  try {
    if(actionId && isUndoAvailable) {
      yield put(lastMoveCards.actions.remove(actionId));
    }
    firestoreRedux.save(`boards/${boardId}/cards`, cards, { localWrite: true });
    yield call(moveCardsApi, { boardId, columnId, cardIdWiseOrder });
    yield delay(100);
    yield put({type: board.actions.CARD_SELECTION_UPDATE, cardIds, columnId });
  } catch (error) {
    console.error(`Failed to undo move cards`, error);
  }
}

/*
 * Moves selected cards to top of the column with localWrites.
 */
function* moveSelectedCardsToTop() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const columnId = board.selectors.selectedColumn(state);
  const selectedCards = board.selectors.selectedCards(state);
  if (!boardId || !columnId || isEmpty(selectedCards)) {
    return;
  }
  const allItems = board.selectors.cardsByColumn(state, columnId) || [];
  const currentItems = filter(allItems, (item) => selectedCards.includes(item.id));
  const withoutMovedItems = filter(allItems, (item) => !selectedCards.includes(item.id));
  const before = get(head(withoutMovedItems), 'id');

  const newItems = utils.getNewOrder({ allItems, movedItems: currentItems, before, sortByKey: 'columnOrder' });
  let cardIdWiseOrder = {};
  forEach(newItems, (item) => {
    cardIdWiseOrder[item.id] = item.columnOrder
  })

  if (isEmpty(cardIdWiseOrder)) {
    return;
  }

  try {
    firestoreRedux.save(`boards/${boardId}/cards`, newItems, { localWrite: true });
    yield call(moveCardsApi, { boardId, columnId, cardIdWiseOrder });
    yield put(lastMoveCards.actions.update({boardId, columnId, cards: currentItems, actionBy: auth.selectors.currentUserId(state), actionAt: Date.now(), actionName: 'CARDS_MOVE_TO_TOP'}));
  } catch (error) {
    firestoreRedux.save(`boards/${boardId}/cards`, currentItems, { localWrite: true });
    console.error(`Failed to move cards at top`, { boardId, columnId, cardIdWiseOrder, error });
  }
}

/**
 * Moves selected cards to bottom of the column with localWrites.
 */
 function* moveSelectedCardsToBottom() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const columnId = board.selectors.selectedColumn(state);
  const selectedCards = board.selectors.selectedCards(state);
  if (!boardId || !columnId || isEmpty(selectedCards)) {
    return;
  }
  const allItems = board.selectors.cardsByColumn(state, columnId) || [];
  const currentItems = filter(allItems, (item) => selectedCards.includes(item.id));

  const newItems = utils.getNewOrder({ allItems, movedItems: currentItems, sortByKey: 'columnOrder' });
  let cardIdWiseOrder = {};
  forEach(newItems, (item) => {
    cardIdWiseOrder[item.id] = item.columnOrder
  })

  if (isEmpty(cardIdWiseOrder)) {
    return;
  }

  try {
    firestoreRedux.save(`boards/${boardId}/cards`, newItems, { localWrite: true });
    yield call(moveCardsApi, { boardId, columnId, cardIdWiseOrder });
    yield put(lastMoveCards.actions.update({boardId, columnId, cards: currentItems, actionBy: auth.selectors.currentUserId(state), actionAt: Date.now(), actionName: 'CARDS_MOVE_TO_BOTTOM'}));
  } catch (error) {
    firestoreRedux.save(`boards/${boardId}/cards`, currentItems, { localWrite: true });
    console.error(`Failed to move cards at bottom`, { boardId, columnId, cardIdWiseOrder, error});
  }
}

/**
 * Moves given or selected or all cards of the column to the Done column.
 * @param {*} param0
 *  @property {String} columnId Source column Id.
 *  @property {Array} selectedCards List of card Ids. It's optional. When it's not provided, done all the card of source column.
 */
function* moveCardsToDone({columnId, selectedCards}) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  let selectedColumn = board.selectors.selectedColumn(state);
  selectedColumn = columnId || selectedColumn;
  selectedCards = selectedCards || board.selectors.selectedCards(state);
  const cards = yield call(getCardsToBeMoved, { selectedCards, selectedColumn });

  if (isEmpty(cards)) {
    return;
  }
  const destBoardId = get(cards, '0.boardId')
  const columns = board.selectors.columns(state, destBoardId);
  const destColumnId = get(find(columns, { type: 'DONE' }), 'id');

  try {
    yield put(board.actions.clearSelection());
    const confirmResponse = yield call(moveCards, { destBoardId, destColumnId, cards });
    if(confirmResponse) {
      const actionAt = Date.now();
      const actionName = 'MOVE_TO_DONE';
      const actionId = `${actionName}-${actionAt}`;
      showSnackBar({ id: actionId, message: i18next.t('board-card:doneToast.message'), actionButton: { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.undoMove({ cards, actionId })) } } });
      yield put(lastMoveCards.actions.update({boardId, columnId: selectedColumn, cards, actionBy: auth.selectors.currentUserId(state), actionAt, actionName}));
    }
  } catch (error) {
    console.error('Failed to move cards to Done', error);
  }
}

/**
 * Moves given or selected or all cards of the column to the Trash column.
 * @param {*} param0
 *  @property {String} columnId Source column Id.
 *  @property {Array} selectedCards List of card Ids. It's optional. When it's not provided, trashes all the card of source column.
 */
function* moveCardsToTrash({columnId, selectedCards}) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  let selectedColumn = board.selectors.selectedColumn(state);
  selectedColumn = columnId || selectedColumn;
  selectedCards = selectedCards || board.selectors.selectedCards(state);
  const cards = yield call(getCardsToBeMoved, { selectedCards, selectedColumn });

  if (isEmpty(cards)) {
    return;
  }
  const destBoardId = get(cards, '0.boardId')
  const columns = board.selectors.columns(state, destBoardId);
  const destColumnId = get(find(columns, { type: 'TRASH' }), 'id');

  try {
    yield put(board.actions.clearSelection());
    const confirmResponse = yield call(moveCards, { destBoardId, destColumnId, cards });
    if(confirmResponse) {
      const actionAt = Date.now();
      const actionName = 'MOVE_TO_TRASH';
      const actionId = `${actionName}-${actionAt}`;
      showSnackBar({ id: actionId, message: i18next.t('board-card:trashToast.message'), actionButton: { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.undoMove({ cards, actionId })) } } });
      yield put(lastMoveCards.actions.update({boardId, columnId: selectedColumn, cards, actionBy: auth.selectors.currentUserId(state), actionAt, actionName}));
    }
  } catch (error) {
    console.error('Failed to move cards to Trash', error);
  }
}

/**
 * Moves selected or all cards of the column to another column of the board.
 * @param {Object} action action payload e.g {type: CARDS_MOVE_TO_COLUMN, srcColumnId, destColumnId}
 */
function* moveCardsToAnotherColumn({srcColumnId, destColumnId}) {
  const state = yield select();
  const destBoardId = router.selectors.pageBoardId(state);
  const selectedColumn = srcColumnId || board.selectors.selectedColumn(state);
  const selectedCards = board.selectors.selectedCards(state);

  const cards = yield call(getCardsToBeMoved, { selectedCards, selectedColumn });
  if (isEmpty(cards)) {
    return;
  }

  try {
    yield put(board.actions.clearSelection());
    const confirmResponse = yield call(moveCards, { destBoardId, destColumnId, cards });
    if(confirmResponse) {
      const actionAt = Date.now();
      const actionName = 'CARDS_MOVE_TO_COLUMN';
      const actionId = `${actionName}-${actionAt}`;
      const columnName = columnsSelectors.column(state, destColumnId).name;
      const actionButton = { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.undoMove({ cards, actionId })) } };
      showSnackBar({ id: actionId, message: i18next.t('board-card:moveToAnotherColumn.toast', {columnName}), actionButton });
      yield put(lastMoveCards.actions.update({boardId: destBoardId, columnId: selectedColumn, cards, actionBy: auth.selectors.currentUserId(state), actionAt, actionName}));
    }
  } catch (error) {
    console.error('Failed to move cards to another column', error);
  }
}

/**
 * Moves selected or all cards of the column to another column of the board.
 * @param {Object} action action payload e.g { type: CARDS_MOVE_TO_BOARD, srcColumnId, destColumnId, destBoardId }
 */
function* moveCardsToAnotherBoard({ destBoardId, srcColumnId, destColumnId }) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const selectedColumn = srcColumnId || board.selectors.selectedColumn(state);
  const selectedCards = board.selectors.selectedCards(state);
  const cards = yield call(getCardsToBeMoved, { selectedCards, selectedColumn });
  if (isEmpty(cards)) {
    return;
  }

  try {
    const confirmResponse = yield call(moveCards, { destBoardId, destColumnId, cards });
    if(confirmResponse) {
      const accountId = router.selectors.accountId(state);
      const boardName = get(state, `writableBoards.${accountId}.favorite.${destBoardId}`) || get(state, `writableBoards.${accountId}.other.${destBoardId}`);
      const columnName = columnsSelectors.column(state, destColumnId).name;
      const actionAt = Date.now();
      const actionName = 'CARDS_MOVE_TO_BOARD';
      const actionId = `${actionName}-${actionAt}`;
      const actionButton = { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.undoMove({ cards, actionId })) } };
      showSnackBar({ id: actionId, message: i18next.t('board-card:moveToAnotherBoardDialog.toast', {boardName, columnName}), actionButton });
      yield put(lastMoveCards.actions.update({boardId, columnId: selectedColumn, cards, actionBy: auth.selectors.currentUserId(state), actionAt, actionName}));
    }
  } catch (error) {
    console.error('Failed to move cards to another board', error);
  }
}

/**
 * @param {*} param0
 *  @property {Array} selectedCards List of selected card's ids.
 *  @property {String} selectedColumn Selected column Id. When selected cards provided, it's ignored, otherwise gets all cards of the selected column.
 * @returns {Array} list of card's documents.
 */
 function* getCardsToBeMoved({ selectedCards, selectedColumn }) {
  const state = yield select();
  if (isEmpty(selectedCards) && !selectedColumn) {
    return;
  }

  if (isEmpty(selectedCards) && selectedColumn) {
    const cardsByColumn = board.selectors.cardsByColumn(state, selectedColumn) || [];
    selectedCards = map(cardsByColumn, 'id');
  }

  if (isEmpty(selectedCards)) {
    return;
  }

  let cards = [];
  forEach(selectedCards, (id) => {
    const doc = firestoreRedux.selectors.doc(state, 'cards', id);
    if (doc) {
      cards.push(doc);
    }
  })

  return cards;
}

/**
 * Moves selected cards to Done column.
 * When no card is selected, moves all cards of the given column to Done column.
 */
function* moveViewsCardsToDone({ columnId }) {
  const state = yield select();
  const boardWiseCardIds = yield call(getViewsCardsToBeMoved, columnId);
  try {
    let openTaskCount = 0;
    let _cardIds = [];
    forEach(boardWiseCardIds, (cardIds, boardId) => {
      forEach(cardIds, (cardId) => {
        _cardIds.push(cardId);
        const summary = selectors.summary(state, cardId);
        if(summary && summary.tasks && summary.tasks.open) {
          openTaskCount = openTaskCount + summary.tasks.open;
        }
      });
    });

    //Get confirmation when openTaskCount and destColumnType is DONE.
    let confirmResponse = true;
    if(openTaskCount) {
      const promise = yield call(openCardMoveToDoneConfirmDialog, openTaskCount, _cardIds && _cardIds.length || 0);
      confirmResponse = yield promise;
    }

    if(!confirmResponse) {
      return;
    }

    forEach(boardWiseCardIds, (cardIds, boardId) => {
      if (isEmpty(cardIds)) {
        console.warn('moveViewsCardsToDone: No cards available for move');
        return;
      }
      const body = { boardId, cardIds, columnType: 'DONE' };
      //TODO
      requestApi(`/card/cards/move`, { excludeErrors: [400, 403, 404], method: 'POST', body });
    });
    showSnackBar({ message: i18next.t('board-card:doneToast.message'), actionButton: { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.restoreViewsCards(boardWiseCardIds)) } } });
  } catch (error) {
    console.error('Views error while moving cards to Done', error);
  }
}

/**
 * Moves selected cards to Trash column.
 * When no card is selected, moves all cards of the given column to Trash column.
 */
function* moveViewsCardsToTrash({ columnId }) {
  const boardWiseCardIds = yield call(getViewsCardsToBeMoved, columnId);
  try {
    forEach(boardWiseCardIds, (cardIds, boardId) => {
      if (isEmpty(cardIds)) {
        console.warn('moveViewsCardsToTrash: No cards available for move');
        return;
      }
      const body = { boardId, cardIds, columnType: 'TRASH' };
      requestApi(`/card/cards/move`, { excludeErrors: [400, 403, 404], method: 'POST', body });
    });
    showSnackBar({ message: i18next.t('board-card:trashToast.message'), actionButton: { caption: i18next.t('buttons.undo'), callback: () => { store.dispatch(actions.restoreViewsCards(boardWiseCardIds)) } } });
  } catch (error) {
    console.error('Views error while moving cards to Trash', error);
  }
}

/**
 * Restores given cards to it's previous column.
 * @param {Object} boardWiseCardIds Board wise card ids. e.g {'brd_5jgpem59gldl': ['crd_4jnbmkfvjdk', ...], ...}
 */
function* restoreViewsCards({ boardWiseCardIds }) {
  try {
    forEach(boardWiseCardIds, (cardIds) => {
      const body = { cardIds };
      requestApi(`/card/cards/restore`, { method: 'POST', body, excludeErrors: [400, 409] });
    });
  } catch (error) {
    console.error('Views error while restoring cards from Done or Trash column.', error);
  }
}

/**
 * @param {String} columnId Column Id of VIEW from which cards are being moved to done / trash.
 * @returns {Object} Boardwise card ids e.g. {$boardId: [$cardId1, $cardId2, ...], ...}
 */
function* getViewsCardsToBeMoved(columnId) {
  const state = yield select();
  const selectedCards = views.selectors.selectedCards(state);
  columnId = columnId || views.selectors.selectedColumn(state);

  let cards = selectedCards;
  if (isEmpty(cards) && columnId) {
    const cardsByColumn = get(views.selectors.cardsList({ state, columnId }), 'cards');
    cards = map(cardsByColumn, 'cardId');
  }


  // Returns if no cards found selected or in given column.
  if (isEmpty(cards)) {
    return;
  }


  // Filter out cards which have write permission on board.
  let boardWiseCardIds = {};
  forEach(cards, (cardId) => {
    const cardAttr = selectors.attrs(state, cardId);
    const boardId = get(cardAttr, 'boardId');
    const boardEffectivePemrission = board.selectors.boardEffectivePermission(state, boardId);
    if (boardEffectivePemrission && boardEffectivePemrission.hasWrite()) {
      if (!boardWiseCardIds[boardId]) {
        boardWiseCardIds[boardId] = [cardId];
      } else {
        boardWiseCardIds[boardId].push(cardId);
      }
    }
  })
  return boardWiseCardIds;
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    takeEvery(actions.LOAD_CARDS_BY_COLUMN_TYPE, loadCardsByColumnType),
    takeEvery(actions.DISCONNECT_CARDS_BY_COLUMN_TYPE, disconnectCardsByColumnType),
    takeEvery(actions.LOAD_BOARD_CARDS, loadBoardCards),
    takeEvery(actions.ADD_NEW_CARD, addNewCard),
    takeEvery(actions.MOVE_TO_DONE, moveToDone),
    takeEvery(actions.MOVE_TO_TRASH, moveToTrash),
    takeEvery(actions.RESTORE, restore),
    takeEvery(actions.RESTORE_ALL, restoreAll),
    takeEvery(actions.UNDO_RESTORE, undoRestore),
    takeEvery(actions.GET_LINK, getLink),
    takeEvery(actions.COPY, copyCards),
    takeEvery(actions.PERMANENT_DELETE, permanentDelete),
    takeEvery(actions.SORT, sortCards),
    takeEvery(actions.UNDO_SORT, undoSort),
    takeEvery(actions.MOVE_CARDS_TO_TOP, moveSelectedCardsToTop),
    takeEvery(actions.MOVE_CARDS_TO_BOTTOM, moveSelectedCardsToBottom),
    takeEvery(actions.MOVE_CARDS_TO_DONE, moveCardsToDone),
    takeEvery(actions.MOVE_CARDS_TO_TRASH, moveCardsToTrash),
    takeEvery(actions.UNDO_MOVE, undoMove),
    takeEvery(actions.MOVE_CARDS_TO_ANOTHER_COLUMN, moveCardsToAnotherColumn),
    takeEvery(actions.MOVE_CARDS_ACROSS_BOARD, moveCardsToAnotherBoard),
    takeEvery(actions.MOVE_CARDS_WITHIN_BOARD_BEFORE_CARD, moveCardsWithinBoardBeforeCard),
    takeEvery(actions.MOVE_VIEWS_CARDS_TO_DONE, moveViewsCardsToDone),
    takeEvery(actions.MOVE_VIEWS_CARDS_TO_TRASH, moveViewsCardsToTrash),
    takeEvery(actions.RESTORE_VIEWS_CARDS, restoreViewsCards)
  ]);
}

  export default saga;