
/**
 * @param {Object} data - Path name
 */
export const pageNotFoundRouter = async (data) => {
  data.page = {
    name: 'NOT_FOUND',
    params: {}
  };
  
  return data;
}