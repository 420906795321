import { put, takeEvery, take, takeLatest, call, select, all, debounce, fork, cancel } from 'redux-saga/effects';
import * as router from '../router';
import * as auth from '../auth';
import * as selectors from './selectors.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import { requestApi } from '../../request-api';
import isEqual from 'lodash-es/isEqual';

import {
  PAGE_OPENED,
  PAGE_CLOSED,
  PREFERENCES,
  PREFERENCES_DONE,
  PREFERENCES_FAILED
} from './actions.js';
import { merge } from 'lodash-es';

let previousPage;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = state.router.page.name;
  if (currentPage === 'ACCOUNT_PREFERENCES') {
    if (previousPage !== currentPage) {
      yield put({ type: PAGE_OPENED });
    }
  } else if (previousPage === 'ACCOUNT_PREFERENCES') {
    yield put({ type: PAGE_CLOSED });
  }
  previousPage = state.router.page.name;
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

function* accountPreferencesFlow() {
  while (yield take(PAGE_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(loadAccountPreferences),
        takeEvery(PREFERENCES, updatePreferences),
        takeEvery(router.actions.ACCOUNT_CHANGED, accountChanged)
      ]);
    })
    yield take(PAGE_CLOSED);
    yield call(disconnect);
    yield cancel(task);
  }
}

function* accountChanged(action) {
  const currentAccountId = action && action.currentAccountId;
  const prevAccountId = action && action.prevAccountId;
  if(prevAccountId) {
    yield call(disconnect, prevAccountId)
  }

  if(currentAccountId) {
    yield call(loadAccountPreferences, currentAccountId);
  }
}

/**
 * Load account preferences from firebase.
 */
function* loadAccountPreferences(accountId) {
  let state = yield select();
  accountId = accountId || router.selectors.accountId(state);
  let userId = auth.selectors.currentUserId(state);
  if(userId && accountId) {
    firestoreRedux.query('account-user-preferences', { id: `account-user-preferences-${accountId}-${userId}`,  where: [['userId', '==', userId], ['accountId', '==', accountId]], requesterId: `account-preferences-${accountId}-${userId}` });
  }
}

/**
 * Disconnect from firestore.
 */
function* disconnect(accountId) {
  let state = yield select();
  accountId = accountId || router.selectors.accountId(state);
  let userId = auth.selectors.currentUserId(state);
  if (userId && accountId) {
    firestoreRedux.cancelQueryByRequester(`account-preferences-${accountId}-${userId}`);
  }
}

/**
 * Send request to preferences Change for account.
 * @param {Object} action action payload.
 */
function* updatePreferences(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const currentPreferenceDoc = selectors.accountPreferences(state);
  const newPreferenceDoc = merge({}, currentPreferenceDoc, action.preferences);
  const localWritePath = `accounts/${accountId}/account-user-preferences`;
  try {
    if(isEqual(currentPreferenceDoc, newPreferenceDoc)) {
      console.warn("updatePreferences > current and new account preferences are same.")
      return;
    }
    firestoreRedux.save(localWritePath, newPreferenceDoc, { localWrite: true });
    yield requestApi(`/account/preferences/${accountId}`, { method: 'PUT', body: newPreferenceDoc });
    yield put({ type: PREFERENCES_DONE });
  } catch (err) {
    firestoreRedux.save(localWritePath, currentPreferenceDoc, { localWrite: true });
    yield put({ type: PREFERENCES_FAILED });
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(watchRouter),
    call(accountPreferencesFlow)
  ]);
}

export default saga;