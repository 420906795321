import * as actions from "./actions";

const INITIAL_STATE = {
  changePreferences: { inProgress: false }
};

export const accountPreferences = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.PREFERENCES:
      return { ...state, changePreferences: { inProgress: true } };
    case actions.PREFERENCES_DONE:
    case actions.PREFERENCES_FAILED:
      return { ...state, changePreferences: { inProgress: false } };
    default:
      return state;
  }
};
