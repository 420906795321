/**
 * @param {object} data - Passed data.
 * @return Promise - This promise function is reject if authentication is completed otherwise resolve it..
 */
export const waitForAuth = (data) => {
  return new Promise(function (resolve, reject) {
    if (
      data.user === undefined ||
      data.ownedAccounts === undefined ||
      data.lastAccessibleAccount === undefined ||
      data.accessibleAccounts === undefined ||
      data.accessibleBoards === undefined
    ) {
      resolve(data);
      return;
    }
    reject(data);
  });
};
