

let clarityAPIKey;

/**
 * Initialize clarity.
 * @param {Object} params
 *  - @property {String} APIKey
 */
export const init = ({APIKey}) => {
  clarityAPIKey = APIKey;
  if(!clarityAPIKey) {
    return;
  }

  console.debug("clarity > Initialize");
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", clarityAPIKey);
}

/**
 * Set's user-id.
 * @param {Number} userId
 */
export const setUserId = (userId) => {
  if(!clarityAPIKey) {
    return;
  }

  console.debug("clarity > Set userId");
  window.clarity("identify", `${userId}`);
  window.clarity("set", "kerika_user_id", `${userId}`);
}

/**
 * Set's tag.
 * @param {String} name tag name 
 * @param {String} value tag value
 */
export const setTag = (name, value) => {
  if(!clarityAPIKey) {
    return;
  }

  console.debug(`clarity > Set ${name} tag`, value);
  window.clarity("set", `${name}`, `${value}`);
}