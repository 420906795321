
export const MARK_AS_KNOWN = 'DOWNLOAD_APP_MARK_AS_KNOWN';

/**
 * Dispatch `MARK_AS_KNOWN` action to marks download app dialog is presented for user.
 */
export const markAsKnown = () => {
  return { 
    type: MARK_AS_KNOWN 
  };
}
