import { takeEvery, all, call, select, put, take, fork, cancel, debounce } from 'redux-saga/effects';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as unreadHighlights from '../unread-highlights';
import * as board from '../board';
import * as boardTagsSelectors from '../board-tags/selectors.js';
import * as actions from './actions';
import * as selectors from './selectors';
import { store } from '../../store';
import { requestApi, isNetworkError } from "../../request-api"
import firestoreRedux from '@dreamworld/firestore-redux';
import uniq from 'lodash-es/uniq';
import map from 'lodash-es/map';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import without from 'lodash-es/without';
import forEach from 'lodash-es/forEach';
import last from 'lodash-es/last';
import findIndex from 'lodash-es/findIndex';
import find from 'lodash-es/find';
import slice from 'lodash-es/slice';
import reverse from 'lodash-es/reverse';
import { getIdWoPrefix } from '../../utils';
import entityIdProvider from '../../entity-id-provider';

/**
 * Loads views-preferences from firestore.
 * Firebase path: views/$userId/view/$id
 * @returns {Promise}
 */
function* loadPreferences() {
  try {
    const state = yield select();
    const userId = auth.selectors.currentUserId(state);
    const id = get(state, 'router.page.params.viewId');
    const viewId = selectors.currentUserViewId(state, id);
    const requesterId = `view-preferences`;
    firestoreRedux.getDocById(`views/${userId}/view`, viewId, { requesterId });
  } catch (error) {
    console.error('Failed to load view preferences', error);
  }
}

/**
 * Requests to update current view groupBy or criteria.
 */
function* updatePreferences({ groupBy, assignedToMe }) {
  const state = yield select();
  const id = get(state, 'router.page.params.viewId');
  const viewId = selectors.currentUserViewId(state, id);
  const userId = auth.selectors.currentUserId(state);
  const currentDoc = firestoreRedux.selectors.doc(state, 'view', viewId);
  if (isEmpty(currentDoc)) {
    console.warn('views: updatePrefernces: view preferences is not loaded yet.');
    return;
  }
  try {
    groupBy = groupBy !== undefined ? groupBy : currentDoc.groupBy
    assignedToMe = assignedToMe !== undefined ? assignedToMe : currentDoc.assignedToMe;
    const newDoc = { ...currentDoc, groupBy, assignedToMe };
    firestoreRedux.save(`views/${userId}/view`, newDoc, { localWrite: true });
    yield requestApi(`/views/system-views/${viewId}/preferences`, { method: 'PUT', body: { groupBy, assignedToMe, userId } });
    yield call(refresh);
  } catch (error) {
    firestoreRedux.save(`views/${userId}/view`, currentDoc, { localWrite: true });
    console.error('Failed to update view groupBy or criteria', error);
  }
}

/**
 * Loads last active column of the current view from firestore. (Only in mobile layout.)
 */
function* loadScrollPosition() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const viewId = selectors.currentViewId(state);
  const wideLayout = app.selectors.wideLayout(state);
  if (wideLayout) {
    return;
  }
  firestoreRedux.query('views-last-active-columns',
    {
      id: `views-last-active-columns-${viewId}`,
      where: [['viewId', '==', viewId], ['userId', '==', userId]],
      requesterId: `views-last-active-columns`
    }
  );
}

/**
 * Loads `views-hidden-columns` from firestore.
 */
function* loadHiddenColumns() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const viewId = selectors.currentViewId(state);
  firestoreRedux.query('views-hidden-columns',
    {
      id: `views-hidden-columns-${viewId}`,
      where: [['viewId', '==', viewId], ['userId', '==', userId]],
      requesterId: `views-hidden-columns`
    }
  );
}


/**
 * Loads participated boards & their accounts from firestore.
 */
function* loadParticipatedBoards() {
  try {
    const state = yield select();
    const requesterId = `view-participated-boards`;
    const userId = auth.selectors.currentUserId(state);
    const boardTeamMemberQuery = firestoreRedux.query('board-team-members', { where: [['userId', '==', userId]], once: true });
    const boardQuery = firestoreRedux.query('boards', { where: [['ownerId', '==', userId]], once: true });
    const teamMemberQueryResult = yield boardTeamMemberQuery.result;
    const boardQueryResult = yield boardQuery.result;
    const teamMembersBoardIds = map(teamMemberQueryResult, 'boardId');
    const ownedBoardIds = map(boardQueryResult, 'id');
    const data = uniq([...teamMembersBoardIds, ...ownedBoardIds]);
    // Loads all participated boards from firestore.
    let boardQueries = [];
    forEach(data, (boardId) => {
      const query = firestoreRedux.getDocById('boards', boardId, { requesterId });
      boardQueries.push(query.result);
    })
    const allBoardsAttrs = yield Promise.all(boardQueries); // Resolves when all boards are loaded.

    // Gets accountId from all participated boards list.
    let accountIds = [];
    forEach(allBoardsAttrs, (attr) => {
      accountIds.push(attr.accountId);
    })

    // Loads all accounts from firestore.
    accountIds = uniq(accountIds);
    let accountQueries = [];
    forEach(accountIds, (id) => {
      const query = firestoreRedux.getDocById('accounts', id, { requesterId });
      accountQueries.push(query.result);
    })
    yield Promise.all(accountQueries); // Resolves when all accounts loaded.

    yield put({ type: actions.LOAD_PARTICIPATED_BOARDS_DONE, data });
  } catch (err) {
    console.error('Error while load participated boards', err);
    yield put({ type: actions.LOAD_PARTICIPATED_BOARDS_FAILED });
  }
}


/**
 * Loads view settings data from firestore.
 */
function* loadViewSettings() {
  try {
    const state = yield select();
    const userId = auth.selectors.currentUserId(state);
    const docId = `vws_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
    const requesterId = `view-settings`;
    firestoreRedux.getDocById('view-settings', docId, { requesterId });
  } catch (error) {
    console.error('Failed to load view settings data', error);
  }
}

/**
 * Requests to update view settings of current user.
 * @param {*} param0
 *  @property {String} includeBoards Possible values: 'FAVORITE', 'ALL' or 'SELECTED'
 *  @property {Array} includeBoardIds List of board ids. It's considered only when value of `includeBoards` is 'SELECTED'
 */
function* updateSettings({ includeBoards, includeBoardIds }) {
  try {
    if (includeBoards !== 'SELECTED') {
      includeBoardIds = undefined;
    }
    yield requestApi('/views/view-settings', { method: 'PUT', body: { includeBoards, includeBoardIds } });
    yield call(refresh);
  } catch (error) {
    console.error('Failed to update view settings', error);
  }
}

/**
 * Performs write operation on firestore to update last active column details for current view.
 */
function* updateScrollPosition({columnIndex}) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const viewId = selectors.currentViewId(state);
  const columns = selectors.columns(state);
  const dataLoadInProgress = selectors.initDataLoadInProgress(state);
  const currentViewGroupBy = selectors.currentViewGroupBy(state);
  const column = get(columns, columnIndex);
  const wideLayout = app.selectors.wideLayout(state);
  if (wideLayout || isEmpty(column) || dataLoadInProgress) {
    return;
  }

  const allLastActiveColumns = firestoreRedux.selectors.docsByQueryResult(state, `views-last-active-columns-${viewId}`, 'views-last-active-columns');
  let currentDoc = find(allLastActiveColumns, { groupBy: currentViewGroupBy });
  if (!currentDoc) {
    currentDoc = {
      id: `vlac_${entityIdProvider.getId()}`,
      userId,
      viewId,
      groupBy: currentViewGroupBy
    }
  }
  const newDoc = { ...currentDoc, columnId: column.id, columnIndex };
  const collectionPath = `users/${userId}/views-last-active-columns`;
  try {
    firestoreRedux.save(collectionPath, newDoc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    console.error(`Failed to update scroll position of view`, error, viewId, currentViewGroupBy);
  }

}

/**
 * Based on current view id & groupBy of current view, & scrollPosition of current view,
 * filters all cards of column & dispatch MARK_AS_READ redux action.
 */
function* markColumnAsRead(action) {
  const state = yield select();
  const columnId = action.columnId;
  const allColumns = selectors.columns(state);
  const activeColumnId = get(filter(allColumns, (column) => column.id == columnId), '0.id');
  if (!activeColumnId) {
    return;
  }
  const cards = selectors.cardsList({state, columnId: activeColumnId}).cards;
  if (cards) {
    let cardIds = [];
    for (let card of cards) {
      cardIds.push(card.cardId);
    }
    yield call(store.dispatch, unreadHighlights.actions.markAllAsRead(cardIds));
    //Refresh data after 1 seconds. So users can get updated data from server.
    setTimeout(() => {
      store.dispatch({ type: actions.MARK_COLUMN_AS_READ_DONE });
    }, 1000);
  }
}


/**
 * Requests server to refresh current view.
 */
function* refresh() {
  const state = yield select();
  const pageName = router.selectors.pageName(state);
  if(pageName !== 'VIEWS') {
    return;
  }

  const viewId = get(state, 'router.page.params.viewId');
  const userId = auth.selectors.currentUserId(state);
  try {
    const userIdWoPrefix = getIdWoPrefix({ id: userId, prefix: 'usr_' });
    const id = `vw_${userIdWoPrefix}_${viewId}`;
    const data = yield call(requestApi, `/views/views/${id}/refresh`, { method: 'PUT' });
    yield put(actions._refreshDone(viewId, data));
    yield call(loadViewData, data);
  } catch (error) {
    yield put(actions._refreshFailed(viewId, error && error.code || 'UNKNOWN'));
    if(isNetworkError(error)) {
      return;
    }
    console.error(`Failed to refresh view`, { viewId, error });
  }
}

/**
 * Loads data of current view.
 */
let _boardIds = [];
let _cardIds = {};
let summaryQuery, dataQuery;
function* loadViewData(refreshData) {
  console.time(`view data load > first batch`);
  const state = yield select();
  const id = get(state, 'router.page.params.viewId');
  const userId = auth.selectors.currentUserId(state);
  const viewId = selectors.currentUserViewId(state, id);
  const requesterId = 'view-data';
  try {
    yield put({ type: actions.LOAD_VIEW_DATA, id });
    // Cancels current running queries for views summary & views data.
    summaryQuery && firestoreRedux.cancelQuery(summaryQuery.id);
    dataQuery && firestoreRedux.cancelQuery(dataQuery.id);

    let allPromises = [];
    summaryQuery = firestoreRedux.query('view-data-summary', { id: `view-data-summary-${viewId}` , where: [['userId', '==', userId], ['viewId', '==', viewId]], requesterId });
    allPromises.push(summaryQuery.waitTillResultAvailableInState());

    dataQuery = firestoreRedux.query('view-data', { id: `view-data-${viewId}`, where: [['userId', '==', userId], ['viewId', '==', viewId]], requesterId });
    const tagColorsQuery = firestoreRedux.getDocById('tag-colors', 'tc_0', { requesterId });

    // wait for system tags.
    if(isEmpty(boardTagsSelectors.systemColors(state))) {
      yield tagColorsQuery.waitTillResultAvailableInState();
    }

    let data = yield dataQuery.result;
    data = get(data, '0', {});
    yield dataQuery.waitTillResultAvailableInState();
    yield put(actions._initDataLoaded(id, data));

    let firstBatchCards = {};
    let boardWiseCardIds = {};

    // First 10 cards added of first 5 columns.
    forEach(data.columns, (column, columnIndex) => {
      forEach(column.cards, (card, index) => {
        const cardId = card.cardId;
        const boardId = card.boardId;
        const boardCards = _cardIds[boardId] || [];

        //return when card is alredy loaded.
        if(boardCards.includes(cardId)) {
          return;
        }
        
        if(columnIndex < 10 && index < 20) {
          const cards = firstBatchCards[boardId] || [];
          cards.push(cardId);
          firstBatchCards[card.boardId] = cards;
        } else {
          const cards = boardWiseCardIds[boardId] || [];
          cards.push(cardId);
          boardWiseCardIds[card.boardId] = cards;
        }

        boardCards.push(cardId);
        _cardIds[boardId] = boardCards;
      });
    });

    const loadBoardDetails = (boardId) => {
      const promises = [];
      const boardQuery = firestoreRedux.getDocById('boards', boardId, { requesterId });
      const boardMoveQuery = firestoreRedux.getDocById('board-move-requests', `bmr_${getIdWoPrefix({ id: boardId, prefix: 'brd_' })}`, { requesterId });
      const boardColumnsQuery = firestoreRedux.query('columns', { id: `board_columns_${boardId}`, where: [['boardId', '==', boardId]], requesterId });
      const boardMembersQuery = firestoreRedux.query('board-team-members', { id: `board-team-members_boardId-${boardId}`, where: [['boardId', '==', boardId]], requesterId });
      const boardTagsQuery = firestoreRedux.query('board-tags', { id: `board-tags-${boardId}`, where: [['boardId', '==', boardId]], requesterId });
      store.dispatch(board.actions.subscribeMembers(boardId));
      promises.push(boardQuery.waitTillResultAvailableInState());
      promises.push(boardMoveQuery.waitTillResultAvailableInState());
      promises.push(boardColumnsQuery.waitTillResultAvailableInState());
      promises.push(boardMembersQuery.waitTillResultAvailableInState());
      promises.push(boardTagsQuery.waitTillResultAvailableInState());
      return promises;
    }

    const loadCardDetails = (boardId, cardIds) => {
      const promises = [];
      const cardQuery = firestoreRedux.query('cards', { where: [['id', 'in', cardIds], ['boardId', '==', boardId]], requesterId });
      const cardSummaryQuery = firestoreRedux.query('cards-summary', { where: [['cardId', 'in', cardIds], ['boardId', '==', boardId]], requesterId });
      const cardUnreadQuery = firestoreRedux.query('card-unread-user-details', { where: [['cardId', 'in', cardIds], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });
      promises.push(cardQuery.waitTillResultAvailableInState());
      promises.push(cardSummaryQuery.waitTillResultAvailableInState());
      promises.push(cardUnreadQuery.waitTillResultAvailableInState());
      return promises;
    }

    const chunkSize = 10;
    forEach(firstBatchCards, (__cardIds, boardId) => {
      for (let i = 0; i < __cardIds.length; i += chunkSize) {
        const cardIds = __cardIds.slice(i, i + chunkSize);
        if(boardId && !isEmpty(cardIds)) {
          const cardPromises = loadCardDetails(boardId, cardIds);
          allPromises.push(...cardPromises);
        }
      }

      if (_boardIds.includes(boardId)) {
        return;
      }
      _boardIds.push(boardId);
      const boardPromises = loadBoardDetails(boardId);
      allPromises.push(...boardPromises);
    });

    //Wait for load first Batch
    yield Promise.all(allPromises);
    allPromises = [];
    console.timeEnd(`view data load > first batch`);
    console.time(`view data load time > second batch`);

    for(const boardId in boardWiseCardIds) {
      if (!_boardIds.includes(boardId)) {
        _boardIds.push(boardId);
        const boardPromises = loadBoardDetails(boardId);
        allPromises.push(...boardPromises);
      }

      const __cardIds = boardWiseCardIds[boardId];
      let allCardPromises = [];
      for (let i = 0; i < __cardIds.length; i += chunkSize) {
        const cardIds = __cardIds.slice(i, i + chunkSize);
        if(boardId && !isEmpty(cardIds)) {
          const cardPromises = loadCardDetails(boardId, cardIds);
          allCardPromises.push(...cardPromises);
          if(allCardPromises && allCardPromises.length > 200) {
            yield Promise.all(allCardPromises);
            allCardPromises = [];
          }
        }
      }

      if(allCardPromises && allCardPromises.length) {
        yield Promise.all(allCardPromises);
        allCardPromises = [];
      }
    }

    yield Promise.all(allPromises);
    yield put({ type: actions.LOAD_VIEW_DATA_DONE, id, data });
    console.timeEnd(`view data load time > second batch`);
  } catch (error) {
    console.error('Error in load view data: ', error);
    yield put({ type: actions.LOAD_VIEW_DATA_FAILED, id });
  }
}

/**
 * Disconnects data from firebase.
 */
function* disconnectFirestore() {
  firestoreRedux.cancelQueryByRequester('view-participated-boards');
  firestoreRedux.cancelQueryByRequester('view-settings');
  firestoreRedux.cancelQueryByRequester('view-data');
  firestoreRedux.cancelQueryByRequester('view-preferences');
  firestoreRedux.cancelQueryByRequester('views-hidden-columns');
  firestoreRedux.cancelQueryByRequester('views-last-active-columns');
  forEach(_boardIds, (boardId) => {
    if(boardId) {
      store.dispatch(board.actions.unsubscribeMembers(boardId));
    }
  });
  _boardIds = [];
  _cardIds = {};
  summaryQuery = null;
  dataQuery = null;
}


let previousPage;
let previousViewId;
let previousDialog;
/**
 * Manages PAGE_OPENED, PAGE_CLOSED & PAGE_CHANGED.
 */
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = get(state, 'router.page.name');
  const currentDialog = get(state, 'router.dialog.name');
  const currentViewId = get(state, 'router.page.params.viewId');
  if (currentPage === 'VIEWS') {
    if (previousPage !== currentPage && previousDialog !== 'CARD') {
      yield put({ type: actions.PAGE_OPENED, viewId: currentViewId });
    } else {
      if (previousViewId !== currentViewId || previousDialog === 'CARD') {
        yield put({ type: actions.PAGE_CHANGED, viewId: currentViewId, previousViewId });
      }
    }
  } else if (previousPage === 'VIEWS' && currentDialog !== 'CARD') {
    yield put({ type: actions.PAGE_CLOSED, viewId: previousViewId });
  }
  previousPage = currentPage;
  previousViewId = currentViewId;
  previousDialog = currentDialog;
}

/**
 * Watches router change.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Clear card selection.
 */
function* clearCardSelection() {
  store.dispatch(actions.clearSelection());
}

/**
 * Toggle selection of given card Id.
 * @param {Object} action action payload. e.g {cardId, columnId}
 */
function* toggleCardSelection(action) {
  const state = yield select();
  const selectedColumn = selectors.selectedColumn(state);
  let selectedCards = selectors.selectedCards(state);

  if (isEmpty(selectedCards) || action.columnId !== selectedColumn) {
    store.dispatch(actions.setSelection([action.cardId], action.columnId));
    return;
  }

  if (selectedCards.indexOf(action.cardId) !== -1) {
    selectedCards = without(selectedCards, action.cardId);
  } else {
    selectedCards.push(action.cardId);
  }

  store.dispatch(actions.setSelection([...selectedCards], action.columnId));
}

/**
 * Selects card upto given cardId.
 * @param {Object} action action payload. eg {cardId, columnId}
 */
function* uptoCardSelection(action) {
  const state = yield select();
  const selectedCards = selectors.selectedCards(state);
  const selectedColumn = selectors.selectedColumn(state);

  if (isEmpty(selectedCards) || action.columnId !== selectedColumn) {
    store.dispatch(actions.setSelection([action.cardId], action.columnId));
    return;
  }
  const lastSelectedCard = last(selectedCards);
  const cardsByColumn = get(selectors.cardsList({state, columnId: action.columnId}), 'cards');
  let allCards = [];
  forEach(cardsByColumn, (obj) => {
    allCards.push(obj.cardId);
  });
  const indexOfLastSelectedCard = findIndex(allCards, (cardId) => cardId == lastSelectedCard);
  const indexOfCurrentSelectedCard = findIndex(allCards, (cardId) => cardId == action.cardId);

  if (indexOfCurrentSelectedCard == indexOfLastSelectedCard) {
    return;
  }

  let newSelection = [];
  if (indexOfCurrentSelectedCard > indexOfLastSelectedCard) {
    newSelection = slice(allCards, (indexOfLastSelectedCard + 1), (indexOfCurrentSelectedCard + 1));
  } else {
    newSelection = reverse(slice(allCards, indexOfCurrentSelectedCard, indexOfLastSelectedCard));
  }

  store.dispatch(actions.setSelection([...selectedCards, ...newSelection], action.columnId));
}

/**
 * Updates visibility of given column into current view.
 */
function* updateColumnVisibility({ columnId, visibility }) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const viewId = selectors.currentViewId(state);
  const allHiddenColumns = firestoreRedux.selectors.collection(state, 'views-hidden-columns');
  let currentDoc = find(allHiddenColumns, { columnId, viewId });
  if (!currentDoc) {
    currentDoc = {
      id: `vhc_${entityIdProvider.getId()}`,
      userId,
      viewId,
      columnId,
      hidden: visibility
    }
  }
  const newDoc = {...currentDoc, hidden: !visibility}
  const collectionPath = `users/${userId}/views-hidden-columns`;
  try {
    firestoreRedux.save(collectionPath, newDoc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
    yield put(actions.clearSelection());
  } catch (error) {
    firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    console.error(`Failed to update views column visibility`, error);
  }
}

/**
 * Manages load/disconnect
 */
function* manageViewFlow() {
  while (yield take(actions.PAGE_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(loadPreferences),
        call(loadScrollPosition),
        call(loadHiddenColumns),
        call(refresh),
        debounce(50, [actions.PAGE_CHANGED, actions.MARK_COLUMN_AS_READ_DONE, actions.REFRESH], refresh),
        takeEvery(actions.PAGE_CHANGED, loadPreferences),
        takeEvery(actions.PAGE_CHANGED, loadHiddenColumns),
        takeEvery(actions.PAGE_CHANGED, loadScrollPosition),
        takeEvery(actions.UPDATE_SETTINGS, updateSettings),
        takeEvery(actions.UPDATE_PREFERENCES, updatePreferences),
        takeEvery(actions.LOAD_PARTICIPATED_BOARDS, loadParticipatedBoards),
        takeEvery(actions.SETTINGS_DIALOG_OPENED, loadViewSettings),
        takeEvery(actions.UPDATE_SCROLL_POSITION, updateScrollPosition),
        takeEvery(actions.MARK_COLUMN_AS_READ, markColumnAsRead),
        takeEvery(actions.UPDATE_COLUMN_VISIBILITY, updateColumnVisibility),
        takeEvery(actions.TOGGLE_CARD_SELECTION, toggleCardSelection),
        takeEvery(actions.SELECTION_UPTO_CARD, uptoCardSelection),
        takeEvery(actions.LOAD_VIEW_DATA_DONE, clearSelectionForRemovedCards)
      ]);
    })
    yield take(actions.PAGE_CLOSED);
    yield call(clearCardSelection);
    yield call(disconnectFirestore);
    yield cancel(task);
  }
}

/**
 * Load load view summary for current user.
 */
function* loadSummary() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  firestoreRedux.query('view-data-summary', { id: 'view-data-summary', where: [['userId', '==', userId]], requesterId: `views-summary` });
}

/**
 * Stop view summary live read from firestore.
 */
function* disconnectSummary() {
  firestoreRedux.cancelQueryByRequester(`views-summary`);
}

function* summaryFlow() {
  yield all([
    takeEvery(actions.LOAD_SUMMARY, loadSummary),
    takeEvery(actions.DISCONNECT_SUMMARY, disconnectSummary)
  ]);
}

/**
 * On VIEWS refresh, when any selected card is removed from data, removes it from selected cards list.
 */
function* clearSelectionForRemovedCards({ data }) {
  const state = yield select();

  const selectecCards = selectors.selectedCards(state);
  if (isEmpty(selectecCards)) {
    return;
  }
  let allCards = [];
  forEach(data, (column) => {
    forEach(column.cards, (card) => {
      allCards.push(card.cardId);
    })
  })

  forEach(selectecCards, (cardId) => {
    if (!allCards.includes(cardId)) {
      store.dispatch(actions.toggleSelection(cardId, selectors.selectedColumn(state)))
    }
  })
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(watchRouter),
    call(manageViewFlow),
    call(summaryFlow)
  ]);
}

export default saga;