import { sagaMiddleware } from '../../store';
import saga from './saga';
import * as cardActions from './actions';
import * as cardSelectors from './selectors';
import * as _persister from './persister';

export const actions = cardActions;
export const selectors = cardSelectors;
export const persister = _persister;

sagaMiddleware.run(saga);
