import { sagaMiddleware } from '../../store';

import * as attachmentActions from './actions';
import * as attachmentsSelectors from './selectors';
import saga from './saga';
import * as attachmentsUtils from './utils';

export let actions = attachmentActions;
export let selectors = attachmentsSelectors;
export let utils = attachmentsUtils;

sagaMiddleware.run(saga);
