import requestApi from './request-api.js';

export const getNewIds = async(ids) => {
  try {
    const response = await requestApi('/util/get-new-ids-by-old-ids', {method: 'POST', body: { ids}, excludeErrors: [502, 401, 403, 404] });
    return response;
  } catch (error) {
    return {};
  }
}

export const getOldIds = async(ids) => {
  try {
    const response = await requestApi('/util/get-old-ids-by-new-ids', {method: 'POST', body: { ids}, excludeErrors: [502, 401, 403, 404] });
    return response;
  } catch (error) {
    return {};
  }
}

export default getNewIds;