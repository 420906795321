import { css } from 'lit-element';
import { KerikaSelectDialog } from '../../components/kerika-select-dialog';

class FilterChipsMoreSelectDialog extends KerikaSelectDialog {
	static get styles() {
		return [
			super.styles,
			css`
        :host([large]) {
          --dw-select-dialog-border-radius: 8px;
        }

        :host([large]) .item,
        :host .item {
          height: 48px;
        }
      `
		];
	}
}
window.customElements.define('filter-chips-more-select-dialog', FilterChipsMoreSelectDialog);