import { takeEvery, select, all, call, fork, put } from 'redux-saga/effects';
import firestoreRedux from '@dreamworld/firestore-redux';
import { requestApi, isNetworkError } from '../../request-api';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import get from 'lodash-es/get';

import * as auth from '../auth';
import * as router from '../router';

import * as actions from './actions';
import * as selectors from './selectors';
import * as utils from '../../utils.js';

function* updateProperties({ properties }) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const id = `ups_${utils.getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
  const localWritePath = `users/${userId}/user-properties`;
  const currentDoc = selectors.userProperties(state);
  try {
    const newDoc = merge({}, { id }, currentDoc, properties);
    firestoreRedux.save(localWritePath, newDoc, { localWrite: true });
    yield call(requestApi, `/user/users/me/properties`, { method: 'PATCH', body: properties, excludeErrors: [400] });
  } catch (error) {
    isEmpty(currentDoc)
      ? firestoreRedux.delete(localWritePath, id, { localWrite: true })
      : firestoreRedux.save(localWritePath, currentDoc, { localWrite: true });
    if (isNetworkError(error)) {
      return;
    }

    console.error('user > updateProperties > failed due to this: ', error);
  }
}

function* unsubscribeTasksummary({token}) {
  const state = yield select();
  const params = router.selectors.pageParams(state);
  token = token || get(params, 'token');
  try {
    yield call(requestApi, `/account/preferences/unsubscribe-tasks-summary`, { method: 'POST', body: {token}});
    router.actions.setQueryParams({'token': undefined});
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }
    console.log("user > unsubscribe-tasks-summary: failed due to this:", error);
  }
}

/**
 * Init Saga.
 */
function* userSaga() {
  yield all([
    takeEvery(actions.UPDATE_PROPERTIES, updateProperties),
    takeEvery(actions.UNSUBSCRIBE_TASK_SUMMARY, unsubscribeTasksummary)
  ]);
}

export default userSaga;
