import { createSelector } from 'reselect';
import * as multipleLanguage from '../multiple-language';
import * as board from '../board';
import * as commonSelectors from './common-selectors.js';

// Lodash
import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import orderBy from 'lodash-es/orderBy';

/**
 * @returns {String} translate board status.
 */
export const status = (state, boardId) => get(state, `board-translations.translateBoard.${boardId}.status`);

/**
 * @returns {String} translate board status.
 */
export const retranslateBoardStatus = (state, boardId) => get(state, `board-translations.retranslateBoard.${boardId}.status`);

/**
 * @returns {Number} already translated boards count.
 */
export const translatedBoardsCount = createSelector(
  (state, boardId) => commonSelectors.translateBoardDocs(state, boardId),
  (translateBoards) => {
    const filterBoards = filter(translateBoards, item => item && item.initialized) || [];
    return filterBoards && filterBoards.length || 0;
  }
);

/**
 * @returns {Boolean} board has transalted board
 */
export const hastranslatedBoards = createSelector(
  (state, boardId) => boardId,
  (state, boardId) => commonSelectors.translateBoardDocs(state, boardId),
  (boardId, boards) => {
    const filterBoards = filter(boards, item => item && item.baseBoardId && item.baseBoardId === boardId) || [];
    return !!(filterBoards && filterBoards.length || 0);
  }
);

export const translateBoards = createSelector(
  (state, boardId) => board.selectors.attrs(state, boardId),
  (state, boardId) => multipleLanguage.selectors.supportedLanguages(state),
  (state, boardId) => multipleLanguage.selectors.systemDefaultLanguage(state),
  (state, boardId) => commonSelectors.translateBoardDocs(state, boardId),
  (attrs, supportedLanguages, defaultLanguage, translateBoards) => {
    if(isEmpty(attrs) || supportedLanguages === undefined || translateBoards === undefined) {
      return undefined;
    }
    
    const boardLang = attrs && attrs.lang || 'en';
    const isBaseBoard = defaultLanguage === boardLang;
    let list = [];
    translateBoards = [...[attrs], ...translateBoards];
    forEach(supportedLanguages, (lang) => {
      let board = {};
      let langCode = lang && lang.code;
      let defaultBoard = langCode === defaultLanguage;
      forEach(translateBoards, (item) => {
        let itemLang = item && item.lang || 'en';
        if(itemLang === langCode) {
          board = item;
          return false;
        }
      });

      const item = {...lang, board, defaultBoard, current: boardLang === langCode, hasBoard: !isEmpty(board)};
      if(isBaseBoard || item.hasBoard) {
        list.push(item);
      }
    });

    return orderBy(list, ['defaultBoard', 'hasBoard', 'englishName', 'name'], ['desc', 'desc']);
  }
);