import * as actions from "./actions";
import { CREATE_BOARD_DONE } from "../board-explorer/create-board/actions.js"
import { JOIN_USING_SECRET_DONE, LOAD_JOINED_ACCOUNT_DATA_DONE, CREATE_ACCOUNT_DONE } from "../signup/actions";
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { ACCOUNT_CHANGED } from '../router/actions.js';

/**
 * Auth Reducer
 */
export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_AUTH:
      if (action.auth) {
        return {
          ...action.auth,
        };
      }
      return null;
    case CREATE_ACCOUNT_DONE:
      return ReduxUtils.replace(state, `ownedAccounts`, [action.accountId]);
    case CREATE_BOARD_DONE:
      return ReduxUtils.replace(
        state,
        `accessibleBoards.${action.accountId}.${action.boardId}`,
        action.boardType
      );
    case JOIN_USING_SECRET_DONE:
      if (action.boardId) {
        return ReduxUtils.replace(
          state,
          `accessibleBoards.${action.accountId}.${action.boardId}`,
          action.boardType
        );
      } else {
        return ReduxUtils.replace(
          state,
          `accessibleAccounts.${action.accountId}`,
          action.accountRole
        );
      }
    case LOAD_JOINED_ACCOUNT_DATA_DONE:
      return ReduxUtils.replace(
        state,
        `accessibleAccounts.${action.accountId}`,
        action.accountRole
      );
    case ACCOUNT_CHANGED:
      return ReduxUtils.replace(
        state,
        `lastAccessibleAccount`,
        action.currentAccountId
      );
    default:
      return state;
  }
};

/**
 * Login and signup reducer
 */
const LOGIN_INITIAL_STATE = {};
export const loginAndSignupReducer = (state = LOGIN_INITIAL_STATE, action) => {
  let user;
  switch (action.type) {
    case actions.LOGIN_AND_SIGNUP_DATA_RESET:
      return LOGIN_INITIAL_STATE;
    case actions.LOGIN:
      user =
        action.service === "kerika"
          ? { email: action.email, password: action.password }
          : undefined;
      return ReduxUtils.replace(state, `login`, {
        status: "IN_PROGRESS",
        error: undefined,
        service: action.service,
        user,
      });
    case actions.LOGIN_FAILED:
      state = ReduxUtils.replace(state, `login.status`, "FAILED");
      return ReduxUtils.replace(state, `login.error`, action.error);
    case actions.LOGIN_SUCCESS:
      state = ReduxUtils.replace(state, `login.status`, "SUCCESS");
      return ReduxUtils.replace(state, `login.error`, undefined);
    case actions.SIGNUP:
    case actions.SIGNUP_USING_JOIN_SECRET:
      user =
        action.service === "kerika"
          ? {
              email: action.email,
              firstName: action.firstName,
              lastName: action.lastName,
              password: action.password,
            }
          : undefined;
      return ReduxUtils.replace(state, `signup`, {
        status: "IN_PROGRESS",
        error: undefined,
        service: action.service,
        user,
      });
    case actions.SIGNUP_FAILED:
    case actions.SIGNUP_USING_JOIN_SECRET_FAILED:
      state = ReduxUtils.replace(state, `signup.status`, "FAILED");
      return ReduxUtils.replace(state, `signup.error`, action.error);
    case actions.SIGNUP_SUCCESS:
    case actions.SIGNUP_USING_JOIN_SECRET_SUCCESS:
      state = ReduxUtils.replace(state, `signup.requestId`, action.requestId);
      state = ReduxUtils.replace(state, `signup.status`, "SUCCESS");
      return ReduxUtils.replace(state, `signup.error`, undefined);
    case actions.RESEND_VERIFICATION_EMAIL:
      return ReduxUtils.replace(state, `signup.resendVerificationEmail`, {
        status: "IN_PROGRESS",
        error: undefined,
      });
    case actions.RESEND_VERIFICATION_EMAIL_FAILED:
      return ReduxUtils.replace(state, `signup.resendVerificationEmail`, {
        status: "FAILED",
        error: action.error,
      });
    case actions.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return ReduxUtils.replace(state, `signup.resendVerificationEmail`, {
        status: "SUCCESS",
        error: undefined,
      });
    case actions.GET_JOIN_SECRET_INFO:
      return ReduxUtils.replace(state, `joinSecretInfo`, {
        status: "IN_PROGRESS",
        error: undefined,
        joinSecret: action.joinSecret,
      });
    case actions.GET_JOIN_SECRET_INFO_FAILED:
      state = ReduxUtils.replace(state, `joinSecretInfo.status`, "FAILED");
      return ReduxUtils.replace(state, `joinSecretInfo.error`, action.error);
    case actions.GET_JOIN_SECRET_INFO_SUCCESS:
      state = ReduxUtils.replace(state, `joinSecretInfo.status`, "SUCCESS");
      state = ReduxUtils.replace(state, `joinSecretInfo.error`, undefined);
      return ReduxUtils.replace(state, `joinSecretInfo.data`, action.data);
    case actions.LOGIN_AND_SIGNUP_PAGE_OPENED:
    case actions.LOGIN_AND_SIGNUP_PAGE_CLOSED:
      state = ReduxUtils.replace(state, `login`, undefined);
      return ReduxUtils.replace(state, `signup`, undefined);
    default:
      return state;
  }
};
