import { DwSurface } from "@dreamworld/dw-surface";
import { css } from "lit-element";

/**
 * Extended version of [dw-icon](https://github.com/DreamworldSolutions/dw-icon).
 */
export class KerikaSurface extends DwSurface {
  static get styles() {
    return [
      super.styles,
      css`
        .scroller {
          display: var(--kerika-surface-scroller-display, block);
          flex-direction: var(--kerika-surface-scroller-direction, column);
          overflow: var(--kerika-surface-scroller-overflow, auto);
          width: var(--kerika-surface-scroller-width, auto);
        }

        :host([new-style]) ::slotted(h5) {
          color: var(--mdc-theme-secondary);
        }

        :host([new-style]) ::slotted(:last-child) {
          margin-bottom: 0;
        }
      `,
    ];
  }
}

customElements.define("kerika-surface", KerikaSurface);
