import { html, css } from 'lit-element';
import { DwSelect } from '@dreamworld/dw-select/dw-select';
import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import { sharedStyles } from '../theme/shared-styles.js';
import './kerika-input';
import './kerika-select-dialog';
import { scrollStyles } from '../theme/scroll-styles.js';

/**
 * Extended version of [dw-select](https://github.com/DreamworldSolutions/dw-select/blob/master/dw-select.js).
 * It's mainly element is used to provide trigger element with selection dialog.
 */

export class KerikaSelect extends DwSelect {
  static get styles() {
    return [
      super.styles,
      sharedStyles,
      scrollStyles,
      css`
        :host {
          --dw-select-dropdown-container-padding: 0;
          --dw-select-item-icon-color: var(--icon-color);
        }

        :host([disabled]) .main-container {
          pointer-events: none;
        }

        .error-message {
          display: none;
        }

        #overlay {
          z-index: var(--kerika-select-overlay-z-index, 99);
        }

        .tippy-box {
          background-color: #fff;
        }
      `
    ]
  }
  static get properties() {
    return {
      queryParamValue: { type: String },
      queryParamName: { type: String },
      disabled: { type: Boolean, reflect: true },

      /**
       * Default item is selected or not.
       * When select value is not found in the list then defualt item selected or not.
       */
      defaultItemSelect: { type: Boolean },

      /**
       * Input property.
       */
      dense: { type: Boolean },

      /**
       * Input property. proxy to select dialog.
       */
      width: { type: Number },
      
      /**
       * Shows a large select when this is true.
       */
      large: { type: Boolean, reflect: true }
    };
  }

    constructor() {
      super();
      this.backIcon = 'close';
      this.backIconPosition = 'right';
      this.doNotDelayRendering = true;
      this.errorMessage = '';
      this.placeholder = '';
      this.large = false;
    }

    _getDefaultTriggerElement(){
      return html `
        <kerika-input
          ?wide-layout=${!this.mobileMode}
          ?large=${this.large}
          .dense=${this.dense}
          .disabled=${this.disabled}
          .readOnly=${this.readOnly}
          .required=${this.required}
          role="${'select'}"
          .label=${this.label}
          .placeholder=${this.placeholder}
          .errorMessage=${this.errorMessage}
          .invalid=${this.invalid}
          .value=${this._selectedItemsText(this.items, this.value, this.itemLabel, this.itemValue) || ''}>
        </kerika-input>
      `;
    }

    validate(dryRun) {
      super.validate && super.validate(dryRun);
      this._inputValidatyCheck();
      setTimeout(() => {
        this._inputValidatyCheck();
      }, 0);
    }

    _inputValidatyCheck() {
      const input = this.shadowRoot.querySelector('kerika-input');
      input && input.validate && typeof input.validate === 'function' && input.validate();
      input && input.layout && typeof input.layout === 'function' && input.layout();
    }

    /**
     * @override
     */
    _renderSelectDialog() {
        return html`
      <kerika-select-dialog
        id="select-dialog"
        .large=${this.large}
        .items=${this.items}
        .disabledItems=${this.disabledItems}
        .itemLabel=${this.itemLabel}
        .itemValue=${this.itemValue}
        .listItemIconSize=${this.listItemIconSize}
        .positionTarget=${this._positionTarget}
        .noHeader=${this.noHeader}
        .mobileMode=${this.mobileMode}
        .width=${this.width}
        .filterPlaceholder=${this.filterPlaceholder}
        .opened=${this.opened}
        .hAlign=${this.hAlign}
        .vAlign=${this.vAlign}
        .hOffset=${this.hOffset}
        .vOffset=${this.vOffset}
        .singleSelect=${this.singleSelect}
        .value=${this.value}
        .groupBy=${this.groupBy}
        .allowFilter=${this.allowFilter}
        .groupByOrder=${this.groupByOrder}
        .groupText=${this.groupText}
        .dialogTitle=${this.dialogTitle}
        .hideSelectAllBtn="${this.hideSelectAllBtn}"
        .alwaysFullScreenInMobile=${this.alwaysFullScreenInMobile}
        .hideResetBtn="${this.hideResetBtn}"
        .stickySelectionButtons="${this.stickySelectionButtons}"
        .selectionButtonsAlign="${this.selectionButtonsAlign}"
        @value-changed=${this._valueChanged}
        @opened-changed=${this._openedChanged}
        .backIcon="${this.backIcon}"
        .backIconPosition="${this.backIconPosition}"
        .noBackIcon="${this.noBackIcon}"
        .backIconSize="${this.backIconSize}"
        .clearIconSize="${this.clearIconSize}"
        .queryParamValue="${this.queryParamValue}"
        .queryParamName="${this.queryParamName}"
      ></kerika-select-dialog>
    `;
    }

    firstUpdated(changeProps) {
      super.firstUpdated && super.firstUpdated(changeProps);
      this._defaultItemSelect();
    }

    /**
     * Default template select
     */
    _defaultItemSelect() {
      if(!this.defaultItemSelect) {
        return;
      }

      if(!this.value || (!isEmpty(this.items) && !find(this.items, {[this.itemValue]: this.value}))) {
        let firstItem = this.items && this.items[0];
        this.value = firstItem && firstItem[this.itemValue];

        //After update completed fired value-changed event.
        this.updateComplete.then(() => {
          this.dispatchEvent(new CustomEvent('value-changed', {
            detail: {value: this.value}
          }));
        });
      }
    }

    /**
     * Getter for value.
     */
    get value() {
      return this._value;
    }

    /**
     * Setter for value.
     */
    set value(value) {
      let oldValue = this._value;
      if (oldValue === value) {
        return;
      }
      this._value = value;
      this.requestUpdate('value', oldValue);

      //Set default item if item is not fount in the list.
      if (!isEmpty(this.items) && value) {
        this._defaultItemSelect();
      }
    }

    /**
     * Getter of items.
     */
    get items() {
      return this.__items;
    }

    /**
     * Setter of items.
     */
    set items(value) {
      let oldValue = this.__items;
      if (oldValue === value) {
        return;
      }
      this.__items = value;
      this.requestUpdate('items', oldValue);

      //Set default item if item is not fount in the list.
      if (!isEmpty(value) && this.value) {
        this._defaultItemSelect();
      }
    }

  /**
   * When dialog closed, removes `mdc-text-field--focused` style of input field.
   * @override
   * @param {Object} e Event
   */
  _openedChanged(e) {
    super._openedChanged(e);
    const opened = e.detail.opened;
    if (!opened) {
      const input = this.shadowRoot.querySelector('kerika-input');
      const textField = input && input.shadowRoot.querySelector('.mdc-text-field');
      textField && textField.classList.remove('mdc-text-field--focused');
    }
  }
}

customElements.define('kerika-select', KerikaSelect);