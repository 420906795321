import { sagaMiddleware, store } from '../../store.js';
import * as _selectors from './selectors.js';
import * as _actions from './actions.js';
import * as _reducers from './reducers.js';
import importBoardSaga from './saga.js';

export const selectors = _selectors;
export const actions = _actions;

sagaMiddleware.run(importBoardSaga);

store.addReducers({
  'import-board': _reducers.importBoard
});