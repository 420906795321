import * as actions from './actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

const INITIAL_STATE = {
    status: 'NORMAL',
    error: null,
    passwordReset: false
  };


export const fileStore = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.ACCESS_REVOKED_DETECTED:
      return {
        ...state,
        status: 'NORMAL',
        error: null,
        passwordReset: action.code === 'PASSWORD_RESET_REQUIRED'
      };
    case actions.REQUEST_AUTHORIZATON_TO_AO:
    case actions.REAUTHORIZE:
        return {
          ...state,
          status: 'IN_PROGRESS',
          error: null
        };
    case actions.REQUEST_AUTHORIZATON_TO_AO_DONE:
    case actions.REAUTHORIZE_DONE:
      return {
				...state,
        status: 'SUCCESS',
        error: null
      };
      case actions.REQUEST_AUTHORIZATON_TO_AO_FAILED:
      case actions.REAUTHORIZE_FAILED:
        return {
          ...state,
          status: 'FAILED',
          error: action.error
        };
    default:
      return state;
	}
};

export const fileStorePreferredStoreSelection = (state = {}, action) => {
  let oState = {...state};
  switch (action.type) {
    case actions.PREFERRED_STORE_SELECTION_PENDING:
      return ReduxUtils.replace(oState, 'preferredStoreSelection', {status: 'NORMAL', error: null, service: null, email: null, authEmail: null});
    case actions.PREFERRED_STORE_SELECTION:
      return ReduxUtils.replace(oState, 'preferredStoreSelection', {status: 'IN_PROGRESS', service: action.storage, error: null, email: action.email, authEmail: action.authEmail});
    case actions.PREFERRED_STORE_SELECTION_DONE:
      return ReduxUtils.replace(oState, 'preferredStoreSelection', {status: action.status || 'SUCCESS', error: null, authEmail: action.authEmail, service: action.storage});
    case actions.PREFERRED_STORE_SELECTION_FAILED:
      return ReduxUtils.replace(oState, 'preferredStoreSelection', {status: 'FAILED', error: action.error});
    case actions.CHANGE_PREFERRED_STORE:
      return ReduxUtils.replace(oState, 'changePreferredStore', {status: 'IN_PROGRESS', service: action.storage, error: null, email: action.email, authEmail: action.authEmail});
    case actions.CHANGE_PREFERRED_STORE_DONE:
      return ReduxUtils.replace(oState, 'changePreferredStore', {status: action.status || 'SUCCESS', error: null, authEmail: action.authEmail, service: action.storage});
    case actions.CHANGE_PREFERRED_STORE_FAILED:
      return ReduxUtils.replace(oState, 'changePreferredStore', {status: 'FAILED', error: action.error});
    case actions.CHECK_PREFERRED_STORE_SELECTION:
      return ReduxUtils.replace(oState, 'currentPreferredStoreData', undefined);
    case actions.CHECK_PREFERRED_STORE_SELECTION_DONE:
      return ReduxUtils.replace(oState, 'currentPreferredStoreData', action.data);
    case actions.CHECK_PREFERRED_STORE_SELECTION_FAILED:
      return ReduxUtils.replace(oState, 'currentPreferredStoreData.preferredStoreSelectionDone', false);
    default:
      return state;
	}
};