import { isAccountHomePage, isApplicationRootPage, isManageAccountPage, isAccountPreferencesPage, isViewPage } from './validate-url';
import { isFullBoardUrl, isPublicBoard } from './compute-full-url';
import URI from '@dw/urijs-esm';


export const redirectToLoginAndSignup = (data) => {
  return new Promise(async function (resolve, reject) {
    //If user is logged in then reject promise
    if (data.user && data.user.id) {
      reject(data);
      return;
    }

    //If user is not logged in and access private page (non of board) then resolved to login
    if (isAccountHomePage(data.path) || isApplicationRootPage(data.path) || isManageAccountPage(data.path) || isAccountPreferencesPage(data.path) || isViewPage(data.path)) {
      data.page = getLoginAndSignupPageData(data.params);
      resolve(data);
      return;
    }

    const joinSecret = data.params && data.params['join'];
    //If join secret found in URL.
    if(joinSecret) {
      data.page = getLoginAndSignupPageData(data.params);
      resolve(data);
      return;
    }

    //If url is board url
    if (isFullBoardUrl(data.path)) {
      const uri = new URI(data.path);
      const boardId = uri.segment()[2]; 

      const publicBoard = await isPublicBoard(boardId);
      if (publicBoard) {
        reject(data);
        return;
      }

      data.page = getLoginAndSignupPageData(data.params);
      resolve(data);
      return;
    }

    reject(data);
  });
}

/**
 * @returns {Object} Login page data.
 */
const getLoginAndSignupPageData = (params) => {
  return {name: 'LOGIN_AND_SIGNUP', params}
}