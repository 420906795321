import URI from '@dw/urijs-esm/index.js';
import { isPreferredStoreSelectionPage } from './validate-url.js';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If path is /reauthorize-cloud-store then resolve promise with re-authorized-file-store page data.
 *                    Otherwise reject a promise.
 */
export const preferredStoreSelectionPage = (data) => {
  return new Promise((resolve, reject) => {
    if(isPreferredStoreSelectionPage(data.path)) {
      let params = new URI().query(true);
      data.page = {
        name: 'PREFERRED_STORE_SELECTION',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}