import * as selectors from './selectors.js';

export const getLangFont = (lang) => {
  lang = lang || 'en';
  const langFont = selectors.langFont();
  const font = langFont[lang] || langFont['default'] || {};
  const link = font.link || langFont['default']['link'] || '';
  const fontFamily = font.fontFamily || langFont['default']['fontFamily'] || ''; 
  return {
    link,
    fontFamily
  }
}