import get from 'lodash-es/get';

import * as auth from '../auth';
import * as router from '../router';
import * as selectorCreator from '../selector-creator.js';

/**
 * @returns {Array} Ids of copied cards for current account which is not older than 5 minutes.
 */
export const copiedCards = selectorCreator.createDeepEqualSelector(
  router.selectors.accountId,
  auth.selectors.accessibleBoards,
  (state) => get(state, `clipboard`),
  (accountId, accessibleBoards, clipboard) => {
    const currAccountBoards = get(accessibleBoards, accountId);
    if (!clipboard || clipboard.action !== 'COPY_CARDS' || !get(currAccountBoards, get(clipboard, 'data.boardId'))) {
      return [];
    }
    return get(clipboard, 'data.cards');
  }
);