import * as selectors from './selectors.js';

export const MARK_AS_KNOWN = 'KNOWN_FEATURES_MARK_AS_KNOWN';
export const CHANGE_OPENED_ONCE = 'KNOWN_FEATURES_CHANGE_OPENED_ONCE'

/**
 * Dispatch `MARK_AS_KNOWN` action to mark feature as known.
 * Note: it doesn't dispatch the real action on redux if feature is already known the user.
 * @param { String } name - Feature name which is now known to user
 */
export const markAsKnown = (name, extraDetails) =>  (dispatch, getState) => {
  const isKnown = selectors.isKnown({ state: getState(), name });
  if (isKnown) {
    return;
  }
  dispatch({
    type: MARK_AS_KNOWN,
    name,
    extraDetails
  })
}

/**
 * Dispatch this action to change opened once value of feature.
 */
export const changeOpenedOnce = (name, openedOnce = false) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_OPENED_ONCE,
    name,
    openedOnce
  })
}
