import { css } from 'lit-element';
export const shadow = css `
  .shadow-none {
    box-shadow: none;
  }
  
  .shadow-elevation-top {
    box-shadow: 0 -2px 4px 2px rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.24);
  }

  .shadow-elevation-bottom {
    box-shadow: 0 2px 4px -2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);
  }

  .shadow-elevation-1dp {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .shadow-elevation-2dp {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .shadow-elevation-3dp {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .shadow-elevation-4dp {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .shadow-elevation-5dp {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
`;