export const UPDATE_FCM_TOKEN = 'DEVICE_UPDATE_FCM_TOKEN';
export const UPDATE_FCM_TOKEN_DONE = 'DEVICE_UPDATE_FCM_TOKEN_DONE';
export const UPDATE_FCM_TOKEN_FAILED = 'DEVICE_UPDATE_FCM_TOKEN_FAILED';

export const AUTO_REFRESH_FCM_TOKEN = 'DEVICE_AUTO_REFRESH_FCM_TOKEN';

export const DENIED_FCM_TOKEN = 'DEVICE_DENIED_FCM_TOKEN';
export const DENIED_FCM_TOKEN_DONE = 'DEVICE_DENIED_FCM_TOKEN_DONE';
export const DENIED_FCM_TOKEN_FAILED = 'DEVICE_DENIED_FCM_TOKEN_FAILED';

export const INIT = 'DEVICE_INIT';
export const LOAD_DATA = 'DEVICE_LOAD_DATA';

export const MARK_NOTIFICATION_PERMISSION_DIALOG_PRESENTED = 'DEVICE_MARK_NOTIFICATION_PERMISSION_DIALOG_PRESENTED';

export const init = () => {
  return {
    type: INIT
  }
}

/**
 * Dispatch action to update FCM token
 * @param {String} token 
 */
export const updateFcmToken = (token) => {
  return {
    type: UPDATE_FCM_TOKEN,
    token
  }
}

/**
 * Dispatch action to denied FCM token.
 */
export const deniedFcmToken = () => {
  return {
    type: DENIED_FCM_TOKEN
  }
}

export const autoRefreshFcmToken = (token) => {
  return {
    type: AUTO_REFRESH_FCM_TOKEN,
    token
  }
}

export const updateFcmTokenDone = (token) => {
  return {
    type: UPDATE_FCM_TOKEN_DONE,
    token
  }
}

export const updateFcmTokenFailed = (token, error) => {
  return {
    type: UPDATE_FCM_TOKEN_FAILED,
    token,
    error
  }
}

export const deniedFcmTokenDone = () => {
  return {
    type: DENIED_FCM_TOKEN_DONE
  }
}

export const deniedFcmTokenFailed = (error) => {
  return {
    type: DENIED_FCM_TOKEN_FAILED,
    error
  }
}

export const markNotificationPermissionDialogPresented = () => {
  return {
    type: MARK_NOTIFICATION_PERMISSION_DIALOG_PRESENTED
  }
}