import i18next from '@dw/i18next-esm';
import isEmpty from 'lodash-es/isEmpty';

/**
 * When given column is system/default column then return column name using i18next resource.
 * Otherwise return column name using column object name key.
 * @param { Object } column give column model to get column name.
 * @returns {String} column name
 * @private
 */
export const getColumnName = (column) => {
  if (!column || !column.type) {
    return '';
  }

  if (column.type == 'OTHER') {
    return column.name;
  }

  return i18next.t(`defaultColumns.${column.type}`);
}

/**
 * @returns {Boolean} `true` when board is active.
 */
export const isBoardActive = (attrs) => {
  if (!attrs || attrs.status !== 'ACTIVE' || attrs.movingStatus === 'ACCEPTED' || attrs.movingStatus === 'FAILED') {
    return false;
  }
  
  // When translation is in progress
  if(attrs.initialized === false) {
    return false;
  }

  return true;
}

/**
 * @returns {Object} board attrs.
 */
export const attrs = (_attrs, _moveRequestData) => {
  if (isEmpty(_moveRequestData) || isEmpty(_attrs)) {
    return _attrs;
  }

  _moveRequestData = _moveRequestData  || {};
  const movingStatus = _moveRequestData.status || '';
  const movingTo = _moveRequestData.destUserId || '';
  return { ..._attrs, ...{ movingTo, movingStatus } };
}