import { requestApi } from '../../request-api.js';

/**
 * Send otp on given `email` for given `purpose`
 * @param {String} email
 * @param {Enum} purpose
 *  - possible value: `RESET_PASSWORD` and `DELETE_USER`.
 */
export const request = ({ email, purpose }) => {
  return requestApi(`/email-otp-auth/send`, {
    method: 'POST',
    body: { email, purpose },
    excludeErrors: [400, 404],
  });
};

/**
 * Re-send otp on given `email`.
 * @param {String} id
 */
export const resend = (id) => {
  return requestApi(`/email-otp-auth/resend`, {
    method: 'POST',
    body: { id },
    excludeErrors: [400, 404],
  });
};

/**
 * Verify given `otp` for given `id`.
 * @param {String} id
 * @param {Number} otp
 * @param {String} email
 * @param {Enum} purpose
 */
export const verify = ({ id, otp, email, purpose }) => {
  return requestApi(`/email-otp-auth/validate`, {
    method: 'POST',
    body: { id, otp, email, purpose },
    excludeErrors: [400, 404],
  });
};
