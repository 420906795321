import { store } from '../../store.js';
import sortBy from 'lodash-es/sortBy';
import * as board from '../board';
import * as card from '../card';
import map from 'lodash-es/map';
import * as user from '../user';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';

const statusSortList = ['BLOCKED', 'NEEDS_REVIEW', 'NEEDS_REWORK', 'IN_PROGRESS', 'READY', 'NORMAL', 'ON_HOLD'];
const prioritySortList = ['CRITICAL', 'HIGH_PRIORITY', 'NORMAL'];

/**
 * Sorts cards by provided sort. If order of any of the card isn't changed, returns empty object.
 * @param {String} sortByValue sortBy value. Possible values: 'SORT_BY_DUE_DATE', 'SORT_BY_STATUS', 'SORT_BY_PRIORITY', 'SORT_BY_ASSIGNMENT', 'SORT_BY_TITLE'
 * @param {String} columnId Column Id.
 * @returns {Object} list of cards. key is cardId & order in it's value. e.g {'crd_fi5ls3krnvlforif': 200}. Return value has ONLY cards whose order is actually changed.
 */
export const sortBoardCards = (sortByValue, columnId) => {
  const cards = getCards(columnId);
  let currentOrders = []
  forEach(cards, (card) => {
    currentOrders.push(card.columnOrder)
  });

  let sortedCards = [];
  switch (sortByValue) {
    case 'SORT_BY_DUE_DATE':
      sortedCards = sortBy(cards, ['dueDate']);
      break;
    case 'SORT_BY_STATUS':
      sortedCards = sortBy(cards, ['status']);
      break;
    case 'SORT_BY_PRIORITY':
      sortedCards = sortBy(cards, ['priority']);
      break;
    case 'SORT_BY_ASSIGNMENT':
      sortedCards = sortBy(cards, ['userName']);
      break;
    case 'SORT_BY_TITLE':
      sortedCards = sortBy(cards, ['title']);
      break;
    default:
      console.error('sortBy is not known.', sortByValue);
  }

  let sortedOrders = {};
  forEach(sortedCards, (card, index) => {
    if (card.columnOrder !== currentOrders[index]) {
      sortedOrders[card.id] = currentOrders[index];
    }
  });
  return sortedOrders;
}

/**
 * @param {String} columnId Column Id
 * @returns List of cards based on selection or selected column. e.g [{id, title, status, dueDate, priority, order, userName}]
 */
const getCards = (columnId) => {
  const state = store.getState();
  let cards = board.selectors.selectedCards(state); // Selected cards.

  if (isEmpty(cards) || cards.length <= 1) {
    cards = map(board.selectors.cardsByColumn(state, columnId) || [], 'id');  // All the cards of column.
  }

  let list = [];
  forEach(cards, (cardId) => {
    let attrs = card.selectors.attrs(state, cardId);
    let userNames = [];
    forEach(get(attrs, 'assignments'), (id) => {
      const userAttrs = user.selectors.attrs(state, id);
      userNames.push({name: `${get(userAttrs, 'firstName', '')} ${get(userAttrs, 'lastName', '')} ${get(userAttrs, 'email')}`});
    })
    let userName = get(sortBy(userNames, 'name'), '0.name');
    userName = userName ? userName.trim().toLowerCase() : '∀'; // When no assignment set, '∀' is used to move it to last as it's value is greater than all string characthers.
    list.push({
      id: attrs.id,
      title: get(attrs, 'title', '').toLowerCase(),
      status: statusSortList.indexOf(get(attrs, 'status', 'NORMAL')),
      priority: prioritySortList.indexOf(get(attrs, 'priority', 'NORMAL')),
      dueDate: get(attrs, 'minDue') || get(attrs, 'dueDate') || 999999999999999, // Non scheduled cards are at bottom.
      userName,
      columnOrder: get(attrs, 'columnOrder')
    });
  })
  return sortBy(list, ['columnOrder']);
}