import isEmpty from 'lodash-es/isEmpty';

import { hide as hideSnackbar } from '../../components/kerika-snackbar';
import { takeEvery, select, all, put } from 'redux-saga/effects';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as cardActions from '../card/actions.js';
import * as columnActions from '../columns/actions.js';

function* undo(action) {
  const state = yield select();
  try {
    const data = selectors.data(state);
    if(isEmpty(data)) {
      yield put(actions._undoDone());
      return;
    }

    const actionName = data.actionName;
    const actionAt = data.actionAt;
    const actionId = `${actionName}-${actionAt}`;
    const actionType = data.actionType || 'CARDS_MOVE';
    hideSnackbar(actionId);

    if(actionType === 'COLUMN_MOVE') {
      const columns = data && data.columns || [];
      if(actionName && !isEmpty(columns)) {
        yield put(columnActions.undoMove({columns}));
      }
    } else {
      const cards = data && data.cards || [];
      if(actionName && !isEmpty(cards)) {
        yield put(cardActions.undoMove({cards}));
      }
    }
    yield put(actions._undoDone());
  } catch (error) {
    yield put(actions._undoFailed(error));
  }
}

/**
 * Init Saga.
 */
export function* lastMoveCardsSaga() {
  yield all([
    takeEvery(actions.UNDO, undo)
  ]);
}