export const UNDO = 'LAST_MOVE_CARDS_UNDO';
export const UNDO_DONE = 'LAST_MOVE_CARDS_UNDO_DONE';
export const UNDO_FAILED = 'LAST_MOVE_CARDS_UNDO_FAILED';

export const UPDATE = 'LAST_MOVE_CARDS_UPDATE';
export const REMOVE = 'LAST_MOVE_CARDS_REMOVE';

/**
 * Dispatch action to undo last move cards.
 */
export const undo = () => {
  return {
    type: UNDO
  }
}

export const _undoDone = () => {
  return {
    type: UNDO_DONE
  }
}

export const _undoFailed = (error) => {
  return {
    type: UNDO_FAILED,
    error
  }
}

/**
 * Dispatch action to update last move cards/column details.
 */
export const update = ({boardId, columnId, cards, columns, actionAt, actionBy, actionName, actionType = 'CARDS_MOVE'}) => {
  return {
    type: UPDATE,
    data: {boardId, columnId, cards, columns, actionAt, actionBy, actionName, actionType}
  }
}

/**
 * Dispatch action to remove last move cards details.
 */
 export const remove = (actionId) => {
  return {
    type: REMOVE,
    actionId
  }
}