import { put, takeEvery, take, call, select, all, fork, cancel, delay, spawn } from 'redux-saga/effects';
import firestoreRedux from '@dreamworld/firestore-redux';
import { requestApi, isNetworkError } from '../../request-api';
import * as actions from './actions';
import * as selectors from './selectors';
import * as app from '../app';
import * as router from '../router';
import * as authModule from '../auth';
import * as amplitude from '../../analytics/amplitude.js';
import * as lastVisitedPage from '../router/last-visited-page';
import * as user from '../user';
import * as device from '../device';
import * as multipleLanguage from '../multiple-language';
import buildURL from '../router/build-url';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { createBoard } from '../board-explorer/create-board/actions.js';
import { logEvent as amplitudeLogEvent } from '../../analytics/amplitude.js';
import { isAccountHomePage } from '../router/routers/validate-url';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';
import URI from '@dw/urijs-esm';
import { store } from '../../store.js';
import { getUserName } from '../../components/utils.js';
import { getIdWoPrefix } from '../../utils';

/**
 * Send reuest for join detail. After successfull response, send request join account/board using join secret secret.
 * On success,
 *  After join request succeed, wait for account / board data in redux state for 30 seconds.
 *  When it's found in 30 seconds, dispath 'JOIN_USING_SECRET_DONE' action & redirects user to root / board page,
 *  otherwise after 30 seconds dispatch 'JOIN_USING_SECRET_FAILED' action to redirects user to root page.
 * On failure, dispatch 'JOIN_USING_SECRET_FAILED' redux action & redirects to root page
 * @param {Object} action action payload
 */
function* joinUsingSecret(action) {
  let state = yield select();
  const page = router.selectors.page(state);
  const next = get(page, 'params.next');
  const nextUrl = next ? window.decodeURIComponent(next): '';
  const uri = new URI(nextUrl);
  const pageData = lastVisitedPage.getPage();
  try {
    //Excludes 404: NOT_FOUND, & 409: USED_BY_OTHER_USER
    const joinDetail = yield call(
      requestApi,
      `/user/users/by-join-secret?secret=${action.joinSecret}`,
      { excludeErrors: [404, 409] }
    );
    yield call(requestApi, `/user/users/join`, {
      method: 'PUT',
      body: { secret: action.joinSecret },
      excludeErrors: [404, 409],
    });

    const accountId = get(joinDetail, `joinedAccounts.0.id`);
    let accountRole;
    let boardType;
    let boardId;

    let intervalId = setInterval(() => {
      state = store.getState();
      if (isAccountHomePage(uri.path())) {
        accountRole = get(state, `auth.accessibleAccounts.${accountId}`);
      } else {
        const segment = uri.segment();
        boardId = segment[segment.length - 1];
        boardType = get(find(firestoreRedux.selectors.allDocs(state, 'user-accessible-boards'), { boardId }), 'type');
      }

      if (accountRole || boardType) {
        clearInterval(intervalId);
        intervalId = null;
        if (accountRole) {
          store.dispatch({
            type: actions.JOIN_USING_SECRET_DONE,
            accountId,
            accountRole,
          });
        } else {
          store.dispatch({
            type: actions.JOIN_USING_SECRET_DONE,
            accountId,
            boardId,
            boardType,
          });
        }

        if(isAccountHomePage(uri.path())) {
          uri.path(isEmpty(pageData) ? '/': buildURL(pageData));
        }
        router.actions.navigate(uri.toString(), true);
      }
    }, 200);

    setTimeout(() => {
      if (intervalId) {
        clearInterval(intervalId);
        store.dispatch({ type: actions.JOIN_USING_SECRET_FAILED });
        const pageData = lastVisitedPage.getPage();
        uri.path(isEmpty(pageData) ? '/': buildURL(pageData));
        router.actions.navigate(uri.toString(), true)
      }
    }, 30000);
  } catch (err) {
    yield put({ type: actions.JOIN_USING_SECRET_FAILED });
    const pageData = lastVisitedPage.getPage();
    uri.path(isEmpty(pageData) ? '/': buildURL(pageData));
    router.actions.navigate(uri.toString(), true)
  }
}


/**
 * Loads join account suggestion, account basic details of each account & each account's owner's basic detail.
 * @param {Object} action action payload e.g {type: ACCOUNT_LOAD_JOIN_SUGGESTED_LIST }
 * API path: /account/accounts/join-suggestions, method: 'GET'
 * API Doc: https://git.kerika.net/kerikav4/account-server/-/blob/master/openapi.yaml
 */
 function* loadJoinAccountSugestion() {
  try {
    let list = yield call(requestApi, `/account/accounts/join-suggestions`, {
      method: 'GET',
      log401AsError: true,
    });
    forEach(list, (obj) => {
      firestoreRedux.getDocById(`accounts`, obj.id);
      firestoreRedux.getDocById(`users`, obj.ownerId);
    })
    yield put({ type: actions.ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_DONE, list });
  } catch (err) {
    yield put({ type: actions.ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_FAILED });
  }
}

/**
 * Send request to join account.
 * On success, dispatches JOIN_ACCOUNT_DONE action.
 * On failure, dispatch 'JOIN_ACCOUNT_FAILED' redux action
 * @param {Object} action action payload
 */
 function* joinAccount(action) {
  try {
    const accountId = action.accountId;
    amplitudeLogEvent('account joined');
    yield call(requestApi, `/account/accounts/${accountId}/join`, {
      method: 'POST',
      excludeErrors: [403, 409],
    });
    yield put(actions.updateSignupDetails('JOIN_ACCOUNT', { accountId: accountId }));
    yield put({ type: actions.JOIN_ACCOUNT_DONE, accountId });
  } catch (err) {
    if (isNetworkError(err)) {
      return;
    }
    
    //If user has joined this account already, navigates to the root page.
    if (err && (err.code === 'ACCOUNT_ALREADY_SETUP' || err.code === 'ALREADY_IN_TEAM')) {
      router.actions.navigate('/');
    }
    
    yield put({ type: actions.JOIN_ACCOUNT_FAILED });
  }
}

/**
 * Wait for account & board data in firebase-redux.
 *    When it is loaded in 30 seconds, dispath 'LOAD_JOINED_ACCOUNT_DATA_DONE' & redirects to joined account home page.
 *  otherwise after 30 seconds dispatch 'LOAD_JOINED_ACCOUNT_DATA_FAILED' action & reload page.
 * @param {Object} action action payload
 */
 function* waitTillJoinedAccountDataLoaded(action) {
  let accountRole;
  let intervalId = setInterval(async () => {
    const state = store.getState();
    accountRole = get(state, `auth.accessibleAccounts.${action.accountId}`);
    const accessibleBoards = get(state, `auth.accessibleBoards.${action.accountId}`);
    if (accountRole && !isEmpty(accessibleBoards)) {
      clearInterval(intervalId);
      intervalId = null;
      forEach(accessibleBoards, (type, boardId) => {
        firestoreRedux.getDocById('boards', boardId);
      })
      store.dispatch({
        type: actions.LOAD_JOINED_ACCOUNT_DATA_DONE,
        accountId: action.accountId,
        accountRole,
      });
      router.actions.navigate(`/`);
    }
  }, 200);

  setTimeout(() => {
    if (intervalId) {
      clearInterval(intervalId);
      store.dispatch({ type: actions.LOAD_JOINED_ACCOUNT_DATA_FAILED });
      location.reload();
    }
  }, 30000);
}

/**
 * Waits for the user's default language to be set in the user preferences document.
 * 
 * @param {string} userId - The ID of the user whose default language is being set.
 * @param {string} lang - The language to wait for.
 * @param {number} [timeout=5000] - The maximum time to wait for the language to be set, in milliseconds.
 * @returns {Promise<void>} - A promise that resolves when the user's default language is set, or rejects with an error if the timeout is exceeded.
*/
let unsubscribeDefaultLanguageSet;
function waitTillUserDefaultLanguageSet(userId, lang, timeout = 5000) {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res; reject = rej; });
  
  const timeoutId = setTimeout(() => {
    unsubscribeDefaultLanguageSet && unsubscribeDefaultLanguageSet();
    reject(new Error('Timeout waiting for user default language to be set'));
  }, timeout);

  unsubscribeDefaultLanguageSet = ReduxUtils.subscribe(store, `firestore.docs.user-preferences.up_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, (userPreferences) => {
    if (userPreferences && userPreferences.lang === lang) {
      clearTimeout(timeoutId);
      unsubscribeDefaultLanguageSet && unsubscribeDefaultLanguageSet();
      resolve();
    }
  });

  return promise;
}

/**
 * Sets the user's default language in the user preferences document.
 * This function checks if the user's current language is different from the system default language. If so, it updates the user's language preference.
 */
function* setUserDefaultLanguage() {
  const state = yield select();
  const baseLang = multipleLanguage.selectors.systemDefaultLanguage(state);
  const lang = multipleLanguage.selectors.currentLanguage(state);
  if (baseLang !== lang) {
    try {
      const userId = authModule.selectors.currentUserId(state);
      const userPreferencesReq = firestoreRedux.getDocById(`users/${userId}/user-preferences`, `up_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`);
      const userPreferences = yield userPreferencesReq.result;
      
      if (!userPreferences || userPreferences.lang !== lang) {
        yield put(multipleLanguage.actions.changeLanguage(lang));
        yield call(waitTillUserDefaultLanguageSet, userId, lang, 5000);
      }
    } catch (error) {
      console.error('Failed to set user default language:', error);
    }
  }
}

/**
 * Send request to create new account.
 * On success, dispatch 'CREATE_ACCOUNT_DONE' redux action & set action query to "create-first-board".
 * On failure, dispatch 'CREATE_ACCOUNT_FAILED' redux action.
 * When board data is given, auto creates board in created account.
 * @param {Object} action action payload
 * API path: /account/accounts, method: 'POST', body: {name: accountName}
 */
function* createAccount(action) {
  const state = yield select();
  const currentAction = router.selectors.actionName(state);
  try {
    let ownedAccounts = authModule.selectors.currentUserOwnedAccounts(state);
    let accountId = ownedAccounts && ownedAccounts[0];
    const accessibleAccounts = authModule.selectors.accessibleAccounts(state);
    if (!accountId) {
      yield call(setUserDefaultLanguage);
      const response = yield call(requestApi, `/account/accounts`, {
        method: 'POST',
        body: { name: action.name },
        excludeErrors: [409],
      });
      yield delay(3000);
      accountId = response.id;
      yield put({ type: actions.CREATE_ACCOUNT_DONE, accountId });
      amplitudeLogEvent('account created', {'account_id': accountId, 'joined_accounts': accessibleAccounts && accessibleAccounts.length || 0});
    }
    if (currentAction !== 'auto-create-board') {
      if (action.boardData) { 
        yield put(createBoard({ ...action.boardData, accountId }));
      } else {
        yield put(actions.updateSignupDetails('FRESH_USER', {accountId: accountId}));
        yield call(openAccountHomePage, accountId);
      }
    }
  } catch (err) {
    //If user has already setup owned account then redirect on application root.
    if (err && err.status == 409) {
      router.actions.navigate('/');
    }
    yield put({ type: actions.CREATE_ACCOUNT_FAILED });
  }
}

/**
 * Navigates to the account home page for the specified account ID.
 * If the app is in wide layout mode, it navigates to the global home page instead.
 * This function waits until the user's owned accounts are available before navigating.
 * @param {string} accountId - The ID of the account to navigate to the home page for.
 */
function* openAccountHomePage(accountId) {
  let state = yield select();
  yield call(waitTillOwnedAccountAvailable);
  state = yield select();
  const pageName = router.selectors.pageName(state);
  if(pageName !== 'BOARD') {
    let dialogUrl  = '';
    const actionName = router.selectors.actionName(state);
    if (actionName === 'use-case-boards') {
      const pageParams = router.selectors.pageParams(state);
      const industry = pageParams && pageParams.industry || '';
      dialogUrl = industry ? `?action=use-case-boards&industry=${industry}` : '?action=use-case-boards';
    } else if(actionName === 'import-board-info') {
      dialogUrl = '?action=import-board-info';
    } else if(actionName === 'import-board-data-info') {
      dialogUrl = '?action=import-board-data-info';
    }
    
    let accountHomePageUrl = `/${accountId}/home/favorite/boards`;
    if(app.selectors.wideLayout(state)) {
      accountHomePageUrl=  `/home/favorite/boards`;
    }
    yield call(router.actions.navigate, `${accountHomePageUrl}${dialogUrl}`, true);
  }
}

/**
 * Wait till auth data available.
 */
function waitTillOwnedAccountAvailable() {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `auth.ownedAccounts`, (ownedAccounts)=> {
    if(!isEmpty(ownedAccounts)) {
      unsubscribe && unsubscribe();
      resolve();
    }
  });
  return promise;
}

let previousPage;
let previousAction;
let previousTemplateId;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = get(state, 'router.page.name');
  const currentAction = router.selectors.actionName(state);
  const currentTemplateId = router.selectors.templateId(state);
  if (currentPage === 'SIGNUP') {
    if (previousPage !== currentPage) {
      yield put({ type: actions.PAGE_OPENED });
    }
  } else if (previousPage === 'SIGNUP') {
    yield put({ type: actions.PAGE_CLOSED });
  }

  if(currentAction === 'auto-create-board' && previousAction !== 'auto-create-board') {
    yield put(actions.createAutoBoard(currentTemplateId));
  }

  previousPage = currentPage;
  previousAction = currentAction;
  previousTemplateId = currentTemplateId;
}

/**
 * create auto board in owned account.
 */
function* createAutoBoard(action) {
  let state = yield select();
  let force = router.selectors.force(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  try {
    const templateId = action.templateId;
    let bCreateNewBoard = force;
    if(!templateId) {
      router.actions.setQueryParams({action: undefined, templateId: undefined});
      return;
    }

    let ownedAccounts = authModule.selectors.currentUserOwnedAccounts(state);
    const user = authModule.selectors.currentUser(state);
    const userId = authModule.selectors.currentUserId(state);
    if(isEmpty(ownedAccounts)) {
      const accountName = `${getUserName(user)}'s Account`;
      bCreateNewBoard = true;
      if(!force) {
        router.actions.setQueryParams({force: true});
        force = true;
      }
      yield put(actions.createAccount(accountName));
    }

    ownedAccounts = yield call(waitTillUserOwnedAccountCreate);
    if(!bCreateNewBoard) {
      state = yield select();
      const userUIDetailsReq = firestoreRedux.getDocById(`user-ui`, `sud_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`)
      const userUIDetails = yield userUIDetailsReq.result;
      const signupSource = userUIDetails && userUIDetails.source;
      bCreateNewBoard = !signupSource;
    }

    const templateDetailsReq = firestoreRedux.getDocById('boards', action.templateId, { once: true, waitTillSucceed: true });
    const templateDetails = yield templateDetailsReq.result;
    const name = templateDetails && templateDetails.name;
    const boardType = templateDetails && templateDetails.type;
    const accountId = ownedAccounts && ownedAccounts[0];

    if(bCreateNewBoard) {
      if(!force) {
        router.actions.setQueryParams({force: true});
        force = true;
      }
      yield put(createBoard({name, boardType, accountId, templateId}));
    } else {
      if(embedLoginSignup) {
        useTemplateCompletedPostMessage('/');
      } else {
        router.actions.navigate('/', true);
      }
    }
  } catch (error) {
    if(embedLoginSignup) {
      useTemplateCompletedPostMessage('/');
    } else {
      router.actions.setQueryParams({action: undefined, templateId: undefined});
    }
  }
}

function useTemplateCompletedPostMessage(url) {
  const uri = new URI();
  uri.path(url);
  uri.removeQuery('embed-login-signup');
  uri.removeQuery('action');
  uri.removeQuery('force');
  uri.removeQuery('template');
  uri.removeQuery('fresh-user');
  uri.removeQuery('auto-create-account');
  uri.removeQuery('account-name');
  uri.removeQuery('action-type');
  const opener = window.top || window.opener;
  if(opener) {
    opener.postMessage({
      type: 'USE_TEMPLATE_COMPLETED',
      url: uri.toString()
    }, "*");
  }
}

function waitTillUserOwnedAccountCreate() {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `auth.ownedAccounts`, (ownedAccounts)=> {
    if(!isEmpty(ownedAccounts)) {
      unsubscribe && unsubscribe();
      resolve(ownedAccounts);
    }
  });
  return promise;
}

function* updateUtmDetails(action) {
  const state = yield select();
  const userId = action && action.userId || authModule.selectors.currentUserId(state);
  const deviceId = action && action.deviceId || device.selectors.getId(state);
  const impersonatedUser = authModule.selectors.impersonatedUser(state);
  const details = action.details || {};
  try {
    if(!userId || isEmpty(details)) {
      return;
    }

    const currentDoc = selectors.utmDetails(state);
    const currentUtmDetails = currentDoc && currentDoc.utmDetails || {};

    if(isEqual(currentUtmDetails, details)) {
      return;
    }

    const doc = {
      id: `uuud_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      type: 'utm-details',
      userId,
      utmDetails: merge({}, currentUtmDetails, details)
    }

    firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
    firestoreRedux.save('device-ui', { id: `du_${deviceId}`, deviceId, utmDetails: {} }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    console.error("signup > updateUtmDetails > failed due to this: ", error);
  }
}

/**
 * Update signup details on firebase.
 */
function* updateSignupDetails(action) {
  const state = yield select();
  const userId = authModule.selectors.currentUserId(state);
  const impersonatedUser = authModule.selectors.impersonatedUser(state);
  const source = action.source;
  const details = action.details || {};
  const data = {...details, ...{source}};
  try {
    const oldValue = selectors.signupDetails(state);
    if(!userId || isEqual(oldValue, data) || !isEmpty(oldValue)) {
      return;
    }
    
    if(source) {
      amplitude.logEvent('signup completed', {'signup_source': source});
      amplitude.setUserProperties(userId, {'signup_source': source});
    }
    
    const doc = {
      id: `sud_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      type: 'signup-details',
      userId
    }
  
    firestoreRedux.save(`users/${userId}/user-ui`, {...doc, ...data}, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    console.error("signup > updateSignupDetails > failed due to this: ", error);
  }

  const doc = {
    id: `sud_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
    type: 'signup-details',
    userId
  }

  firestoreRedux.save(`users/${userId}/user-ui`, {...doc, ...data});
  const properties = yield call(getUserProperties, data);
  yield put(user.actions.updateProperties(properties));
}

function* getUserProperties(data) {
  let properties = {
    signup: {
      type: data.source,
    }
  };

  if(data.accountId) {
    properties.signup.accountId = data.accountId;
  }

  if(data.boardId) {
    properties.signup.boardId = data.boardId;
  }

  if(data.templateId) {
    try {
      properties.signup.template = {};
      properties.signup.template.id = data.templateId;
      properties.signup.template.name = 'NA';
      properties.signup.template.category = 'NA';
      const query = firestoreRedux.getDocById('boards', data.templateId, { once: true });
      const attrs = yield query.result;
      properties.signup.template.name = attrs && attrs.name || 'NA';
      properties.signup.template.category = attrs && attrs.templateCategory || 'NA';
    } catch (error) {
      console.warn(error);
    }
  }

  return properties;
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * This is edge case.
 * When user's signup is completed already & she comes with /signup URL directly without join secret,
 * redirects her to accounts' home page.
 */
function* checkForSignupCompleted() {
  const state = yield select();
  const signupCompleted = selectors.signupCompleted(state);
  const joinSecret = selectors.joinSecret(state);
  if (signupCompleted && !joinSecret) {
    router.actions.navigate('/', true);
  }
}

function* disconnectSignupDetails() {
  firestoreRedux.cancelQueryByRequester(`signup-details`);
}

function* loadSignupDetails() {
  yield spawn(loadLastSeenDetails);
}

function* loadLastSeenDetails() {
  const state = yield select();
  const userId = authModule.selectors.currentUserId(state);
  if(userId) {
    try {
      const lastseenDetailsReq = firestoreRedux.getDocById(`users/${userId}/lastseen-details`, `ulsd_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId: 'signup-details' });
      yield lastseenDetailsReq.result;
    } catch (error) {
      console.error(`Failed to load ${userId} lastseen-details due to this: `, error);
    }
  }
}

/**
 * Mark welcomeDialogPresented as true.
 */
function* markJoinAccountWelcomeDialogPresented() {
  const state = yield select();
  const userId = authModule.selectors.currentUserId(state);
  const impersonatedUser = authModule.selectors.impersonatedUser(state);
  try {
    firestoreRedux.save(`users/${userId}/user-ui`, {
      id: `jawd_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      type: 'join-account-welcome-dialog-presented',
      userId,
      presented: true,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    console.error("signup > markJoinAccountWelcomeDialogPresented > failed due to this: ", error);
  }
}

/**
 * Start use setup flow.
 */
function* signupFlow() {
  while (yield take(actions.PAGE_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(checkForSignupCompleted),
        call(loadSignupDetails),
        takeEvery(actions.ACCOUNT_LOAD_JOIN_SUGGESTED_LIST, loadJoinAccountSugestion),
        takeEvery(actions.JOIN_USING_SECRET, joinUsingSecret),
        takeEvery(actions.JOIN_ACCOUNT, joinAccount),
        takeEvery(actions.JOIN_ACCOUNT_DONE, waitTillJoinedAccountDataLoaded)
      ]);
    })
    yield take(actions.PAGE_CLOSED);
    yield cancel(task);
    yield call(disconnectSignupDetails);
  }
}

/**
 * Init Saga.
 */
function* signupSaga() {
  yield all([
    call(watchRouter),
    call(signupFlow),
    takeEvery(actions.CREATE_ACCOUNT, createAccount),
    takeEvery(actions.CREATE_AUTO_BOARD, createAutoBoard),
    takeEvery(actions.UPDATE_SIGNUP_DETAILS, updateSignupDetails),
    takeEvery(actions.UPDATE_UTM_DETAILS, updateUtmDetails),
    takeEvery(actions.MARK_JOIN_ACCOUNT_WELCOME_DIALOG_PRESENTED, markJoinAccountWelcomeDialogPresented)
  ]);
}

export default signupSaga;