import get from 'lodash-es/get.js';
import { createSelector } from 'reselect';
import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as boardSettingSelectors from '../board-setting/selectors.js';
import * as cardSelectors from '../card/selectors.js';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import {
  getEndOfNextMonthTime,
  getEndOfNextWeekTime,
  getEndOfThisMonthTime,
  getEndOfThisWeekTime,
  getIdWoPrefix,
  getTodayTime,
  getTomorrowTime,
} from '../../utils';

/**
 * @param {Object} state Redux state
 * @returns Highlights data for current opened board.
 */
export const currentBoardHighlights = (state) => get(state, `board-highlights.${router.selectors.pageBoardId(state)}`);

/**
 * Current board hash Highlights or not.
 */
export const hasCurrentBoardHighlights = createSelector(
  (state) => currentBoardHighlights(state),
  (_currentBoardHighlights) => {
    return !isEmpty(_currentBoardHighlights);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns `true` when card matches board highlights criteria.
 */
export const isCardHighlighted = createSelector(
  (state, cardId) => firestoreRedux.selectors.doc(state, 'cards', cardId),
  currentBoardHighlights,
  auth.selectors.currentUserId,
  (state, cardId, boardId) => boardSettingSelectors.tagsEnabled(state, boardId),
  router.selectors.pageName,
  router.selectors.referrerPage,
  (state, cardId) => cardSelectors.summary(state, cardId),
  (card, highlights, currentUserId, tagsEnabled, pageName, referrerPage, summary) => {
    if (!highlights || pageName !== 'BOARD' || referrerPage === 'VIEWS') {
      return;
    }

    const isActive = card && card.columnType !== 'DONE' && card.columnType !== 'TRASH';

    // Overdue card or not.
    const dueDate = get(card, `dueDate`);
    const minDue = get(card, `minDue`);
    const curTime = Date.now();
    const overDue = isActive && dueDate && (curTime > dueDate || curTime > minDue);
    const status = get(card, `status`, 'NORMAL');

    switch (highlights.type) {
      case 'ASSIGNED_TO_ME':
        const assignments = get(card, `assignments`, []);
        return assignments.includes(currentUserId);

      case 'NEEDS_ATTENTION':
        const priority = get(card, 'priority');
        return (
          overDue ||
          priority == 'CRITICAL' ||
          priority === 'HIGH_PRIORITY' ||
          (isActive && (status === 'BLOCKED' || status === 'NEEDS_REVIEW' || status === 'NEEDS_REWORK' || status === 'ON_HOLD'))
        );

      case 'HIGH_PRIORITY_AND_CRITICAL':
        return get(card, `priority`) === 'HIGH_PRIORITY' || get(card, `priority`) === 'CRITICAL';

      case 'OVERDUE':
        return overDue;

      case 'CUSTOM':
        let assignmentMatched = false;
        let statusMatched = false;
        let dueDateMatched = false;
        let priorityMatched = false;
        let tagsMatched = false;

        // Assignments
        const cardAssignments = get(card, `assignments`, []);
        const selectedAssignments = get(highlights, `data.assignments`);
        if (
          !selectedAssignments ||
          selectedAssignments.includes('ANY') ||
          (selectedAssignments.includes('NO_ONE') && isEmpty(cardAssignments))
        ) {
          assignmentMatched = true;
        } else {
          if (!isEmpty(selectedAssignments)) {
            for (let id of selectedAssignments) {
              if (cardAssignments.includes(id)) {
                assignmentMatched = true;
                break;
              }
            }
          }
        }

        // Status
        const selectedStatus = get(highlights, `data.status`);
        if (!selectedStatus || selectedStatus.includes('ANY') || (isActive && selectedStatus.includes(status))) {
          statusMatched = true;
        }

        // Due Date
        const selectedDueDate = get(highlights, `data.dueDate`);
        if (!selectedDueDate || selectedDueDate.includes('ANY') || (isActive && !dueDate && selectedDueDate.includes('NOT_SCHEDULED'))) {
          dueDateMatched = true;
        } else {
          switch (selectedDueDate) {
            case 'OVERDUE':
              dueDateMatched = isActive && overDue;
              break;
            case 'TODAY':
              dueDateMatched = isActive && dueDate && dueDate > curTime && dueDate <= getTodayTime();
              break;
            case 'TOMORROW':
              dueDateMatched = isActive && dueDate && dueDate > getTodayTime() && dueDate <= getTomorrowTime();
              break;
            case 'THIS_WEEK':
              dueDateMatched = isActive && dueDate && dueDate > curTime && dueDate <= getEndOfThisWeekTime();
              break;
            case 'NEXT_WEEK':
              dueDateMatched = isActive && dueDate && dueDate > getEndOfThisWeekTime() && dueDate <= getEndOfNextWeekTime();
              break;
            case 'THIS_MONTH':
              dueDateMatched = isActive && dueDate && dueDate > curTime && dueDate <= getEndOfThisMonthTime();
              break;
            case 'NEXT_MONTH':
              dueDateMatched = isActive && dueDate && dueDate > getEndOfThisMonthTime() && dueDate <= getEndOfNextMonthTime();
              break;
          }
        }

        // Priority
        const cardPriority = get(card, `priority`, 'NORMAL');
        const selectedPriority = get(highlights, `data.priority`);
        if (!selectedPriority || selectedPriority.includes('ANY') || selectedPriority.includes(cardPriority)) {
          priorityMatched = true;
        }

        // Tags
        const cardTags = get(summary, `tags`, []) || [];
        const selectedTags = tagsEnabled ? get(highlights, `data.tags`) : ['ANY'];
        if (!selectedTags || selectedTags.includes('ANY') || (selectedTags.includes('WITHOUT_TAG') && isEmpty(cardTags))) {
          tagsMatched = true;
        } else {
          if (!isEmpty(selectedTags)) {
            for (let id of selectedTags) {
              if (cardTags && cardTags.includes(id)) {
                tagsMatched = true;
                break;
              }
            }
          }
        }

        return assignmentMatched && statusMatched && dueDateMatched && priorityMatched && tagsMatched;
    }
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);
