import device from "current-device";
import {
  UPDATE_SERVICE_WORKER_UPDATE_STATUS,
  UPDATE_LATEST_VERSION,
  UPDATE_WIDE_LAYOUT,
  SET_PAGE_TITLE,
  UPDATE_TOUCH_DEVICE,
  UPDATE_APP_CONNECTION_STATUS,
  UPDATE_LAST_CONNECTION_FAIL_TIME,
  ORIENTATION_CHANGED,
  DEVICE_READY
} from './actions.js';

import Bowser from "bowser";
import merge from 'lodash-es/merge.js';

/**
 * @returns {Object} common/default configration for all enviroments.
 */
const getDefaultConfig = () => {
  return {
    attachment: {
      file: {
        maxSizeInMb: 200
      }
    },
    auth: {
      baseUrl: 'https://auth.kerika-test2.dreamworld.solutions',
      googleClientId: '837399046096-qu9s9rm843o0fhvuqosr5kb0qqncmg3o.apps.googleusercontent.com',
      loginApiUrl: '/authcallback/{service}?api-login&code={authCode}',
      additionalAuthApiUrl: '/user/api/link-auth?service={service}&code={authCode}',
      userUIBaseUrl: 'https://auth-user.kerika-test2.dreamworld.solutions'
    },
    appLink: {
      ios: 'https://kerika-test2.dreamworld.solutions',
      android: 'https://kerika-test2.dreamworld.solutions'
    },
    bgColors: ["#E8E4DD", "#E9F0FF", "#F1FFE9", "#FFE9F1", "#D5D8DC", "#DBC0DA", "#F7CDDB", "#8CA3C9", "#C2B1C5", "#D8E3DF", "#D8E1E3", "#E1E3D8", "#D8DCE3", "#C0C7C8", "#BCCBCE"],
    iframelyApiKey: 'a7cde2e1e1bb57df0e542e',
    //In minutes
    undoCardTimeout: 3,
    lastSeenPushIntervalInSeconds: 30,
    lastSeenPushIntervalInSecondsForFreshUser: 10,
    //In Days
    fcmTokenRefreshTime: 30,
    redirectEndpoint: '/util/r?url={url}&referrer={referrer}&lang={lang}&context={context}',
  }
}

/**
 * @returns {Object} enviroments configration.
 */
const getConfig = () => {
  let config = window.K.config || {};
  let authCongfig = {
    baseUrl: window.K.config.authConfig_baseURL ,
    googleClientId: window.K.config.authConfig_googleClientId,
    loginApiUrl: '/authcallback/{service}?api-login&code={authCode}',
    userUIBaseUrl: window.K.config.authConfig_userUIBaseURL
  };

  let appLink = {
    ios: window.K.config.appLink_ios,
    android: window.K.config.appLink_android
  }

  let clarity = {
    projectId: window.K.config.clarityProjectId,
    enableForDays: window.K.config.clarityEnableForDays
  }

  const bgColors = window.K.config.bgColors && K.config.bgColors.split(',') || [];

  const serviceWorker = { latestVersionDocumentPath: "app/app-pwa", latestVersionField: "latestVersion" }

  return merge({}, config, {auth: authCongfig, bgColors, appLink, serviceWorker, clarity});
}

// Set device OS, browser, platform information from bowser library.
const bowser = Bowser.parse(window.navigator.userAgent);

const INITIAL_STATE = {
  config: merge({}, getDefaultConfig(), getConfig()),
  version: {
    current: window.K.config.version,
    latest: undefined,
    updateStatus: undefined
  },
  pageTitle: 'Kerika',
  touchDevice: false,
  bowser: bowser,
  orientation: device.orientation,
  deviceReady: false
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SERVICE_WORKER_UPDATE_STATUS:
      return {
        ...state,
        version: {
          ...state.version,
          updateStatus: action.value
        }
      };

    case UPDATE_LATEST_VERSION:
      return {
        ...state,
        version: {
          ...state.version,
          latest: action.latestVersion
        }
      }

    case UPDATE_WIDE_LAYOUT:
      return {
        ...state,
        wideLayout: action.wide
      };

    case UPDATE_TOUCH_DEVICE:
      return {
        ...state,
        touchDevice: action.value
      }

    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.title
      }
    }

    case UPDATE_APP_CONNECTION_STATUS:
      return {
        ...state,
        online: action.status
      }

    case UPDATE_LAST_CONNECTION_FAIL_TIME:
      return {
        ...state,
        lastConnectionFailTime: action.value
      }

    case ORIENTATION_CHANGED:
    return {
      ...state,
      orientation: action.orientation
    }
    case DEVICE_READY:
      return { ...state, deviceReady: true }
    default:
      return state;
  }
};

export default app;
