import { LitElement, html, css, unsafeCSS } from 'lit';

import { shadow } from '../theme/shadow.js';
import { sharedStyles } from '../theme/shared-styles.js';
import URI from '@dw/urijs-esm';
import typographyLiterals from '../theme/typography-literals.js';

import { openExternalLink } from '../components/open-external-link.js';
import * as router from '../redux/router';
import * as amplitude from '../analytics/amplitude.js';

import '../components/kerika-surface.js';
import '../components/kerika-ripple.js';

/*
    Behaviors:-
    - shows board thumbnail, name and description.

    usage pattern:-
    <use-case-board-card .model=${board}> </use-case-board-card>
*/
export class UseCaseBoardCard extends LitElement {
  static get styles() {
    return [
      shadow,
      sharedStyles,
      css`
        :host {
          width: var(--use-case-board-card-width, 336px);
          min-width: var(--use-case-board-card-width, 336px);
          min-width: var(--use-case-board-card-width, 336px);
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
        }

        kerika-surface {
          position: relative;
          border: 1px solid var(--separator-color);
          border-radius: 4px;
          padding: 12px;
          margin: var(--use-case-board-card-surface-margin, 0 24px);
          cursor: pointer;
          min-height: 206px;
        }

        img {
          width: 246px;
          height: 120px;
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
        }

        img.loaded {
          opacity: 1;
        }

        .name {
          margin-top: 12px;
          ${unsafeCSS(typographyLiterals.headline6)};
        }

        .description {
          user-select: none;
          margin-top: 16px;
          ${unsafeCSS(typographyLiterals.body1)};
        }

        kerika-ripple{
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
        }
      `,
    ];
  }

  static properties = {
    /**
     * input property
     */
    model: { type: Object },

    /**
     * board open in new tab.
     */
    openInNewTab: { type: Boolean },

    /**
     * embeded use case boards flow is trigger or not.
     */
    embedUseCaseBoards: { type: Boolean },
  };

  render() {
    return html`
      <kerika-surface elevation=${4} @click=${() => this._onUseCaseBoardClick(this.model)}>
        <img @load=${this._onLoadImage} src="${this.model.thumbnail}" />
        <div class="name">${this.model.name}</div>
        <kerika-ripple></kerika-ripple>
      </kerika-surface>
      <div class="description">${this.model.description}</div>
    `;
  }

  /**
   * Logs `trello import boards initiated` event.
   * @private
   */
  __logUseCaseBoardOpenedAmplutudeEvent(boardId) {
    amplitude.logEvent('use-case board opened', { referrer: 'SIGNUP_PAGE', boardId });
  }

  /**
   * Navigates to the board page.
   */
  _onUseCaseBoardClick(board) {
    this.__logUseCaseBoardOpenedAmplutudeEvent(board.boardId);
    const boardUrl = `/${board.accountId}/board/${board.boardId}`;
    const uri = new URI();
    uri.pathname(boardUrl);
    uri.hash('');
    uri.query('');
    const opener = this.embedUseCaseBoards ? window.top || window.opener: null;
    if(opener) {
      opener.postMessage({
        type: 'USE_CASE_BOARDS_OPEN_BOARD',
        url: uri.toString()
      }, "*");
      return;
    }

    if(this.openInNewTab) {
      openExternalLink({ href: uri.toString()});
      return;
    }

    window.dispatchEvent(new CustomEvent('use-case-board-opened', { detail: { board } }));
    router.actions.navigate(boardUrl);
  }

  /**
   * On image load, adds `loaded` class to apply smooth animation.
   * @param {Object} e Event
   */
  _onLoadImage(e) {
    const img = e && e.target;
    img && img.classList && img.classList.add('loaded');
  }
}
customElements.define('use-case-board-card', UseCaseBoardCard);
