
import { html, css } from 'lit-element';
import { KerikaCompositeDialog } from '../kerika-composite-dialog.js';
import { store } from '../../store.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import * as app from '../../redux/app/index.js';
import * as auth from '../../redux/auth/index.js';
import * as router from '../../redux/router';
import * as amplitude from '../../analytics/amplitude.js';
import * as dialogRouter from '../../redux/router/routers/dialog-router.js';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import URI from '@dw/urijs-esm';
import typographyLiterals from "../../theme/typography-literals.js";

import { waitForEntryAnimation } from '../../utils.js';

import '../kerika-loader.js';
import './kerika-login-and-signup-view.js';

/**
 * ## Purpose
 *  - Provides way to login/signup into application with Google, Box, Microsoft or Direct email.
 *
 *  Usage pattern:
 *  - <kerika-login-and-signup-dialog></kerika-login-and-signup-dialog>
 * @class
 */

class KerikaLoginAndSignupDialog extends localize(i18next)(KerikaCompositeDialog) {
  static get styles() {
    return [
      super.styles,
      css`
        :host([placement="center"][type="modal"]) .mdc-dialog__surface {
          --dw-dialog-min-width: 500px;
          --dw-dialog-max-width: 500px;
        }

        :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content {
          padding-left: 60px;
          padding-right: 60px;
        }


        :host([type="fit"]) #dialog-content {
          padding: 72px 32px 32px 32px;
        }

        :host([placement="center"][type="modal"]) .header.close-header .title,
        :host([type="fit"]) .close-header .title {
          display: flex;
          flex: 1;
          justify-content: center;
          text-transform: unset;
        }

        :host([type="fit"]) .close-header {
          padding-top: 32px;
          height: 72px;
        }

        :host([type="fit"]) .close-header .title {
          ${typographyLiterals.headline5};
          padding-right: 32px;
        }

        :host([type="fit"][error="EMAIL_NOT_AVAILABLE"]) .close-header .title {
          text-align: center;
        }

        :host([type="fit"]) .close-header kerika-icon-button[icon="close"] {
          position: absolute;
          top: 24px;
          right: 8px;
        }

        h6 {
          ${typographyLiterals.body1};
          text-align: center;
          white-space: pre-wrap;
          margin-top: 40px;
          margin-bottom: 24px;
        }

        :host([wide-layout]) h6, :host([mode="LOGIN"]) h6 {
          margin-bottom: 40px;
        }

        :host([type="fit"]) {
          ${typographyLiterals.body1};
        }

        :host([type="fit"]) h6 {
          margin-top: 24px;
        }

        :host([type="fit"]) .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100px;
        }

        @media (max-height: 792px) {
          :host([type="fit"]) #dialog-content {
            padding: 12px 32px 12px 32px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.i18nextNameSpaces = ['login-and-signup'];

    this._joinSecret =  new URI().query(true)['join'];
    if(this._joinSecret) {
      store.dispatch(auth.actions.getJoinSecretInfo(this._joinSecret));
    }

    this.largeCloseIcon = true;
  }

  static get properties() {
    return {
      /**
       * Read data from redux
       * Current app is installed app or not.
       */
      _isInstalledApp: {type: Boolean, reflect: true, attribute: 'installed-app'},

      /**
       * Read data from redux
       * `true` if current window width is higher than `767px`
       */
      _wideLayout: { type: Boolean , reflect: true, attribute: 'wide-layout'},

      /**
       * Read data from redux
       * Last error.
       */
      _error: { type: String, reflect: true, attribute: 'error' },

      /**
       * Read data from redux
       * signup service.
       */
      _signupService: { type: String },

      /**
       * Read data from redux
       * login service.
       */
      _loginService: { type: String },

      /**
       * Read data from redux
       * Login/signup process is running or not.
       */
      _inProgress: { type: Boolean },

      /**
       * Currently signup-mode url or not.
       */
      _signupMode: { type: Boolean, reflect: true, attribute: 'signup-mode' },

      /**
       * Invited user or not.
       */
      _hasInvitedUser: { type: Boolean, reflect: true, attribute: 'has-invited-user' },

      /**
       * Join secret for invited user.
       */
      _joinSecret: { type: String },

      /**
       * Join secret information.
       */
      _joinSecretInfo: { type: Object },

      /**
       * Get join secret info process status.
       */
      _getJoinSecretInfoStatus: { type: String },

      /**
       * signup dialog open for use tempalte or not.
       */
      _useTemplate: { type: Boolean },

      /**
       * K + K signup is accepted or not.
       */
      _acceptedSignupMode: { type: Boolean }
    }
  }

  get _contentTemplate() {
    if(!this.language || this._joinSecret && this._getJoinSecretInfoStatus === 'IN_PROGRESS') {
      return html`
        <div class="loading-container">
          <kerika-loader></kerika-loader>
        </div>
      `;
    }

    return html`
      <h6 ?hidden=${this._acceptedSignupMode || this._error === 'EMAIL_NOT_AVAILABLE' || !isEmpty(this._joinSecretInfo)}>${unsafeHTML(this._getSubTitle())}</h6>
      <kerika-login-and-signup-view
        .mode=${this._acceptedSignupMode ? 'ACCEPTED_SIGNUP': this._error === 'EMAIL_NOT_AVAILABLE' ? 'EMAIL_NOT_AVAILABLE': this._signupMode ? 'SIGNUP': 'LOGIN'}
        .installedApp=${!!this._isInstalledApp}
        .embedLoginSignup=${!!this._embedLoginSignup}
        .inProgress=${this._inProgress}
        .error=${this._error}
        .service=${this._signupMode ? this._signupService: this._loginService}
        @accepted-signup=${()=> {this._acceptedSignupMode = true;}}
        @reset-data=${()=> { this.__resetData() }}>
      </kerika-login-and-signup-view>
    `;
  }

  __resetData() {
    this._acceptedSignupMode = false; 
    store.dispatch(auth.actions.resetLoginAndSignupData());
  }

  updated(changeProps) {
    super.updated && super.updated(changeProps);
    if(changeProps.has('_signupMode')) {
      if(!this._embedLoginSignup) {
        if(this._signupMode) {
          amplitude.logEvent('signup opened', {trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
        } else {
          amplitude.logEvent('login opened', {trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
        }
      }
    }

    if(changeProps.has('_useTemplate') || changeProps.has('_error') || changeProps.has('_acceptedSignupMode') || changeProps.has('_signupMode')) {
      this._setDialogTitle();
    }
  }

  /**
   * Reset input value.
   * @override
   * @param {Object} e Event
   */
  _onDialogClosed(e) {
    super._onDialogClosed && super._onDialogClosed(e);
    if (dialogRouter.getDialogName() === this.dialogName) {
      this.__trackClosedEvents();
      router.actions.back();
    }
  }

  __trackClosedEvents() {
    if(this._embedLoginSignup) {
      return;
    }

    const ux = this.__lastClosingReason || 'UNKNOWN';
    const trigger = router.selectors.signupLoginPageOpenTrigger(store.getState());
    if(this._signupMode) {
      amplitude.logEvent('signup closed', {trigger, ux}, { trackReferrer: true });
    } else {
      amplitude.logEvent('login closed', {trigger, ux}, { trackReferrer: true });
    }
  }

  /**
   * Invoked when user click on sign up buttons.
   * Redirect to signup page.
   * @private
   */
  __redirectToSignupPage() {
    router.actions.setHashQueryParams({'mode': 'signup'});
  }

  /**
   * Invoked when user click on login buttons.
   * Redirect to login page.
   * @private
   */
  __redirectToLoginPage() {
    router.actions.setHashQueryParams({'mode': 'login'});
  }

  /**
   * Invoked When language is changed.
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this._setDialogTitle();
  }

  _setDialogTitle() {
    if(!this.language) {
      this.title = '';
      return;
    }

    if(this._acceptedSignupMode) {
      this.title = i18next.t('login-and-signup:signup.signupAcceptNotification.title');
      return;
    }

    if(this._error === 'EMAIL_NOT_AVAILABLE') {
      const service = this._signupMode ? this._signupService: this._loginService
      this.title = i18next.t('login-and-signup:emailNotAvailable.title', { service: service ? i18next.t(`service.${service}`): ''});
      return;
    }

    if(!isEmpty(this._joinSecretInfo)) {
      const accountName = get(this._joinSecretInfo, 'joinedAccounts.0.name');
      this.title = i18next.t('login-and-signup:invitedUser.title', {accountName});
      return;
    }
    this.title = this._signupMode ? i18next.t('login-and-signup:signup.title') :i18next.t('login-and-signup:login.title');
  }

  _getSubTitle() {
    if(this._acceptedSignupMode || this._error === 'EMAIL_NOT_AVAILABLE' || !isEmpty(this._joinSecretInfo)) {
      return;
    }

    return this._signupMode ? this._useTemplate ? i18next.t('login-and-signup:signup.userTemplateSubTitle'): i18next.t('login-and-signup:signup.subTitle') : this._useTemplate ? i18next.t('login-and-signup:login.userTemplateSubTitle'):i18next.t('login-and-signup:login.subTitle');
  }

  /**
   * Close dialog.
   * @protected
   */
    async _onCloseClick(e) {
    await waitForEntryAnimation(e);
    if(this._embedLoginSignup) {
      const opener = window.top || window.opener;
      if(opener) {
        opener.postMessage({
          type: 'AUTH_LOGIN_CLOSED'
        }, "*");
        return;
      }
    }
    this.__lastClosingReason = 'CLOSE_ICON_BUTTON';
    this.close();
  }

  /**
   * Invoked whenever redux state is changed
   * @param {Object} state - Redux store
   */
  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);
    this._isInstalledApp = app.selectors.isInstalledApp(state);
    this._wideLayout = app.selectors.wideLayout(state);
    this._loginLastError = auth.selectors.loginError(state);
    this._signupError = auth.selectors.signupError(state);
    const loginStatus = auth.selectors.loginStatus(state);
    const signupStatus = auth.selectors.signupStatus(state);
    this._signupService = auth.selectors.signupService(state);
    this._loginService = auth.selectors.loginService(state);
    this._joinSecretInfo =  auth.selectors.joinSecretInfo(state);
    this._hasInvitedUser = !isEmpty(this._joinSecretInfo);
    this._inProgress = loginStatus === 'IN_PROGRESS' || loginStatus === 'SUCCESS' || signupStatus === 'IN_PROGRESS' || (signupStatus === 'SUCCESS' && this._hasInvitedUser) || (this._signupService && this._signupService !== 'kerika' && signupStatus === 'SUCCESS');

    this._embedLoginSignup = router.selectors.embedLoginSignup(state);
    const getJoinSecretInfoStatus = this._getJoinSecretInfoStatus;
    this._getJoinSecretInfoStatus = auth.selectors.getJoinSecretStatus(state);
    if(getJoinSecretInfoStatus === 'IN_PROGRESS' && this._getJoinSecretInfoStatus === 'SUCCESS' && this._hasInvitedUser) {
      this.__redirectToSignupPage();
    }
    const page = router.selectors.page(state);
    const signupMode = router.selectors.signupMode(state);
    if(this._isInstalledApp && !this._hasInvitedUser && this._getJoinSecretInfoStatus !== 'IN_PROGRESS') {
      this._signupMode = false;
    } else {
      this._signupMode = signupMode;
    }

    this.noCancelOnOutsideClick =  this._inProgress;
    this.noCancelOnEscKey = this._inProgress;
    this.closeIconDisabled =  this._inProgress;
    const template =  router.selectors.templateId(state);
    const source = router.selectors.source(state);
    this._useTemplate = !!(template && source === 'template');
    this._error = this._signupMode ? this._signupError : this._loginLastError;
  }
}

window.customElements.define('kerika-login-and-signup-dialog', KerikaLoginAndSignupDialog);
