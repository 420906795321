import get from 'lodash-es/get';
import * as actions from "./actions";
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

const INITIAL_STATE = {};

export const lastMoveCardsReducer = (state = INITIAL_STATE, action) => {
  let oState = {...state};
  switch (action.type) {
    case actions.UPDATE:
      oState = ReduxUtils.replace(oState, 'status', 'INIT');
      return ReduxUtils.replace(oState, 'data', action.data);
    case actions.REMOVE:
      const actionId = `${get(state, 'data.actionName')}-${get(state, 'data.actionAt')}`;
      if(actionId == action.actionId) {
        return INITIAL_STATE;
      }
      return state;
    case actions.UNDO:
      return ReduxUtils.replace(oState, 'status', 'IN_PROGRESS');
    case actions.UNDO_DONE:
      return ReduxUtils.replace(oState, 'status', 'SUCCESS');
    case actions.UNDO_FAILED:
        return ReduxUtils.replace(oState, 'status', 'FAILED');
    default:
      return state;
  }
};