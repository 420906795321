import get from 'lodash-es/get';
import * as boardTagsSelectors from '../board-tags/selectors.js';
import * as boardSelectors from '../board/selectors.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import forEach from 'lodash-es/forEach';
import first from 'lodash-es/first';
import last from 'lodash-es/last';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual.js';
import sortBy from 'lodash-es/sortBy';
import { getIdWoPrefix } from '../../utils';
import { createSelector } from 'reselect';

/**
 *
 * @param {Object} state Redux state.
 * @param {String} cardId Card Id.
 * @returns {Object} Card's basic details.
 */
export const attrs = (state, cardId) => firestoreRedux.selectors.doc(state, 'cards', cardId);

/**
 * @returns {Object} card's summary. e.g. {id, boardId, cardId, chats, openTasks, closedTasks, attachments}
 */
export const summary = createSelector(
  (state, cardId) => firestoreRedux.selectors.doc(state, `cards-summary`, `cs_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}`) || {},
  (summary) => {
    return summary;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Object} Card's sub-task summary e.g. {id, boardId, cardId, openTasksAssignments, openTasks, closedTasks, maxDueDate, minDueDate, updatedAt}
 */
export const taskSummary = createSelector(
  (state, cardId) => summary(state, cardId),
  (summary) => {
    summary = summary || {};
    const task = summary.tasks || {};
    const openTasks = task.open || summary.openTasks || 0;
    const closedTasks = task.closed || summary.closedTasks || 0;
    const minDueDate = task.minDueDate || 0;
    const maxDueDate = task.maxDueDate || 0;
    if(!openTasks && !closedTasks) {
      return {};
    }

    return {closedTasks, openTasks, minDueDate, maxDueDate};
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @returns {Array} given card assigned tags.
 */
export const tags = createSelector(
  (state, cardId) => summary(state, cardId),
  (summary) => {
    return summary && summary.tags || [];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 500,
    },
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state.
 * 	@property {String} cardId Card Id.
 * @returns {Array} list of card assigned tags. e.g. [{id,  name, color}, ...]
 */
export const cardAssignedTags = createSelector(
  (state, cardId, boardId) => tags(state, cardId),
  (state, cardId, boardId) => firestoreRedux.selectors.collection(state, 'board-tags'),
  (state, cardId, boardId) => boardTagsSelectors.allowedColors(state),
  (cardTags, boardTags, allowedColors) => {
    let list = [];
    forEach(cardTags, (id) => {
      const name = get(boardTags, `${id}.name`);
      const colorId = get(boardTags, `${id}.color`);
      const color = get(allowedColors, `${colorId}.value`, '');
      list.push({ id, name, color });
    });
    return sortBy(list, ['name']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 3000,
    },
  }
);

/**
 * @returns {Object} Card's assignments with it's role on the board. e.g. {'usr_j59gkd9495ugjf': 'TEAM_MEMBER', ...}
 */
export const assignments = createSelector(
  attrs,
  (state, cardId, boardId) => boardSelectors.members(state, boardId),
  (_attrs, boardMembers) => {
    _attrs = _attrs || {};
    let _assignements = {};
    forEach(_attrs.assignments, (userId) => {
      _assignements[userId] = get(boardMembers, userId);
    });
    return _assignements;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 3000,
    },
  }
);

/**
 * Select top & bottom card from column.
 * @param {String} boardId Board Id
 * @param {String} columnId Column Id
 */
export const columnTopBottom = createSelector(
  boardSelectors.cardsByColumn,
  (cards) => {
    const firstCard = first(cards);
    const lastCard = last(cards);

    if (isEmpty(firstCard)) {
      return null;
    }

    return {
      top: get(firstCard, 'columnOrder'),
      bottom: get(lastCard, 'columnOrder'),
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

export const cardBoardId = (state, cardId) => {
  const _attrs = attrs(state, cardId);
  return _attrs && _attrs.boardId;
}

export const cardBoardAttrs = (state, cardId) => {
  const boardId = cardBoardId(state, cardId);
  return boardId && boardSelectors.attrs(state, boardId);
}