import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';

import * as routerSelectors from '../router/selectors.js';
import * as board from '../board';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

import { getUserName } from '../../components/utils';

import i18next from '@dw/i18next-esm';

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} cardId Card Id.
 *  @property {String} boardId board Id. optional
 * @returns {Array} List of sub-tasks of given card id, sorted by it's order.
 */
const tasks = selectorCreator.default({maxSize: 1000})(
  ({ state, cardId, boardId }) => firestoreRedux.selectors.docsByQueryResult(state, `tasks-${boardId || routerSelectors.pageBoardId(state)}-${cardId}`, 'tasks'),
  (tasks) => {
    return tasks;
  }
);

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} cardId Card Id.
 *  @property {String} boardId board Id. optional
 * @returns {Array} List of open sub-tasks of given card id, sorted by it's order.
 */
export const openTasks = selectorCreator.default({maxSize: 200})(
  ({ state, cardId, boardId }) => tasks({ state, cardId, boardId: boardId || routerSelectors.pageBoardId(state)}),
  (tasks) => {
    const openTasks = filter(tasks, (task) => !task.closed);
    return sortBy(openTasks, ['order']);
  }
);

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} cardId Card Id.
 *  @property {String} boardId board Id. optional
 * @returns {Array} List of closed sub-tasks of given card id, sorted by it's order.
 */
export const closedTasks = selectorCreator.default({maxSize: 200})(
  ({ state, cardId, boardId }) => tasks({ state, cardId, boardId: boardId || routerSelectors.pageBoardId(state)}),
  (tasks) => {
    const closedTasks = filter(tasks, { closed: true });
    return sortBy(closedTasks, ['order']);
  }
);

/**
 * @param {Object} state  Redux state
 * @param {String} id Task Id
 * @returns {Object} task document of given task id.
 */
export const task = (state, id) => firestoreRedux.selectors.doc(state, 'tasks', id);

/**
 * @returns {Object} Task's assignment with it's role on the board. e.g. {'usr_j59gkd9495ugjf': 'TEAM_MEMBER', ...}
 */
export const assignments = selectorCreator.default({maxSize: 500})(
  ({ state, taskId }) => task(state, taskId) || {},
  ({ state, taskId }) => {
    const _task = task(state, taskId) || {};
    return board.selectors.members(state, _task.boardId);
  },
  (_task, boardMembers) => {
    let _assignements = {};
    forEach(_task.assignments, (userId) => {
      _assignements[userId] = get(boardMembers, userId);
    })
    return _assignements;
  }
);

/**
 * @returns {Object} Member wise open tasks. e.g `{$userId: [35345345, 234234234], $userId2: [234234234, 234234]}`
 */
export const openTaskAssignments = selectorCreator.default({maxSize: 500})(
  openTasks,
  (_openTasks) => {
    let list = {};
    if (!isEmpty(_openTasks)) {
      for (let task of _openTasks) {
        const assignments = get(task, `assignments`) || [];
        for (let memberId of assignments) {
          if (isEmpty(list[memberId])) {
            list[memberId] = [];
          }
          list[memberId].push(task.id)
        }
      }
    }
    return list;
  }
);

/**
 * @param {Object} state Redux state
 * @param {String} cardId Card Id
 * @returns {Boolean} `true` When query for tasks is pending.
 */
export const loading = selectorCreator.reselct.createSelector(
  (state, cardId) => firestoreRedux.selectors.queriesByRequester(state, `tasks-${cardId}`),
  (queries) => {
    const query = get(queries, '0');
    return isEmpty(query) || query.status === 'PENDING';
  }
);

/**
 * @returns {String} Names of users for which given task is assigned. e.g. 'Nirmal, Chirag and Sudhir'
 */
export const assignmentNames = selectorCreator.default({ maxSize: 1000 })(
  ({ state, taskId }) => firestoreRedux.selectors.doc(state, 'tasks', taskId) || {},
  ({state, taskId }) => firestoreRedux.selectors.collection(state, 'users'),
  (doc, allUsers) => {
    let aUserName = [];
    forEach(doc.assignments, (assignmentId) => {
      const oUser = get(allUsers, assignmentId);
      if (!isEmpty(oUser)) {
        aUserName.push(getUserName(oUser));
      }
    });

    //Sort user name wise
    aUserName = sortBy(aUserName, (name) => name.toLowerCase());

    let sAllAssignedUserName = '';
    forEach(aUserName, (sFullName, index) => {
      if (!sAllAssignedUserName) {
        sAllAssignedUserName = sFullName;
      } else if (sAllAssignedUserName && index != (aUserName.length - 1)) {
        sAllAssignedUserName = sAllAssignedUserName + ', ' + sFullName;
      } else {
        sAllAssignedUserName = sAllAssignedUserName + ' and ' + sFullName;
      }
    });

    sAllAssignedUserName = sAllAssignedUserName.trim();

    if (doc.closed && sAllAssignedUserName) {
      return ', ' + i18next.t('task:item.by') + ' ' + sAllAssignedUserName;
    }

    return sAllAssignedUserName;
  }
);


/**
 * given task board id.
 */
export const taskBoardId = (state, taskId) => {
  const _task = taskId && task(state, taskId);
  return _task && _task.boardId;
}