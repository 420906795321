import { put, take, fork, takeEvery, call, select, all, cancel, delay } from 'redux-saga/effects';
import * as actions from './actions.js';
import * as router from '../router/index.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import get from 'lodash-es/get.js';
import isEmpty from 'lodash-es/isEmpty.js';
import { requestApi, isNetworkError } from "../../request-api.js";

/**
 * Load board setting details.
 */
function* load(action) {
  const state = yield select();
  const boardId = action && action.boardId || router.selectors.pageBoardId(state);
  const requesterId = action && action.requesterId || `translatation-details-${boardId}`;
  //Load translate boards details.
  try {
    const attrsQuery = firestoreRedux.getDocById('boards', boardId, { requesterId });
    const attrs = yield attrsQuery.result;
    const baseBoardId = get(attrs, 'baseBoardId');
    const id = baseBoardId || boardId;
    const response = yield call(requestApi, `/board/boards/${id}/translated`, { excludeErrors: [502] });
    yield put(actions._loadDone(id, response));
    
    forEach(response, (item) => {
      if(item && item.id) {
        try { firestoreRedux.getDocById('boards', item.id, { requesterId }); } catch (error) {}
      }
    });
    
    // Load base board details.
    if(baseBoardId) {
      try { firestoreRedux.getDocById('boards', baseBoardId, { requesterId }); } catch (error) {}
    }

  } catch (error) {}
}

function* disconnect(action) {
  const state = yield select();
  const boardId = action && action.boardId || router.selectors.pageBoardId(state);
  const requesterId = action && action.requesterId || `translatation-details-${boardId}`;
  firestoreRedux.cancelQueryByRequester(requesterId);
}

function* translateBoard(action) {
  const state = yield select();
  const boardId = action.boardId || router.selectors.pageBoardId(state);
  const languages = action.languages || [];
  if(!boardId || isEmpty(languages)) {
    console.error("translateBoard > failed due to this: boardId or languages are not found.", {boardId, languages});
    return;
  }

  try {
    const response = yield call(requestApi, `/board/boards/${boardId}/translate`, { method: 'POST', body: { languages }, excludeErrors: [502] });
    yield put(actions._translateBoardDone(boardId, languages, response));
  } catch (error) {
    yield put(actions._translateBoardFailed(boardId, languages, error && error.code || 'UNKNOWN'));
    if(isNetworkError(error)) {
      return;
    }
    console.error("translateBoard > failed due to this: ", error);
  }
}

function* retranslateBoard(action) {
  const state = yield select();
  const currentBoardId = router.selectors.pageBoardId(state);
  const boardId = action.boardId || currentBoardId;
  if(!boardId) {
    console.error("retranslateBoard > failed due to this: boardId is not found.", {boardId});
    return;
  }

  try {
    const response = yield call(requestApi, `/board/boards/${boardId}/re-translate`, { method: 'POST', excludeErrors: [502] });
    yield put(actions._retranslateBoardDone(boardId, response));
    if(boardId === currentBoardId) {
      router.actions.navigate(`/${response.accountId}/board/${response.id}`, true);
    }
  } catch (error) {
    yield put(actions._retranslateBoardFailed(boardId, error && error.code || 'UNKNOWN'));
    if(isNetworkError(error)) {
      return;
    }
    console.error("retranslateBoard > failed due to this: ", error);
  }
}

/**
 * Watch router change.
 */
function* watchRouter() {
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

let previousDialog;
function* routeChangeHandler() {
  const state = yield select();
  const currentDialog = get(state, 'router.dialog.name');
  const currentPageName = get(state, 'router.page.name');
  if (currentDialog === 'TRANSLATIONS_DIALOG') {
    if (previousDialog !== currentDialog && currentPageName === 'BOARD') {
      yield put({ type: actions.DIALOG_OPENED });
    }
  } else if (previousDialog === 'TRANSLATIONS_DIALOG') {
    yield put({ type: actions.DIALOG_CLOSED });
  }
  previousDialog = get(state, 'router.dialog.name');
}

function* boardTranslationsFlow() {
  while (yield take(actions.DIALOG_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(load)
      ]);
    })
    yield take(actions.DIALOG_CLOSED);
    yield call(disconnect)
    yield cancel(task);
  }
}

function* refreshTranslatedBoards() {
  yield delay(100);
  yield call(load);
}

/**
 * Init Saga.
 */
function* boardTranslationsSaga() {
  yield all([
    call(watchRouter),
    call(boardTranslationsFlow),
    takeEvery(actions.LOAD, load),
    takeEvery(actions.DISCONNECT, disconnect),
    takeEvery(actions.TRANSLATE_BOARD, translateBoard),
    takeEvery(actions.RETRANSLATE_BOARD, retranslateBoard),
    takeEvery(actions.TRANSLATE_BOARD_DONE, refreshTranslatedBoards),
    takeEvery(actions.RETRANSLATE_BOARD_DONE, refreshTranslatedBoards)
  ]);
}

export default boardTranslationsSaga;