import URI from '@dw/urijs-esm';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
export { default as getFiles } from './get-files.js';
import { createSelector } from 'reselect';

/**
 * @param {String} url give a url to get file id.
 * @returns {Boolean} when given url is colud store url and with match cloudStoreType then return file id from url, otherwise return empty string.
 */
export const getCloudFileId = (url, cloudStoreType) => {
  if(isGoogleColudStoreLink(url) && (cloudStoreType == 'KERIKA' || cloudStoreType == 'GOOGLE' || cloudStoreType == 'GOOGLE_MARKET_PLACE')) {
    return getGoogleColudFileId(url);
  }

  if(isBoxColudStoreLink(url) && cloudStoreType == 'BOX') {
    return boxGoogleColudFileId(url);
  }

  if(isMicrosoftCloudStoreLink(url) && cloudStoreType == 'MICROSOFT') {
    return getMicrosoftCloudFileId(url);
  }

  return '';
}

/**
 * @returns {Boolean} `true` when given url is cloud store url or not.
 */
export const isColudStoreLink = (url) => {
  return isGoogleColudStoreLink(url) || isBoxColudStoreLink(url) || isMicrosoftCloudStoreLink(url);
}

export const isGoogleColudStoreLink = (url) => {
  let regex1 = new RegExp('(docs|drive|spreadsheet)\\.google\\.com\\/(?!folderview).*[\\?\\&]id=([a-zA-Z_0-9\\-]+)');
  let regex2 = new RegExp('(docs|drive|spreadsheet)\\.google\\.com\\/.*[\\?\\&]srcid=([a-zA-Z_0-9\\-]+)');
  let regex3 = new RegExp('(docs|drive|spreadsheet)\\.google\\.com\\/.*[\\?\\&]key=([a-zA-Z_0-9\\-]+)');
  let regex4 = new RegExp('(docs|drive|spreadsheet)\\.google\\.com\\/(?!folder).+\\/d\\/([a-zA-Z_0-9\\-]+)');
  return regex1.test(url) || regex2.test(url) || regex3.test(url) || regex4.test(url) || false;
}

export const isBoxColudStoreLink = (url) => {
  let regex1 = new RegExp('\\.box\\.com\\/files\\/0\\/f\\/+[0-9]+\\/1\\/f_([0-9]+)');
  let regex2 = new RegExp('\\.box\\.com\\/services\\/google_docs\\/+[0-9]{3}\\/([0-9]+)');
  let regex3 = new RegExp('\\.box\\.com\\/services\\/web_documents\\/+[0-9]{3}\\/([0-9]+)');
  let regex4 = new RegExp('\\.box\\.com\\/notes\\/([0-9]+)');
  let regex5 = new RegExp('\\.box\\.com\\/services/box_for_office_online\\/+[0-9]{4}\\/([0-9]+)');
  let regex6 = new RegExp('\\.box\\.com\\/file\\/([0-9]+)');
  return regex1.test(url) || regex2.test(url) || regex3.test(url) || regex4.test(url) || regex5.test(url) || regex6.test(url) || false;
}

export const isMicrosoftCloudStoreLink = (url) => {
  return isMicrosoftCloudStoreLongLink(url) || isMicrosoftCloudStoreSortLink(url);
}

export const isMicrosoftCloudStoreLongLink = (url) => {
  const regex1 = new RegExp('onedrive\.live\.com.*\/?.*[\?|\&](id|resid|resId)=([^&?]*)&?');
  const regex2 = new RegExp('photos\.onedrive\.com.*\/?.*[\?|\&](id|resid|resId)=([^&?]*)&?');
  return regex1.test(url) || regex2.test(url);
}

export const isMicrosoftCloudStoreSortLink = (url) => {
  const regex1 = new RegExp('1drv\.ms\/(.*)\/(.*)');
  const regex2 = new RegExp('.*\-my\.sharepoint\.com\/.*((cfa_|bfa_|cnvsa_)[^.)_]+)');
  return regex1.test(url) || regex2.test(url);
}

export const getGoogleColudFileId = (url) => {
  let uri = new URI(url);
  let aPath = uri.pathname(true).split('/');
  return aPath[3];
}

export const boxGoogleColudFileId = (url) => {
  let uri = new URI(url);
  let aPath = uri.pathname(true).split('/');
  return aPath[2];
}

export const getMicrosoftCloudFileId = (url) => {
  if(isMicrosoftCloudStoreLongLink(url)) {
    return decodeURIComponent(getMicrosoftCloudLongLinkFileId(url));
  }

  if(isMicrosoftCloudStoreSortLink(url)) {
    return decodeURIComponent(getMicrosoftCloudSortLinkFileId(url));
  }

  return '';
}

const getMicrosoftCloudLongLinkFileId = (url) => {
  const regex1 = new RegExp('onedrive\.live\.com.*\/?.*[\?|\&](id|resid)=([^&?]*)&?');
  let groups = [];
  if(regex1.test(url)) {
    try {
      groups = regex1.exec(url) || [];
    } catch (error) {}
  
    if(groups && groups[2]) {
      return groups[2];
    }
  }
  
  const regex2= new RegExp('photos\.onedrive\.com.*\/?.*[\?|\&](id|resid|resId)=([^&?]*)&?');
  if(regex2.test(url)) {
    groups = [];
    try {
      groups = regex2.exec(url) || [];
    } catch (error) {}
  
    if(groups && groups[2]) {
      return groups[2];
    }
  }

  return '';
}

const getMicrosoftCloudSortLinkFileId = (url) => {
  const regex1 = new RegExp('1drv\.ms\/(.*)\/(.*)');
  let groups = [];
  if(regex1.test(url)) {
    try {
      groups = regex1.exec(url) || [];
    } catch (error) {}
  
    return groups && groups[2] || '';
  }

  const regex2 = new RegExp('.*\-my\.sharepoint\.com\/.*((cfa_|bfa_|cnvsa_)[^.)_]+)');
  if(regex2.test(url)) {
    try {
      groups = regex2.exec(url) || [];
    } catch (error) {}
  
    return groups && groups[1] || '';
  }

  return '';
}

/**
 * @param {Object} model attachment model.
 * @returns { Object } update details e.g. `{time, updatedBy}`.
 */
export const getUpdatedDetails = createSelector(
  (model) => model,
  (model) => {
    if(isEmpty(model)) {
      return {time: 0, updatedBy: ''};
    }
    const createdAt = model.createdAt || 0;
    const contentUpdatedAt = model.contentUpdatedAt || 0;
    const updatedAt = model.updatedAt || 0;
    const time = Math.max(createdAt, contentUpdatedAt, updatedAt);

    if(time === updatedAt && model.updatedBy) {
      return { updatedBy: model.updatedBy, updatedAt: time };
    }

    if(time === contentUpdatedAt && model.contentUpdatedBy) {
      return { updatedBy: model.contentUpdatedBy, updatedAt: time };
    }

    return { updatedBy: model.createdBy || '', updatedAt: time || 0 };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 150,
    },
  }
);