// Check linked In event is track or not.
let isLinkedInEventTrack = !!window.K.config.linkedInPartnerId;

/**
 * Initialize LinkedIn.
 * @param {String} APIKey
 */
 export const init = (linkedInPartnerId) => {
  if(!isLinkedInEventTrack) {
    return;
  }

  if(!linkedInPartnerId) {
    isLinkedInEventTrack = false;
    return;
  }

  //Create tag script and add into head.
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(linkedInPartnerId);
  (function (l) {
    if (!l) {window.lintrk = function (a, b) {window.lintrk.q.push([a, b]);};window.lintrk.q = [];}
    let s = document.body;let b = document.createElement("script");
    b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);
  let noscript = document.createElement("noscript");
  let img = document.createElement("img");img.src = "https://px.ads.linkedin.com/collect/?pid=" + linkedInPartnerId + "&fmt=gif";
  img.height = "1";img.width = "1";img.style.display = "none";img.alt="";
  noscript.appendChild(img);
  let body = document.body;
  body.parentNode.insertBefore(noscript, body);
}

/**
 * Track LinkedIn event.
 * @param {Object} conversionId conversion Id
 */
export const logEvent = (conversionId) => {
  if(!isLinkedInEventTrack) {
    return;
  }
  window.lintrk && window.lintrk('track', {'conversion_id': conversionId});
}
