import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import keys from 'lodash-es/keys';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import * as app from '../../app';
import * as authSelectors from '../selectors';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../../utils';
import * as selectorCreator from '../../selector-creator.js';

/**
 * Accessible accounts for login user.
 * @returns {Array} List of accessible account ids. e.g. ['acc_tjiv9eunfdecf', ...]
 */
export const accessibleAccounts = selectorCreator.createDeepEqualSelector(
  authSelectors.currentUserOwnedAccounts,
  authSelectors.currentUserAccessibleAccount,
  (ownedAc, otherAc) => {
    return [...ownedAc, ...keys(otherAc)];
  }
);

/**
 * Accessible accounts basic detail for login user.
 * @reuturn {Array} List of account details. e.g. [{id, name, ownerId, description, createdAt }, ...]
 */
export const accessibleAccountsDetails = selectorCreator.createDeepEqualSelector(
  accessibleAccounts,
  (state) => firestoreRedux.selectors.collection(state, 'accounts'),
  (state) => firestoreRedux.selectors.collection(state, 'account-cloud-stores'),
  (accessibleAcc, allAccounts, allAccountCloudStores) => {
    let list = [];
    forEach(accessibleAcc, (id) => {
      const accountDetail = get(allAccounts, `${id}`);
      const fileStoreDoc = get(allAccountCloudStores, `acs_${getIdWoPrefix({ id, prefix: 'acc_' })}`);
      const preferredStore = get(fileStoreDoc, `preferredStore`);
      const nextPreferredStore = get(fileStoreDoc, `nextPreferredStore`);
      const fileStore = { preferredStore, nextPreferredStore };
      !isEmpty(accountDetail) && list.push((merge({}, {fileStore}, accountDetail)));
    });

    return sortBy(list, (o) => o.name.toLowerCase());;
  }
);

/**
 * Gets the length of the list of accessible account details for the current user.
 * @returns {number} The length of the list of accessible account details.
 */
export const accessibleAccountsDetailsLength  = selectorCreator.reselct.createSelector(
  accessibleAccountsDetails,
  (accounts) => {
    return (accounts && accounts.length) || 0;
  }
);

/**
 * Gets the name of the first accessible account for the current user.
 * @returns {string} The name of the first accessible account, or an empty string if there are no accessible accounts.
 */
export const fristAccountName = selectorCreator.createDeepEqualSelector(
  accessibleAccountsDetails,
  (accounts) => {
    return (accounts && accounts.length) ? accounts[0].name : '';
  }
);

/**
 * Writable accessible accoutns for login user.
 * @returns {Array} List of id of writable accessible accounts. e.g. ['acc_3j58f8fjfk', 'acc_5j3jkfjnd9fj', ...]
 */
export const writableAccessibleAccounts = selectorCreator.default({maxSize: 50})(
  authSelectors.currentUserOwnedAccounts,
  authSelectors.currentUserAccessibleAccount,
  (ownedAc, otherAc) => {
    let accessibleAccounts = [];

    //User ownerd accounts
    forEach(ownedAc, (value) => {
      accessibleAccounts.push(value);
    });


    //User other accounts
    forEach(otherAc, (value, key) => {
      if (value === 'TEAM_MEMBER' || value === 'ACCOUNT_ADMIN') {
        accessibleAccounts.push(key);
      }
    });
    return accessibleAccounts;
  }
);
/**
 * Give one of the account with write permission.
 */
export const accountWithWritePermission = (state) => {
  const accounts = writableAccessibleAccounts(state);
  return accounts && accounts[0];
};

/**
 * Writable accessible accoutns detail for login user.
 */
export const writableAccessibleAccountsDetails = selectorCreator.createDeepEqualSelector(
  writableAccessibleAccounts,
  accessibleAccountsDetails,
  (writebleAcc, accessibleAccDetails) => {
    return filter(accessibleAccDetails, (acc) => writebleAcc.includes(acc.id));
  }
);

/**
 * @returns {Number} The length of the list of writable accessible accounts for the current user.
 */
export const writableAccessibleAccountsDetailsLength = selectorCreator.reselct.createSelector(
  writableAccessibleAccountsDetails,
  (accounts) => {
    return accounts && accounts.length || 0;
  }
);

/**
 * @returns {Object} template account basic details.
 */
export const templateAccountDetails = (state) => {
  const accId = app.selectors.templateAcId(state);
  return firestoreRedux.selectors.doc(state, 'accounts', accId);
};

/**
 * @param {Object} state redux state
 * @returns {Number} last user access account.
 */
export const lastUsedAccount = (state) => {
  let userId = authSelectors.currentUserId(state);
  const lastUserAccount = get(
    state,
    `firestore.users.${userId}.user-ui.laa_${getIdWoPrefix({id: userId, prefix: 'usr_'})}.accountId`
  );
  const accounts = accessibleAccounts(state);
  if (accounts && accounts.includes(lastUserAccount)) {
    return lastUserAccount;
  }

  return accounts && accounts[0];
};

/**
 * @param {Object} state Redux state
 * @param {Number} accountId Account Id
 * @returns Account name of given account id.
 */
export const accountName = (state, accountId) => {
  const doc = firestoreRedux.selectors.doc(state, 'accounts', accountId);
  return get(doc, 'name', '');
};

/**
 * @param {Object} state Redux state
 * @param {Number} accountId Account Id
 * @returns Account Owner id.
 */
 export const accountOwnerId = (state, accountId) => {
  const doc = firestoreRedux.selectors.doc(state, 'accounts', accountId);
  return get(doc, 'ownerId', '');
};

/**
 * @returns {Number} Count of current user's accessible accounts.
 */
export const accessibleAccountsCount = selectorCreator.reselct.createSelector(
  accessibleAccounts,
  (accounts) => {
    return (accounts && accounts.length) || 0;
  }
);

export const accessibleAdminAccounts = selectorCreator.createDeepEqualSelector(
  accessibleAccountsDetails,
  authSelectors.currentUserId,
  (state) => firestoreRedux.selectors.collection(state, 'account-team-members'),
  (_accessibleAccounts, userId, allAccTeamMembers) => {
    let accessibleAdminAccounts = [];

  forEach(_accessibleAccounts, (account) => {
    const accountId =  account && account.id;
    const ownerId = account && account.ownerId;
    const currentUserAccountDetails = filter(allAccTeamMembers, { accountId, userId });
    const isAdmin = ownerId === userId || get(currentUserAccountDetails, `0.role`) === 'ACCOUNT_ADMIN';
    if(isAdmin) {
      accessibleAdminAccounts.push(accountId);
    }
  });

  return accessibleAdminAccounts;
  }
);

/**
 * Accessible accounts basic detail for login user.
 */
export const accessibleAdminAccountsDetails = selectorCreator.createDeepEqualSelector(
  (state) =>firestoreRedux.selectors.collection( state, 'accounts'),
  accessibleAdminAccounts,
  (allAccounts, accessibleAcc) => {
    let list = [];
    forEach(accessibleAcc, (id) => {
      const accountDetail = get(allAccounts, `${id}`);
      !isEmpty(accountDetail) && list.push(accountDetail);
    });

    list = sortBy(list, (o) => o.name.toLowerCase());
    return list;
  }
);
