import { put, takeEvery, takeLatest, call, select, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as router from '../router';
import * as auth from '../auth';
import * as utils from '../../utils';
import { requestApi } from '../../request-api';
import { show as showSnackBar } from '../../components/kerika-snackbar';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import once from 'lodash-es/once';
import forEach from 'lodash-es/forEach';
import entityIdProvider from '../../entity-id-provider';
import firestoreRedux from '@dreamworld/firestore-redux';

const SEARCH_HISTORY_LIMIT = 100;

const loadHistory = once((userId) => {
  try {
    firestoreRedux.query('search-history',
      { where: [['userId', '==', userId]], orderBy: [['lastUsedAt', 'desc']], limit: SEARCH_HISTORY_LIMIT }
    );
  } catch (error) {
    console.error('Failed to load search history', error);
  }
});

function* onSetQuery() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  loadHistory(userId);
}

/**
 * Add/Edit search history with current time.
 * @param {String} query Search string.
 */
function* updateHistory(query) {
  const state = yield select();
  const userId = yield auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  query = query && query.trim() || '';
  if (!userId || !query) {
    return;
  }

  query = query.toLowerCase();
  const currentHistory = selectors.searchHistory(state);
  let filteredItem = find(currentHistory, (item) => {
    const itemQuery = item && item.query && item.query.trim() || '';
    return itemQuery.toLowerCase() === query;
  });

  const lastUsedAt = Date.now();
  if (filteredItem) {
    const doc = { ...filteredItem, lastUsedAt };
    firestoreRedux.save(`users/${userId}/search-history`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } else {
    const id = entityIdProvider.getId();
    firestoreRedux.save(`users/${userId}/search-history`, { id, userId, query, lastUsedAt }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  }
}

function* search({ query }) {
  const state = yield select();
  query = query || get(state, `search.query`, '');
  query = query.trim();
  if (!query) {
    return;
  }

  yield call(updateHistory, query);

  const currentType = selectors.currentType(state);
  const selectedBoards = selectors.currentSelectedBoards(state);
  const currentBoardId = router.selectors.pageBoardId(state);

  //Reset search results when old query and new query is not same.
  const lastQuery = get(state, 'search.lastSearchRequest.query');
  const lastBoardId = get(state, 'search.lastSearchRequest.boardId');
  if((lastQuery && query !== get(state, 'search.lastSearchRequest.query')) || (lastBoardId && currentBoardId !== lastBoardId)) {
    yield put(actions.resetResults());
  }

  try {
    // Request URL based on current type.
    let url = currentType === 'CARDS' ? `/search/cards` : currentType === 'ATTACHMENTS' ? `/search/attachments` : '';

    const isHashNumber = utils.isHashNumber(query);

    // Prepare body based on current type, filters & selected boards.
    const filters = isHashNumber ? selectors.defaultCardsRefNoFilters(state) : currentType === 'CARDS' ? selectors.cardFilters(state) :
      currentType === 'ATTACHMENTS' ? selectors.attachmentFilters(state) : {};

    let body = {...filters};
    body.q = isHashNumber ? utils.removeSpaces(query): query;
    if (currentBoardId && !isHashNumber) {
      if (selectedBoards === 'CURRENT') {
        body.includeBoardIds = [currentBoardId];
        body.excludeBoardIds = [];
      } else {
        body.excludeBoardIds = [currentBoardId];
        body.includeBoardIds = [];
      }
    } else {
      body.includeBoardIds = [];
      body.excludeBoardIds = [];
    }
    const response = yield call(requestApi, url, { excludeErrors: [400, 401], method: 'POST', body });

    yield put(actions._searchSuccess({
      query,
      results: isEmpty(response.results) ? []: response.results,
      didYouMean: response.didYouMean,
      currentType: isHashNumber ? 'CARDS': currentType,
      selectedBoards: isHashNumber ? 'OTHER': selectedBoards,
      currentBoardId
    }));

  } catch (error) {
    if (!error || !error.code) {
      return;
    }

    yield put(actions._searchFailed({ error: error.code, message: error.message }));
    if (error.code === 'SEARCH_STRING_NOT_PROVIDED') {
      showSnackBar({ message: i18next.t('search:toast.searchStringNotProvided'), type: 'ERROR' });
      return;
    }

    console.error('Failed to search.', error);
  }
}

/**
 * On upate type, search again.
 */
function* updateType() {
  const state = yield select();
  const currentType = selectors.currentType(state);
  const result = currentType === "CARDS" ? selectors.cardSearchResult(state) :selectors.attachmentSearchResult(state);
  if(result == undefined) {
    yield put(actions.search());
  }
}

/**
 * On update selected bords, search again.
 */
function* updateSelectedBoards() {
  const state = yield select();
  const currentType = selectors.currentType(state);
  const result = currentType === "CARDS" ? selectors.cardSearchResult(state) :selectors.attachmentSearchResult(state);
  if(result == undefined) {
    yield put(actions.search());
  }
}

function* updateCardFilters({ filters }) {
  yield put(actions.search());
}

function* updateAttachmentFilters({ filters }) {
  yield put(actions.search());
}

let lastAbortControllerForFilterData;
function* loadFilterData(action) {
  const filters = action.filters;
  let url = `/search/filters`;
  url = `${url}?templates=${filters.templates}`;
  url = `${url}&include-unparticipated-boards=${filters.includeUnparticipatedBoards}`;
  if(!isEmpty(filters.includeBoardIds)) {
    url = `${url}&include-board-ids=${filters.includeBoardIds}`;
  }
  if(!isEmpty(filters.excludeBoardIds)) {
    url = `${url}&exclude-board-ids=${filters.excludeBoardIds}`;
  }
  if(!isEmpty(filters.boardStatus)) {
    url = `${url}&board-status=${filters.boardStatus}`;
  }

  try {
    lastAbortControllerForFilterData && lastAbortControllerForFilterData.abort && lastAbortControllerForFilterData.abort();
    lastAbortControllerForFilterData = new AbortController();
    const options = {
      excludeErrors: [400, 401],
      method: 'GET',
      signal: lastAbortControllerForFilterData.signal
    }

    firestoreRedux.getDocById('tag-colors', 'tc_0', { requesterId: 'search' });
    const response = yield call(requestApi, url, options);
    yield put(actions._loadFilterDataSuccess(response));
  } catch (error) {
    yield put(actions._loadFilterDataFailed());
  }
}

/**
 * Update search reference number tip last seen time.
 */
function* updateRefNoTipLastSeen() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  if (!userId) {
    return;
  }
  try {
    const id = `srnt_${utils.getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
    const lastSeen = Date.now();
    const doc = { id, type: 'search-reference-number-tip', userId, lastSeen };
    firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  } catch (error) {
    console.error("search > updateRefNoTipLastSeen > failed due to this: ", error);
  }
}

/**
 * Init Saga.
 */
export function* saga() {
  yield all([
    takeLatest(actions.SET_QUERY, onSetQuery),
    takeEvery(actions.UPDATE_CARD_FILTERS, updateCardFilters),
    takeEvery(actions.UPDATE_ATTACHMENT_FILTERS, updateAttachmentFilters),
    takeLatest(actions.SEARCH, search),
    takeEvery(actions.UPDATE_TYPE, updateType),
    takeEvery(actions.UPDATE_SELECTED_BOARDS, updateSelectedBoards),
    takeEvery(actions.LOAD_CARDS_FILTER_DATA, loadFilterData),
    takeEvery(actions.UPDATE_REF_NO_TIP_LAST_SEEN, updateRefNoTipLastSeen)
  ]);
}
