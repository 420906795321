export const UPDATE = 'CLIPBOARD_UPDATE';
export const RESET = 'CLIPBOARd_RESET';

/**
 * Stores given data into clipboard with action.
 * @param {String} action Name of the action. e.g 'COPY_CARDS'
 * @param {Object} data It could be any data which needed to be stored into clipboard. 
 */
export const update = (action, data) => {
  return {
    type: UPDATE,
    action,
    data
  }
}

/**
 * Resets the clipboard data.
 */
export const reset = () => {
  return {
    type: RESET
  }
}