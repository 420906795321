import device from "current-device";
import semver from '@dw/semver-esm/semver.js';
import get from 'lodash-es/get';
import * as firestoreRedux from '@dreamworld/firestore-redux';

/**
 * @param {Object} state Redux state
 * @returns {String} minimum version of installed app based on current device.
 */
export const minVersion = (state) => {
  const doc = firestoreRedux.selectors.doc(state, 'app', 'installed-app-pwa');
  const os = device.ios() ? 'ios' : 'android';
  return get(doc, `${os}.minVersion`);
};

/**
 * @param {Object} state Redux state
 * @returns {String} version of the application the user is currently using in his device.
 */
export const version = (state) => {
  const userAgent = get(state, `app.config.installedAppUserAgent`);
  let splitWords = window.navigator.userAgent.split(`${userAgent}/`);
  splitWords = splitWords && splitWords[1] && splitWords[1].split(' ') || [];
  return splitWords[0] ? splitWords[0]: '';
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} version of the application the user is currently using in his device is valid or not.
 */
export const isValidVersion = (state) => {
  const _minVersion = minVersion(state);
  const _currentDeviseVersion = version(state);
  if(!_minVersion) {
    return true;
  }

  if(_minVersion && !_currentDeviseVersion) {
    return false;
  }
  return semver.gt(_currentDeviseVersion, _minVersion) || semver.eq(_currentDeviseVersion, _minVersion);
}