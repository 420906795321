import { css, html } from 'lit-element';
import { DwSelectDialog } from '@dreamworld/dw-select/dw-select-dialog';
import { DialogHistoryManager } from '../components/dialog-history-manager';
import { sharedStyles } from '../theme/shared-styles.js';
import localize from '@dw/pwa-helpers/localize';
import i18next from '@dw/i18next-esm';
import * as flexLiterals from '@dreamworld/flex-layout/flex-layout-literals';
import * as alighmentLiterals from '@dreamworld/flex-layout/flex-layout-alignment-literals';
import * as factorLiterals from '@dreamworld/flex-layout/flex-layout-factors-literals';
import typographyLiterals from '../theme/typography-literals.js'
import { theme } from '../theme/theme';

import './kerika-icon';

/**
 * Extended version of [dw-select-dialog](https://github.com/DreamworldSolutions/dw-select/blob/master/dw-select-dialog.js).
 * It's mainly element is used to provide selection dialog.
 * It's also Extended `dialog-history-manager` to use for manage dialog history.
 */

export class KerikaSelectDialog extends DialogHistoryManager(localize(i18next)(DwSelectDialog)) {
  static get styles() {
    return [
      theme,
      super.styles,
      sharedStyles,
      css`
        :host {
          z-index: var(--kerika-select-dialog-z-index, 100);
          max-width: 768px;
          margin-left: auto;
          margin-right: auto;
          --dw-select-dialog-header-padding: 0 8px 0 16px;
          --dw-select-check-icon: var(--check-icon-color, var(--light-green-color));
          --dw-select-item-padding: 0px;
          --dw-select-item-icon-margin: 0px 16px 0px 0px;
          --dw-select-kb-highlighted-bg-color: transparent;
          --dw-select-dialog-single-select-padding-bottom: var(--kerika-select-dialog-single-select-padding-bottom, 0px);
          --dw-select-dialog-header-seprater-display: none;
          --dw-select-item-icon-color: var(--icon-color);
          --dw-select-kb-highlighted-hover-bg-color: var(--list-item-hover-background-color);
        }

        :host(:not([mobile-mode])) {
          --dw-select-dialog-menu-header-title-font-size: var(--body2-font-size);
          --dw-select-dialog-menu-header-title-font-weight: var(--body2-font-weight);
          --dw-select-dialog-menu-header-title-line-height: var(--body2-line-height);
          --dw-select-dialog-menu-header-title-letter-spacing: var(--body2-letter-spacing);
          --dw-select-item-icon-margin: 0 12px 0 0;
          --dw-select-item-content-text-transform: none;
          --dw-select-item-font-size: var(--body2-font-size);
          --dw-select-item-font-weight: var(--body2-font-weight);
          --dw-select-item-line-height: var(--body2-line-height);
          --dw-select-item-letter-spacing: var(--body2-letter-spacing);
        }

        :host([large]) {
          --dw-select-dialog-menu-header-title-font-size: var(--body1-font-size);
          --dw-select-dialog-menu-header-title-font-weight: var(--body1-font-weight);
          --dw-select-dialog-menu-header-title-line-height: var(--body1-line-height);
          --dw-select-dialog-menu-header-title-letter-spacing: var(--body1-letter-spacing);
          --dw-select-item-font-size: var(--body1-font-size);
          --dw-select-item-font-weight: var(--body1-font-weight);
          --dw-select-item-line-height: var(--body1-line-height);
          --dw-select-item-letter-spacing: var(--body1-letter-spacing);
        }

        :host(:not([mobile-mode])[single-select]) {
          --dw-select-dialog-single-select-padding-bottom: var(--kerika-select-dialog-single-select-padding-bottom, 8px);
        }

        :host([mobile-mode]) {
          top: auto !important;
          --dw-select-item-hover-color: transparent;
          box-shadow:none;
        }

        :host(:not([opened])) {
          display: none;
        }

        .header .dialog-header .close-icon, .header .dialog-header dw-icon-button[icon="close"] {
          position: absolute;
          right: 8px;
          outline: none;
          cursor: pointer;
        }

        .header .dialog-header  .count {
          display: none;
        }

        .header .dialog-header .title {
          color: var(--dialog-header-title-color);
          text-transform: uppercase;
        }

        .header .border {
          border: none;
        }

        .group-label{
          border-top: 1px solid var(--divider-color);
        }

        :host([mobile-mode]) .footer {
          padding: 0px 12px;
          ${flexLiterals.displayFlex};
          ${flexLiterals.horizontal};
          ${flexLiterals.wrap};
          ${alighmentLiterals.endJustified};
          min-height: 56px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }

        :host(:not([mobile-mode])) .footer {
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
          height: 48px;
          padding: 8px 16px;
        }

        :host([mobile-mode]) .footer kerika-button {
          --kerika-button-height: 40px;
          margin: 8px;
          ${typographyLiterals.body1};
          ${factorLiterals.flexFactor};
        }

        .main-content .selection-action-buttons {
          display: none;
        }

        .item {
          position: relative;
          padding: 0px 24px 0px 20px;
          height: 56px;
          cursor: pointer;
        }

        :host(:not([mobile-mode])) .item {
          padding: 0 16px;
          height: 48px;
        }

        :host([large]) .item {
          padding: 0 16px;
          height: var(--kerika-select-dialog-large-item-height, 56px);
        }

        :host(:not([mobile-mode])) .item[selected] {
          padding-right: 48px;
        }

        dw-select-item.item {
          padding-right: 18px;
        }


        .item[selected] {
          padding: 0px 48px 0px 20px;
        }


        .item .check-icon {
          position: absolute;
          right: 18px;
          height: 24px;
          width: 24px;
        }

        .item .check-icon kerika-icon, .item .check-icon dw-icon {
          --dw-icon-color: var(--check-icon-color);
        }

        .item .content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item .content-icon {
          margin-right: 16px;
        }

        .header .input-container #filter {
          height: 40px;
          padding: 8px 32px 12px 8px;
          text-indent: 0;
          ${typographyLiterals.body2};
          color: var(--primary-text-color);
        }

        .header .input-container {
          padding: 8px 16px;
        }

        .header .input-container .clear-text-icon  {
          top: 19px;
          right: 24px;
        }

        dw-select-item {
          line-height: normal;
        }
      `
    ]
  }

  static get properties() {
    return {
      /**
       * Input property. width of dialog, applied only when mobileMode property is false.
       */
      width: { type: Number },

      /**
       * Shows a large select when this is true.
       */
      large: { type: Boolean, reflect: true }
    }
  }

  constructor(){
    super();

    /**
     * Passed action dialog name to set in url as a action.
     */
    this.queryParamValue = '';
    this.doNotDelayRendering = true;
  }

  /**
   * @override
   * @protected
   */
  _triggerOpenedChange(){
    super._triggerOpenedChange && super._triggerOpenedChange();
    this.dialogHistoryManage();
  }

     /**
   * @return {*} check icon.
   * @protected
   */
  _getCheckIcon(){
    return html `<kerika-icon name="check_circle"></kerika-icon>`;
  }

  updated(changedProps){
    super.updated && super.updated(changedProps);
    if(changedProps.has('opened') || changedProps.has('width') || changedProps.has('mobileMode')) {
      if(this.opened && !this.mobileMode && this.width) {
        this.style.setProperty('--dw-select-dialog-width', `${this.width}px`)
      }
    }

    if (changedProps.has('opened') && this.opened && this.allowFilter) {
      const inputEl = this.renderRoot.querySelector('input');
      setTimeout(() => {
        inputEl && inputEl.focus();
      })
    }
  }
}

customElements.define('kerika-select-dialog', KerikaSelectDialog);