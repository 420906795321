import { sagaMiddleware } from '../../store';
import saga from './saga';
import * as _actions from './actions';
import * as _selectors from './selectors';
import { viewReducer } from './reducers';
import { store } from '../../store.js';



store.addReducers({
  'views': viewReducer
});

sagaMiddleware.run(saga);

export const actions = _actions
export const selectors = _selectors;
