import * as router from '../router';
import * as device from '../device';
import { store } from '../../store.js';

/** 
 * Handle DeepLinks for installed app using `IonicDeeplink` plugin.
 */
export const init = async () => {
  document.addEventListener("deviceready", function () {
    if (!window.cordova || !window.cordova.plugins || !window.cordova.plugins.firebase || !window.cordova.plugins.firebase.messaging) {
      console.warn('FCM plugin is not available.');
      return;
    }

    window.cordova.plugins.firebase.messaging.onMessage((payload) => {
      let data = {};
      try { data = payload.additionalData ? JSON.parse(payload.additionalData): {};} catch (error) {}
      console.log("FCM > onMessage: ", data);
    });

    window.cordova.plugins.firebase.messaging.onBackgroundMessage((payload) => {
      let data = {};
      try { data = payload.additionalData ? JSON.parse(payload.additionalData): {};} catch (error) {}
      if(data && data.cardId && data.boardId && data.accountId) {
        router.actions.navigate({dialog: {name: 'CARD', params: {boardId: data.boardId, cardId: data.cardId}}, accountId: data.accountId});
      }
    });

    window.cordova.plugins.firebase.messaging.onTokenRefresh((payload) => {
      store.dispatch(device.actions.updateFcmToken(payload));
    });
  }, false);
}