import * as actions from './actions';

const INITIAL_STATE = {};

export const clipboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return {
        ...state,
        action: action.action,
        createdAt: Date.now(),
        data: action.data
      }
    case actions.RESET:
      return {};
    default:
      return state;
  }
};
