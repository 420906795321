import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import orderBy from 'lodash-es/orderBy';
import findIndex from 'lodash-es/findIndex';
import find from 'lodash-es/find';
import groupBy from 'lodash-es/groupBy';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';

import * as auth from '../auth';
import * as router from '../router';
import * as card from '../card';
import * as columnsSelectors from '../columns/selectors.js';
import * as board from '../board';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';
import * as boardTagsSelectors from '../board-tags/selectors.js';

import { getIdWoPrefix } from '../../utils';
import { viewIdToUrlPath } from '../router/build-url';

/**
 * Current view id.
 * @param {Object} state State
 */
export const currentViewId = state => get(state, `views.currentId`);

/**
 * @param {Object} state Redux state
 * @param {String} id View Id
 * @returns {String} firestore view id based on current user id & system view id.
 */
export const currentUserViewId = (state, id) => {
  id = id || get(state, 'router.page.params.viewId');
  const userId = auth.selectors.currentUserId(state);
  return `vw_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}_${id}`;
}

/**
 * @returns {Boolean} `true` when assignedToMe filter is applied.
 */
export const currentViewAssignedToMe = selectorCreator.reselct.createSelector(
  (state) => get(state, 'router.page.params.viewId'),
  (state) => firestoreRedux.selectors.doc(state, 'view', currentUserViewId(state)),
  (viewId, doc) => {
    if(viewId === 'ASSIGNED_TO_ME') {
      return false;
    }

    return get(doc, 'assignedToMe', false);
  }
);

/**
 * @param {Object} state Redux State
 * @returns {Boolean} `true` when data load is in progress.
 */
export const loadInProgress = state => get(state, `views.view.${currentViewId(state)}.loadInProgress`);

/**
 * @param {Object} state Redux State
 * @returns {Boolean} `true` when init data loading is in progress.
 */
export const initDataLoadInProgress = state => get(state, `views.view.${currentViewId(state)}.initDataLoadInProgress`);

/**
 * @param {Object} state Redux state
 * @returns Last Updated time for current view.
 */
export const lastUpdatedTime = selectorCreator.reselct.createSelector(
  (state) => summary(state, currentViewId(state)),
  (_summary) => {
    return get(_summary, 'lastUpdatedAt', 0);
  }
);

/**
 * When `assignedToMe` filter is applied & no assigned card found, hides that column.
 * @returns {Array} e.g When viewId is 'new', [{id: '34234', title: 'Kerika Main Board'}]
 *                      When viewId is 'ASSIGNED_TO_ME`, [{id: '34234', title: 'Kerika Main Board'}] or [{id: 'OVERDUE'}, {id: 'TODAY']
 *                      When viewId is 'ATTENTION`, [{id: '34234', title: 'Kerika Main Board'}] or [{id: 'CRITICAL'}, {id: 'ON_HOLD']
 *                      When viewId is 'WHATS_DUE', [{id: '34234', title: 'Kerika Main Board'}] or [{id: 'OVERDUE'}, {id: 'TOMORROW']
 *                      When viewId is 'GOT_DONE', [{id: '34234', title: 'Kerika Main Board'}] or [{id: 'TODAY'}, {id: 'YESTERDAY']
 */
export const columns = selectorCreator.default({maxSize: 10})(
  (state) => data(state, currentViewId(state)),
  (data) => {
    let list = [];
    forEach(get(data, 'columns', []), (column) => {
      list.push({ id: column.id, title: column.name });
    })
    return list;
  }
);

/**
 * When `assignedToMe` filter applied, filter out current user's assigned card & counts other member's assigned cards.
 * @param {String} columnId  ${boardId} or status/dueDate ENUM. e.g 234234, OVERDUE, CRITICAL
 * @returns {Object} e.g {cards: [{cardId, boardId}, {cardId, boardId}, {cardId, boardId}], otherAssignedCardsCount: 5 }
 */
const columnCardIds = selectorCreator.default({maxSize: 500})(
  (state, columnId) => columnId,
  (state, columnId) => data(state, currentViewId(state)),
  (columnId, data) => {
    const column = find(get(data, 'columns'), { id: columnId });
    return get(column, 'cards', []);
  }
);
const columnCards = selectorCreator.default({maxSize: 500})(
  (state, columnId) => loadInProgress(state),
  (state, columnId) => columnCardIds(state, columnId),
  (state, columnId) => firestoreRedux.selectors.collection(state, 'cards'),
  (state, columnId) => get(state, `views.view.${currentViewId(state)}.data.cards`),
  (loadInProgress, allCards, cardsData, _cardsData) => {
    const cards = [];
    forEach(allCards, (obj) => {
      const cardAttrs = cardsData && cardsData[obj.cardId];
      const _cardAttrs = _cardsData && _cardsData[obj.cardId];
      const attrs = cardAttrs ? cardAttrs: loadInProgress ? _cardAttrs: cardAttrs;
      cards.push({...obj, ...{columnType: attrs && attrs.columnType || 'OTHER'}});
    });
    return cards;
  }
);
export const cardsList = selectorCreator.default({maxSize: 500})(
  ({state, columnId}) => currentViewId(state),
  ({state, columnId}) => currentViewAssignedToMe(state),
  ({state, columnId}) => columnCards(state, columnId),
  (viewId, assignedToMe, allCards) => {
    const cards = [];
    forEach(allCards, (obj) => {
      if (viewId === 'UPDATES' || (viewId === 'GOT_DONE' && obj.columnType === 'DONE') || (viewId !== 'UPDATES' && viewId !== 'GOT_DONE' && obj.columnType === 'OTHER')) {
        if (!assignedToMe || obj.assignedToMe) {
          cards.push(obj);
        };
      }
    })

    let otherAssignedCardsCount = 0;
    if (assignedToMe) {
      const otherAssignedCards = filter(allCards, { assignedToMe: false });
      otherAssignedCardsCount = otherAssignedCards.length;
    }
    return { cards, otherAssignedCardsCount };
  }
);

/**
 * @returns {String} `includeBoards` value of current user's view-settings. Possible values: 'FAVORITE', 'ALL' or 'SELECTED'
 */
export const includeBoards = selectorCreator.reselct.createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'view-settings', `vws_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (doc) => {
    return get(doc, 'includeBoards', 'FAVORITE');
  }
);

/**
 * @param {Object} state Redux state.
 * @returns {Array} List of selected participated board ids. e.g. ['brd_4t2Tc0rYkku4MeMVPbspIg', ...]
 */
export const includeBoardIds = selectorCreator.default({maxSize: 10})(
  (state) => firestoreRedux.selectors.doc(state, 'view-settings', `vws_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (doc) => {
    return get(doc, 'includeBoardIds', []);
  }
);

/**
 * @returns {Array} list of participated active baords. e.g [{id: boardId, name: boardName, accountName: 'Account Name}, {id: boardId, name: boardName, accountName: 'Account Name}]
 */
export const participatedBoards = selectorCreator.default({maxSize: 10})(
  (state) => get(state, `views.participatedBoards.data`),
  (state) => firestoreRedux.selectors.collection(state, 'boards'),
  (state) => firestoreRedux.selectors.collection(state, 'accounts'),
  (participatedBoards, allBoards, allAccounts) => {
    if (!participatedBoards) {
      return;
    }
    let list = [];
    for (let boardId of participatedBoards) {
      const boardAttrs = get(allBoards, boardId);
      const accountName = boardAttrs && boardAttrs.accountId ? get(allAccounts, `${boardAttrs.accountId}.name`): '';
      if (!isEmpty(boardAttrs) && accountName && boardAttrs.status !== 'TRASHED' && boardAttrs.status !== 'ARCHIVED') {
        const boardName = boardAttrs && boardAttrs.name;
        list.push({ id: boardId, name: boardName, accountName });
      }
    }

    return orderBy(list, [obj => obj.accountName.toLowerCase(), obj => obj.name.toLowerCase()]);
  }
);

/**
 * Note: At preset this is used only in desktop as in mobile `select-dialog` is used & it has groupBy feature already.
 * @returns List of participated boards grouped by it's account name. e.g [{accountName, boards}, {accountName, boards}]
 */
export const groupedParticipatedBoards = selectorCreator.default({maxSize: 10})(
  participatedBoards,
  (_participatedBoards) => {
    const groupedList = groupBy(_participatedBoards, (obj) => obj.accountName);
    let list = [];
    forEach(groupedList, (boards, accountName) => {
      list.push({ accountName, boards });
    })
    return list;
  }
);

/**
 * @returns `true` when participated boards are being loaded.
 * @param {Object} state Redux state
 */
export const participatedBoardsLoadInProgress = state => get(state, `views.participatedBoards.loadInProgress`);

/**
 * @param {Object} state State
 * @param {String} columnId Column Id
 * @returns {Boolean} `true` when given column is visible in current view.
 */
export const columnVisibility = selectorCreator.default({maxSize: 50})(
  ({state, columnId}) => columnId,
  ({state, columnId}) => firestoreRedux.selectors.docsByQueryResult(state, `views-hidden-columns-${currentViewId(state)}`, 'views-hidden-columns'),
  (columnId, allHiddenColumns) => {
    const doc = find(allHiddenColumns, { columnId });
    return !get(doc, 'hidden', false);
  }
);

/**
 * @param {Object} state redux state
 * @param {String} columnId view columnId
 * @returns {Boolean} `true` when column has unread highlights.
 */
export const columnUnread = selectorCreator.default({maxSize: 200})(
  (state, columnId) => columnCardIds(state, columnId),
  (state, columnId) => auth.selectors.currentUserId(state),
  (state, columnId) => firestoreRedux.selectors.collection(state, 'card-unread-user-details'),
  (cardIds, userId, allUnreadUserDetails) => {
    if(!userId || isEmpty(allUnreadUserDetails) || isEmpty(cardIds)) {
      return false;
    }

    let unread = false;
    forEach(cardIds, (card) => {
      const cardId = card && card.cardId;
      if(cardId) {
        const unreadDetails = get(allUnreadUserDetails, `cuu_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}`)
        if(unreadDetails && unreadDetails.anyUnread) {
          unread = true;
          return false;
        }
      }
    });
    return unread;
  }
);

/**
 * @returns {String} Current view's groupBy preference. Possible values: 'DUE_DATE', 'STATUS', 'BOARD'.
 */
export const currentViewGroupBy = selectorCreator.reselct.createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'view', currentUserViewId(state)),
  (doc) => {
    return get(doc, 'groupBy', null);
  }
);

/**
 *
 * @param {Object} state state
 * @returns {Array} Selected cards in column. e.g [234234, 236234, 239234]
 */
 export const selectedCards = state => get(state, `views.selectedCards`);

 /**
  *
  * @param {Object} state State
  * @returns {String} Id of selected column.
  */
export const selectedColumn = state => get(state, `views.selectedColumn`);

/**
 * @param {Object} state Redux state
 * @param {String} id view id. e.g. 'ASSIGNED_TO_ME
 * @returns {Object} View summary of given system view id.
 */
const summary = selectorCreator.default({maxSize: 10})(
  (state, id) => firestoreRedux.selectors.docsByQueryResult(state, `view-data-summary-${currentUserViewId(state, id)}`, 'view-data-summary'),
  (state, id) => currentUserViewId(state, id),
  (allSummary, viewId) => {
    return find(allSummary, { viewId }) || {};
  }
);

/**
 * @param {Object} state Redux state
 * @param {String} id view id. e.g. 'ASSIGNED_TO_ME'
 * @returns {Object} view data of given system view id.
 */
const data = selectorCreator.default({maxSize: 10})(
  (state, id) => firestoreRedux.selectors.docsByQueryResult(state, `view-data-${currentUserViewId(state, id)}`, 'view-data'),
  (state, id) => currentUserViewId(state, id),
  (allData, viewId) => {
    return find(allData, { viewId }) || {};
  }
);

/**
 * @returns {Array} view list with individual view summary.
 * e.g [{id: WHATS_ASSIGNED_TO_ME, assignedCards: 5, cards: 10, lastUpdatedAt: 1567053934995}]
 */
export const viewsList = selectorCreator.default({maxSize: 10})(
  auth.selectors.currentUserId,
  (state) => get(state, `views.viewIds`),
  (state) => firestoreRedux.selectors.collection(state, 'view-data-summary'),
  (userId, viewIds, allSummary) => {
    let list = [];
    forEach(viewIds, (id) => {
      const _summary = find(allSummary, { viewId: `vw_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}_${id}` }) || {};
      list.push({ id, lastUpdatedAt: _summary.lastUpdatedAt, cards: _summary.cards, assignedCards: _summary.assignedCards, urlPath: viewIdToUrlPath(id) });
    });
    return list;
  }
);

/**
 * @returns {Number} last active column index.
 * When last column exists in columns list, preferes it over index of column othwerwise index of that column.
 */
export const lastActiveColumnIndex = selectorCreator.reselct.createSelector(
  columns,
  loadInProgress,
  currentViewId,
  currentViewGroupBy,
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `views-last-active-columns-${currentViewId(state)}`, 'views-last-active-columns'),
  (_columns, _loadInProgress, viewId, _currentViewGroupBy, allLastActiveColumns) => {
    if (!viewId || isEmpty(_columns) || _loadInProgress) {
      return;
    }

    const lastActiveColumn = find(allLastActiveColumns, { groupBy: _currentViewGroupBy });
    if (!lastActiveColumn) {
      return 0;
    }

    const index = findIndex(_columns, (o) => lastActiveColumn.columnId == o.id);
    const finalIndex = index >=0 ? index : Math.min(lastActiveColumn.columnIndex, (_columns.length - 1));
    return finalIndex;
  }
);

/**
 * @returns {String} Id of current active column.
 */
export const activeColumnId = selectorCreator.reselct.createSelector(
  columns,
  lastActiveColumnIndex,
  (_columns, _lastActiveColumnIndex) => {
    return get(_columns, `${_lastActiveColumnIndex}.id`);
  }
);

/**
 * @returns {Boolean} can only done cards or not.
 */
export const canOnlyDoneCards = ({ state, columnId }) => {
  const viewId = currentViewId(state);
  if(viewId === 'GOT_DONE' || !columnId) {
    return true;
  }

  const _selectedCards = selectedCards(state);
  let allCards = [..._selectedCards] || [];
  if(isEmpty(_selectedCards)) {
    const _cardsList = cardsList({ state, columnId });

    const cardsByColumn = get(_cardsList, 'cards');
    if (isEmpty(_selectedCards)) {
      forEach(cardsByColumn, (obj) => {
        allCards.push(obj.cardId);
      });
    }
  }
  if(isEmpty(allCards)) {
    return true;
  }

  let onlyDoneCards = true;
  forEach(allCards, (cardId) => {
    const _attrs = card.selectors.attrs(state, cardId);
    if(_attrs && (_attrs.columnType === 'OTHER' || _attrs.columnType === 'TRASH')) {
      onlyDoneCards = false;
      return false;
    }
  });

  return onlyDoneCards;
};

/**
 * @returns {Boolean} can only done cards or not.
 */
export const canOnlyTrashCards = ({ state, columnId }) => {
  if(!columnId) {
    return true;
  }

  const _selectedCards = selectedCards(state);
  let allCards = [..._selectedCards] || [];
  if(isEmpty(_selectedCards)) {
    const _cardsList = cardsList({ state, columnId });

    const cardsByColumn = get(_cardsList, 'cards');
    if (isEmpty(_selectedCards)) {
      forEach(cardsByColumn, (obj) => {
        allCards.push(obj.cardId);
      });
    }
  }
  if(isEmpty(allCards)) {
    return true;
  }

  let onlyTrashCards = true;
  forEach(allCards, (cardId) => {
    const _attrs = card.selectors.attrs(state, cardId);
    if(_attrs && (_attrs.columnType === 'OTHER' || _attrs.columnType === 'DONE')) {
      onlyTrashCards = false;
      return false;
    }
  });

  return onlyTrashCards;
};

const _cardAttrs = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => get(state, `views.view.${currentViewId(state)}.data.cards.${cardId}`),
  (attrs) => {
    return attrs;
  }
);

/**
 * @retuns given card details.
 */
export const cardAttrs = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => loadInProgress(state),
  (state, cardId) => card.selectors.attrs(state, cardId),
  (state, cardId) => _cardAttrs(state, cardId),
  (loadInProgress, attrs, _attrs) => {
    return attrs ? attrs: loadInProgress ? _attrs: attrs;
  }
);

const _cardSummary = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => get(state, `views.view.${currentViewId(state)}.data.cardsSummary.cs_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}`),
  (summary) => {
    return summary || {};
  }
);

export const cardSummary = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => loadInProgress(state),
  (state, cardId) => card.selectors.summary(state, cardId),
  (state, cardId) => _cardSummary(state, cardId),
  (loadInProgress, summary, _summary) => {
    return isEmpty(summary) ? loadInProgress ? _summary : summary : summary;
  }
);

export const cardTaskSummary = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => cardSummary(state, cardId),
  (summary) => {
    summary = summary || {};
    const task = summary.tasks || {};
    const openTasks = task.open || summary.openTasks || 0;
    const closedTasks = task.closed || summary.closedTasks || 0;
    const minDueDate = task.minDueDate || 0;
    const maxDueDate = task.maxDueDate || 0;
    if(!openTasks && !closedTasks) {
      return {};
    }

    return { closedTasks, openTasks, minDueDate, maxDueDate };
  }
);

const _cardBoardMembers = selectorCreator.default({ maxSize: 100 })(
  (state, boardId) => boardId,
  (state, boardId) => get(state, `views.view.${currentViewId(state)}.data.boardTeamMembers`),
  (boardId, allBoardTeamMembers) => {
    const docs = filter(allBoardTeamMembers, { boardId });
    let _members = {};
    forEach(docs, (doc) => {
      _members[doc.userId] = doc.role;
    });
    return _members;
  }
);

export const cardBoardMembers = selectorCreator.default({ maxSize: 100 })(
  (state, boardId) => loadInProgress(state),
  (state, boardId) => board.selectors.members(state, boardId),
  (state, boardId) => _cardBoardMembers(state, boardId),
  (loadInProgress, members, _members) => {
    return isEmpty(members) ? loadInProgress ? _members : members : members;
  }
);

export const cardAssignments = selectorCreator.default({ maxSize: 500 })(
  (state, cardId, boardId) => cardAttrs(state, cardId),
  (state, cardId, boardId) => cardBoardMembers(state, boardId),
  (_attrs, boardMembers) => {
    _attrs = _attrs || {};
    let _assignements = {};
    forEach(_attrs.assignments, (userId) => {
      _assignements[userId] = get(boardMembers, userId);
    });
    return _assignements;
  }
);

/**
 * @returns {Array} given card assigned tags.
 */
export const cardTags = selectorCreator.default({ maxSize: 500 })(
  (state, cardId) => cardSummary(state, cardId),
  (summary) => {
    return summary && summary.tags || [];
  }
);

/**
 * @param {*}
 * 	@property {Object} state Redux state.
 * 	@property {String} cardId Card Id.
 * @returns {Array} list of card assigned tags. e.g. [{id,  name, color}, ...]
 */
const _cardAssignedTags = selectorCreator.default({ maxSize: 500 })(
  (state, cardId, boardId) => cardTags(state, cardId),
  (state, cardId, boardId) => get(state, `views.view.${currentViewId(state)}.data.boardTags`),
  (state, cardId, boardId) => boardTagsSelectors.allowedColors(state),
  (cardTags, boardTags, allowedColors) => {
    let list = [];
    forEach(cardTags, (id) => {
      const name = get(boardTags, `${id}.name`);
      const colorId = get(boardTags, `${id}.color`);
      const color = get(allowedColors, `${colorId}.value`, '');
      list.push({ id, name, color });
    });
    return sortBy(list, ['name']);
  }
);

export const cardAssignedTags = selectorCreator.default({maxSize: 500})(
  (state, cardId, boardId) => loadInProgress(state),
  (state, cardId, boardId) => card.selectors.cardAssignedTags(state, cardId, boardId),
  (state, cardId, boardId) => _cardAssignedTags(state, cardId),
  (loadInProgress, tags, _tags) => {
    return isEmpty(tags) ? loadInProgress ? _tags : tags : tags;
  }
);

export const cardBoardAttrs = selectorCreator.default({maxSize: 100})(
  (state, boardId) => loadInProgress(state),
  (state, boardId) => board.selectors.attrs(state, boardId || router.selectors.pageBoardId(state)),
  (state, boardId) => get(state, `views.view.${currentViewId(state)}.data.boards.${boardId || router.selectors.pageBoardId(state)}`),
  (loadInProgress, attrs, _attrs) => {
    return attrs ? attrs: loadInProgress ? _attrs: attrs;
  }
);

export const cardTagsEnabled = selectorCreator.createDeepEqualSelector(
  (state, boardId) => cardBoardAttrs(state, boardId || router.selectors.pageBoardId(state)),
  (doc) => {
    if(!doc) {
      return false;
    }
    const tagsEnabled = get(doc, 'tagsEnabled');
    if(tagsEnabled === null) {
      return true;
    }
    return tagsEnabled;
  }
);

export const cardAutoNumberEnabled = selectorCreator.createDeepEqualSelector(
  (state, boardId) => cardBoardAttrs(state, boardId || router.selectors.pageBoardId(state)),
  (doc) => {
    return get(doc, 'showCardRefNo');
  }
);

export const cardBoardName = selectorCreator.createDeepEqualSelector(
  (state, boardId) => cardBoardAttrs(state, boardId || router.selectors.pageBoardId(state)),
  (doc) => {
    return get(doc, 'name');
  }
);

const _cardBoardAttrs = (state, cardId) => {
  const _attrs = cardAttrs(state, cardId);
  return _attrs && cardBoardAttrs(state, _attrs.boardId);
};

const _cardUnreadUserDetails = selectorCreator.default({maxSize: 500})(
  (state, cardId) => loadInProgress(state),
  (state, cardId) => firestoreRedux.selectors.doc(state, 'card-unread-user-details', `cuu_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (state, cardId) => get(state, `views.view.${currentViewId(state)}.data.cardUnreadUserDetails.cuu_${getIdWoPrefix({ id: cardId, prefix: 'crd_' })}_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (loadInProgress, unreadDetails, _unreadDetails) => {
    return unreadDetails ? unreadDetails: loadInProgress ? _unreadDetails: unreadDetails;
  }
);

/**
 * @param {*}
 *  @property {Object} state Redux state
 *  @property {String} cardId Card Id.
 * @returns {Object} card unread details. e.g. { created: true, title: true, description: true, dueDate: true, tasks: true, chats: true, attachments: true }
 */
export const cardUnreadChanges = selectorCreator.default({maxSize: 1000})(
  ({ state, cardId }) => _cardBoardAttrs(state, cardId),
  ({ state, cardId }) => _cardUnreadUserDetails(state, cardId),
  (boardAttrs, doc) => {
    if (!boardAttrs || boardAttrs.status !== 'ACTIVE' || boardAttrs.movingStatus === 'ACCEPTED' || boardAttrs.movingStatus === 'FAILED' || boardAttrs.initialized === false) {
      return {};
    }

    if(!doc || !doc.anyUnread) {
      return {};
    }

    const cardUnreads = get(doc, 'unread', {});
    if (cardUnreads.created) {
      return { created: true };
    }
    return cardUnreads;
  }
);

/**
 * @returns {Object} column details.
 * @param {Object} state State
 * @param {String} columnId Column Id
 */
export const cardColumn = selectorCreator.default({maxSize: 1000})(
  (state, columnId) => loadInProgress(state),
  (state, columnId) => columnsSelectors.column(state, columnId),
  (state, columnId) => get(state, `views.view.${currentViewId(state)}.data.columns.${columnId}`),
  (loadInProgress, column, _column) => {
    return !isEmpty(column) ? column: loadInProgress ? _column: column;
  }
);