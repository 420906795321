import * as actions from './actions.js';

const INITIAL_STATE = {
  status: 'START'
};


export const forgotPassword = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.PAGE_OPENED:
    case actions.PAGE_CLOSED:
      return INITIAL_STATE;
    // START: send otp flow
    case actions.REQUEST_OTP: 
      return {
        ...state, 
        status: 'OTP_REQ_IN_PROGRESS',
        error: null
      }
    case actions.REQUEST_OTP_DONE: 
      return {
        ...state, 
        status: 'OTP_SENT',
        requesterId: action.id,
        error: null
      }
    case actions.REQUEST_OTP_FAILED: 
      return {
        ...state, 
        status: 'START',
        error: action.error
      }
    // END: send otp flow

    // START: re-send otp flow
    case actions.RESEND_OTP: 
      return {
        ...state, 
        status: 'OTP_RESEND_IN_PROGRESS',
        error: null
      }
    case actions.RESEND_OTP_DONE: 
      return {
        ...state, 
        status: 'OTP_SENT',
        error: null
      }
    case actions.RESEND_OTP_FAILED: 
      return {
        ...state,
        status: action.error === 'NOT_FOUND' ? 'START' : 'OTP_SENT',
        error: action.error
      }
    // END: re-send otp flow

    // START: Verify otp flow
    case actions.VERIFY_OTP: 
      return {
        ...state, 
        status: 'OTP_VERIFICATION_IN_PROGRESS',
        error: null
      }
    case actions.VERIFY_OTP_DONE: 
      return {
        ...state, 
        status: 'OTP_VERIFIED',
        error: null
      }
    case actions.VERIFY_OTP_FAILED: 
      return {
        ...state,
        status: action.error === 'OTP_REQ_NOT_FOUND' || action.error === 'OTP_USED' ? 'START' : 'OTP_SENT',
        error: action.error
      }
    // END: Verify otp flow

    // START: Reset password flow
    case actions.RESET: 
      return {
        ...state, 
        status: 'RESET_PASSWORD_IN_PROGRESS',
        error: null
      }
    case actions.RESET_DONE: 
      return {
        ...state, 
        status: 'RESET_PASSWORD_IN_PROGRESS',
        error: null
      }
    case actions.RESET_FAILED: 
      return {
        ...state,
        status: action.error === 'OTP_REQ_NOT_FOUND' || action.error === 'OTP_USED' ? 'START' : 'OTP_SENT',
        error: action.error
      }
    default:
      return state;
  }
};