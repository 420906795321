import i18next from '@dw/i18next-esm';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import isEqual from 'lodash-es/isEqual.js';
import { setCookieVal } from '../utils.js';
import { store } from '../store.js';

import * as multipleLanguage  from '../redux/multiple-language';

import log from'@dw/loglevel';
const _log = log.getLogger('localize');
_log.setLevel(3);

let SUPPORTED_LANGUAGES = [];
let BASE_LANG;

/**
 * Get Current app lang.
 * Local storage language is not supported then WARN log.
 * @returns { String } Retunn lang from localStorage if available and valid lang otherwise return default lang is return.
 */
export const getLanguage = ({state}) => {
  let lang = multipleLanguage.selectors.currentLanguage(state);
  if(!isSupportedLang(lang)) {
    lang = BASE_LANG;
  }

  return lang;
}

export const isSupportedLang = (lang) => {
  return (SUPPORTED_LANGUAGES && SUPPORTED_LANGUAGES.indexOf(lang) !== -1) || (BASE_LANG == lang);
}

export const setBaseLang = (lang) => {
  if(BASE_LANG === lang) {
    return;
  }
  BASE_LANG = lang;
}

export const setSupportedLanguages = (langs) => {
  if(isEqual(SUPPORTED_LANGUAGES, langs)) {
    return;
  }
  SUPPORTED_LANGUAGES = langs;
  i18next.supportedLanguages = langs;
}

export const init = ({state, supportedLanguages, baseLang}) => {
  i18next.supportedLanguages = SUPPORTED_LANGUAGES;
  setSupportedLanguages(supportedLanguages);
  setBaseLang(baseLang);
  const options = {
    fallbackLng: baseLang,
    lng: getLanguage({state, baseLang}),
    ns: ['app', 'video'],
    defaultNS: 'app',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: true
    },
    detection: {
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      caches: []
    },
    interpolation: {escapeValue: false},
  }

  i18next.use(LanguageDetector).use(Backend).init(options);
}

/**
 * Change application's language
 * @param {*} lang
 */
export const changeI18NextLanguage = (lang) => {
  if(!lang || !isSupportedLang(lang)) {
    lang = BASE_LANG;
  }

  if(lang == i18next.language) {
    return;
  }

  try {
    if(!multipleLanguage.selectors.embedView(store.getState())) {
      setCookieVal('lang', lang, `.${window.location.host.replace(/^a2?\./, '')}`, 365);
      localStorage && localStorage.setItem && localStorage.setItem('lang', lang);
    }
    i18next.changeLanguage(lang);
  } catch (error) {}
}