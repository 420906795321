import { css } from 'lit-element';
import typographyLiterals from '../theme/typography-literals.js';
export const sharedStyles = css`
  :host {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }

  /**
   * Provide a way to select any app text.
   */
  :host, :host > * {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: -moz-text;
    -o-user-select: text;
    -ms-user-select: text;
    user-select: text;
    outline: none;
  }

  :host([draggable="true"]), :host([draggable="true"]) > *,
  :host([draggable-item]), :host([draggable-item]) > *,
  *[disable-user-selection], *[disable-user-selection] > *,
  :host([disable-user-selection]), :host([disable-user-selection]) > *,
  :host([draggable-container="true"]), :host([draggable-container="true"]) > * {
    /* Disable default text selection*/
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  :host > * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .tab-toolbar {
    background-color: var(--primary-background-color);
  }

  :host([hidden]), [hidden], *[hidden] {
    display: none !important;
  }

  /**
   * This is used for common empty state message.
   */
  .empty-message {
    white-space: pre-wrap;
    text-align: center;
    color: var(--secondary-text-color);
    ${typographyLiterals.body1};
  }

  app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    transform: none !important;
  }

  header kerika-icon-button[icon="arrow_back"],
  app-header .back-icon kerika-icon-button[icon="arrow_back"] {
    height: 48px;
    width: 48px;
    margin-right: 4px;
    margin-left: 4px;
  }

  header .title, app-header .title {
    color: var(--page-header-title-color);
    cursor: default;
    outline: none;
    ${typographyLiterals.headline6};
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  header .title-overline, app-header .title-overline {
    color: var(--page-header-title-overline-color);
    ${typographyLiterals.caption};
  }

  header.back-header, app-header {
    --mdc-theme-primary: var(--mdc-theme-primary-on-dark);
    --mdc-theme-secondary: var(--mdc-theme-secondary-on-dark);
    --mdc-theme-background: var(--mdc-theme-background-on-dark);
    --mdc-theme-surface: var(--mdc-theme-surface-on-dark);
    --mdc-theme-on-primary: var(--mdc-theme-on-primary-on-dark);
    --mdc-theme-on-secondary: var(--mdc-theme-on-secondary-on-dark);
    --mdc-theme-on-surface: var(--mdc-theme-text-primary-on-dark);
    --mdc-theme-on-error: var(--mdc-theme-on-error-on-dark);

    --mdc-theme-text-primary: var(--mdc-theme-text-primary-on-dark);
    --mdc-theme-text-secondary: var(--mdc-theme-text-secondary-on-dark);
    --mdc-theme-text-hint: var(--mdc-theme-text-hint-on-dark);
    --mdc-theme-text-disabled: var(--mdc-theme-text-disabled-on-dark);

    --mdc-theme-divider: var(--mdc-theme-divider-on-dark);

    --dw-icon-color-active: var(--dw-icon-color-active-on-dark);
    --dw-icon-color: var(--dw-icon-color-on-dark);
    --dw-icon-color-disabled: var(--dw-icon-color-disabled-on-dark);

    /* surface overlay properties */
    --dw-surface-overlay-color: var(--mdc-theme-on-surface);
    --dw-surface-overlay-opacitiy-elevation-1: 0.05;
    --dw-surface-overlay-opacitiy-elevation-2: 0.07;
    --dw-surface-overlay-opacitiy-elevation-3: 0.08;
    --dw-surface-overlay-opacitiy-elevation-4: 0.09;
    --dw-surface-overlay-opacitiy-elevation-6: 0.11;
    --dw-surface-overlay-opacitiy-elevation-8: 0.12;
    --dw-surface-overlay-opacitiy-elevation-12: 0.14;
    --dw-surface-overlay-opacitiy-elevation-16: 0.15;
    --dw-surface-overlay-opacitiy-elevation-24: 0.16;
    background-color: var(--mdc-theme-background-on-dark);
  }

  .secondary-text {
    color: var(--secondary-text-color);
  }

  .primary-color {
    color: var(--mdc-theme-primary);
  }

  button, img, input, textarea, a, *[tabindex] {
    outline: none;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
  }

  p.bold {
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }

  hr {
    border-color: var(--divider-color);
    border-width: 1px 0 0 0;
    margin: 8px 0px;
  }

  .item-group-header, .group-label {
    height: 38px;
    --dw-select-group-label-height: 38px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 16px 16px 0px 16px;
    color: var(--secondary-text-color);
    cursor: default;
    ${typographyLiterals.subtitle2};
    border-top: 1px solid var(--divider-color);
    font-weight: 400 !important;
    text-transform: uppercase !important;
  }

  :host([wide-layout]) .item-group-header,
  :host([wide-layout]) .group-label {
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    ${typographyLiterals.body2};
  }

  /**
   * Rotating animation start
   */
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
  /**
   * Rotating animation end
   */

  /**
   * Page info or empty message style.
   */
  .page-msg {
    white-space: pre-wrap;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 26px;
    color: var(--secondary-text-color);
  }

  span.mark {
    background-color: var(--list-item-highlight-background-color);
    border-radius: 2px;
  }
`;