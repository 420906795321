import URI from '@dw/urijs-esm/urijs-esm.js';
import * as InAppBrowser from '../cordova-plugins/in-app-browser.js';

/**
 * Provides way to authorized store based on given `service`.
 * Authorized using child window.
 * @param {String} service give service to authorized store.
 * @returns {Promise} Resolved promise when authorized store successfully done
 *                    Otherwise reject with errorCode.
 */
export const authorizeStore = ({service, email, token, accountId, inSameWindow, installedApp}) => {
  return authorizedStoreInChildWindow({service, email, token, accountId, inSameWindow, installedApp});
}

let __childWindow;
/**
 * Authorized store using with child window.
 */
const authorizedStoreInChildWindow = ({service, email, token, accountId, inSameWindow, installedApp}) => {
  if(installedApp) {
    installedAppAuthorizedStoreInChildWindow({service, email, token, accountId, inSameWindow, installedApp});
    return;
  }
  
  let redirectURL = getAuthorizedStoreUrl({service, email, token, accountId, inSameWindow, installedApp});
  //Authintication flow in same window
  if(inSameWindow) {
    window.location.href = redirectURL;
    return;
  }

  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  let width = window.innerWidth > 767 ? 400 : window.innerWidth;
  let height = window.innerWidth > 767 ? 500 : window.innerHeight;
  let left = window.innerWidth > 767 ? ((screen.width - width) / 2) : 0;
  let top = window.innerWidth > 767 ? ((screen.height - height) / 2) : 0;
  __childWindow && __childWindow.close && __childWindow.close();
  __childWindow = window.open(redirectURL, "", "titlebar=no, width=" + width + ", height=" + height + ", left=" + left + ", top=" + top + ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, directories=no, status=no");

  let timer = setInterval(checkChild, 1000);

  function checkChild() {
    if (!__childWindow || __childWindow.closed) {
      reject({code: 'CANCELED'});
      clearInterval(timer);
    }
  }

  // listen for the AUTH_SUCCESSFULLY_COMPLETED and  AUTH_LOGIN_FAILED event (which will be triggered by the child window)
  const listener = (e) => {
    const type = e.data && e.data.type;
    const error = e.data && e.data.error || e.data && e.data.status || 'UNKNOWN';
    if (type === 'REAUTHORIZED_CLOUD_STORAGE_SUCCESS' || type === 'REAUTHORIZED_CLOUD_STORAGE_FAILED') {
      clearInterval(timer);
      window.removeEventListener('message', listener);
      type === 'REAUTHORIZED_CLOUD_STORAGE_SUCCESS' ? resolve(e.data) : reject({code: error, authFailed: true});
    }
  };

  window.addEventListener('message', listener);
  return promise;
}

const getMessageData = (url) => {
  const uri = new URI(url);
  const _queryParams = uri.query(true);
  const status = _queryParams.status || null;
  const error = _queryParams.error || null;
  return {
    status,
    success: status == 'ok',
    type: (status == 'ok') ? 'REAUTHORIZED_CLOUD_STORAGE_SUCCESS' : 'REAUTHORIZED_CLOUD_STORAGE_FAILED',
    authEmail: _queryParams['auth-email'] || null,
    error: status === 'access_denied' ? 'CANCELED': error,
    authFailed: status == 'ok' ? false: true
  };
}

const isFallBackPage = (url) => {
  const fallbackPageUrl = getFallbackPageUrl();
  if(!fallbackPageUrl || !url || !url.startsWith) {
    return false;
  }
  return url.startsWith(fallbackPageUrl);
}

const installedAppAuthorizedStoreInChildWindow = async({service, email, token, accountId, inSameWindow, installedApp}) => {
  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  let redirectURL = getAuthorizedStoreUrl({service, email, token, accountId, inSameWindow, installedApp});

  const loadstop = (params) => {
    if (__childWindow != undefined) {
      const url = params && params.url || '';
      if(isFallBackPage(url)) {
        const data = getMessageData(url);
        __childWindow && __childWindow.close && __childWindow.close();
        data && data.success ? resolve(data) : reject({code: data.error});
      }
      __childWindow && __childWindow.show && __childWindow.show();
    }
  }

  const exitListener = (e) => {reject({code: 'CANCELED'})};
  const beforeload = (params, callback)=> {callback(params.url)};
  try {
    __childWindow = await InAppBrowser.open(redirectURL, '_blank', 'location=yes,hidden=yes,beforeload=yes,hidenavigationbuttons=yes,hideurlbar=yes,toolbarcolor=#ffffff');
    __childWindow.addEventListener('loadstop', loadstop);
    __childWindow.addEventListener('beforeload', beforeload);
    __childWindow.addEventListener('exit', exitListener);
  } catch (error) {
    window.location.href = redirectURL;
  }
  return promise;
}

/**
 * @param {String} service
 * @returns {String} authorized URL return based on the given service.
 */
const getAuthorizedStoreUrl = ({service, email, token, accountId, inSameWindow, installedApp}) => {
  const uri = new URI(`${window.K.config.apiBaseUrl}/user/authorize-cloud-store`);
  if (service) {
    uri.setSearch('service', service);
  }
  if (email) {
    uri.setSearch('email', email);
  }
  if (token) {
    uri.setSearch('token', token);
  }
  if (accountId) {
    uri.setSearch('accountId', accountId);
  }

  const redirect = getRedirectUrl({service, email, token, accountId, inSameWindow, installedApp});
  if(redirect) {
    uri.setSearch('redirect', redirect);
  }
  const url = uri.toString();
  return url;
}

/**
 * @param {String} service
 * @returns {String} Redirect fallback page url return based on the given service.
 */
const getRedirectUrl = ({service, email, token, accountId, inSameWindow, installedApp}) => {
  if (!inSameWindow || installedApp) {
    const uri = new URI(getFallbackPageUrl());
    if (service) {
      uri.setSearch('service', service);
    }
    if (email) {
      uri.setSearch('email', email);
    }
    if (token) {
      uri.setSearch('token', token);
    }
    if (accountId) {
      uri.setSearch('accountId', accountId);
    }
    return uri.toString();
  }

  const sameWindowUri = new URI();
  sameWindowUri.setSearch('authentication-in-same-window', true);
  sameWindowUri.removeSearch('status');
  sameWindowUri.removeSearch('auth-email');
  sameWindowUri.removeSearch('error');
  return sameWindowUri.toString();
}

const getFallbackPageUrl = () => {
  return `${window.location.protocol}//${window.location.host}/authorize-storage-fallback.html`;
}

export default authorizeStore;