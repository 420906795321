import isURL from 'validator/es/lib/isURL';
/**
 * @param {Object} oUser
 * @return {String} user full name.
 * @protected
 */
export const getUserName = (oUser) => {
  if (!oUser) {
    return '';
  }

  if (!oUser.firstName && !oUser.lastName) {
    return oUser.email || '';
  }

  let sFullName = oUser.firstName || '';
  if (oUser.lastName) {
    sFullName = sFullName + ' ' + oUser.lastName;
  }
  return sFullName;
}

/**
 * Sort members by name.
 * @param {Array} aMembers - Passed members array.
 * @protected
 */
export const sortMembers = (aMembers) => {
  return aMembers.sort(function (m1, m2) {
    if (!m1.name || !m2.name) {
      return -1
    }

    if (m1.name.toLowerCase() < m2.name.toLowerCase()) {
      return -1;
    }
    if (m1.name.toLowerCase() > m2.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

/**
 * Downloads Given URL imperatively.
 * @param {String} url Url to be download
 */
export const downloadUrl = (url) => {
  var a = document.createElement("a");
  a.setAttribute('download', '');
  a.setAttribute('href', url);
  var b = document.body;

  b.appendChild(a);
  a.click();
  setTimeout(() => {
    b.removeChild(a);
  }, 100)
}

/**
 * Check for valid URL.
 * @param {String} url URL
 */
export const isValidUrl = (url) => {
  return isURL(url);
}

/**
 * @param {String} email give a email address
 * @returns {String} return domain name based on given email.
 */
export const getDomainName = (email) => {
  return email.replace(/.*@/, "");
}