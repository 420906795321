import { takeEvery, call, all, select } from 'redux-saga/effects';
import { requestApi } from '../../request-api';
import { show as showSnackBar } from '../../components/kerika-snackbar';

import firestoreRedux from '@dreamworld/firestore-redux';

import * as auth from '../auth';
import * as actions from './actions';
import * as authSelectors from '../auth/selectors.js';

/**
 * Loads messages data.
 */
function* load(action) {
  const state = yield select();
  const userId = action.userId || authSelectors.currentUserId(state);
  if(userId) {
    firestoreRedux.query('messages', { id: `messages-${userId}`, where: [['userId', '==', userId]], requesterId: `messages-${userId}` });
  }
}

/**
 * Disconnects messages data.
 */
function* disconnect(action) {
  const state = yield select();
  const userId = action.userId || authSelectors.currentUserId(state);
  if(userId) {
    firestoreRedux.cancelQueryByRequester(`messages-${userId}`);
  }
}

/**
 * Requests server to mark message as dismissed.
 * @param {Object} action action payload
 */
function* markAsDismissed(action) {
  const state = yield select();
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  if(impersonatedUser) {
    return;
  }
  
  try {
    yield call(requestApi, `/message-center/messages/${action.id}`, { method: 'DELETE', excludeErrors: [400, 403, 404] });
  } catch (err) {
    if (err) {
      showSnackBar({ message: err.message, type: 'ERROR' });
      console.error(err.message);
    }
  }
}

/**
 * Init Saga.
 */
function* messagesSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.DISCONNECT, disconnect),
    takeEvery(actions.MARK_AS_DISMISSED, markAsDismissed)
  ]);
}

export default messagesSaga;