import device from "current-device";
import debounce from 'lodash-es/debounce';
import { store } from '../../store';
import * as _actions from './actions';
import * as _reducers from './reducers';
import * as _selectors from './selectors';
import * as _installedApp from './installed-app'
import * as _utils from './utils'

export const actions = _actions;
export const reducers = _reducers;
export const selectors = _selectors;
export const installedApp = _installedApp;
export const utils = _utils;

/**
 * Orientation changes listen.
 */
device.onChangeOrientation( debounce((orientation) => {
  store.dispatch(actions.orientationChanged(orientation));
}, 100));