import { createSelector } from 'reselect';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as board from '../board';

// Lodash
import get from 'lodash-es/get';
import values from 'lodash-es/values';
import forEach from 'lodash-es/forEach';
import isEqual from 'lodash-es/isEqual';

export const baseBoardId = createSelector(
  (state, boardId) => boardId,
  (state, boardId) => board.selectors.attrs(state, boardId),
  (boardId, attrs) => {
    return attrs && attrs.baseBoardId || boardId;
  }
);

export const translateBoardDocs = createSelector(
  (state, boardId) => get(state, `board-translations.translatedBoards.${baseBoardId(state, boardId)}`),
  (state, boardId) => boardId,
  (state, boardId) => baseBoardId(state, boardId),
  (state) => firestoreRedux.selectors.collection(state, 'boards'),
  (boards, boardId, baseBoardId, allBoards) => {
    let newBoards = {};
    forEach(boards, (item) => {
      let id = item && item.id;
      if(id) {
        let _attrs = get(allBoards, item.id) || {};
        newBoards[id] = {...item, ..._attrs};
      }
    });

    if(!newBoards[boardId]) {
      newBoards[boardId] = get(allBoards, boardId) || {}
    }

    if(!newBoards[baseBoardId]) {
      newBoards[baseBoardId] = get(allBoards, baseBoardId) || {}
    }
    
    return values(newBoards);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 1000,
    },
  }
);