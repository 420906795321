import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as actions from './actions';
const INITIAL_STATE = {};

export const boardTranslations = (state = INITIAL_STATE, action) => {
  let oState = {...state};
	switch (action.type) {
    case actions.TRANSLATE_BOARD:
      oState = ReduxUtils.replace(oState, `translateBoard.${action.boardId}.status`, 'IN_PROGRESS');
      oState = ReduxUtils.replace(oState, `translateBoard.${action.boardId}.languages`, action.languages);
      return ReduxUtils.replace(oState, `translateBoard.${action.boardId}.data`, undefined);
    case actions.TRANSLATE_BOARD_DONE:
      oState = ReduxUtils.replace(oState, `translateBoard.${action.boardId}.status`, 'SUCCESS');
      return ReduxUtils.replace(oState, `translateBoard.${action.boardId}.data`, action.response);
    case actions.TRANSLATE_BOARD_FAILED:
      oState = ReduxUtils.replace(oState, `translateBoard.${action.boardId}.status`, 'FAILED');
      oState = ReduxUtils.replace(oState, `translateBoard.${action.boardId}.error`, action.error);
      return ReduxUtils.replace(oState, `translateBoard.${action.boardId}.data`, undefined);
    case actions.RETRANSLATE_BOARD:
      oState = ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.data`, undefined);
    case actions.RETRANSLATE_BOARD_DONE:
      oState = ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.status`, 'SUCCESS');
      return ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.data`, action.response);
    case actions.RETRANSLATE_BOARD_FAILED:
      oState = ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.status`, 'FAILED');
      oState = ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.error`, action.error);
      return ReduxUtils.replace(oState, `retranslateBoard.${action.boardId}.data`, undefined);
    case actions.LOAD_DONE:
      return ReduxUtils.replace(oState, `translatedBoards.${action.boardId}`, action.response);
    default:
      return state;
	}
};

export default boardTranslations;