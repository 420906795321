import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as fileStore from '../file-store';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

/**
 * Note: `FILE_STORE_ACCESS_REVOKED` is computed message.
 * @returns {Array} List of messages. e.g `[
 *                                          {id, type: "BOARD_INVITATION", boardId, createdAt, data, userCanDismiss, userId},
 *                                          {type: "FILE_STORE_ACCESS_REVOKED"}
 *                                         ]`
 */
export const list = selectorCreator.createDeepEqualSelector(
  app.selectors.wideLayout,
  auth.selectors.currentUserOwnedAccountId,
  (state) => fileStore.selectors.storageAccessRevoked(state, auth.selectors.currentUserOwnedAccountId(state)),
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `messages-${auth.selectors.currentUserId(state)}`, 'messages'),
  (wideLayout, ownedAccountId, fileStoreAccessRevoked, messages) => {
    let list = [];
    if(ownedAccountId && fileStoreAccessRevoked) {
      list.push({ type: 'FILE_STORE_ACCESS_REVOKED' });
    }

    for (let message of messages) {
      const messageType = message && message.type;
      if(messageType) {
        if(messageType === 'BOARD_TRANSFER_REQ_SENT' || messageType === 'BOARD_TRANSFER_REQ_RECEIVED') {
          if(wideLayout) {
            list.push(message);
          }
        } else {
          list.push(message);
        }
      }
    }

    list = sortBy(list, ['createdAt']).reverse();
    return list;
  }
);

/**
 * Note: At present, list is loaded already by app. so count is computed from list directly.
 *        In future it will set from fireStore directly.
 * @returns {Number} Count of messages for current user.
 */
export const count = selectorCreator.reselct.createSelector(
  list,
  (messages) => {
    return messages && messages.length || 0;
  }
);

/**
 * @returns {Number} `messageId` when invitation message for current board exists otherwise `undefined`
 */
export const currentBoardInvtationMessageId = selectorCreator.reselct.createSelector(
  list,
  router.selectors.pageBoardId,
  (messages, boardId) => {
    const boardInvitationMessages = filter(messages, (message) => {
      return message.type === 'BOARD_INVITATION' && message.boardId == boardId;
    });
    return get(boardInvitationMessages, '0.id');
  }
);