export const UPDATE = 'ZOOMOUT_VIEW_UPDATE';
export const RESET = 'ZOOMOUT_VIEW_RESET';

/**
 * Update zoomout view for given page.
 */
export const update = ({zoomout, pageId, pageType}) => {
  return {
    type: UPDATE,
    zoomout,
    pageId,
    pageType
  }
}

/**
 * Resets the zoomout view data.
 */
export const reset = () => {
  return {
    type: RESET
  }
}