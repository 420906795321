import { takeEvery, all, put, call, select} from 'redux-saga/effects';
import * as actions from './actions.js';
import * as router from '../router/index.js';
import { requestApi } from '../../request-api.js';
import * as emailOtpAuth from '../email-otp-auth/email-otp-auth.js';

/**
 * Send OTP code on the given email.
 * API: POST /api-v3/email-otp-auth/send
 */
function* requestOtp(action) {
  const email = action.email;
  try {
    const response = yield emailOtpAuth.request({email, purpose: 'RESET_PASSWORD'});
    yield put({ type: actions.REQUEST_OTP_DONE, id: response.id, email });
  } catch (error) {
    yield put({ type: actions.REQUEST_OTP_FAILED, error: error.code, email });
  }
}

let previousPage;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = router.selectors.pageName(state);
  if (currentPage === 'FORGOT_PASSWORD' && previousPage !== currentPage) {
    yield put({ type: actions.PAGE_OPENED });
  } else if (previousPage === 'FORGOT_PASSWORD') {
    yield put({ type: actions.PAGE_CLOSED });
  }
  previousPage = router.selectors.pageName(state);
}

/**
 * re-send OTP code on the given id.
 * API: POST /api-v3/email-otp-auth/resend
 */
function* resendOtp(action) {
  const id = action.id;
  try {
    yield emailOtpAuth.resend(id);
    yield put({ type: actions.RESEND_OTP_DONE, id });
  } catch (error) {
    yield put({ type: actions.RESEND_OTP_FAILED, error: error.code, id });
  }
}

/**
 * verify OTP code on the given id.
 * API: POST /api-v3/email-otp-auth/validate
 */
function* verifyOtp(action) {
  const id = action.id;
  const otp = action.otp;
  const email = action.email;
  try {
    yield emailOtpAuth.verify({id, otp, email, purpose: 'RESET_PASSWORD'});
    yield put({ type: actions.VERIFY_OTP_DONE, id, otp });
  } catch (error) {
    yield put({ type: actions.VERIFY_OTP_FAILED, error: error.code, id, otp });
  }
}

/**
 * reset password for the given id.
 * API: PUT /users/reset-password
 */
function* reset(action) {
  const id = action.id;
  const otp = action.otp;
  const password = action.password;
  const email = action.email;
  try {
    yield requestApi(`/user/users/reset-password`, { method: 'PUT', body: {email, otpRequestId: id, otp, password, purpose: 'RESET_PASSWORD'}, excludeErrors: [400, 404] });
    yield put({ type: actions.RESET_DONE, id, otp, password, email });
  } catch (error) {
    yield put({ type: actions.RESET_FAILED, error: error.code, id, otp, password, email });
  }
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED or TAB_CHANGED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* forgotPasswordSaga() {
  yield all([
    call(watchRouter),
    takeEvery(actions.REQUEST_OTP, requestOtp),
    takeEvery(actions.VERIFY_OTP, verifyOtp),
    takeEvery(actions.RESEND_OTP, resendOtp),
    takeEvery(actions.RESET, reset)
  ]);
}

export default forgotPasswordSaga;