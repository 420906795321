import get from 'lodash-es/get.js';
import isEmpty from 'lodash-es/isEmpty.js';
import filter from 'lodash-es/filter.js';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual.js';
import * as router from '../router';
import sortBy from 'lodash-es/sortBy';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import forEach from 'lodash-es/forEach';
import { createSelector } from 'reselect';

/**
 * @returns {Array} List of system colors.
 */
export const systemColors = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'tag-colors', 'tc_0'),
  (doc) => {
    return get(doc, 'colors', []);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Object} List of allowed colors.
 */
export const allowedColors = createSelector(
  (state) => systemColors(state),
  (colors) => {
    const _colors = {};
    forEach(colors, (color) => {
      if(color && color.id) {
        _colors[color.id] = color;
      }
    });
    return _colors;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Object} List of tags with local changes for provided board.
 *        Example: `[{id: '4234234', name: 'Bug', color: '#EEFFSS'}, {id: '786544', name: 'Sentry', color: '#EEFFEE'}]`
 * @param {Number} boardId Board Id for which tags needed.
 */
export const list = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `board-tags-${boardId || router.selectors.pageBoardId(state)}`, 'board-tags'),
  allowedColors,
  (tags, _allowdColors) => {
    let list = [];
    forEach(tags, (tag) => {
      const color = get(_allowdColors, `${tag.color}.value`, '');
      list.push({ ...tag, color });
    });
    return sortBy(list, 'name');
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Number} Count of cards on which this tag is assigned.
 * @param {Object} state Redux state
 * @param {Number} tagId Tag Id for which count of cards needed.
 */
export const cardsCountByTag = (state, tagId) => {
  const boardId = router.selectors.pageBoardId(state);
  return get(state, `board-tags.summary.${boardId}.tags.${tagId}`) || 0;
};

/**
 * @returns {Number} count of cards on which no tag is assigned.
 */
export const cardsCountWoTags = (state) => {
  const boardId = router.selectors.pageBoardId(state);
  return get(state, `board-tags.summary.${boardId}.withoutTag`) || 0;
};

/*
 * @returns {Array} List of tag colors which are used in current board.
 */
export const usedColors = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `board-tags-${boardId || router.selectors.pageBoardId(state)}`, 'board-tags'),
  (tags) => {
    let colors = [];
    forEach(tags, (tag) => {
      colors.push(tag.color);
    });
    return colors;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Object} Detail of single tag.
 */
export const tagDetail = (state, tagId) => {
  return firestoreRedux.selectors.doc(state, 'board-tags', tagId) || {};
};

/**
 * @returns {Boolean} `true` when tag is duplicate.
 */
export const existsWithName = createSelector(
  ({ state }) => list(state),
  ({ name }) => name,
  (tags, name) => {
    const obj = find(tags, (tag) => tag.name.toLowerCase() === name.toLowerCase());
    return !isEmpty(obj);
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @returns {Array} List of mergeable tags.
 */
export const mergeableTagsList = createSelector(
  ({ state }) => list(state),
  ({ srcTagId }) => srcTagId,
  (tags, srcTagId) => {
    const _list = filter(tags, (tag) => tag.id !== srcTagId);
    return sortBy(_list, (tag) => tag.name);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

/**
 * @returns {String} Color from the color id.
 */
export const color = createSelector(
  ({ state }) => allowedColors(state),
  ({ colorId }) => colorId,
  (_allowedColors, colorId) => {
    return get(_allowedColors, `${colorId}.value`, '');
  },
  {
    memoizeOptions: {
      maxSize: 100,
    },
  }
);
