import { sagaMiddleware, store } from '../../store';
import saga from './saga';

import * as _selectors from './selectors.js';
import * as _reducers from './reducers';
import * as _actions from './actions.js';

sagaMiddleware.run(saga);

store.addReducers({
  'account-preferences': _reducers.accountPreferences
});

export const selectors = _selectors;
export const actions = _actions;

