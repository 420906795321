import get from 'lodash-es/get.js';
import find from 'lodash-es/find.js';

import * as auth from '../auth';
import * as router from '../router/index.js';
import * as user from '../user';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

import { getIdWoPrefix } from '../../utils';
import { forEach } from 'lodash-es';

/**
 * @param {Object} state Redux State.
 * @returns {Sting} file store status.
 */
export const status = (state) => get(state, `file-store.status`);

/**
 * @param {Object} state Redux State`
 * @returns {*} failed reason.
 */
export const error = (state) => get(state, `file-store.error`);

/**
 * @param {Object} state Redux State`
 * @returns {Boolean} Domain admin password reset require or not.
 */
export const passwordReset = (state) => get(state, `file-store.passwordReset`);

/**
 * @returns {String} preferred signup auth type.
 */
export const preferredSignupAuthType = selectorCreator.reselct.createSelector(
  (state) => router.selectors.accountId(state),
  (state) => auth.selectors.currentUserOwnedAccounts(state),
  (state) => user.selectors.primaryAuthType(state, auth.selectors.currentUserId(state)),
  (state) => preferredStoreSelectionDone(state),
  (accountId, ownedAccoutns, primaryAuthType, preferredStoreSelectionDone) => {
    if(!accountId || !ownedAccoutns || !ownedAccoutns.length || !ownedAccoutns.includes(accountId) || preferredStoreSelectionDone) {
      return '';
    }

    return primaryAuthType;
  }
);

/**
 * @param {Object} state Redux State
 * @returns {String} preferred storage for current account.
 */
export const preferredStorage = selectorCreator.reselct.createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (doc) => {
    return get(doc, `preferredStore`);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * @param {Object} state Redux State
 * @returns {String} cloud account type for current account or given account.
 */
export const cloudAccountType = selectorCreator.reselct.createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (doc) => {
    return get(doc, `cloudAccountType`);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * @param {Object} state Redux State
 * @returns {String} next preferred storage for current account.
 */
export const nextPreferredStorage = selectorCreator.reselct.createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (doc) => {
    return get(doc, `nextPreferredStore`);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 *
 * @param {Object} state Redux state
 * @returns {Object} Current user's emails for file-store access. e.g {googleEmails: ['nirmalb@gmail.com'], boxEmails: ['nirmalb@yahoo.com'], microsoftEmails: ['nirmalb@yahoo.com']}
 */
export const currentUserEmails = selectorCreator.createDeepEqualSelector(
  (state) => firestoreRedux.selectors.doc(state, 'cloud-store-emails', `cse_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`) || {},
  (doc) => {
    return { boxEmails: doc.boxEmails, googleEmails: doc.googleEmails, microsoftPersonalEmails: doc.microsoftPersonalEmails, microsoftWorkEmails: doc.microsoftWorkEmails };
  }
);

/**
 * @returns {String} Email of Account Owner which is associated with current file store.
 */
export const fileStoreOwnerEmail = selectorCreator.reselct.createSelector(
  (state, accountId) => auth.selectors.accountOwnerId(state, accountId || router.selectors.accountId(state)),
  (state, accountId) => preferredStorage(state, accountId || router.selectors.accountId(state)),
  (state) => firestoreRedux.selectors.collection(state, 'user-cloud-stores'),
  (ownerId, preferredStorage, allUserCloudStores) => {
    const doc = find(allUserCloudStores, { userId: ownerId, service: preferredStorage });
    return get(doc, 'email');
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when file-store access is revoked for current account.
 */
export const storageAccessRevoked = selectorCreator.reselct.createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (doc) => {
    return get(doc, `storageAccessRevoked`);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * @param {Object} state Redux State.
 * @returns {String} file store preferred store selection status.
 */
export const preferredStoreSelectionStatus = (state) => get(state, `file-store-preferred-store-selection.preferredStoreSelection.status`);

/**
 * @param {Object} state Redux State`
 * @returns {String} file store preferred store selection failed reason.
 */
export const preferredStoreSelectionError = (state) => get(state, `file-store-preferred-store-selection.preferredStoreSelection.error`);

/**
 * @param {Object} state Redux State`
 * @returns {String} file store preferred store selection service.
 */
export const preferredStoreSelectionService = (state) => get(state, `file-store-preferred-store-selection.preferredStoreSelection.service`);

/**
 * @param {Object} state Redux State`
 * @returns {String} current file store preferred store selection is done or not.
 */
export const currentPreferredStoreSelectionDone = (state) => get(state, `file-store-preferred-store-selection.currentPreferredStoreData.preferredStoreSelectionDone`);

/**
 * @param {Object} state Redux State`
 * @returns {String} file store preferred store selection auth email.
 */
export const preferredStoreSelectionAuthEmail = (state) => get(state, `file-store-preferred-store-selection.preferredStoreSelection.authEmail`);

/**
 * @param {Object} state Redux State`
 * @returns {String} current file-store preferred store.
 */
export const currentPreferredStore = (state) => {
  let storage = preferredStorage(state);
  if(storage) {
    return storage;
  }
  return get(state, `file-store-preferred-store-selection.currentPreferredStoreData.preferredStore`)
};

/**
 * @param {Object} state Redux state
 * @param {String} accountId Account Id
 * @returns {Boolean} `true` when cloud store access is restricted for current user.
 */
export const cloudStoreAccessRestricted = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  (state, accountId) => firestoreRedux.selectors.doc(state, 'cloud-store-access-restricted', `csar_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (currentUserId, doc) => {
    return get(doc, 'userIds', []).includes(currentUserId);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

export const preferredStoreSelectionDone = selectorCreator.reselct.createSelector(
  auth.selectors.currentUserId,
  (state, accountId) => firestoreRedux.selectors.doc(state, 'account-cloud-stores', `acs_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (userId, doc) => {
    const _preferredStoreSelectionDone = get(doc, `preferredStoreSelectionDone`);

    if(_preferredStoreSelectionDone !== false) {
      return true;
    }

    const ownerId = get(doc, `ownerId`);
    if(!userId || !ownerId) {
      return true;
    }

    return !(userId == ownerId);
  },
  {
    memoizeOptions: {
      maxSize: 50
    }
  }
);

/**
 * @param {Object} state Redux State.
 * @returns {String} file store change preferred store status.
 */
export const changePreferredStoreStatus = (state) => get(state, `file-store-preferred-store-selection.changePreferredStore.status`);

/**
 * @param {Object} state Redux State`
 * @returns {String} file store change preferred store failed reason.
 */
export const changePreferredStoreError = (state) => get(state, `file-store-preferred-store-selection.changePreferredStore.error`);

/**
 * @param {Object} state Redux State`
 * @returns {String} file store change preferred store  service.
 */
export const changePreferredStoreService = (state) => get(state, `file-store-preferred-store-selection.changePreferredStore.service`);

/**
 * @returns {Array} current user microsoft accessible accounts.
 */
export const microsoftAccessibleAccounts = selectorCreator.default({maxSize: 20})(
  (state) => auth.selectors.accessibleAccountsDetails(state),
  (accessibleAccountsDetails) => {
    let accounts = [];
    forEach(accessibleAccountsDetails, (account) => {
      if(account && account.fileStore && account.fileStore.preferredStore === 'MICROSOFT') {
        accounts.push(account.id);
      }
    });

    return accounts;
  }
);