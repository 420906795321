export default class Permission {
  constructor(strPermission) {
    this.NONE = 'NONE';
    this.READ = 'READ';
    this.WRITE = 'WRITE';
    this.ADMIN = 'ADMIN';
    this.OWNER = 'OWNER';

    this.validPermissions = [this.NONE, this.READ, this.WRITE, this.ADMIN, this.OWNER];
    if (this.validPermissions.indexOf(strPermission) == -1) {
      throw new Error('Invalid permission ' + strPermission);
    }

    this.permission = strPermission;
  }

  hasRead() {
    return this.permission == this.READ || this.permission == this.WRITE || this.permission == this.ADMIN || this.permission == this.OWNER;
  }

  hasWrite() {
    return this.permission == this.WRITE || this.permission == this.ADMIN || this.permission == this.OWNER;
  }

  hasAdmin() {
    return this.permission == this.ADMIN || this.permission == this.OWNER;
  }

  hasOwner() {
    return this.permission == this.OWNER;
  }

  getPermission() {
    return this.permission;
  }
}