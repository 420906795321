import * as actions from "./actions";
import { ReduxUtils } from '@dw/pwa-helpers/index.js';
import get from 'lodash-es/get';

const INITIAL_STATE = {};

export const subscription = (state = INITIAL_STATE, action) => {
  const computeCharges = get(state, 'computeCharges') || {};
  switch (action.type) {
    case actions.REFUND_REQUEST:
      return ReduxUtils.replace(state, `refundRequest`, { status: 'IN_PROGRESS', error: undefined });
    case actions.REFUND_REQUEST_FAILED:
      return ReduxUtils.replace(state, `refundRequest`, { status: 'FAILED', error: action.error });
    case actions.REFUND_REQUEST_SUCCESS:
      return ReduxUtils.replace(state, `refundRequest`, { status: 'SUCCESS', error: undefined });
    case actions.UPDATE_PENDING_INVOICES_COUNT:
      return ReduxUtils.replace(state, `pendingInvoiceCount`, action.count);
    case actions.UPDATE_PUBLIC_DOMAIN:
      return ReduxUtils.replace(state, `publicDomain`, action.value);
    case actions.RESEND_INVOICE:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.resend`, { status: 'IN_PROGRESS', error: undefined });
    case actions.RESEND_INVOICE_FAILED:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.resend`, { status: 'FAILED', error: action.error });
    case actions.RESEND_INVOICE_SUCCESS:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.resend`, { status: 'SUCCESS', error: undefined });
    case actions.PAY_ONLINE_INVOICE:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.payOnline`, { status: 'IN_PROGRESS', error: undefined });
    case actions.PAY_ONLINE_INVOICE_FAILED:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.payOnline`, { status: 'FAILED', error: action.error });
    case actions.PAY_ONLINE_INVOICE_SUCCESS:
      return ReduxUtils.replace(state, `invoices.${action.invoiceId}.payOnline`, { status: 'SUCCESS', error: undefined });
    case actions.UPDATE_BILLING_ADDRESS:
      return ReduxUtils.replace(state, `changeBillingAddress`, { status: 'IN_PROGRESS', error: undefined });
    case actions.UPDATE_BILLING_ADDRESS_FAILED:
      return ReduxUtils.replace(state, `changeBillingAddress`, { status: 'FAILED', error: action.error });
    case actions.UPDATE_BILLING_ADDRESS_SUCCESS:
      return ReduxUtils.replace(state, `changeBillingAddress`, { status: 'SUCCESS', error: undefined });
    case actions.CHANGE_PLAN:
      return ReduxUtils.replace(state, `changePlan`, { status: 'IN_PROGRESS', error: undefined });
    case actions.CHANGE_PLAN_FAILED:
      return ReduxUtils.replace(state, `changePlan`, { status: 'FAILED', error: action.error, message: action.message });
    case actions.CHANGE_PLAN_SUCCESS:
      return ReduxUtils.replace(state, `changePlan`, { status: 'SUCCESS', error: undefined, offlineInvoicePaymentDuedate: action.offlineInvoicePaymentDuedate });
    case actions.RESET_CHANGE_PLAN_DATA:
      return ReduxUtils.replace(state, `changePlan`, undefined);
    case actions.REQUEST_NON_PROFIT_PLAN:
      return ReduxUtils.replace(state, `requestNonprofitePlan`, { status: 'IN_PROGRESS', error: undefined });
    case actions.REQUEST_NON_PROFIT_PLAN_FAILED:
      return ReduxUtils.replace(state, `requestNonprofitePlan`, { status: 'FAILED', error: action.error });
    case actions.REQUEST_NON_PROFIT_PLAN_SUCCESS:
      return ReduxUtils.replace(state, `requestNonprofitePlan`, { status: 'SUCCESS', error: undefined });
    case actions.RESET_REQUEST_NON_PROFIT_PLAN_DATA:
      return ReduxUtils.replace(state, `requestNonprofitePlan`, undefined);
    case actions.COMPUTE_CHARGES:
      return ReduxUtils.replace(state, `computeCharges`, {...computeCharges, ...{ status: 'IN_PROGRESS', error: undefined, type: action.subscriptionsType, downgrade: action.downgrade }});
    case actions.COMPUTE_CHARGES_FAILED:
      return ReduxUtils.replace(state, `computeCharges`, {...computeCharges, ...{ status: 'FAILED', error: action.error}});
    case actions.COMPUTE_CHARGES_SUCCESS:
      return ReduxUtils.replace(state, `computeCharges`, {...computeCharges, ...{ status: 'SUCCESS', error: undefined, subscriptions:action.subscriptions, expirationDate: action.expirationDate, paymentAmount: action.paymentAmount, taxAmount: action.taxAmount }});
    case actions.RESET_COMPUTE_CHARGES_DATA:
      return ReduxUtils.replace(state, `computeCharges`, undefined);
    case actions.UPDATE_MANAGE_TEAM_PENDING_ACTION_DATA: 
      return ReduxUtils.replace(state, `mangeTeamPendingAction`, action.data);
    case actions.RESET_MANAGE_TEAM_PENDING_ACTION_DATA: 
      return ReduxUtils.replace(state, `mangeTeamPendingAction`, undefined);
    case actions.REMOVE_SUBSCRIPTIONS:
    case actions.ADD_SUBSCRIPTIONS:
    case actions.RENEW:
      return ReduxUtils.replace(state, `changeSubsciptions`, { status: 'IN_PROGRESS', error: undefined });
    case actions.REMOVE_SUBSCRIPTIONS_SUCCESS:
    case actions.ADD_SUBSCRIPTIONS_SUCCESS: 
    case actions.RENEW_SUCCESS:
      return ReduxUtils.replace(state, `changeSubsciptions`, { status: 'SUCCESS', error: undefined, offlineInvoicePaymentDuedate: action.offlineInvoicePaymentDuedate });
    case actions.REMOVE_SUBSCRIPTIONS_FAILED:
    case actions.ADD_SUBSCRIPTIONS_FAILED:
    case actions.RENEW_FAILED:
      return ReduxUtils.replace(state, `changeSubsciptions`, { status: 'FAILED', error: action.error, message: action.message });
    case actions.RESET_CHANGE_SUBSCRIPTIONS_DATA:
      return ReduxUtils.replace(state, `changeSubsciptions`, undefined);
    case actions.MESSAGE_MARK_AS_KNOWN:
      return ReduxUtils.replace(state, `messages.${action.accountId}.${action.name}.known`, true);
    default:
      return state;
  }
};