import get from 'lodash-es/get.js';

/**
 * @param {Object} state redux state
 * @returns {String} current status of forgot password.
 */
export const status = (state) => get(state, 'forgot-password.status');

/**
 * @param {Object} state redux state
 * @returns {String} forgot password process failed reason.
 */
export const error = (state) => get(state, 'forgot-password.error');

/**
 * @param {Object} state redux state
 * @returns {String} forgot password process Id.
 */
export const requesterId = (state) => get(state, 'forgot-password.requesterId');