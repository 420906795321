import Clipboard from '@migrate-to-esm/clipboard/src/clipboard';

/**
 * It is module to copy text into clipboard.
 * 
 * USAGE PATTERN: 
 *  import { clipboard } from './clip-board';
 *  clipboard.copy(text);
 * 
 */
class KerikaClipboard {
  copy(text) {
    const button = document.createElement('button');
    button.setAttribute('data-clipboard-text', text);
    new Clipboard(button);
    button.click();
  }
}

export const clipboard = new KerikaClipboard();

export default clipboard;