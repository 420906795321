import moment from 'moment/src/moment';

export const getTodayStartTime = () => window.parseInt(moment().startOf('day').format('x')) || null;
export const getTodayEndTime = () => window.parseInt(moment().endOf('day').format('x')) || null;

export const getTommorrowStartTime = () => window.parseInt(moment().add(1, 'd').startOf('day').format('x')) || null;
export const getTommorrowEndTime = () => window.parseInt(moment().add(1, 'd').endOf('day').format('x')) || null;

export const getThisWeekStartTime = () => window.parseInt(moment().startOf('week').format('x')) || null;
export const getThisWeekEndTime = () => window.parseInt(moment().endOf('week').format('x')) || null;

export const getNextWeekStartTime = () => window.parseInt(moment().add(1, 'w').startOf('week').format('x')) || null;
export const getNextWeekEndTime = () => window.parseInt(moment().add(1, 'w').endOf('week').format('x')) || null;

export const getLastWeekStartTime = () => window.parseInt(moment().subtract(1, 'w').startOf('week').format('x')) || null;
export const getLastWeekEndTime = () => window.parseInt(moment().subtract(1, 'w').endOf('week').format('x')) || null;

export const getThisMonthStartTime = () => window.parseInt(moment().startOf('month').format('x')) || null;
export const getThisMonthEndTime = () => window.parseInt(moment().endOf('month').format('x')) || null;

export const getNextMonthStartTime = () => window.parseInt(moment().add(1, 'M').startOf('month').format('x')) || null;
export const getNextMonthEndTime = () => window.parseInt(moment().add(1, 'M').endOf('month').format('x')) || null;

export const getLastMonthStartTime = () => window.parseInt(moment().subtract(1, 'M').startOf('month').format('x')) || null;
export const getLastMonthEndTime = () => window.parseInt(moment().subtract(1, 'M').endOf('month').format('x')) || null;

export const getThisQuartersStartTime = () => window.parseInt(moment().startOf('quarter').format('x')) || null;
export const getThisQuartersEndTime = () => window.parseInt(moment().endOf('quarter').format('x')) || null;

export const getNextQuartersStartTime = () => window.parseInt(moment().add(1, 'Q').startOf('quarter').format('x')) || null;
export const getNextQuartersEndTime = () => window.parseInt(moment().add(1, 'Q').endOf('quarter').format('x')) || null;

export const getLastQuartersStartTime = () => window.parseInt(moment().subtract(1, 'Q').startOf('quarter').format('x')) || null;
export const getLastQuartersEndTime = () => window.parseInt(moment().subtract(1, 'Q').endOf('quarter').format('x')) || null;

export const getDiffInMinutes = (timestamp) => moment().diff(timestamp, 'minutes');