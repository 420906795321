import URI from '@dw/urijs-esm';
import * as InAppBrowser from '../cordova-plugins/in-app-browser.js';

/**
 * Provides way to login in oauth2 based on given `service`.
 * If the service is `google` or `box` or `microsoft` then login using child window.
 * If the service is `kerika` then login using iframe.
 * @param {String} service give service to login into oauth2.
 * @param {Boolean} linkAdditionalAuth `true` when request is to link additional auth.
 * @param {Boolean} autoLogin `true` when system detect auto login for user.
 * @param {Boolean} installedApp `true` when user login through a installed app.
 * @returns {Promise} Resolved promise when login successfully done
 *                    Otherwise reject with errorCode.
 */
export const oauth2Login = ({service, linkAdditionalAuth = false, autoLogin = false, embedLogin = false, installedApp = false, successURL = ''}) => {
  if(autoLogin && (service == 'google' || service == 'box' || service == 'microsoft')) {
    return doLoginInMaindWindow(service, linkAdditionalAuth, installedApp, successURL);
  }

  if (service == 'google' || service == 'box' || service == 'microsoft') {
    return doLoginInChildWindow(service, linkAdditionalAuth, embedLogin, installedApp, successURL);
  } else {
    return doLoginUsingIframe(service, linkAdditionalAuth, embedLogin, installedApp, successURL);
  }
}

/**
 * Iframe timeout in miliseconds.
 */
const TIMEOUT = 30000;

/**
 * Login using Iframe.
 */
const doLoginUsingIframe = (service, linkAdditionalAuth, embedLogin, installedApp, successURL) => {
  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });

  //create iframe
  let iframe = document.createElement('iframe');
  iframe.id = "authLoginIframe";
  iframe.src = getServiceLoginUrl(service);
  iframe.style.width = 0;
  iframe.style.height = 0;
  iframe.style.borderWidth = 0;
  iframe.style.borderStyle = 'none';

  let timeoutHandle = window.setTimeout(() => {
    document.body.removeChild(iframe);
    window.removeEventListener('message', listener);
    reject({code: 'TIMEOUT' });
  }, TIMEOUT);

  // listen for the AUTH_SUCCESSFULLY_COMPLETED and  AUTH_LOGIN_FAILED event (which will be triggered by the iframe)
  const listener = (e) => {
    let type = e.data && e.data.type;
    if (type === 'AUTH_SUCCESSFULLY_COMPLETED' || type === 'AUTH_LOGIN_FAILED') {
      type === 'AUTH_SUCCESSFULLY_COMPLETED' ? resolve(e.data) : reject({code: e.data.error});
      window.clearTimeout(timeoutHandle);
      document.body.removeChild(iframe);
      window.removeEventListener('message', listener);
    }
  };

  window.addEventListener('message', listener);

  //append iframe to the body, so it starts working
  document.body.appendChild(iframe);
  return promise;
}

let __childWindow;
/**
 * Login using child window.
 * @param {String} service Auth service. Possible values: 'gogle' or 'box' or 'microsoft'
 * @param {Boolean} linkAdditionalAuth `true` when request is to link additional auth.
 * @returns
 */
const doLoginInChildWindow = async(service, linkAdditionalAuth, embedLogin, installedApp, successURL) => {
  if(installedApp) {
    return installedAppChildWindowLogin(service, linkAdditionalAuth, installedApp, successURL);
  }

  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  let serviceLoginUrl = getServiceLoginUrl(service, linkAdditionalAuth, successURL);

  let innerWidth = embedLogin ? window.screen && window.screen.width || window.innerWidth: window.innerWidth;
  let innerHeight = embedLogin ? window.screen && window.screen.height || window.innerHeight: window.innerHeight;
  let width = innerWidth > 767 ? 400 : innerWidth;
  let height = innerWidth > 767 ? 500 : innerHeight;
  let left = innerWidth > 767 ? ((screen.width - width) / 2) : 0;
  let top = innerWidth > 767 ? ((screen.height - height) / 2) : 0;
  __childWindow && __childWindow.close && __childWindow.close();
  __childWindow = window.open(serviceLoginUrl, "", "titlebar=no, width=" + width + ", height=" + height + ", left=" + left + ", top=" + top + ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, directories=no, status=no");

  let timer = setInterval(checkChild, 1000);
  function checkChild() {
    if (!__childWindow || __childWindow.closed) {
      reject({code: 'CANCELED'});
      clearInterval(timer);
    }
  }

  /**
   * Listens for AUTH_SUCCESSFULLY_COMPLETED, AUTH_LOGIN_FAILED, `AUTH_IDENTITY_SUCCESS` & `AUTH_IDENTITY_FAILED` events (which will be triggered by the child window)
   * @param {Object} e Event details.
   */
  const listener = (e) => {
    let type = e.data && e.data.type;
    if (type === 'AUTH_SUCCESSFULLY_COMPLETED' || type === 'AUTH_LOGIN_FAILED' ||
        type === 'AUTH_IDENTITY_SUCCESS' || type === 'AUTH_IDENTITY_FAILED') {
      clearInterval(timer);
      window.removeEventListener('message', listener);
      type === 'AUTH_SUCCESSFULLY_COMPLETED' || type === 'AUTH_IDENTITY_SUCCESS' ? resolve(e.data) : reject({code: e.data.error});
    }
  };

  window.addEventListener('message', listener);
  return promise;
}

const getMessageData = (url, linkAdditionalAuth) => {
  const uri = new URI(url);
  const _queryParams = uri.query(true);
  const error = _queryParams.error || null;
  if(!linkAdditionalAuth) {
    return {
      success: !error,
      type: error ? 'AUTH_LOGIN_FAILED' : 'AUTH_SUCCESSFULLY_COMPLETED',
      service: _queryParams.service || null,
      error,
      signup: _queryParams.signup === 'true' || false,
      invited: _queryParams.invited === 'true' || false,
      actualService: _queryParams['actual-service'] || null,
      authFailed: status == 'ok' ? false: true
    }
  }
  const status = _queryParams.status || null;
  return {
    success: status == 'ok',
    status,
    error: status !== 'ok' ? error || status: null,
    type: (status == 'ok') ? 'AUTH_IDENTITY_SUCCESS' : 'AUTH_IDENTITY_FAILED',
    authFailed: status == 'ok' ? false: true
  }
}

const isFallBackPage = (url, linkAdditionalAuth) => {
  const fallbackPageUrl = getFallbackPageUrl(linkAdditionalAuth);
  if(!fallbackPageUrl || !url || !url.startsWith) {
    return false;
  }
  return url.startsWith(fallbackPageUrl);
}

const installedAppChildWindowLogin = async (service, linkAdditionalAuth, installedApp, successURL) => {
  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  let serviceLoginUrl = getServiceLoginUrl(service, linkAdditionalAuth, successURL);
  const loadstop = (params) => {
    if (__childWindow != undefined) {
      const url = params && params.url || '';
      if(isFallBackPage(url || '', linkAdditionalAuth)) {
        const data = getMessageData(url, linkAdditionalAuth);
        __childWindow && __childWindow.close && __childWindow.close();
        data && data.success ? resolve(data) : reject({code: data.error});
      }
      __childWindow && __childWindow.show && __childWindow.show();
    }
  }
  const beforeload = (params, callback)=> {callback(params.url)};
  const exitListener = (e) => {reject({code: 'CANCELED'})};

  try {
    __childWindow = await InAppBrowser.open(serviceLoginUrl, '_blank', 'location=yes,hidden=yes,beforeload=yes,hidenavigationbuttons=yes,hideurlbar=yes,toolbarcolor=#ffffff');
    __childWindow.addEventListener('loadstop', loadstop);
    __childWindow.addEventListener('beforeload', beforeload);
    __childWindow.addEventListener('exit', exitListener);
  } catch (error) {
    return doLoginInMaindWindow(service, linkAdditionalAuth, installedApp, successURL);
  }
  return promise;
}

/**
 * Login using main window.
 * @param {String} service Auth service. Possible values: 'gogle' or 'box' or 'microsoft'
 * @param {Boolean} linkAdditionalAuth `true` when request is to link additional auth.
 * @returns
 */
const doLoginInMaindWindow = (service, linkAdditionalAuth, installedApp, successURL) => {
  const redirectURL = successURL ? `${window.K.config.webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(getRedirectUrl(service, linkAdditionalAuth, successURL))}`: `${window.K.config.webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(window.location.href)}`;
  if (!linkAdditionalAuth) {
    window.location.href = `${window.K.config.apiBaseUrl}/user/login?s=${service}&redirect=${encodeURIComponent(redirectURL)}`;
  } else {
    window.location.href = `${window.K.config.apiBaseUrl}/user/link-auth?s=${service}&redirect=${encodeURIComponent(redirectURL)}`;
  }
}

/**
 * @param {String} service
 * @param {Boolean} linkAdditionalAuth
 * @returns {String} Login URL based on given service & linkAdditionalAuth.
 */
const getServiceLoginUrl = (service, linkAdditionalAuth, successURL) => {
  if (linkAdditionalAuth) {
    return `${window.K.config.apiBaseUrl}/user/link-auth?s=${service}&redirect=${encodeURIComponent(getRedirectUrl(service, linkAdditionalAuth, successURL))}`;;
  }
  return `${window.K.config.apiBaseUrl}/user/login?s=${service}&redirect=${encodeURIComponent(getRedirectUrl(service, linkAdditionalAuth, successURL))}`;;
}

/**
 * @param {String} service
 * @param {Boolean} linkAdditionalAth `true` when request is for link additional auth for cloud store.
 * @returns {String} Redirect fallback page url. Is is based on the given service & linkAdditionalAuth.
 */
const getRedirectUrl = (service, linkAdditionalAuth, successURL) => {
  const realRedirectURL = `${getFallbackPageUrl(linkAdditionalAuth)}?service=${service}`;
  if (!linkAdditionalAuth) {
    return `${window.K.config.websiteBaseUrl}/redirect.html?redirect=${encodeURIComponent(successURL || realRedirectURL)}`;
  }
  return successURL || realRedirectURL;
}

const getFallbackPageUrl = (linkAdditionalAuth) => {
  if(linkAdditionalAuth) {
    return `${window.location.protocol}//${window.location.host}/auth-identity-fallback.html`;
  }
  return `${window.location.protocol}//${window.location.host}/social-media-login-fallback.html`;
}

export default oauth2Login;