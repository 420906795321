export const UPDATE_PROPERTIES = 'USER_UPDATE_PROPERTIES';
export const UNSUBSCRIBE_TASK_SUMMARY = 'USER_UNSUBSCRIBE_TASK_SUMMARY';

/**
 * Update `user-properties`.
 * @param {Object} properties 
 */
export const updateProperties = (properties) => {
  return {
    type: UPDATE_PROPERTIES,
    properties
  }
}

/**
 * Dispatch this action when unsubscribe page is opened
 */
export const unsubscribeTasksummary = (token) => {
  return {
    type: UNSUBSCRIBE_TASK_SUMMARY,
    token
  }
}