import { put, takeEvery, call, select, all, cancel } from 'redux-saga/effects';
import * as actions from './actions.js';
import * as router from '../router/index.js';
import * as board from '../board';
import { requestApi, isNetworkError } from "../../request-api.js";

/**
 * Load board summary data.
 */
function* load({ boardId }) {
  const state = yield select();
  const boardAttrs = board.selectors.attrs(state, boardId);
  const privatePage = router.selectors.privatePage(state) || boardAttrs === null;
  if (privatePage || !boardId) {
    console.debug("board summary > load > board is private.");
    return;
  }

  try {
    const summary = yield call(requestApi, `/card/cards/board-summary?boardId=${boardId}`, {excludeErrors: [403]});
    yield put({ type: actions.LOAD_BOARD_SUMMARY_DONE, summary, id: boardId });
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }
    console.error("load bord summary is failed due to: ", error);
  }
}

/**
 * Loads column wise cards count.
 * @param {*} param0
 *  @property {String} boardId Board ID
 */
function* loadColumnSummary({ boardId }) {
  try {
    const summary = yield call(requestApi, `/card/cards/column-summary?boardId=${boardId}`, {excludeErrors: [403]});
    yield put({ type: actions.LOAD_COLUMN_SUMMARY_DONE, summary, id: boardId });
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }
    console.error("load column summary is failed due to: ", error);
  }
}

/**
 * Init Saga.
 */
function* boardSummarySaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, load),
    takeEvery(actions.LOAD_COLUMN_SUMMARY, loadColumnSummary)
  ]);
}

export default boardSummarySaga;