import { eventChannel } from 'redux-saga';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { getIdWoPrefix, setCookieVal } from '../../utils.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import requestApi from '../../request-api.js';
import { changeI18NextLanguage } from '../../components/i18next.js';

import get from 'lodash-es/get.js';
import merge from 'lodash-es/merge.js';

import * as auth from '../auth';
import * as selectors from './selectors.js';
import * as actions from './actions.js';

function* init() {
  const storageEventChannel = eventChannel((emitter) => {
    window && window.addEventListener('storage', emitter);
    return () => window && window.removeEventListener('storage', emitter);
  });

  const cookieChangeChannel = eventChannel((emitter) => {
    try {
      cookieStore && cookieStore.addEventListener('change', emitter);
      return () => cookieStore && cookieStore.addEventListener('change', emitter);
    } catch(error) {
    }
  });

  yield all([
    yield takeEvery(storageEventChannel, storageChanged),
    yield takeEvery(cookieChangeChannel, cookieChanged),
  ]);
}

function* storageChanged(event) {
  if (get(event, 'key') == 'lang') {
    yield put(actions.changeLanguage(get(event, 'newValue')));
  }
}

function* cookieChanged(event) {
  if (get(event, 'name') == 'lang') {
    yield put(actions.changeLanguage(get(event, 'value')));
  }

  if (get(event, 'name') == 'SESSION') {
    yield call(loadUserPreference);
  }
}

function* changeLanguage(params) {
  const lang = params && params.lang;
  if (!lang) {
    return;
  }

  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `up_${getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
  const localWritePath = `users/${userId}/user-preferences`;
  const currentPreferenceDoc = selectors.userPreferences(state);
  try {
    const newPreferenceDoc = merge({}, currentPreferenceDoc, { lang, id, userId, presentLangSelection: false });
    changeI18NextLanguage(lang);

    //lang value set as a preferred language of login user.
    if (userId) {
      firestoreRedux.save(localWritePath, newPreferenceDoc, { localWrite: true });
      if(!impersonatedUser) {
        yield requestApi('/user/users/me/user-preference', { method: 'PATCH', excludeError: [502, 401], body: { lang, presentLangSelection: false } });
      }
    }

    yield call(langSelect, lang);
    yield put(actions._changeLanguageSuccess(lang));
  } catch (error) {
    if(userId) {
      firestoreRedux.save(localWritePath, currentPreferenceDoc, { localWrite: true });
    }
    yield put(actions._changeLanguageFailed(lang, (error && error.code) || 'UNKNOWN'));
  }
}

function* langSelect(lang) {
  const time = Date.now();
  const domain = window.location.host.replace(/^a2?\./, '')
  setCookieVal('lang', lang, `.${domain}`, 365);
  setCookieVal('lang-select', true, `.${domain}`, 365);
  setCookieVal('lang-selecttion-time', time, `.${domain}`, 365);
  localStorage && localStorage.setItem && localStorage.setItem('lang', lang);
  localStorage && localStorage.setItem && localStorage.setItem('lang-select', true);
  localStorage && localStorage.setItem && localStorage.setItem('lang-selecttion-time', time);
}

function* loadData() {
  try {
    //Alredy auth module load this details so, here not loaded repeated.

    // firestoreRedux.getDocById(`system-language`, `default`);
    // firestoreRedux.getDocById('beta-users', 'bus_0');
    // yield call(loadUserPreference);
  } catch (error) {
    console.error('multple-language > loadData > failed due to this: ', error);
  }
}

function* loadUserPreference() {
  try {
    const state = yield select();
    const userId = auth.selectors.currentUserId(state);
    if (!userId) {
      return;
    }

    const id = `up_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`;
    const userPreferencesReq = firestoreRedux.getDocById(`users/${userId}/user-preferences`, id);
    yield userPreferencesReq.result;
  } catch (error) {
    console.error('multple-language > loadUserPreference > failed due to this: ', error);
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(init),
    yield takeEvery(actions.LOAD_DATA, loadData),
    yield takeEvery(actions.CHANGE_LANGUAGE, changeLanguage)
  ]);
}

export default saga;
