import TimeMe from './timeme.js';
import { requestApi, isNetworkError } from '../../request-api';
import { store } from '../../store.js';
import get from 'lodash-es/get';

import * as app from '../../redux/app';
import * as device from '../../redux/device';
import * as user from '../../redux/user';

let updateLastSeenID;

/**
 * Update user last seen time using server API.
 */
async function updateUserLastSeen () {
  try {
    const id = device.selectors.getId();
    const state = store.getState();
    const bowser = get(state, `app.bowser`);

    const type = app.selectors.isMobileDevice(state) ? 'MOBILE' : app.selectors.isTabletDevice(state) ? 'TABLET' : 'DESKTOP';
    const os = bowser.os.name;
    const osVersion = bowser.os.version;
    let browser = bowser.browser.name.toLowerCase();
    browser = browser.includes('chrome')
      ? 'CHROME'
      : browser.includes('firefox')
      ? 'FIREFOX'
      : browser.includes('safari')
      ? 'SAFARI'
      : browser.includes('edge')
      ? 'EDGE'
      : 'OTHER';
    const browserVersion = bowser.browser.version;
    const pwaVersion = app.selectors.currentVersion(state);
    const appVersion = app.installedApp.selectors.version(state);
    const activityDuration = getActivityDuration();
    const body = { id, type, os, osVersion, browser, browserVersion, pwaVersion, appVersion, activityDuration };
    await requestApi('/user/users/me/last-seen', { method: 'PUT', body, excludeErrors: [502, 503, 400, 401, 403, 404] });
  } catch (error) {
    if(isNetworkError(error)) {
      return;
    }

    const code = error && error.code || 'UNKNOWN';
    if(code === 'NOT_ALLOWED_CONTINUES_CALL' || code === 'UNDER_MAINTENANCE' || code === 'ACCESS_COOKIE_NOT_AVAILABLE') {
      return;
    }
    console.error("updateUserLastSeen > failed due to this: ", error);
  }
}

export const startTrackFirstSessionTime = (force) => {
  const isUserCurrentlyOnPage = TimeMe && TimeMe.isUserCurrentlyOnPage;
  if(isUserCurrentlyOnPage || force) {
    TimeMe && TimeMe.initialize({currentPageName: 'first-session-time', idleTimeoutInSeconds: 5});
  }
}

export const stopTrackFirstSessionTime = () => {
  TimeMe && TimeMe.stopTimer('first-session-time');
}

export const getFirstSessionTimeOnPageInSeconds = () => {
  let duration = TimeMe && TimeMe.getTimeOnPageInSeconds('first-session-time') || 0;
  TimeMe && TimeMe.resetRecordedPageTime('first-session-time');
  if(!duration) {
    return Math.floor(duration);
  }

  return Math.floor(Math.ceil(duration / 5) * 5);
}

function documentVisibilityChange() {
  const visibility = document.visibilityState;
  if(visibility === 'visible') {
    TimeMe.isUserCurrentlyOnPage = true;
    startTrackFirstSessionTime();
  }
  if(visibility === 'hidden') {
    TimeMe.isUserCurrentlyOnPage = false;
    stopTrackFirstSessionTime();
  }
}

function getActivityDuration() {
  const state = store.getState();
  if(user.selectors.firstSessionEnd(state)) {
    stopTrackFirstSessionTime();
    document.removeEventListener("visibilitychange", documentVisibilityChange);
    return 0;
  }
  
  document.removeEventListener("visibilitychange", documentVisibilityChange);
  document.addEventListener("visibilitychange", documentVisibilityChange);
  const duration = getFirstSessionTimeOnPageInSeconds();
  stopTrackFirstSessionTime();
  startTrackFirstSessionTime();
  return duration;
}

/**
 * Start update user last seen time scheduler every `lastSeenPushIntervalInMins` minutes.
 */
export const startLastSeenUpdateScheduler = () => {
  stopLastSeenUpdateScheduler();
  updateUserLastSeen();

  const state = store.getState();
  const config = app.selectors.config(state);
  const lastSeenPushIntervalInSeconds = config && config.lastSeenPushIntervalInSeconds || 30;
  const lastSeenPushIntervalInSecondsForFreshUser = config && config.lastSeenPushIntervalInSecondsForFreshUser || 10;
  const intervalSeconds = user.selectors.firstSessionEnd(state) ? lastSeenPushIntervalInSeconds: lastSeenPushIntervalInSecondsForFreshUser;
  updateLastSeenID = setInterval(updateUserLastSeen, intervalSeconds * 1000);
};

/**
 * Stop update user last seen time scheduler.
 */
export const stopLastSeenUpdateScheduler = (trackLastSeen = false) => {
  stopTrackFirstSessionTime();
  updateLastSeenID && clearInterval(updateLastSeenID);
  trackLastSeen && updateUserLastSeen();
};