import URI from '@dw/urijs-esm';
import * as router from '../redux/router';

/**
 * Provides a way to manage history for dialog.
 * 
 * ## Behaviour
 * - When `queryParamValue` is define 
 *  - If Dialog is opened and queryParamValue is not set as action then set action query params as a `queryParamValue` property using `setQueryParams` method of router.
 *  - If Dialog is closed and action query params is equal to `queryParamValue` then history back.
 */
export const DialogHistoryManager = (baseElement) => class extends baseElement {
  constructor(){
    super();
    this.queryParamValue = '';
    this.queryParamName = 'action';
  }

  static get properties() {
    return {

      /**
       * value of query param on which dialog should be opened.
       */
      queryParamValue: {type: String},

      /**
       * Name of query param, on which dialog should be opened.
       */
      queryParamName: { type: String }

    }
  }

  /**
   * When `queryParamValue` is define 
   *  If Dialog is opened and queryParamValue is not set as action then set action query params as a `queryParamValue` property using `setQueryParams` method of router.
   *  If Dialog is closed and action query params is equal to `queryParamValue` then history back.
   * @public
   */
  dialogHistoryManage() {
    if(!this.queryParamValue) {
      return;
    }

    const uri = new URI();
    const queryParams = uri.query(true) || [];
    const action = queryParams[this.queryParamName];

    if (this.opened && action != this.queryParamValue) {
      router.actions.setQueryParams({ [this.queryParamName]: this.queryParamValue }, true);
      return;
    }

    if (!this.opened && action == this.queryParamValue) {
      router.actions.back();
    }
  }
}