import * as actions from './actions';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';


const INITIAL_STATE = {};

export const zoomOutViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return ReduxUtils.replace(state, `${action.pageType === 'VIEW' ? 'views': 'boards'}.${action.pageId}`, action.zoomout ? true: undefined);
    case actions.RESET:
      return {};
    default:
      return state;
  }
};
