import { LitElement, html, css } from 'lit-element';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';

import '../kerika-ripple.js';
import '../kerika-icon.js';

/**
 * Guide: https://m3.material.io/components/chips/guidelines#11bc122b-5203-4cf5-bb12-470d86ecd3b5
 */
class FilterChipItem extends localize(i18next)(LitElement) {
  static properties = {
    /**
     * Input property.
     * e.g. {value: something-else, name: General}
     */
    item: { type: Object },

    /**
     * Current item is selected or not.
     */
    selected: { type: Boolean, reflect: true, attribute: 'selected' }
  }

  static styles = [
    css`
      :host {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        padding: 0px 12px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid var(--divider-color);
        cursor: pointer;
        position: relative;
        -webkit-transition: width 20s, transform 20s;
        -moz-transition: width 20s, transform 20s;
        -o-transition: width 20s, transform 20s;
        transition: width 20s, transform 20s;
        --kerika-ripple-border-radius: 7px;
        background-color:  transparent;
      }

      :host([selected]) {
        cursor: default;
        pointer-events: none;
        background-color: rgb(0, 128, 0, 0.12);
        border: 1px solid transparent;
      }

      kerika-icon {
        padding: 0px 8px;
      }
    `
  ]

  connectedCallback() {
    super.connectedCallback && super.connectedCallback();
    this.updateComplete.then(() => {
      window.requestAnimationFrame(() => {
        this.__dispatchConnectedChangedEvent(true);
      });
    });
  }

  render(){
    return html`
      <!-- ${this.selected ? html`<kerika-icon .name=${'done'} .size=${18}></kerika-icon>`: ''} -->
      ${this.item.name}
      ${this.selected ? '': html`<kerika-ripple ></kerika-ripple>`}
    `;
  }

  __dispatchConnectedChangedEvent(value) {
    this.dispatchEvent(new CustomEvent('connected-changed', { detail: { value }, bubbles: false}));
  }

  disconnectedCallback() {
    this.__dispatchConnectedChangedEvent(false);
    super.disconnectedCallback && super.disconnectedCallback();
  }
}
customElements.define('filter-chip-item', FilterChipItem);