/**
 * This is trigger when current user accessible account is changed.
 */
export const ACCESSIBLE_ACCOUNTS_CHANGED = 'ACCESSIBLE_ACCOUNTS_CHANGED';

export const LEAVE_ACCOUNT = "LEAVE_ACCOUNT";
export const LEAVE_ACCOUNT_DONE = "LEAVE_ACCOUNT_DONE";
export const LEAVE_ACCOUNT_FAILED = "LEAVE_ACCOUNT_DONE_FAILED";

/**
 * Dispatch action to leave account team.
 */
export const leaveAccount = (accountId) => {
  return {
    type: LEAVE_ACCOUNT,
    accountId
  }
}

export const _leaveAccountDone = (accountId) => {
  return {
    type: LEAVE_ACCOUNT_DONE,
    accountId
  }
}

export const _leaveAccountFailed = (accountId, error) => {
  return {
    type: LEAVE_ACCOUNT_FAILED,
    accountId,
    error
  }
}