

import {LitElement, html, css, unsafeCSS } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin.js';
import { store } from '../../store.js';
import i18next from '@dw/i18next-esm/index.js';
import localize from '@dw/pwa-helpers/localize.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import { typography } from '@dreamworld/material-styles/typography.js';
import { validateEmail } from '../../utils.js';
import typographyLiterals from '../../theme/typography-literals.js';
import * as app from '../../redux/app/index.js';
import * as auth from '../../redux/auth/index.js';
import * as router from '../../redux/router/index.js';
import * as amplitude from '../../analytics/amplitude.js';
import * as linkedIn from '../../analytics/linkedin.js';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import '../common/kerika-button.js';
import '../kerika-input.js';
import '../kerika-loader.js';

/**
 * This element provides way to signup into application with direct email.
 * It's connected element.
 *
 * Behavior:
 *  - Rendering:
 *    - Shows  `First Name`, `Last Name` ,`Email`, `Password`, `Confirm password`, `Forgot Password?` link and `SIGNUP` button
 *    - On choose 'SIGNUP'
 *      - If all feild is valid then
 *        - If join secret email is found then dispatch action to verify oauth user.
 *        - Otherwise dispatch ation to send confarmation.
 *      - Shows loader instead of sinup and forgot password buttons.
 */

class KerikaSignupWithDirectEmail extends connect(store)(localize(i18next)(LitElement)) {
  static get styles() {
    return [
      sharedStyles,
      typography,
      css`
        :host {
          display: block;
          ${unsafeCSS(typographyLiterals.body1)};
          --kerika-button-height: 44px;
        }

        .user-name, .user-password {
          display: flex;
        }

        .user-name kerika-input, .user-password kerika-input {
          width: 100%;
        }

        .buttons kerika-button {
          width: 100%;
          margin-top: 24px;
        }

        .buttons {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .join-info {
          text-align: center;
          margin-bottom: 16px;
        }
      `
    ];
  }

  constructor() {
    super();
    this.i18nextNameSpaces = ['login-and-signup'];
  }

  static get properties() {
    return {
      /**
       * Read data from redux
       * Current app is installed app or not.
       */
      _installedApp: { type: Boolean, reflect: true, attribute: 'installed-app' },

      /**
       * User name type on input.
       */
      _name: { type: String },

      /**
       * User email type on email input.
       */
      _email: { type: String },

      /**
       * User password type on password input.
       */
      _password: { type: String },

      /**
       * Join secret email address.
       */
      _joinSecretEmail: { type: String },

      /**
       * Signup failed due this error.
       */
      _signupError: { type: String },

      /**
       * Read data from redux
       * signup service.
       */
      _signupService: { type: String },

      /**
       * Read data from redux
       * login service.
       */
      _loginService: { type: String },

      /**
       * Signup process status.
       */
      _signupStatus: { type: String },

      /**
       * Action state in progress or not.
       */
      _inProgress: { type: Boolean },

      /**
       * Current action. Computed from URL.
       */
      _action: { type: String, reflect: true, attribute: 'action' },

      _userName: { type: String }
    }
  }

  render() {
    return html`
      ${!!this._joinSecretEmail ? html`
        <div class="join-info">${unsafeHTML(i18next.t('login-and-signup:invitedUser.message', {email: this._joinSecretEmail}))}</div>
      `: ''}
      <div class="user-name">
        <kerika-input
          class="first-name"
          required
          showasfilled
          .dense=${true}
          .disabled=${this._inProgress}
          .value=${this._name}
          .errorMessage=${i18next.t('login-and-signup:signup.signupForm.name.errorMessage')}
          .placeholder=${i18next.t('login-and-signup:signup.signupForm.name.label')}
          @value-changed=${(e)=> {
            this._name = e.detail.value;
          }}
          @enter=${this.__onSignup}>
        </kerika-input>
      </div>
      ${!this._joinSecretEmail ? html`
        <kerika-input
          required
          type="email"
          class="email"
          showasfilled
          .dense=${true}
          ?hidden=${!!this._joinSecretEmail}
          .disabled=${this._joinSecretEmail || this._inProgress}
          .value=${this._joinSecretEmail || this._email}
          .errorMessage=${this.__getEmailErrorMessage()}
          .placeholder=${i18next.t('login-and-signup:signup.signupForm.email.label')}
          @value-changed=${(e)=> {
              this._email= e.detail.value;
            }}
          @enter=${this.__onSignup}>
        </kerika-input>
      `: ''}

      <div class="user-password">
        <kerika-input
          class="password"
          type="password"
          required
          showasfilled
          .dense=${true}
          .minLength=${6}
          .pattern=${'^.{6,}$'}
          .value=${this._password}
          .disabled=${this._inProgress}
          .errorMessage=${i18next.t('login-and-signup:signup.signupForm.password.errorMessage')}
          .placeholder=${i18next.t('login-and-signup:signup.signupForm.password.label')}
          @value-changed=${(e)=> {
            this._password= e.detail.value;
          }}
          @enter=${this.__onSignup}>
        </kerika-input>
      </div>

      <div class="buttons">
        ${this._signupInProgress? html`
          <div class="loding-cotainer">
            <kerika-loader></kerika-loader>
          </div>
        `: html`
          <kerika-button  .disabled=${this._inProgress} large outlined @click=${this.__onSignup}>${i18next.t('login-and-signup:signup.signupForm.buttons.signup')}</kerika-button>
        `}
      </div>
    `;
  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('_signupError') && this._signupError) {
      const emailEL = this.renderRoot.querySelector('.email');
      if(this._signupError == 'USER_ALREADY_EXIST' && emailEL) {
        emailEL.invalid = true;
        emailEL.focus && emailEL.focus();
      }
    }

    if((changedProps.has('language') || changedProps.has('_userName')) && this._userName) {
      this._name = this._userName;
    }

    if(changedProps.has('_signupStatus')) {
      if(!this._joinSecretEmail && this._signupService === 'kerika' && this._signupStatus === 'SUCCESS' && changedProps.get('_signupStatus') === 'IN_PROGRESS') {
        this.dispatchEvent(new CustomEvent('accepted-signup', {
          bubbles: true,
          cancelable: true,
          composed: true,
          detail: { }
        }));
        this.__resetForm();
      }
    }
  }

  /**
   * @return {String} error message for email input
   * @private
   */
  __getEmailErrorMessage() {
    if(this._signupError == 'USER_ALREADY_EXIST') {
      return i18next.t('login-and-signup:error.USER_ALREADY_EXIST');
    }
    return i18next.t('login-and-signup:signup.signupForm.email.errorMessage');
  }

  /**
   * @param {String} name
   * @returns {Object} first and last name.
   */
  __splitName(name = '') {
    name = name || '';
    try {
      const [firstName, ...lastName] = name.split(' ').filter(Boolean);
      return {
        firstName,
        lastName: lastName.join(' ')
      }
    } catch (error) {
      const nameSplite = this._name && this._name.split(' ');
      const firstName = nameSplite[0] || '';
      const lastName = nameSplite[1] || '';
      return {
        firstName,
        lastName
      }
    }
  }

  /**
   * Invoked when user click on signup button.
   * @private
   */
  __onSignup() {
    if(!this.__validateAllInput()) {
      return;
    }
    amplitude.logEvent('auth initiated', {service: 'KERIKA', trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
    amplitude.logEvent('signup requested', {trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
    linkedIn.logEvent(6794714);

    const nameSplite = this.__splitName(this._name);
    const firstName = nameSplite && nameSplite.firstName || '';
    const lastName = nameSplite && nameSplite.lastName || '';
    if(this._joinSecretEmail && this._joinSecret) {
      store.dispatch(auth.actions.signupUsingJoinSecret({service: 'kerika', email: this._joinSecretEmail, firstName, lastName, password: this._password, secret: this._joinSecret}));
      return;
    }
    store.dispatch(auth.actions.signup({service: 'kerika', email: this._email, firstName, lastName, password: this._password}));
  }

  __resetForm() {
    this._name = '';
    this._email = '';
    this._password = '';
    this._userName = '';
  }

  /**
   * Validate all input and focus first invalid input.
   * @private
   */
  __validateAllInput() {
    const elements = this.renderRoot.querySelectorAll('kerika-input');
    let focused = false;
    let valid = true;
    forEach(elements, (element)=>{
      let invalid = element && element.validate && element.validate() === false;
      if(invalid && valid) {
        valid = false;
      }

      if(element && element.type === 'email' && !invalid && !validateEmail(element.value)) {
        element.invalid = true;
        invalid = true;
        valid = false;
      }

      if(!focused && invalid) {
        setTimeout(() => {
          element && element.focus && element.focus();
        }, 0);
        focused =  true;
      }
    });

    return valid;
  }

  /**
   * Invoked whenever redux state is changed
   * @param {Object} state - Redux store
   */
  stateChanged(state) {
    this._joinSecret = auth.selectors.joinSecret(state);
    this._joinSecretInfo = auth.selectors.joinSecretInfo(state);
    this._joinSecretStatus = auth.selectors.getJoinSecretStatus(state);
    this._joinSecretEmail = this._joinSecretStatus === 'SUCCESS' ? get(this._joinSecretInfo, 'user.email'): undefined;
    this._userName = this._joinSecretStatus === 'SUCCESS' ? get(this._joinSecretInfo, 'user.name', ''): undefined;
    this._signupError = auth.selectors.signupError(state);
    const loginStatus = auth.selectors.loginStatus(state);
    const signupStatus = auth.selectors.signupStatus(state);
    this._signupService = auth.selectors.signupService(state);
    this._loginService = auth.selectors.loginService(state);
    this._hasInvitedUser = !isEmpty(this._joinSecretInfo);
    this._inProgress = loginStatus === 'IN_PROGRESS' || loginStatus === 'SUCCESS' || signupStatus === 'IN_PROGRESS' || (signupStatus === 'SUCCESS' && this._hasInvitedUser) || (this._signupService && this._signupService !== 'kerika' && signupStatus === 'SUCCESS');
    this._signupStatus = signupStatus;
    const config  = app.selectors.config(state);
    this._websiteBaseUrl = config.websiteBaseUrl;
    this._action = router.selectors.actionName(state);
  }
}

window.customElements.define('kerika-signup-with-direct-email', KerikaSignupWithDirectEmail);
