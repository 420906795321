import get from 'lodash-es/get';
import * as app from '../app/index.js';
import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

/**
 * @param { Object } state - Redux state detail object
 * @return { Object } - return currently opened account preferences details.
 */
export const accountPreferences = selectorCreator.default({maxSize: 50})(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `account-user-preferences-${router.selectors.accountId(state)}-${auth.selectors.currentUserId(state)}`, 'account-user-preferences'),
  (doc) => {
    return doc && doc[0] || undefined;
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @return { Object } - return last used account preferences details.
 */
const lastUsedAccountPreferences = selectorCreator.default({maxSize: 50})(
  (state) => firestoreRedux.selectors.docsByQueryResult(state, `account-user-preferences-${auth.selectors.lastUsedAccount(state)}-${auth.selectors.currentUserId(state)}`, 'account-user-preferences'),
  (doc) => {
    return doc && doc[0] || undefined;
  }
);

/**
 * When user has currently opened accountd has a background color then return it.
 * Otherwise, If last used account has the background color then returns it.
 * @param { Object } state Redux state detail object
 * @return { String } return account background color based layout.
 */
export const backgroundColor = selectorCreator.reselct.createSelector(
  accountPreferences,
  app.selectors.wideLayout,
  lastUsedAccountPreferences,
  (preferences, wideLayout, lastUsedAccountPreferences) => {
    let backgroundColor = wideLayout ? preferences && preferences.backgroundColor && preferences.backgroundColor.desktop : preferences && preferences.backgroundColor && preferences.backgroundColor.mobile;
    if(!backgroundColor) {
      preferences = lastUsedAccountPreferences;
      backgroundColor = wideLayout ? preferences && preferences.backgroundColor && preferences.backgroundColor.desktop : preferences && preferences.backgroundColor && preferences.backgroundColor.mobile;
    }
    return backgroundColor;
  }
);

/**
 * @param {Object} state Redux state
 * @returns `true` when API request to change preferences is in progress.
 */
export const preferencesChangeInProgress = (state) => get(state, 'account-preferences.changePreferences.inProgress');

/**
 * @param {Object} state Redux state
 * @returns {Array} application background color list from application configration.
 */
export const backgroundColorList = selectorCreator.createDeepEqualSelector(
  app.selectors.config,
  (config) => {
    return config && config.bgColors || [];
  }
);