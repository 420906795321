import { takeEvery, all, call, select, put } from 'redux-saga/effects';
import * as auth from '../../auth'
import * as router from '../index.js';
import * as actions from './actions.js';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../../utils';
import { store } from '../../../store.js';

/**
 * Storage key of last visited page.
 */
const LOCAL_STORAGE_KEY = 'last-visited-page'

let previousPageData;
let previousAccountId;

/**
 * When current page data and previous page data is not same and the page need to be tracked as last-visited-page then dispatch `UPDATE` action with page data.
 */
function* routeChangeHandler() {
  let state = yield select();
  let currentPageData = router.selectors.page(state);
  let currentAccountId = router.selectors.accountId(state);
  if ((!isEqual(previousPageData, currentPageData) && !isEmpty(currentPageData)) || (currentAccountId && currentAccountId != previousAccountId)) {
    const userId = auth.selectors.currentUserId(state);
    if(shouldTrackPage(currentPageData.name, userId)) {
      yield put({ type: actions.UPDATE, page: currentPageData});
    }
  }
  previousPageData = router.selectors.page(state);
  previousAccountId = router.selectors.accountId(state);
}

/**
 * Saves the current router page information into local-storage.
 */
function* updateLastVisitedPage() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const currentPageData = router.selectors.page(state) || {};
  const currentDialogData = router.selectors.dialog(state) || {};
  const data = {...currentPageData, ...{accountId, dialog: currentDialogData }};
  localStorage && localStorage.setItem && localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
}

let prevAccId;
/**
 * Updates `last-accessed-account` on firestore.
 */
function* updateLastAccessedAccount() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const accountId = router.selectors.accountId(state);
  if (!userId || !accountId || accountId === prevAccId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const docId = `laa_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`;
  const doc = {
    id: docId,
    type: 'last-accessed-account',
    userId,
    accountId,
  };
  firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
  prevAccId = accountId;
}

/**
 * Watch router change.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* lastVisitedPageSaga() {
  yield all([
    takeEvery(actions.UPDATE, updateLastVisitedPage),
    call(watchRouter),
    takeEvery(router.actions.UPDATE_ROUTER, updateLastAccessedAccount)
  ]);
}

/**
 * Determines whether the page is being tracked for the last-visited page or not. As per the requirement,
 * we track only main pages like board, view etc. We don't track the side pages like preferences, manage account etc.
 * @param {String} pageName It's the pageName as per the router data.
 * @returns {Boolean} `true` when given `pageName` is tracked for the last-visited page, Otherwise `false`.
 */
const shouldTrackPage = (pageName, userId) => {
  return userId && (pageName == 'BOARD' || pageName == 'BOARD_EXPLORER' || pageName === 'VIEWS');
}

/**
 * @returns {Object} last visited page data.
 */
export const getLastVisitedPage = () => {
  try {
    const state = store.getState();
    const impersonatedUser = auth.selectors.impersonatedUser(state);
    if(impersonatedUser) {
      return {};
    }

    const value = localStorage && localStorage.getItem && localStorage.getItem(LOCAL_STORAGE_KEY) || null;
    return JSON.parse(value) || {};
  } catch(e) {
    return {};
  }
}

export default lastVisitedPageSaga;