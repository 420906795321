import { put, takeEvery, takeLatest, call, select, all, delay } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as board from '../board';
import * as router from '../router';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { show as showSnackBar } from '../../components/kerika-snackbar';
import i18next from '@dw/i18next-esm';

const CLIPBOARD_TIMEOUT = (3 * 60 * 1000); // 3 minutes.


/**
 * Resets clipboard after `CLIPBOARD_TIMEOUT`
 */
function* resetAfterDelay() {
  yield delay(CLIPBOARD_TIMEOUT);
  yield put(actions.reset());
}

function* onUpdate() {
  yield call(resetAfterDelay);
  
  // shows toast to the visitor.
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const effectivePermission = board.selectors.boardEffectivePermission(state, boardId);
  if (effectivePermission && !effectivePermission.hasWrite()) {
    showSnackBar({ message: i18next.t('board-card:copy.toast.visitor')});
  }
}

/**
 *  When clipboad data is older than 5 minutes, resets clipboard.
 */
function* manageCopyCards() {
  const state = yield select();
  const action = get(state, `clipboard.action`);
  const createdAt = get(state, `clipboard.createdAt`);
  const copiedCards = selectors.copiedCards(state);
  if (isEmpty(copiedCards) || action !== 'COPY_CARDS') {
    return;
  }

  // Resets when data is older than `CLIPBOARD_TIMEOUT`
  if (createdAt + CLIPBOARD_TIMEOUT < Date.now()) {
    yield put(actions.reset());
    return;
  }
}

/**
 * Init Saga.
 */
export function* clipboardSaga() {
  yield all([
    call(manageCopyCards),
    takeEvery(board.actions.BOARD_LOADED, manageCopyCards),
    takeLatest(actions.UPDATE, onUpdate),
  ]);
}