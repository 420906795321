import get from 'lodash-es/get.js';
import isEmpty from 'lodash-es/isEmpty';
import filter from 'lodash-es/filter';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';

/**
 * Feature is available or not for current user.
 * @param {Object} state redux state.
 * @param {String} featureId
 */
export const isAvailable = (state, featureId, accountId) => {
  const config = app.selectors.config(state);
  if(config === undefined || !featureId) {
    return false;
  }

  const queries = firestoreRedux.selectors.queriesByRequester(state, 'app-data');
  const pendingQueries = filter(queries, { status: 'PENDING', collection: 'app' });
  if(isEmpty(queries) || !isEmpty(pendingQueries)) {
    return false;
  }

  const featureDetails = get(config, `features.${featureId}`);
  if(isEmpty(featureDetails) || featureDetails.availability == 'PUBLIC') {
    return true;
  }

  if(featureDetails.availability == 'NONE') {
    return false;
  }

  const userId = auth.selectors.currentUserId(state);
  if(featureDetails.availability == 'BETA') {
    const betaUsers = firestoreRedux.selectors.doc(state, 'beta-users', 'bus_0');
    const users = get(betaUsers, 'users', []);
    return userId && users && users.includes(userId) || false;
  }

  if(featureDetails.availability == 'SELECTED_USERS') {
    return userId && featureDetails.users && featureDetails.users.includes(userId) || false;
  }

  accountId = accountId || router.selectors.accountId(state);
  if(featureDetails.availability == 'SELECTED_ACCOUNTS') {
    return accountId && featureDetails.accounts && featureDetails.accounts.includes(accountId) || false;
  }

  return false;
};
