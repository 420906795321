import { store, sagaMiddleware } from '../../store';
import * as _actions from './actions';
import * as _selectors from './selectors';
import * as _utils from './utils';
import { searchReducer } from './reducers';
import { saga } from './saga';

export const actions = _actions;
export const selectors = _selectors;
export const utils = _utils;

sagaMiddleware.run(saga);

store.addReducers({
  'search': searchReducer
});
