import { sagaMiddleware } from '../../store';
import saga from './saga';
import createBoardSaga from './create-board/saga';

import * as _selectors from './selectors';
import * as _createBoardSelectors from './create-board/selectors';

import * as _actions from './actions';
import * as _createBoardActions from './create-board/actions';

sagaMiddleware.run(createBoardSaga);
sagaMiddleware.run(saga);

export const selectors = {..._selectors, ..._createBoardSelectors};
export const actions = {..._actions, ..._createBoardActions};

