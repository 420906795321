import * as actions from './actions';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';

const INITIAL_STATE = {};

export const deviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.MARK_NOTIFICATION_PERMISSION_DIALOG_PRESENTED:
      return ReduxUtils.replace(state, `notificationPermissionDialogPresented`, true);
    default:
      return state;
  }
};

export default deviceReducer;