import * as actions from "./actions";

const INITIAL_STATE = {
  suggestedList: [],
  suggestedListLoaded: false,
  createAccountInProgress: false,
  joinAccountInProgress: false,
  signupInSameSession: false,
  signupUsingJoinSecret: false
};

export const signupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_DONE:
      return {
        ...state,
        suggestedList: action.list,
        suggestedListLoaded: true
      };
    case actions.ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_FAILED:
      return {
        ...state,
        suggstedList: [],
        suggestedListLoaded: true
      }
    case actions.CREATE_ACCOUNT:
      return {
        ...state,
        createAccountInProgress: true
      }
    case actions.CREATE_ACCOUNT_DONE:
    case actions.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        createAccountInProgress: false
      }
    case actions.JOIN_ACCOUNT:
      return {
        ...state,
        joinAccountInProgress: true
      }
    case actions.JOIN_ACCOUNT_DONE:
      return {
        ...state,
        signupUsingJoinSecret: true
      }
    case actions.JOIN_ACCOUNT_FAILED:
      return {
        ...state,
        joinAccountInProgress: false
      }
    
    case actions.PAGE_OPENED: 
    return {
      ...state,
      signupInSameSession: true,
      joinAccountInProgress: false
    }
    case actions.PAGE_CLOSED:
      return {
        ...state,
        joinAccountInProgress: false
      }
    default:
      return state;
  }
};