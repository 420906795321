/**
 * Provides a common properties for login and signup.
 */
export const KerikaLoginAndSignupMixin = (baseElement) =>
  class extends baseElement {
    constructor() {
      super();
      this.mode = 'LOGIN';
      this.installedApp = false;
      this.inProgress = false;
      this.i18nextNameSpaces = ['login-and-signup'];
    }

    static get properties() {
      return {
        /**
         * Input property.
         * Current mode of login/signup page.
         * Possible values: SIGNUP, LOGIN, ACCEPTED_SIGNUP, EMAIL_NOT_AVAILABLE.
         */
        mode: { type: String, reflect: true },

        language: { type: String, reflect: true },

        /**
         * Input property.
         * Current app is installed app or not.
         */
        installedApp: {
          type: Boolean,
          reflect: true,
          attribute: "installed-app",
        },

        /**
         * Input property.
         * Login/signup process is running or not.
         */
        inProgress: { type: Boolean, reflect: true, attribute: "in-progress" },

        /**
         * Input property.
         * Login/signup process is failed due to this error.
         * Possible value: TIMEOUT, USER_NOT_FOUND, INVALID_PASSWORD, USER_ALREADY_EXIST, PASSWORD_NEED_TO_MATCH, EMAIL_NOT_AVAILABLE, UNKNOWN.
         */
        error: { type: String, reflect: true },

        /**
         * Input property.
         * Login/signup service name.
         */
        service: { type: String, reflect: true },

        /**
         * embeded login page is opened or not.
         */
        embedLoginSignup: { type: Boolean, reflect: true, attribute: "embed-login-signup" }
      };
    }
  };

  export default KerikaLoginAndSignupMixin;