import { KerikaSelect } from '../../components/kerika-select';
import { html, css } from 'lit-element';
import './filter-chips-more-select-dialog.js';

export class FilterChipsMoreSelect extends KerikaSelect {
  constructor() {
    super();
    this.singleSelect = true;
    this.animation = 'expand';
    this.itemLabel = 'name';
    this.itemValue = 'value';
    this.vAlign = 'bottom';
    this.hAlign = 'right';
  }

  static get properties() {
    return {
      /**
       * The element that should be used to position the element
       */
      positionTarget: { type: Object },
    };
  }

  firstUpdated(changedProps) {
    super.firstUpdated(changedProps);
    this._positionTarget = this.positionTarget || this.renderRoot.querySelector('.main-container');
  }

  /**
   * @override
   */
  _renderSelectDialog() {
    return html`
      <filter-chips-more-select-dialog
        id="select-dialog"
        .large=${this.large}
        .items=${this.items}
        .disabledItems=${this.disabledItems}
        .itemLabel=${this.itemLabel}
        .itemValue=${this.itemValue}
        .listItemIconSize=${this.listItemIconSize}
        .positionTarget=${this._positionTarget}
        .noHeader=${this.noHeader}
        .mobileMode=${this.mobileMode}
        .width=${this.width}
        .filterPlaceholder=${this.filterPlaceholder}
        .opened=${this.opened}
        .hAlign=${this.hAlign}
        .vAlign=${this.vAlign}
        .hOffset=${this.hOffset}
        .vOffset=${this.vOffset}
        .singleSelect=${this.singleSelect}
        .value=${this.value}
        .groupBy=${this.groupBy}
        .allowFilter=${this.allowFilter}
        .groupByOrder=${this.groupByOrder}
        .groupText=${this.groupText}
        .dialogTitle=${this.dialogTitle}
        .hideSelectAllBtn="${this.hideSelectAllBtn}"
        .alwaysFullScreenInMobile=${this.alwaysFullScreenInMobile}
        .hideResetBtn="${this.hideResetBtn}"
        .stickySelectionButtons="${this.stickySelectionButtons}"
        .selectionButtonsAlign="${this.selectionButtonsAlign}"
        @value-changed=${this._valueChanged}
        @opened-changed=${this._openedChanged}
        .backIcon="${this.backIcon}"
        .backIconPosition="${this.backIconPosition}"
        .noBackIcon="${this.noBackIcon}"
        .backIconSize="${this.backIconSize}"
        .clearIconSize="${this.clearIconSize}"
        .queryParamValue="${this.queryParamValue}"
        .queryParamName="${this.queryParamName}"
      ></filter-chips-more-select-dialog>
    `;
  }
}

customElements.define('filter-chips-more-select', FilterChipsMoreSelect);