import URI from '@dw/urijs-esm';
import keys from 'lodash-es/keys';
import isEmpty from 'lodash-es/isEmpty';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import { isApplicationRootPage, isViewPage, isForgotPasswordPage, isNotSupportedPage, isManageAccountPage, isAccountPreferencesPage, isNotFoundPage} from './validate-url';
import * as lastVisitedPage from '../last-visited-page';
import firestoreRedux from '@dreamworld/firestore-redux';

const aPartialURLRegExp = {
  "APP_ROOT": "^\/?$",
  "ACCOUNT_CONTEXT": "^/[a-zA-Z0-9-_]+\/?$",
  "HOME_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/?$",
  "HOME_FAVORITE_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/?$",
  "HOME_OTHER_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/?$",
  "BOARD_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(board)\/?$",
  "MANAGE_ACCOUNT_CONTEXT": "^\/(manage-account)(\/|\/summary\/?|\/users\/?|\/transactions\/?|\/settings\/?)?$",
  "PREFERENCES_CONTEXT": "^\/(preferences)(\/|\/general\/?|\/notifications\/?|\/canvases\/?|\/boards\/?)?$"
};

const oBoardLevelPageRegEx = {
  "BOARD_PAGE": "^\/[a-zA-Z0-9-_]+\/(board)\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_CARD": "^\/[a-zA-Z0-9-_]+\/(board)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_PAGE_CANVAS_VIEW": "^\/[a-zA-Z0-9-_]+\/(c)\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_PAGE_INNER_CANVAS_VIEW": "^\/[a-zA-Z0-9-_]+\/(c)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+\/?$"
}

export const computeFullURL = (data) => {
  return new Promise(async function (resolve, reject) {
    //User is not login or board page url.
    if (!data.user || !data.user.id || isFullBoardUrl(data.path) || isViewPage(data.path) || isNotSupportedPage(data.path) || isNotFoundPage(data.path)) {
      reject(data);
      return;
    }

    let uri = new URI(data.path);
    let accountId = uri.segment()[0];

    if(isApplicationRootPage(data.path) || isForgotPasswordPage(data.path)) {
      let pageData = lastVisitedPage.getPage();
      if(!isEmpty(pageData)) {
        data.redirect = pageData;
        resolve(data);
        return;
      }
    }

    //If URL is partial or forgot-password page url.
    if (isPartialUrl(data.path) || isForgotPasswordPage(data.path)) {

      if(isManageAccountPage(data.path) && !isEmpty(data.ownedAccounts)) {
        const accountId = data.ownedAccounts && data.ownedAccounts[0];
        data.redirect = `/${accountId}${data.path}`;
        resolve(data);
        return;
      }

      if(isAccountPreferencesPage(data.path)) {
        const accountId = getLastAccessedAccount(data.lastAccessibleAccount, data.ownedAccounts, data.accessibleAccounts);
        data.redirect = `/${accountId}${data.path}`;
        resolve(data);
        return;
      }

      data.redirect = await getLastAccessedAccountHomePage(data.lastAccessibleAccount, data.ownedAccounts, data.accessibleAccounts, data.wideLayout, data.user);
      resolve(data);
      return;
    }

    if(accountId === 'acc_0') {
      const accountId = getLastAccessedAccount(data.lastAccessibleAccount, data.ownedAccounts, data.accessibleAccounts);
      data.redirect = data.path.replace('/acc_0', `/${accountId}`);
      resolve(data);
      return;
    }

    //For full url exclude board Url.
    if (!data.wideLayout && !isValidAccountForCurrentUser(accountId, data.ownedAccounts, data.accessibleAccounts)) {
      data.redirect = await getLastAccessedAccountHomePage(data.lastAccessibleAccount, data.ownedAccounts, data.accessibleAccounts,  data.wideLayout, data.user);
      resolve(data);
      return;
    }

    reject(data);
  });
}

/**
 * @param {String} pathname Passed pathname
 * @return {Boolean} `true` when `pathname` argument is match with partialUrl. `false` otherwise.
 */
export const isPartialUrl = (pathname) => {
  let bMatched = false;
  for (let pageName in aPartialURLRegExp) {
    if (pathname.match(aPartialURLRegExp[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with board path. `false` otherwise.
 */
export const isFullBoardUrl = (pathname) => {
  let bMatched = false;
  for (let pageName in oBoardLevelPageRegEx) {
    if (pathname.match(oBoardLevelPageRegEx[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} currentAccount Current account
 * @param {Array} ownedAccounts Owned account list
 * @param {Objects} accessibleAccounts Accessible account hash
 * @return {String} `true` when `currentAccount` is in `ownedAccounts` or `accessibleAccounts`. otherwise `false`.
 */
export const isValidAccountForCurrentUser = (currentAccount, ownedAccounts, accessibleAccounts) => {
  currentAccount = currentAccount || '';
  ownedAccounts = ownedAccounts || [];
  accessibleAccounts = accessibleAccounts || {};
  return (accessibleAccounts[currentAccount] || ownedAccounts.includes(currentAccount));
}

/**
 * @param {String} lastAccessedAccount Last access account
 * @param {Array} ownedAccounts Owned account list
 * @param {Object} accessibleAccounts Accessible account hash
 * @param {Object} user Current logged in user.
 * @return {String} when user has favorite boards, returns url of favorite boards page otherwise returns url of other boards page.
 */
export const getLastAccessedAccountHomePage = async (lastAccessedAccount, ownedAccounts, accessibleAccounts, wideLayout, user) => {
  const query = firestoreRedux.query('favorite-boards', { where: [['userId', '==', user.id]], once: true  });
  const favoriteBoards = await query.result;

  if (wideLayout) {
    return isEmpty(favoriteBoards) ? `/home/other/boards` : `/home/favorite/boards`;
  }

  const lastAccId = getLastAccessedAccount(lastAccessedAccount, ownedAccounts, accessibleAccounts);
  const favBoards = filter(favoriteBoards, { accountId: lastAccId });
  return `${lastAccId}/home/${isEmpty(favBoards) ? 'other' : 'favorite'}/boards`;
}

/**
 * @param {Number} lastAccessedAccount Last access account
 * @param {Array} ownedAccounts Owned account list
 * @param {Objects} accessibleAccounts Accessible account hash
 * @return {Number} when user has owned or accessible account then return one of the accountId.
 */
export const getLastAccessedAccount = (lastAccessedAccount, ownedAccounts, accessibleAccounts) => {
  ownedAccounts = ownedAccounts || [];
  accessibleAccounts = accessibleAccounts || {};
  lastAccessedAccount = lastAccessedAccount || 0;

  if (!accessibleAccounts[lastAccessedAccount]) {
    lastAccessedAccount = (ownedAccounts && ownedAccounts[0]) || keys(accessibleAccounts)[0];
  }

  return lastAccessedAccount;
}

/**
 * Loads board from firestore & checks whether iss PUBLIC or not.
 * @param {Number} boardId Board Id
 * @returns Boolean `true` if board is public otherwise `false`
 */
export const isPublicBoard = async (boardId) => {
  try {
    const query = firestoreRedux.getDocById('boards', boardId, { once: true });
    const doc = await query.result;
    return get(doc, 'privacy') === 'PUBLIC';
  } catch (error) {
    console.warn('isPublicBoard : Failed to load board', boardId, error);
    return false;
  }
}