import { takeEvery, all, call, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import { requestApi } from '../../request-api';
import * as amplitude from '../../analytics/amplitude.js';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as auth from '../auth';


function* markAsRead({ entityId, attributes = [] }) {
  const state = yield select();
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  if (impersonatedUser) {
    return;
  }
  try {

    if (entityId.startsWith('brd_')) {
      if (attributes.includes('attachments')) {
        amplitude.logBoardEvent('marked as read', { action: 'AUTO', type: 'BOARD_ATTACHMENT' });
      } else if (attributes.includes('chats')) {
        amplitude.logBoardEvent('marked as read', { action: 'AUTO', type: 'BOARD_CHAT' });
      }
    } else if (entityId.startsWith('crd_')) {
      let cardAttributes = {};
      forEach(attributes, (value) => {
        cardAttributes[value] = true;
      });

      amplitude.logBoardEvent('marked as read', { action: 'AUTO', type: 'CARD', attributes: cardAttributes });
    }
    requestApi(`/unread-highlights/mark-attributes-as-read`, { method: 'PUT', body: { entityId, attributes } });
  } catch (error) {
    console.error(`Failed to mark as read`, { entityId, attributes, error });
  }
}

function* markAllAsRead({ entityIds, actionPerform, localWritesData }) {
  const state = yield select();
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  if (impersonatedUser) {
    return;
  }
  actionPerform = actionPerform || 'AUTO';
  localWritesData = localWritesData || {};
  let hasLocalWrites = !isEmpty(localWritesData);
  let collectionPath;
  let currentDoc;

  if(hasLocalWrites) {
    collectionPath = yield call(getCollectionPath, localWritesData);
  }

  hasLocalWrites = hasLocalWrites && !!collectionPath;
  if(hasLocalWrites) {
    currentDoc = yield call(getDoc, localWritesData);
  }

  try {
    if(hasLocalWrites) {
      const newDoc = merge({}, currentDoc, {unread: {}, anyUnread: false});
      firestoreRedux.save(collectionPath, newDoc, { localWrite: true });
    }
    amplitude.logBoardEvent('marked as read', { action: actionPerform, type: 'CARD', attributes: {} });
    requestApi(`/unread-highlights/mark-all-as-read`, { method: 'PUT', body: { entityIds } });
  } catch (error) {
    if(hasLocalWrites) {
      firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    }
    console.error(`markAllAsRead: Failed to mark all as read`, { entityIds, error });
  }
}

function* getCollectionPath(localWritesData) {
  try {
    localWritesData = localWritesData || {};
    const type = localWritesData && localWritesData.type;
    const id = localWritesData && localWritesData.id;
    const cardId = localWritesData && localWritesData.cardId;
    const boardId = localWritesData && localWritesData.boardId;
  
    if(!boardId || !id || !type) {
      return '';
    }
  
    if(type === 'CHAT') {
      return cardId ? `boards/${boardId}/cards/${cardId}/card-chat-messages/${id}/card-chat-unread-user-details` : `boards/${boardId}/board-chat-messages/${id}/board-chat-unread-user-details`;
    }
  
    if(type === 'TASK') {
      return cardId ? `boards/${boardId}/cards/${cardId}/tasks/${id}/task-unread-user-details`: '';
    }

    if(type === 'ATTACHMENT') {
      return cardId ? `boards/${boardId}/cards/${cardId}/card-attachments/${id}/card-attachment-unread-user-details` : `boards/${boardId}/board-attachments/${id}/board-attachment-unread-user-details`;
    }

    return '';
  } catch (error) {
    return '';
  }
}

function* getDoc(localWritesData) {
  try {
    const state = yield select();
    localWritesData = localWritesData || {};
    const type = localWritesData && localWritesData.type;
    const id = localWritesData && localWritesData.id;
  
    if(type === 'CHAT') {
      return selectors._chatUnred(state, id) || {};
    }
  
    if(type === 'TASK') {
      return selectors._taskUnread(state, id) || {};
    }

    if(type === 'ATTACHMENT') {
      return selectors._attachmentUnread(state, id) || {};
    }
    return {};
  } catch (error) {
    return {};
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    takeEvery(actions.MARK_AS_READ, markAsRead),
    takeEvery(actions.MARK_ALL_AS_READ, markAllAsRead)
  ]);
}

export default saga;;