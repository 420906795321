import { createSelector } from 'reselect';
import * as router from '../router/index.js';
import get from 'lodash-es/get.js';
import isEqual from 'lodash-es/isEqual.js';

/**
 * @param {Object} state Redux state
 * @returns {Object} currently opened board summary.
 */
export const summary = (state) => get(state, `board-summary.${router.selectors.pageBoardId(state)}`);

/**
 * @param {Object} state Redux state
 * @param {String} boardId Board Id.
 * @returns {Object} Column wise cards count.
 */
export const columnSummary = createSelector(
  (state, boardId) => get(state, `board-summary.${boardId}.column-summary`),
  (doc) => {
    return doc || {};
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);
