export const PAGE_OPENED = 'HOME_PAGE_OPENED';
export const PAGE_CLOSED = 'HOME_PAGE_CLOSED';
export const FILTER_CHANGED = 'HOME_PAGE_FILTER_CHANGED';
export const ARCHIVE = 'ARCHIVE_BOARD';
export const ARCHIVE_DONE = 'BOARD_ARCHIVE_DONE';
export const ARCHIVE_FAILED = 'BOARD_ARCHIVE_FAILED';
export const RESTORE = 'RESTORE_BOARD';
export const RESTORE_DONE = 'BOARD_RESTORE_DONE';
export const RESTORE_FAILED = 'BOARD_RESTORE_FAILED';
export const TRASH = 'TRASH_BOARD';
export const TRASH_DONE = "BOARD_TRASH_DONE";
export const TRASH_FAILED = "BOARD_TRASH_FAILED";
export const UPDATE_ARCHIVE_FILTERS = 'BOARD_EXPLORER_UPDATE_ARCHIVE_FILTERS';
export const UPDATE_ARCHIVE_FILTERS_DONE = 'BOARD_EXPLORER_UPDATE_ARCHIVE_FILTERS_DONE';
export const UPDATE_TRASH_FILTERS = 'BOARD_EXPLORER_UPDATE_TRASH_FILTERS';
export const UPDATE_TRASH_FILTERS_DONE = 'BOARD_EXPLORER_UPDATE_TRASH_FILTERS_DONE';
export const UPDATE_ACCOUNTS_FILTERS =  'BOARD_EXPLORER_UPDATE_ACCOUNTS_FILTERS';
export const UPDATE_ACCOUNTS_FILTERS_DONE =  'BOARD_EXPLORER_UPDATE_ACCOUNTS_FILTERS_DONE';
export const UPDATE_CURRENT_LANGUAGE_FILTERS =  'BOARD_EXPLORER_UPDATE_CURRENT_LANGUAGE_FILTERS';
export const UPDATE_CURRENT_LANGUAGE_FILTERS_DONE =  'BOARD_EXPLORER_UPDATE_CURRENT_LANGUAGE_FILTERS_DONE';

export const PERMANENT_DELETE = "BOARD_PERMANENT_DELETE";
export const PERMANENT_DELETE_FAILED = "BOARD_PERMANENT_DELETE_FAILED";
export const STORE_LAST_VISITED_PAGE_PARAMS = 'BOARD_EXPLORER_STORE_LAST_VISITED_PAGE_PARAMS';
export const CREATE_TEMPLATE = 'BOARD_EXPLORER_CREATE_TEMPLATE';
export const CREATE_TEMPLATE_DONE = 'BOARD_EXPLORER_CREATE_TEMPLATE_DONE';
export const CREATE_TEMPLATE_FAILED = 'BOARD_EXPLORER_CREATE_TEMPLATE_FAILED';
export const MARK_FAVORITES_TAB_PRESENTED = 'BOARD_EXPLORER_MARK_FAVORITES_TAB_PRESENTED';
export const MARK_ALL_OTHERS_TAB_PRESENTED = 'BOARD_EXPLORER_MARK_ALL_OTHERS_TAB_PRESENTED';

// Actions for All others or Favorites template tab category collapse/expand
export const MARK_ALL_OTHERS_TAB_CATEGORY_COLLAPSE = 'BOARD_EXPLORER_MARK_ALL_OTHERS_TAB_CATEGORY_COLLAPSE';
export const MARK_ALL_OTHERS_TAB_CATEGORY_EXPAND = 'BOARD_EXPLORER_MARK_ALL_OTHERS_TAB_CATEGORY_EXPAND';
export const MARK_FAVORITES_TAB_CATEGORY_COLLAPSE = 'BOARD_EXPLORER_MARK_FAVORITES_TAB_CATEGORY_COLLAPSE';
export const MARK_FAVORITES_TAB_CATEGORY_EXPAND = 'BOARD_EXPLORER_MARK_FAVORITES_TAB_CATEGORY_EXPAND';

export const MARK_IMPORT_BOARD_STATUS_ACKNOWLEDGE  = 'BOARD_EXPLORER_MARK_IMPORT_BOARD_STATUS_ACKNOWLEDGE';

//Actions for loading boards extra details like board-team-members, board-move-requests
export const LOAD_BOARDS_DETAILS = 'BOARD_EXPLORER_LOAD_BOARDS_DETAILS';
export const LOAD_BOARDS_DETAILS_DONE = 'BOARD_EXPLORER_LOAD_BOARDS_DETAILS_DONE';
export const LOAD_BOARDS_DETAILS_FAILED = 'BOARD_EXPLORER_LOAD_BOARDS_DETAILS_FAILED';

/**
 * @param {Object} filters Page filters data for home page.
 */
export const storeLastVisitedPageParams = (params) => {
  return {
    type: STORE_LAST_VISITED_PAGE_PARAMS,
    params
  }
}

/**
 * Update user archive filters value.
 * @param {Boolean} value new value of archive filters.
 */
export const updateArchiveFilters = (value) => {
  return {
    type: UPDATE_ARCHIVE_FILTERS,
    value
  }
}

/**
 * Update user trash filters value.
 * @param {Boolean} value new value of trash filters.
 */
 export const updateTrashFilters = (value) => {
  return {
    type: UPDATE_TRASH_FILTERS,
    value
  }
}

/**
 * Update user current language filters value.
 * @param {Boolean} value new value of trash filters.
 */
export const updateCurrentLanguageFilter = (value) => {
  return {
    type: UPDATE_CURRENT_LANGUAGE_FILTERS,
    value
  }
}

/**
 * Update account filters for given account.
 * @param {Number} accountId
 * @param {Boolean} value new value of account filters.
 */
 export const updateAccountsFilters = (accountId, value) => {
  return {
    type: UPDATE_ACCOUNTS_FILTERS,
    accountId,
    value
  }
}

/**
 * Create a new template for given account and using given template.
 * @param {Object} params
 *  - @property {String} name new template name
 *  - @property {String} templateType which type template i created like task template, or white template
 *  - @property {Number} accountId
 *  - @property {String} templateCategory which type of category of new template
 *  - @property {String} templatePrivacy defualt privacy of new template
 */
 export const createTemplate = ({name, templateType, accountId, templateCategory, templatePrivacy}) => {
  return {
    type: CREATE_TEMPLATE,
    name,
    templateType,
    accountId,
    templateCategory,
    templatePrivacy
  }
}

/**
 * Dispatch this action to favorites tabs presented to current user.
 */
export const markFavoritesTabPresented = () => {
  return {
    type: MARK_FAVORITES_TAB_PRESENTED
  }
}

/**
 * Dispatch this action to all-others tabs presented to current user.
 */
export const markAllOthersTabPresented = () => {
  return {
    type: MARK_ALL_OTHERS_TAB_PRESENTED
  }
}

/**
 * Dispatch this action to all-others template tabs category collapse for current user.
 */
export const markAllOthersTabCategoryCollapse = (category) => {
  return {
    type: MARK_ALL_OTHERS_TAB_CATEGORY_COLLAPSE,
    category
  }
}

/**
 * Dispatch this action to all-others template tabs category expand for current user.
 */
export const markAllOthersTabCategoryExpand = (category) => {
  return {
    type: MARK_ALL_OTHERS_TAB_CATEGORY_EXPAND,
    category
  }
}

/**
 * Dispatch this action to favorites template tabs category collapse for current user.
 */
export const markFavoritesTabCategoryCollapse = (category) => {
  return {
    type: MARK_FAVORITES_TAB_CATEGORY_COLLAPSE,
    category
  }
}

/**
 * Dispatch this action to favorites template tabs category expand for current user.
 */
export const markFavoritesTabCategoryExpand = (category) => {
  return {
    type: MARK_FAVORITES_TAB_CATEGORY_EXPAND,
    category
  }
}

/**
 * Dispatch this action to import board status acknowledge.
 */
export const markImportBoardStatusAck = (boardId) => {
  return {
    type: MARK_IMPORT_BOARD_STATUS_ACKNOWLEDGE,
    boardId
  }
}

export const loadBoardsDetails = (boardIds, requesterId) => {
  return {
    type: LOAD_BOARDS_DETAILS,
    boardIds,
    requesterId
  }
}

export const _loadBoardsDetailsDone = (boardIds, requesterId) => {
  return {
    type: LOAD_BOARDS_DETAILS_DONE,
    boardIds,
    requesterId
  }
}

export const _loadBoardsDetailsFailed = (boardIds, requesterId, error) => {
  return {
    type: LOAD_BOARDS_DETAILS_FAILED,
    boardIds,
    requesterId,
    error
  }
}