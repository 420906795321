import forEach from "lodash-es/forEach";
import uniq from "lodash-es/uniq";
import join from "lodash-es/join";
import isEmpty from "lodash-es/isEmpty";
import htmlTrim from '@dreamworld/web-util/htmlTrim';
import textToHtml from '@dreamworld/web-util/textToHtml';
import sortBy from 'lodash-es/sortBy';
import Mark from 'mark.js/src/vanilla.js';

/**
 * @param {Array} snippets
 * @returns {Array} matching keywords from given snippets;
 */
export const keywordsForSnippets = (snippets) => {
  if (isEmpty(snippets)) {
    return [];
  }

  let keywords = [];
  const htmlString = join(snippets);
  let el = document.createElement("div");
  el.innerHTML = htmlString;
  const emTags = el.querySelectorAll("em");
  forEach(emTags, (emTag) => {
    keywords.push(htmlTrim(emTag.textContent));
  });
  el = undefined;
  keywords = uniq(keywords);
  keywords = sortBy(keywords, [function(keyword) { return keyword && keyword.length; }]);
  return keywords.reverse();
};

/**
 * @param {String} htmlString
 * @param {Array} keywords
 * @returns {String} html string return using given keywords.
 */
export const highlightedHtml = (htmlString, keywords) => {
  if (isEmpty(keywords) || !htmlString) {
    return htmlString;
  }

  const newHtml = textToHtml(htmlString);
  const instance = new Mark(newHtml);
  instance.mark(keywords, {
    "element": "span",
    "className": "mark",
    "accuracy": {
      "value": "exactly",
      "limiters": [",", ".", "-"]
    },
    "acrossElements": true
  });
  return htmlTrim(newHtml);
};
