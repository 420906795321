/**
 * It is application level element to show Toasts.
 *
 * Behavior:
 *  - In mobile it's bottom/center aligned while in desktop it's bottom/left aligned.
 *
 * USAGE PATTERN:
 *   <kerika-snackbar></kerika-snackbar>
 *   import {show as showSnackBar} from '../components/kerika-snackbar.js';
 */

import { DwSnackbar } from '@dreamworld/dw-snackbar';
import * as actions from '@dreamworld/dw-snackbar';
import { css } from 'lit-element';
import isEmpty from 'lodash-es/isEmpty';
class KerikaSnackbar extends DwSnackbar {
  constructor() {
    super();
    this.defaultConfig = {
      type: 'INFO',
      timeout: 3000,
      hideDismissBtn: false,
      dismissIcon: 'close'
    };
  }

  /**
   * Purpose: To override the timeout at the base of toast data on the application.
   * @param {*} toastId
   * @returns toast timeout of given toastId from toast list data.
   * @override
   */
  _getToastTimeout(toastId) {
    let toast = this._toastList[toastId];
    let timeout = toast && toast.timeout;

    if(!isEmpty(toast.actionButton)) {
      timeout = 5000;
    }
    return timeout;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          z-index: 999999;
        }
        
        .toast[type="INFO"] dw-button{
          --mdc-theme-primary: var(--dark-orange-color);
        }

        .toast[type="ERROR"] dw-button{
          --mdc-theme-primary: var(--mdc-theme-surface);
        }
      `
    ]
  }


}

export const show = actions.show;
export const hide = actions.hide;
export const setDefaults = actions.setDefaults;
export const setPosition = actions.setPosition;


window.customElements.define('kerika-snackbar', KerikaSnackbar);
