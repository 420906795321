export const MOVE_TO_DONE = 'CARD_MOVE_TO_DONE';
export const MOVE_TO_TRASH = 'CARD_MOVE_TO_TRASH';
export const MOVE_CARDS_TO_TOP = 'CARDS_MOVE_TO_TOP';
export const MOVE_CARDS_TO_BOTTOM = 'CARDS_MOVE_TO_BOTTOM';
export const MOVE_CARDS_TO_DONE = 'CARDS_MOVE_TO_DONE';
export const MOVE_CARDS_TO_TRASH = 'CARDS_MOVE_TO_TRASH';
export const MOVE_VIEWS_CARDS_TO_DONE = 'CARD_MOVE_VIEWS_CARDS_TO_DONE';
export const MOVE_VIEWS_CARDS_TO_TRASH = 'CARD_MOVE_VIEWS_CARDS_TO_TRASH';
export const RESTORE_VIEWS_CARDS = 'CARD_RESTORE_VIEWS_CARDS';
export const MOVE_CARDS_TO_ANOTHER_COLUMN = 'CARDS_MOVE_TO_COLUMN';
export const MOVE_CARDS_ACROSS_BOARD = 'CARDS_MOVE_TO_BOARD';
export const MOVE_CARDS_WITHIN_BOARD_BEFORE_CARD = 'MOVE_CARDS_WITHIN_BOARD_BEFORE_CARD';
export const UNDO_MOVE = 'CARDS_UNDO_MOVE';
export const RESTORE = 'CARD_RESTORE';
export const RESTORE_ALL = 'CARD_RESTORE_ALL';
export const UNDO_RESTORE = 'CARD_UNDO_RESTORE';
export const SORT = 'CARDS_SORT';
export const UNDO_SORT = 'CARDS_UNDO_SORT';
export const GET_LINK = 'CARD_GET_LINK';
export const COPY = 'CARDS_COPY';
export const COPY_SUCCESS = 'CARDS_COPY_SUCCESS';
export const PERMANENT_DELETE = 'CARD_PERMANENT_DELETE';
export const LOAD_BOARD_CARDS = 'CARD_LOAD_BOARD_CARDS';
export const ADD_NEW_CARD = 'CARD_ADD_NEW_CARD';

export const LOAD_CARDS_BY_COLUMN_TYPE = 'CARD_LOAD_CARDS_BY_COLUMN_TYPE';
export const DISCONNECT_CARDS_BY_COLUMN_TYPE = 'CARD_DISCONNECT_CARDS_BY_COLUMN_TYPE';

/**
 * Loads cards by column from firestore.
 * @param {String} requesterId Requester Id
 * @param {String} columnId Column Id
 */
export const loadCardsByColumnType = ({requesterId, boardId, columnType, limit}) => {
  return {
    type: LOAD_CARDS_BY_COLUMN_TYPE,
    requesterId,
    boardId,
    columnType,
    limit
  }
}

/**
 * Cancels cards by column query from firestore.
 * @param {String} requesterId Firestore Requester Id
 */
export const disconnectCardsByColumnType = (requesterId) => {
  return {
    type: DISCONNECT_CARDS_BY_COLUMN_TYPE,
    requesterId
  }
}

/**
 * Copy link of the given or selected card.
 * @param {Number} cardId It is optional. When it's not given, it copy link of selected card.
 */
export const getLink = (cardId) => {
  return{
    type: GET_LINK,
    cardId
  }
}

/**
 * Copy selected/all cards into column.
 * @param {Number} columnId Column Id
 */
export const copyCards = (columnId) => {
  return {
    type: COPY,
    columnId
  }
}

/**
 * Sorts card by provided `sortBy` option.
 * @param {String} sortBy Sort By. Possible values: 'DUE_DATE', 'STATUS', 'PRIORITY', 'ASSIGNMENT', 'TITLE'
 * @param {String} columnId Column Id
 */
export const sortCards = (sortBy, columnId) => {
  return {
    type: SORT,
    sortBy,
    columnId
  }
}

/**
 * Undo the Sort action performed by user.
 * @param {Array} cards Cards docs with their previous order
 */
export const undoSort = (cards, actionId) => {
  return {
    type: UNDO_SORT,
    cards,
    actionId
  }
}

/*
 * Moves selected cards to the top of the column.
 */
export const moveSelectedCardsToTop = () => {
  return {
    type: MOVE_CARDS_TO_TOP,
  }
}

/**
 * Moves selected cards to the bottom of the column.
 */
export const moveSelectedCardsToBottom = () => {
  return {
    type: MOVE_CARDS_TO_BOTTOM,
  }
}

/**
 * Moves selected cards to the DONE column.
 */
export const moveSelectedCardsToDone = () => {
  return moveCardsToDone();
}

/**
 * Moves all cards of the column to the DONE column.
 * @param {String} columnId Column Id
 */
export const moveAllCardsToDone = (columnId) => {
  return moveCardsToDone(columnId);
}


/**
 * Moves cards to the Done column.
 * @param {String} columnId It's an optional. It's not used when board has selection.
 */
export const moveCardsToDone = (columnId) => {
  return {
    type: MOVE_CARDS_TO_DONE,
    columnId
  }
}

/**
 * Moves selected cards to the Trash column.
 */

export const moveSelectedCardsToTrash = () => {
  return moveCardsToTrash();
}

/**
 * Moves all cards to the Trash column.
 * @param {String} columnId Column Id
 */
export const moveAllCardsToTrash = (columnId) => {
  return moveCardsToTrash(columnId);
}

/**
 * Moves cards to the trash column.
 * @param {String} columnId Column Id. It's optional. It's not used when board has selection.
 * @returns
 */
export const moveCardsToTrash = (columnId) => {
  return {
    type: MOVE_CARDS_TO_TRASH,
    columnId
  }
}

/**
 * Moves selected cards to Done column.
 */
 export const moveSelectedViewsCardsToDone = () => {
   return moveViewsCardsToDone();
 }

 /**
 * Moves all cards of given column to Done column.
 */
export const moveAllViewsCardsToDone = (columnId) => {
    return moveViewsCardsToDone(columnId);
 }

/**
 * Moves selected cards to Done column.
 * When no card is selected, moves all cards of the given column to Done column.
 * @param {String} columnId Column Id. It's optional.
 */
export const moveViewsCardsToDone = (columnId) => {
  return {
    type: MOVE_VIEWS_CARDS_TO_DONE,
    columnId
  }
}

/**
 * Moves selected cards to Trash column.
 */
 export const moveSelectedViewsCardsToTrash = () => {
  return moveViewsCardsToTrash();
}

/**
* Moves all cards of given column to Trash column.
*/
 export const moveAllViewsCardsToTrash = (columnId) => {
   return moveViewsCardsToTrash(columnId);
}

/**
* Moves selected cards to Trash column.
* When no card is selected, moves all cards of the given column to Trash column.
* @param {String} columnId Column Id. It's optional.
*/
export const moveViewsCardsToTrash = (columnId) => {
 return {
   type: MOVE_VIEWS_CARDS_TO_TRASH,
   columnId
 }
}

/**
 * Restores given cards to it's previous column.
 * @param {Object} boardWiseCardIds Board wise card ids. e.g {'brd_5jgpem59gldl': ['crd_4jnbmkfvjdk', ...], ...}
 * @returns
 */
export const restoreViewsCards = (boardWiseCardIds) => {
  return {
    type: RESTORE_VIEWS_CARDS,
    boardWiseCardIds
  }
}

/**
 * Moves Selected cards to another column.
 * @param {String} destColumnId Destination column Id where selected cards will be moved.
 */
export const moveSelectedCardsToAnotherColumn = (destColumnId) => {
  return moveCardsToAnotherColumn({ destColumnId });
}

/**
 * Moves all cards to another column.
 * @param {String} srcColumnId Source column Id from where card will b moved.
 * @param {String} destColumnId Destination column id where all cards of the source column will be moved.
 */
export const moveAllCardsToAnotherColumn = (srcColumnId, destColumnId) => {
  return moveCardsToAnotherColumn({ srcColumnId, destColumnId });
}

/**
 * Moves cards to the another column in current board.
 * @param {String} srcColumnId Source column Id from where card will be moved.
 * @param {String} destColumnId Destination column Id where card will be moved.
 */
export const moveCardsToAnotherColumn = ({srcColumnId, destColumnId}) => {
  return {
    type: MOVE_CARDS_TO_ANOTHER_COLUMN,
    srcColumnId,
    destColumnId,
  }
}

/**
 * Moves selected cards to another board.
 * @param {String} destBoardId Id of the board in which cards will be moved.
 * @param {String} destColumnId Id of the column in destination board in which card will be moved.
 */
export const moveSelectedCardsToAnotherBoard = (destBoardId, destColumnId) => {
  return moveCardsToAnotherBoard({ destBoardId, destColumnId });
}

/**
 * Moves all the cards of column to another board.
 * @param {String} srcColumnId Source column Id from where cards will be moved.
 * @param {String} destBoardId Destination board Id where cards will be moved
 * @param {*} destColumnId Destination column Id of destination board in which cards will be moved.
 */
export const moveAllCardsToAnotherBoard = (srcColumnId, destBoardId, destColumnId) => {
  return moveCardsToAnotherBoard({ srcColumnId, destBoardId, destColumnId });
}

/**
 * Moves cards to the another board.
 * @param {String} destBoardId Destination board id
 * @param {String} srcColumnId Source column Id from where card will be moved.
 * @param {String} destColumnId Destination column Id where card will be moved.
 */
export const moveCardsToAnotherBoard = ({ destBoardId, srcColumnId, destColumnId }) => {
  return {
    type: MOVE_CARDS_ACROSS_BOARD,
    srcColumnId,
    destColumnId,
    destBoardId
  }
}

/**
 * Moves card to the Done column
 * @param {String} cardId Card Id
 */
export const moveToDone = (cardId) => {
  return {
    type: MOVE_TO_DONE,
    cardId
  }
}

/**
 * Moves card to the Trash column
 * @param {String} cardId Card Id
 */
export const moveToTrash = (cardId) => {
  return {
    type: MOVE_TO_TRASH,
    cardId
  }
}

export const undoMove = ({ cards, actionId }) => {
  return {
    type: UNDO_MOVE,
    cards,
    actionId
  }
}

/**
 * Loads cards by column from firestore.
 * @param {String} boardId Board Id
 */
export const loadBoardCards = (boardId) => {
  return {
    type: LOAD_BOARD_CARDS,
    boardId,
  }
}

/**
 * Adds new card into column.
 * @param {String} title Card title
 * @param {String} columnId Column Id
 * @param {String} boardId Board Id
 */
export const addNewCard = (title, columnId, boardId) => {
  return {
    type: ADD_NEW_CARD,
    title,
    columnId,
    boardId,
  }
}

/**
 * Moves card within board before given card, When before not given, moves to last of the column.
 * @param {*} param0
 *  @property {Array} cardIds List of moved card's ids.
 *  @property {String} destColumnId Destination column Id.
 *  @property {String} before Card Id, before which cards will be moved.
 */
export const moveCardWithinBoardBeforeCard = ({ cardIds, destColumnId, columnId, before }) => {
  return {
    type: MOVE_CARDS_WITHIN_BOARD_BEFORE_CARD,
    cardIds,
    columnId,
    destColumnId,
    before
  }
}

/**
 * Restore card.
 * @param {Array} cardIds Card Ids of cards to be restored.
 * @param {Boolean} doNotUndo when `true` doesn't show UNDO in toast.
 */
export const restore = ({cardIds, doNotUndo}) => {
  return {
    type: RESTORE,
    cardIds,
    doNotUndo,
  }
}

/**
 * Restores all the card from trash column.
 */
export const restoreAll = () => {
  return {
    type: RESTORE_ALL
  }
}

/**
 * Undo the restore action.
 * @param {Object} cards Map of cards. e.g {2342: 32, 234234: 45}. Key is cardId & value is order.
 */
export const undoRestore = (cards) => {
  return {
    type: UNDO_RESTORE,
    cards
  }
}

/**
 * Deletes provided card or selected or all cards from the provided column.
 * @param {Array} cardIds List of cardIds
 * @param {Number} columnId columnId.
 */
export const permanentDelete = ({ cardIds, columnId }) => {

  return {
    type: PERMANENT_DELETE,
    cardIds,
    columnId
  }
}
