import URI from '@dw/urijs-esm';
import isEmpty from 'lodash-es/isEmpty';
import { isFullBoardUrl, isPublicBoard } from './compute-full-url';

/**
 * @param {Object} data - Router data context
 * @return Promise -  If signup is not completed, redirects to /signup?join={join-secret}&next={current-accessed-uri-wo-join-secret} page.
 *                    Resolves to 'signup' page when /signup page is accessed OR URL has join-secret (`join` query param).
 *                    When URL is board page URL & board is public, rejects.
 *                    otherwise rejects (next routing).
 */
export const signupRouter = (data) => {
  return new Promise(async (resolve, reject) => {
    const uri = new URI();
    const params = uri.query(true);
    const joinSecret = params && params['join'];
    if (!data.user || !data.user.id) {
      reject(data);
      return;
    }

    if (data.path !== '/signup' && joinSecret) {
      var nextUri = new URI(data.path);
      nextUri.query(uri.query());
      nextUri.removeQuery('join');
      data.redirect = `/signup?join=${joinSecret}&next=${encodeURIComponent(nextUri.toString())}`;
      resolve(data);
      return;
    }

    if (data.path === '/signup' || joinSecret) {
      data.page = {
        name: 'SIGNUP',
        params
      };
      resolve(data);
      return;
    }

    if (isEmpty(data.accessibleAccounts) && isEmpty(data.ownedAccounts)) {
      if (isFullBoardUrl(data.path)) {
        let uri = new URI(data.path);
        const boardId = uri.segment()[2];
        try {
          const _publicBoard = await isPublicBoard(boardId);
          if (_publicBoard) {
            reject(data);
            return;
          }
        } catch (err) {
          console.warn('Error in checking public board: ', err);
        }
      }

      data.redirect = '/signup';
      resolve(data);
      return;
    }
    reject(data);
  });
}
