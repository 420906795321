import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as actions from './actions.js';

const INITIAL_STATE = {};

const multipleLanguages = (state = INITIAL_STATE, action) => {
  let oState = {...state};
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      return ReduxUtils.replace(oState, 'request', { status: 'IN_PROGRESS', error: null, lang: action.lang });
    case actions.CHANGE_LANGUAGE_SUCCESS:
      return ReduxUtils.replace(oState, 'request', { status: 'SUCCESS', error: null, lang: action.lang});
    case actions.CHANGE_LANGUAGE_FAILED:
      return ReduxUtils.replace(oState, 'request', { status: 'FAILED', error: action.error, lang: action.lang});
    default:
      return state;
  }
};

export default multipleLanguages;