import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import { createSelector } from 'reselect';
import { getDiffInMinutes } from '../../date-utils.js';

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as board from '../board';

/**
 * last move cards data.
 */
export const data = createSelector(
  (state) => auth.selectors.currentUserId(state),
  (state) => get(state, 'last-move-cards.data'),
  (currentUserId, _data) => {
    if(!currentUserId) {
      return {};
    }

    if(!_data || _data.actionBy != currentUserId) {
      return {};
    }

    return _data;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 5,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Number} action performe time.
 */
export const actionAt = (state) => get(state, 'last-move-cards.data.actionAt');

/**
 * @param {Object} state redux state
 * @returns {String} last move cards status
 */
export const status = (state) => get(state, 'last-move-cards.status');

/**
 * @returns {Boolean} is undo is possible or not.
 */
export const isUndoAvailable = (state) => {
  const _status = status(state);
  if(_status === 'SUCCESS' || _status === 'IN_PROGRESS') {
    return false;
  }

  const _data = data(state);
  if(isEmpty(_data)) {
    return false;
  }

  const boardId = router.selectors.pageBoardId(state);
  if(_data.boardId != boardId) {
    return false;
  }

  const _actionAt = actionAt(state);
  const config = app.selectors.config(state);
  const undoCardTimeout = config.undoCardTimeout;
  if(getDiffInMinutes(_actionAt) >= undoCardTimeout){
    return false;
  }

  return board.selectors.hasColumn(state, boardId, _data.columnId);
}