/**
 * This module validates that accessed URL is an application page or not.
 * It also provides utils method to validate that accessed URL is Acconunt home page, board page, etc...
 */

const oAccountMobileHomePagesRegEx = {
  "ACCOUNT_CONTEXT": "^/[a-zA-Z0-9-_]+\/?$",
  "HOME_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/?$",
  "HOME_FAVORITE_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/?$",
  "HOME_OTHER_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/?$",
  "FAVORITES_VIEWS": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(views)\/?$",
  "FAVORITES_VIEWS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(views)\/(favorite)\/?$",
  "FAVORITES_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(boards)\/?$",
  "FAVORITES_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(boards)\/(favorite)\/?$",
  "FAVORITES_TEMPLATES": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(templates)\/?$",
  "FAVORITES_TEMPLATES_CURRENT_LANG": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(templates)\/(current-lang)\/?$",
  "FAVORITES_TEMPLATES_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(templates)\/(favorite)\/?$",
  "OTHER_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/(boards)\/?$",
  "OTHER_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(boards)\/(other)\/?$",
  "OTHER_TEMPLATES": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/(templates)\/?$",
  "OTHER_TEMPLATES_CURRENT_LANG": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/(templates)\/(current-lang)\/?$",
  "OTHER_TEMPLATES_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(templates)\/(other)\/?$",
  "ARCHIVED_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/(archive)\/?$",
  "ARCHIVED_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(archive)\/(other)\/?$",
  "ARCHIVED_FAVORITE_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(archive)\/?$",
  "ARCHIVED_FAVORITE_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(archive)\/(favorite)\/?$",
  "TRASHED_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(other)\/(trash)\/?$",
  "TRASHED_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(trash)\/(other)\/?$",
  "TRASHED_FAVORITE_BOARDS": "^\/[a-zA-Z0-9-_]+\/(home)\/(favorite)\/(trash)\/?$",
  "TRASHED_FAVORITE_BOARDS_2": "^\/[a-zA-Z0-9-_]+\/(home)\/(trash)\/(favorite)\/?$"
}

const oAccountDesktopHomePagesRegEx = {
  "ACCOUNT_CONTEXT": "^\/?$",
  "HOME_CONTEXT": "^\/(home)\/?$",
  "HOME_FAVORITE_CONTEXT": "^\/(home)\/(favorite)\/?$",
  "HOME_OTHER_CONTEXT": "^\/(home)\/(other)\/?$",
  "FAVORITES_VIEWS": "^\/(home)\/(favorite)\/(views)\/?$",
  "FAVORITES_VIEWS_2": "^\/(home)\/(views)\/(favorite)\/?$",
  "FAVORITES_BOARDS": "^\/(home)\/(favorite)\/(boards)\/?$",
  "FAVORITES_BOARDS_2": "^\/(home)\/(boards)\/(favorite)\/?$",
  "FAVORITES_TEMPLATES": "^\/(home)\/(favorite)\/(templates)\/?$",
  "FAVORITES_TEMPLATES_2": "^\/(home)\/(templates)\/(favorite)\/?$",
  "FAVORITES_TEMPLATES_CURRENT_LANG": "^\/(home)\/(favorite)\/(templates)\/(current-lang)\/?$",
  "OTHER_VIEWS": "^\/(home)\/(other)\/(views)\/?$",
  "OTHER_VIEWS_2": "^\/(home)\/(views)\/(other)\/?$",
  "OTHER_BOARDS": "^\/(home)\/(other)\/(boards)\/?$",
  "OTHER_BOARDS_2": "^\/(home)\/(boards)\/(other)\/?$",
  "OTHER_TEMPLATES": "^\/(home)\/(other)\/(templates)\/?$",
  "OTHER_TEMPLATES_CURRENT_LANG": "^\/(home)\/(other)\/(templates)\/(current-lang)\/?$",
  "OTHER_TEMPLATES_2": "^\/(home)\/(templates)\/(other)\/?$",
  "ARCHIVED_BOARDS": "^\/(home)\/(other)\/(archive)\/?$",
  "ARCHIVED_BOARDS_2": "^\/(home)\/(archive)\/(other)\/?$",
  "ARCHIVED_FAVORITE_BOARDS": "^\/(home)\/(favorite)\/(archive)\/?$",
  "ARCHIVED_FAVORITE_BOARDS_2": "^\/(home)\/(archive)\/(favorite)\/?$",
  "TRASHED_BOARDS": "^\/(home)\/(other)\/(trash)\/?$",
  "TRASHED_BOARDS_2": "^\/(home)\/(trash)\/(other)\/?$",
  "TRASHED_FAVORITE_BOARDS": "^\/(home)\/(favorite)\/(trash)\/?$",
  "TRASHED_FAVORITE_BOARDS_2": "^\/(home)\/(trash)\/(favorite)\/?$"
}

const oBoardLevelPageRegEx = {
  "BOARD_CONTEXT": "^\/[a-zA-Z0-9-_]+\/(board)\/?$",
  "BOARD_PAGE": "^\/[a-zA-Z0-9-_]+\/(board)\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_CARD": "^\/[a-zA-Z0-9-_]+\/(board)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_PAGE_CANVAS_VIEW": "^\/[a-zA-Z0-9-_]+\/(c)\/[a-zA-Z0-9-_]+\/?$",
  "BOARD_PAGE_INNER_CANVAS_VIEW": "^\/[a-zA-Z0-9-_]+\/(c)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+\/?$"
}

const joinBardPageRegEx = "^\/[a-zA-Z0-9-_]+\/(board)\/[a-zA-Z0-9-_]+\/(join)\/?$";

const manageAccountPageRegEx = {
  "MANAGE_ACCOUNT_CONTEXT": "^\/(manage-account)(\/|\/summary\/?|\/users\/?|\/transactions\/?|\/settings\/?)?$",
  "MANAGE_PAGE": "^\/[a-zA-Z0-9-_]+\/(manage-account)\/?$",
  "SETTINGS": "^\/[a-zA-Z0-9-_]+\/(manage-account)\/(settings)\/?$",
  "SUMMARY": "^\/[a-zA-Z0-9-_]+\/(manage-account)\/(summary)\/?$",
  "USERS": "^\/[a-zA-Z0-9-_]+\/(manage-account)\/(users)\/?$",
  "TRANSACTIONS": "^\/[a-zA-Z0-9-_]+\/(manage-account)\/(transactions)\/?$"
}

const unsubscribePageRegEx = {
  "UNSUBSCRIBE_TASK_SUMMARY": "^\/(unsubscribe)\/(tasks-summary)$"
}

const oAccountPreferencesPageRegEx = {
  "PREFERENCES_CONTEXT": "^\/(preferences)(\/|\/general\/?|\/notifications\/?|\/canvases\/?|\/boards\/?)?$",
  "PREFERENCES_BOARD": "^\/[a-zA-Z0-9-_]+\/(preferences)\/(boards)\/?$",
  "PREFRENCES_GENERAL": "^\/[a-zA-Z0-9-_]+\/(preferences)\/(general)\/?$",
  "PREFRENCES_NOTIFICATION": "^\/[a-zA-Z0-9-_]+\/(preferences)\/(notifications)\/?$",
  "PREFERENCES_CANVASES": "^\/[a-zA-Z0-9-_]+\/(preferences)\/(canvases)\/?$",
  "PREFRENCES": "^\/[a-zA-Z0-9-_]+\/(preferences)\/?$"
}


const viewPageRegex = {
  "NEW": "^\/(views)\/(new)\/?$",
  "ATTENTION": "^\/(views)\/((attention)|(needs-attention))\/?$",
  "ASSIGNED_TO_ME": "^\/(views)\/(assigned-to-me)\/?$",
  "DUE": "^\/(views)\/((due)|(whats-due))\/?$",
  "DONE": "^\/(views)\/((done)|(got-done))\/?$"
}

/**
 * @param {object} data - Passed data.
 * @return Promise - Promise which is rejected if passed pathname is an application url otherwise promise is resolved.
 */
export const validateURL = (data) => {
  return new Promise(function (resolve, reject) {
    if(isTestPage(data.path)) {
      data.page = {
        name: 'TEST_PAGE',
        params: {}
      };
      resolve(data);
      return;
    }

    if (isUnsubscribePage(data.path) || isLeaveAppDonePage(data.path) || isBlankPage(data.path) || isAuthorizeFileStorePage(data.path) || isApplicationRootPage(data.path) || isAccountHomePage(data.path) || isAccountPreferencesPage(data.path) || isBoardPage(data.path) || isManageAccountPage(data.path) || isViewPage(data.path)) {
      reject(data);
      return;
    }

    //For v1 and v2 url.
    if(data.path.startsWith('/app')) {
      reject(data);
      return;
    }

    data.page = {
      name: 'NOT_FOUND',
      params: {}
    };
    resolve(data);
  });
}

/**
 * @param {String} pathName Passed pathName
 * @return {Boolean} `true` when `pathName` argument is match with account path. `false` otherwise.
 */
export const isAccountHomePage = (pathName) => {
  return isDesktopHomePage(pathName) || isMobileHomePage(pathName);
}

/**
 * @param {String} pathName Passed pathName
 * @return {Boolean} `true` when `pathName` argument is match with account desktop path. `false` otherwise.
 */
export const isDesktopHomePage = (pathName) => {
  let bMatched = false;
  for (let pageName in oAccountDesktopHomePagesRegEx) {
    if (pathName.match(oAccountDesktopHomePagesRegEx[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} pathName Passed pathName
 * @return {Boolean} `true` when `pathName` argument is match with account mobile path. `false` otherwise.
 */
export const isMobileHomePage = (pathName) => {
  let bMatched = false;
  for (let pageName in oAccountMobileHomePagesRegEx) {
    if (pathName.match(oAccountMobileHomePagesRegEx[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} pathname Passed pathname
 * @return {Boolean} `true` when `pathname` argument is match with account preferences path. `false` otherwise.
 */
export const isAccountPreferencesPage = (pathname) => {
  let bMatched = false;
  for (let pageName in oAccountPreferencesPageRegEx) {
    if (pathname.match(oAccountPreferencesPageRegEx[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with board path. `false` otherwise.
 */
export const isBoardPage = (pathname) => {
  let bMatched = false;
  for (let pageName in oBoardLevelPageRegEx) {
    if (pathname.match(oBoardLevelPageRegEx[pageName])) {
      bMatched = true;
      break;
    }
  }
  return bMatched;
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with join board path. `false` otherwise.
 */
export const isJoinBoardPage = (pathName) => {
  return pathName.match(joinBardPageRegEx);
}

/**
 * @param {String} path Path name
 * @returns {Boolean} `true` when `path` matches with manage-account pages.
 */
export const isManageAccountPage = (pathName) => {
  let pathMatched = false;
  for (let path in manageAccountPageRegEx) {
    if (pathName.match(manageAccountPageRegEx[path])) {
      pathMatched = true;
      break;
    }
  }
  return pathMatched;
}

/**
 * @param {String} pathname Path
 * @returns {Boolean} `true` when matches with view pages.
 */
export const isViewPage = (pathname) => {
  let matched = false;
  for (let pageName in viewPageRegex) {
    if (pathname.match(viewPageRegex[pageName])) {
      matched = true;
      break;
    }
  }
  return matched;
}

export const isTestPage = (pathname) => {
  return (pathname === '/test-page');
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with application root page. `false` otherwise.
 */
export const isApplicationRootPage = (pathname) => {
  return !pathname || pathname === '/';
}


/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with not supported page. `false` otherwise.
 */
export const isNotSupportedPage = (pathname) => {
  return (pathname === '/not-supported');
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with not found page. `false` otherwise.
 */
export const isNotFoundPage = (pathname) => {
  return (pathname === '/not-found');
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with re-authorize file store page. `false` otherwise.
 */
export const isAuthorizeFileStorePage = (pathName) => {
  return (pathName === '/reauthorize-cloud-store');
}

/**
 * @param {String} pathname Passed pathname.
 * @returns {Boolean} `true` when `pathname` is match with unsubscribe tasks-summary page. `false` otherwise.
 */
export const isUnsubscribePage = (pathName) => {
  let pathMatched = false;
  for (let path in unsubscribePageRegEx) {
    if (pathName.match(unsubscribePageRegEx[path])) {
      pathMatched = true;
      break;
    }
  }
  return pathMatched;
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with preferred-store-selection page. `false` otherwise.
 */
export const isPreferredStoreSelectionPage = (pathName) => {
  return (pathName === '/preferred-store-selection');
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with forgot-password page. `false` otherwise.
 */
export const isForgotPasswordPage = (pathName) => {
  return (pathName === '/forgot-password');
}

/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with leave app done page. `false` otherwise.
 */
export const isLeaveAppDonePage = (pathName) => {
  return (pathName === '/leave-app-done');
}


/**
 * @param {String} pathname Passed pathname.
 * @return {Boolean} `true` when `pathname` is match with blank page. `false` otherwise.
 */
export const isBlankPage = (pathName) => {
  return (pathName === '/blank-page');
}