import URI from '@dw/urijs-esm';
import { isViewPage } from './validate-url';

/**
 * This promise function is resolved with parsed page data if currently accessed url is an view page otherwise it's rejected.
 */
export const viewPageRouter = (data) => {
  return new Promise((resolve, reject) => {
    if (!data.user || !data.user.id || !isViewPage(data.path)) {
      reject(data);
      return;
    }
    const uri = new URI(data.path);
    const pathToIdHash = {
      'new': 'UPDATES',
      'assigned-to-me': 'ASSIGNED_TO_ME',
      'attention': 'ATTENTION',
      'needs-attention': 'ATTENTION',
      'done': 'GOT_DONE',
      'got-done': 'GOT_DONE',
      'due': 'WHATS_DUE',
      'whats-due': 'WHATS_DUE'
    }
    data.page = {
      name: 'VIEWS',
      params: {
        viewId: pathToIdHash[uri.segment()[1]],
        ...data.params
      }
    };

    resolve(data);
  });
}