import merge from 'lodash-es/merge';
import isEmpty from 'lodash-es/isEmpty';

// Check google tag manager event is track or not.
let isGA4EventTrack = window.K.config.enableGA4 === true || window.K.config.enableGA4 === 'true';

let GA4APIKey;

window.gtag = function() {};

/**
 * Initialize google tag manager.
 * @param {String} APIKey
 */
export const init = (APIKey, debug) => {
  if(!isGA4EventTrack) {
    return;
  }

  if(!APIKey) {
    isGA4EventTrack = false;
    return;
  }
  GA4APIKey = APIKey;

  //Create tag script and add into head.
  let ga = document.createElement('script');
  ga.type = 'text/javascript';
  ga.async = true;
  ga.src = `https://www.googletagmanager.com/gtag/js?id=${GA4APIKey}`;
  document.head.appendChild(ga);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
  if(debug) {
    window.gtag('config', GA4APIKey, { 'debug_mode': true });
  } else {
    window.gtag('config', GA4APIKey);
  }
}

/**
 * Track google tag manager event.
 * @param {String} name event name
 * @param {Object} properties event properties
 */
export const logEvent = (name, properties = {}) => {
  if(!isGA4EventTrack) {
    return;
  }

  const GA4DefaultDetails = {
    'send_to': [GA4APIKey]
  };

  window.gtag('event', name, merge({}, GA4DefaultDetails, properties || {}));
}

/**
 * Set GA4 user properties.
 * @param {Object} properties
 */
export const setUserProperties = (userId, properties) => {
  if(!isGA4EventTrack) {
    return;
  }

  properties = properties || {};
  if(userId) {
    window.gtag('config', 'MEASUREMENT_ID', {
      'user_id': userId
    });
    properties = merge({'kerika_user_id': userId}, properties);
  }

  if(!isEmpty(properties)) {
    window.gtag('set', 'user_properties', properties);
  }
}
