export const SET_QUERY = 'SEARCH_SET_QUERY';
export const SUGGESTIONS_DIALOG_OPENED_CHANGED = 'SEARCH_SUGGESTIONS_DIALOG_OPENED_CHANGED';

export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';

export const UPDATE_TYPE = 'SEARCH_UPDATE_TYPE';
export const UPDATE_SELECTED_BOARDS = 'SEARCH_UPDATE_SELECTED_BOARDS';

export const LOAD_CARDS_FILTER_DATA = 'SEARCH_LOAD_CARDS_FILTER_DATA';
export const LOAD_CARDS_FILTER_DATA_SUCCESS = 'SEARCH_LOAD_CARDS_FILTER_DATA_SUCCESS';
export const LOAD_CARDS_FILTER_DATA_FAILED = 'SEARCH_LOAD_CARDS_FILTER_DATA_FAILED';

export const UPDATE_CARD_FILTERS = 'SEARCH_UPDATE_CARD_FILTERS';
export const UPDATE_ATTACHMENT_FILTERS = 'SEARCH_UPDATE_ATTACHMENT_FILTERS';

export const RESET = 'SEARCH_RESET';

export const SET_HIGHLIGHTS = 'SEARCH_SET_HIGHLIGHTS';
export const RESET_HIGHLIGHTS = 'SEARCH_RESET_HIGHLIGHTS';

export const RESET_RESULTS = 'SEARCH_RESET_RESULTS';

export const UPDATE_REF_NO_TIP_LAST_SEEN = 'SEARCH_UPDATE_REF_NO_TIP_LAST_SEEN';


/**
 * Updates query in redux state.
 * @param {*} query 
 */
export const setQuery = (query) => {
  return {
    type: SET_QUERY,
    query
  }
}

/**
 * Sets `suggestionsDialogOpened` state into store.
 * @param {Boolean} opened `true` when suggestions dialog is opened.
 */
export const suggestionsDialogOpenedChanged = (opened) => {
  return {
    type: SUGGESTIONS_DIALOG_OPENED_CHANGED,
    opened
  }
}

/**
 * Requests server to get search result based on current type & filters.
 * Add / Update search history of current user on firebase.
 * @param {String} query Search string.
 */
export const search = (query) => {
  return {
    type: SEARCH,
    query
  }
}

/**
 * Based on curren selected type & selected boards, Stores search result into state.
 */
export const _searchSuccess = ({query, results, didYouMean, currentType, selectedBoards, currentBoardId}) => {
  return {
    type: SEARCH_SUCCESS,
    query,
    results,
    didYouMean,
    currentType,
    selectedBoards,
    currentBoardId
  }
}

/**
 * Sets request error details into state.
 * @property {String} error Error code.
 * @property {String} message Error message
 */
 export const _searchFailed = ({error, message}) => {
  return {
    type: SEARCH_FAILED,
    error,
    message
  }
}

/**
 * Updates current type of search.
 * @param {String} value Type of the search. Possible values: 'CARDS', 'ATTACHMENTS' or 'CANVASES'
 */
export const updateType = (value) => {
  return {
    type: UPDATE_TYPE,
    value
  }
}

/**
 * Updates current selected boards for search.
 * @param {String} value Included boards. Possible values: 'CURRENT' or 'OTHER'
 * @returns 
 */
export const updateSelectedBoards = (value) => {
  return {
    type: UPDATE_SELECTED_BOARDS,
    value
  }
}

/**
 * Updates card filters
 * @param {Object} filters 
 *  - @property {Array} searchFields e.g. `['TITLE', 'DESCRIPTION', 'TASK', 'CHAT']`
 *  - @property {Boolean} includeUnparticipatedBoards
 *  - @property {Boolean} templates
 *  - @property {Array} boardStatus e.g. `['ACTIVE', 'ARCHIVED', 'TRASHED']`
 *  - @property {Array} columnTypes e.g. `['OTHER', 'DONE', 'TRASH']`
 *  - @property {Object} filters e.g. `{ lastUpdatedFrom: null, lastUpdatedTo status: null, priority: null, dueDate: null, tags: null, assignments: null }
 */
export const updateCardFilters = (filters) => {
  return {
    type: UPDATE_CARD_FILTERS,
    filters
  }
}


/**
 * Updates attachment filters
 * @param {Object} filters 
 *  - @property {Array} docTypes e.g. `['DOC', 'SPREADSHEET', 'PRESENTATION', 'WEB', 'IMAGE', 'VIDEO']`
 *  - @property {Boolean} includeUnparticipatedBoards
 *  - @property {Boolean} templates
 *  - @property {Array} boardStatus e.g. `['ACTIVE', 'ARCHIVED', 'TRASHED']`
 *  - @property {Array} columnTypes e.g. `['OTHER', 'DONE', 'TRASH']`
 *  - @property {Object} filters e.g. `{ lastUpdatedAt: null }`
 */
export const updateAttachmentFilters = (filters) => {
  return {
    type: UPDATE_ATTACHMENT_FILTERS,
    filters
  }
}

/**
 * When type is 'CARDS', loads filter data for tags & assignments.
 * @param {Object} filters 
 *  - @property {Array} includeBoardIds e.g. `[1234566, 343343434]`
 *  - @property {Array} excludeBoardIds e.g. `[1234566, 343343434]`
 *  - @property {Boolean} includeUnparticipatedBoards
 *  - @property {Boolean} templates
 *  - @property {Array} boardStatus e.g. `['ACTIVE', 'ARCHIVED', 'TRASHED']`
 */
export const loadFilterData = (filters) => {
  return {
    type: LOAD_CARDS_FILTER_DATA,
    filters
  }
}

/**
 * Stores filter data into redux state.
 * @param {Object} data Card filter data. e.g. {tags, assignments}
 */
export const _loadFilterDataSuccess = (data) => {
  return {
    type: LOAD_CARDS_FILTER_DATA_SUCCESS,
    data
  }
}

/**
 * Dispatch this action load filter data failed.
 */
 export const _loadFilterDataFailed = () => {
  return {
    type: LOAD_CARDS_FILTER_DATA_FAILED
  }
}

/**
 * Resets all filters, suggestions & search result to it's default state.
 * Note: search highlghts will not be reset.
 */
export const reset = () => {
  return {
    type: RESET
  }
}

/**
 * Sets search highlights data into redux state.
 * @param {Object} data Highlights data. e.g. {query, matchedOn: ['title', 'tasks'], chatDetails, taskDetails, attachmentDetails}
 */
 export const setHighlights = (data) => {
  return {
    type: SET_HIGHLIGHTS,
    data
  }
 }

 /**
 * Resets search highlights.
 */
  export const resetHighlights = () => {
    return {
      type: RESET_HIGHLIGHTS
    }
  }

/**
 * Dispatch this action to reset a search results.
 */
export const resetResults = () => {
  return {
    type: RESET_RESULTS
  }
}

/**
 * Dispatch this action to update search reference number tip last seen time as a current time.
 */
export const updateRefNoTipLastSeen = () => {
  return {
    type: UPDATE_REF_NO_TIP_LAST_SEEN
  }
}