export const PAGE_OPENED = 'SIGNUP_PAGE_OPENED';
export const PAGE_CLOSED = 'SIGNUP_PAGE_CLOSED';

export const JOIN_USING_SECRET = 'JOIN_USING_SECRET';
export const JOIN_USING_SECRET_DONE = 'JOIN_USING_SECRET_DONE';
export const JOIN_USING_SECRET_FAILED = 'JOIN_USING_SECRET_FAILED';

export const ACCOUNT_LOAD_JOIN_SUGGESTED_LIST = 'ACCOUNT_LOAD_JOIN_SUGGESTED_LIST';
export const ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_DONE = 'ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_DONE';
export const ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_FAILED = 'ACCOUNT_LOAD_JOIN_SUGGESTED_LIST_FAILED';

export const JOIN_ACCOUNT = 'JOIN_ACCOUNT';
export const JOIN_ACCOUNT_DONE = 'JOIN_ACCOUNT_DONE';
export const JOIN_ACCOUNT_FAILED = 'JOIN_USING_SECRET_FAILED';

export const LOAD_JOINED_ACCOUNT_DATA_DONE = 'SIGNUP_LOAD_JOINED_ACCOUNT_DATA_DONE';
export const LOAD_JOINED_ACCOUNT_DATA_FAILED = 'SIGNUP_LOAD_JOINED_ACCOUNT_DATA_FAILED';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_DONE = 'CREATE_ACCOUNT_DONE';
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED';

export const CREATE_AUTO_BOARD = 'SIGNUP_CREATE_AUTO_BOARD';

export const UPDATE_SIGNUP_DETAILS = 'SIGNUP_UPDATE_SIGNUP_DETAILS';

export const MARK_JOIN_ACCOUNT_WELCOME_DIALOG_PRESENTED = 'SIGNUP_MARK_JOIN_ACCOUNT_WELCOME_DIALOG_PRESENTED';

export const UPDATE_UTM_DETAILS = 'SIGNUP_UPDATE_UTM_DETAILS';

/**
 * Dispatch action to join using secret.
 * @param {String} joinSecret join secet
 */
export const joinUsingSecret = (joinSecret) => {
  return {
    type: JOIN_USING_SECRET,
    joinSecret
  }
}


/**
 * Dispatch action to load join suggested account list.
 */
export const loadJoinSuggestedAccountList = () => {
  return {
    type: ACCOUNT_LOAD_JOIN_SUGGESTED_LIST
  }
}

/**
 * Dispatch action to join one of the suggested account.
 * @param {Number} accountId Account Id
 */
export const joinAccount = (accountId) => {
  return {
    type: JOIN_ACCOUNT,
    accountId
  }
}

/**
 * Dispatch action to create own account
 * @param {String} name Account Name
 * @param {String} boardData Board data. When it's provided, auto creates board.
 */
export const createAccount = (name, boardData) => {
  return {
    type: CREATE_ACCOUNT,
    name,
    boardData
  }
}

/**
 * Create a board using this templateId in user owned account.
 */
export const createAutoBoard = (templateId) => {
  return {
    type: CREATE_AUTO_BOARD,
    templateId
  }
}

/**
 * Dispatch action to update user singup details.
 * @param {String} source Where did the user signup?
 * @param {Object} details signup extra details like, boardId, templateId, etc.
 */
export const updateSignupDetails = (source, details) => {
  return {
    type: UPDATE_SIGNUP_DETAILS,
    source,
    details
  }
}

/**
 * Dispatch action to mark join account welcome dialog as presented.
 */
export const markJoinAccountWelcomeDialogPresented = () => {
  return {
    type: MARK_JOIN_ACCOUNT_WELCOME_DIALOG_PRESENTED
  }
}

/**
 * Dispatch actions to update utm-details.
 */
export const updateUtmDetails = ({userId, deviceId, details}) => {
  return {
    type: UPDATE_UTM_DETAILS,
    userId,
    deviceId,
    details
  }
}