import firestoreRedux from '@dreamworld/firestore-redux';

/**
 * Card selection actions.
 */
export const CARD_SELECTION_UPDATE = 'BOARD_CARD_SELECTION_UPDATE';
export const CARD_SELECTION_CLEAR = 'BOARD_CARD_SELECTION_CLEAR';
export const SELECT_ALL = 'BOARD_CARD_SELECT_ALL';
export const CARD_TOGGLE_SELECTION = 'BOARD_CARD_TOGGLE_SELECTION';
export const CARD_SELECTION_UPTO_CARD = 'BOARD_CARD_SELECTION_UPTO_CARD';

export const BOARD_LOADED = 'BOARD_LOADED';
export const UPDATE_BOARD_DRAG_STARTED = 'UPDATE_BOARD_DRAG_STARTED';

export const BOARD_TEAM_ADD_MEMBER = 'BOARD_TEAM_ADD_MEMBER';
export const BOARD_TEAM_ADD_MEMBER_IN_PROGRESS = 'BOARD_TEAM_ADD_MEMBER_IN_PROGRESS';
export const BOARD_TEAM_ADD_MEMBER_DONE = 'BOARD_TEAM_ADD_MEMBER_DONE';
export const BOARD_TEAM_ADD_MEMBER_FAILED = 'BOARD_TEAM_ADD_MEMBER_FAILED';

export const BOARD_TEAM_CHANGE_MEMBER_ROLE = 'BOARD_TEAM_CHANGE_MEMBER_ROLE';
export const BOARD_TEAM_CHANGE_MEMBER_ROLE_DONE = 'BOARD_TEAM_CHANGE_MEMBER_ROLE_DONE';
export const BOARD_TEAM_CHANGE_MEMBER_ROLE_FAILED = 'BOARD_TEAM_CHANGE_MEMBER_ROLE_FAILED';

export const BOARD_TEAM_REMOVE_MEMBER = 'BOARD_TEAM_REMOVE_MEMBER';
export const BOARD_TEAM_LOAD_KNOWN_USERS = 'BOARD_TEAM_LOAD_KNOWN_USERS';
export const LOAD_KNOWN_USERS_DONE = 'BOARD_TEAM_LOAD_KNOWN_USERS_DONE';
export const BOARD_TEAM_LOAD_ACCOUNT_SUBSCRIPTION = 'BOARD_TEAM_LOAD_ACCOUNT_SUBSCRIPTION';
export const BOARD_TEAM_DISCONNECT_KNOWN_USERS = 'BOARD_TEAM_DISCONNECT_KNOWN_USERS';
export const LOAD_COLUMNS = 'BOARD_LOAD_COLUMNS';
export const DISCONNECT_COLUMNS = 'BOARD_DISCONNECT_COLUMNS';

export const BOARD_TEAM_RESEND_INVITATION = 'BOARD_TEAM_RESEND_INVITATION';
export const BOARD_TEAM_RESEND_INVITATION_SUCCESS = 'BOARD_TEAM_RESEND_INVITATION_SUCCESS';
export const BOARD_TEAM_RESEND_INVITATION_FAILED = 'BOARD_TEAM_RESEND_INVITATION_FAILED';

export const MARK_WELCOME_DIALOG_PRESENTED = 'BOARD_MARK_WELCOME_DIALOG_PRESENTED';

export const BOARD_RENAME = 'BOARD_RENAME';

export const BOARD_COPY = 'BOARD_COPY';
export const BOARD_COPY_DONE = 'BOARD_COPY_DONE';
export const BOARD_COPY_FAILED = 'BOARD_COPY_FAILED';

export const USE_CASE_BOARD_COPY = 'USE_CASE_BOARD_COPY';
export const USE_CASE_BOARD_COPY_DONE = 'USE_CASE_BOARD_COPY_DONE';
export const USE_CASE_BOARD_COPY_FAILED = 'USE_CASE_BOARD_COPY_FAILED';

export const BOARD_UPDATE_FAVORITE = 'BOARD_UPDATE_FAVORITE';

export const LOAD_CURRENT_BOARD = 'BOARD_LOAD_CURRENT_BOARD';

export const BOARDS_LOAD_BASIC_DETAILS = 'BOARDS_LOAD_BASIC_DETAILS';
export const BOARDS_BASIC_DETAILS_DISCONNECT = 'BOARDS_BASIC_DETAILS_DISCONNECT';

export const SUBSCRIBE_MEMBERS = 'BOARD_SUBSCRIBE_MEMBERS';
export const UNSUBSCRIBE_MEMBERS = 'BOARD_UNSUBSCRIBE_MEMBERS';

export const SUBSCRIBE_ACCOUNT_MEMBERS = 'SUBSCRIBE_ACCOUNT_MEMBERS';
export const UNSUBSCRIBE_ACCOUNT_MEMBERS = 'UNSUBSCRIBE_ACCOUNT_MEMBERS';

export const UPDATE_BASIC_DETAIL = 'BOARDS_UPDATE_BASIC_DETAIL';
export const UPDATE_BASIC_DETAIL_DONE = 'BOARDS_UPDATE_BASIC_DETAIL_DONE';
export const UPDATE_BASIC_DETAIL_FAILED = 'BOARDS_UPDATE_BASIC_DETAIL_FAILED';

export const OPEN = 'BOARD_OPEN';

export const PAGE_OPENED = 'BOARD_PAGE_OPENED';
export const PAGE_CHANGED = 'BOARD_PAGE_CHANGED';
export const PAGE_CLOSED = 'BOARD_PAGE_CLOSED';

export const MARK_USE_TEMPLATE_WELCOME_DIALOG_PRESENTED  = 'MARK_USE_TEMPLATE_WELCOME_DIALOG_PRESENTED';

export const UPDATE_FRESH_USER_WIZARD_STAGE = 'BOARD_UPDATE_FRESH_USER_WIZARD_STAGE';

export const MARK_TASKBOARD_PARTICIPANT_VIDEO_WATCHED  = 'MARK_TASKBOARD_PARTICIPANT_VIDEO_WATCHED';
export const MARK_TASKBOARD_NON_PARTICIPANT_VIDEO_WATCHED = 'MARK_TASKBOARD_NON_PARTICIPANT_VIDEO_WATCHED';

export const MARK_WHITEBOARD_PARTICIPANT_VIDEO_WATCHED  = 'MARK_WHITEBOARD_PARTICIPANT_VIDEO_WATCHED';
export const MARK_WHITEBOARD_NON_PARTICIPANT_VIDEO_WATCHED = 'MARK_WHITEBOARD_NON_PARTICIPANT_VIDEO_WATCHED';

export const MARK_USE_CASE_WELCOME_DIALOG_PRESENTED = 'MARK_USE_CASE_WELCOME_DIALOG_PRESENTED';

/**
 * Loads all columns of board from Firestore
 * @param {String} requesterId Requester Id
 * @param {String} boardId board Id
 */
export const loadColumns = (requesterId, boardId) => {
  return {
    type: LOAD_COLUMNS,
    requesterId,
    boardId
  }
}

/**
 * Disconnects columns from Firestore.
 * @param {String} requesterId Requester Id
 */
export const disconnectColumns = (requesterId) => {
  return {
    type: DISCONNECT_COLUMNS,
    requesterId
  }
}

/**
 * Load user basic details for given user.
 * @param {String} requesterId Requester Id
 * @param {Number} userId
 */
 export const loadUserBasicDetails = (requesterId, userId) => dispatch => {
  firestoreRedux.getDocById('users', userId, { requesterId });
}

/**
 *
 * @param { Number } boardId - Id of board which data is ready on redux state
 */
export const boardLoaded = (boardId, cardSummary) => (dispatch) => {
  if(!boardId) {
    throw new Error('Board id is not found.');
  }
  dispatch({
    type: BOARD_LOADED,
    boardId,
    cardSummary
  });
}

/**
 * Set card seection for currently open board.
 * When current page is board then only set selected card for current board.
 * @param { Array } cardIds selected card Ids for current board.
 * @param { String } columnId Column Id in which card is selected.
 */
export const setSelection = (cardIds, columnId) => (dispatch) => {
  dispatch({
    type: CARD_SELECTION_UPDATE,
    cardIds,
    columnId
  });
}

/**
 * Given card already in selected-cards then remove card from selected-cards, otherwise add card into selected-cards.
 * @param { Number } cardId card Id for toggle selection.
 * @param { Number } columnId Column Id in which card is selected.
 */
export const toggleSelection = (cardId, columnId) => (dispatch) => {
  dispatch({
    type: CARD_TOGGLE_SELECTION,
    cardId,
    columnId
  });
}

/**
 * Card select upto given card.
 * @param { Number } cardId card Id
 * @param { Number } columnId Column Id in which change a card selection.
 */
export const selectUpto = (cardId, columnId) => (dispatch) => {
  dispatch({
    type: CARD_SELECTION_UPTO_CARD,
    cardId,
    columnId
  });
}

/**
 * Update drag start details for current board or not.
 * @param {Boolean} dragStart `true` when drag is started. `false` drag is completed or canceled.
 * @param {Array} cards
 */
export const updateDragStarted = (dragStarted, cardsOrColumn, mode = 'CARD') => (dispatch) => {
  dispatch({
    type: UPDATE_BOARD_DRAG_STARTED,
    start: dragStarted,
    cardsOrColumn,
    mode
  });
}

/**
 * Clear card selection for currently open board.
 */
export const clearSelection = () => (dispatch) => {
  dispatch({
    type: CARD_SELECTION_CLEAR
  });
}

/**
 * Select all cards into column.
 * @param {Number} columnId Column Id
 */
export const selectAllCards = (columnId) => {
  return {
    type: SELECT_ALL,
    columnId
  }
}

/**
 * Dispatch redux action to mark welcome dialog as presented.
 */
export const markWelcomeDialogAsPresented = () => {
  return {
    type: MARK_WELCOME_DIALOG_PRESENTED
  }
}

/**
 * Marks `useCaseWelcomeDialogPresented` to `true`.
 */
export const markUseCaseWelcomeDialogAsPresented = () => {
  return {
    type: MARK_USE_CASE_WELCOME_DIALOG_PRESENTED
  }
}

/**
 * Dispatch redux action to rename board.
 * @param {Number} boardId Board Id
 * @param {String} value Board Name
 */
export const rename = (boardId, value) => {
  return {
    type: UPDATE_BASIC_DETAIL,
    name: 'name',
    value,
    boardId
  }
}

/**
 * Dispatch redux action to update board description.
 * @param {Number} boardId Board Id
 * @param {String} value Board description
 */
export const updateDescription = (boardId, value) => {
  return {
    type: UPDATE_BASIC_DETAIL,
    name: 'description',
    value,
    boardId
  }
}

/**
 * Dispatch redux action to update template category.
 * @param {Number} boardId Board Id
 * @param {String} value template category
 */
export const updateCategory = (boardId, value) => {
  return {
    type: UPDATE_BASIC_DETAIL,
    name: 'templateCategory',
    value,
    boardId
  }
}

/**
 * Dispatch redux action to update board language.
 * @param {Number} boardId Board Id
 * @param {String} value board language
 */
export const updateLang = (boardId, value) => {
  return {
    type: UPDATE_BASIC_DETAIL,
    name: 'lang',
    value,
    boardId
  }
}

/**
 * Update `WIP limit` preference for given board.
 */
 export const updateWIP = (boardId, value) => {
  return {
    type: UPDATE_BASIC_DETAIL,
    name: 'wipLimitEnabled',
    value,
    boardId
  }
 }

 /**
 * Updates `tagsEnabled` setting for given board.
 */
  export const updateTags = (boardId, value) => {
    return {
      type: UPDATE_BASIC_DETAIL,
      name: 'tagsEnabled',
      value,
      boardId
    }
  }

/**
 * Dispatch redux action to favorite/unfavorite board.
 * @param {Strung} boardId Board Id
 * @param {Boolean} hideToast When it's true, doesn't show toast message.
 */
export const updateFavorite = (boardId, hideToast) => {
  return {
    type: BOARD_UPDATE_FAVORITE,
    boardId,
    hideToast
  }
}

/**
 * Dispatch action to copy board.
 * @param {Number} boardId Board Id
 * @param {Number} accountId Account Id
 * @param {Number} name Board name
 */
export const copy = (boardId, accountId, name, boardType) => {
  return {
    type: BOARD_COPY,
    boardId,
    accountId,
    name,
    boardType
  }
}

/**
 * Dispatches an action to copy a use case board.
 * @param {number} boardId - The ID of the board to copy.
 * @param {number} accountId
 */
export const useCaseBoardCopy = (boardId, accountId) => {
  return {
    type: USE_CASE_BOARD_COPY,
    boardId,
    accountId,
  }
}

export const _useCaseBoardCopyDone = (boardId, accountId) => {
  return {
    type: USE_CASE_BOARD_COPY_DONE,
    boardId,
    accountId
  }
}

export const _useCaseBoardCopyFailed = (boardId, accountId, error) => {
  return {
    type: USE_CASE_BOARD_COPY_FAILED,
    boardId,
    accountId,
    error
  }
}

/**
 * Adds new member into board.
 * @param {Object} param0
 */
export const addMember = ({ requestId, boardId, emails, role }) => {
  return {
    type: BOARD_TEAM_ADD_MEMBER,
    requestId,
    boardId,
    emails,
    role
  }
}

/**
 * Opens provided Board.
 * @param {Number} boardId Board Id
 * @param {Number} accountId Account Id
 */
export const open = (boardId, accountId) => {
  return {
    type: OPEN,
    boardId,
    accountId
  }
}

export const loadKnownUsers = (requesterId) => {
  return {
    type: BOARD_TEAM_LOAD_KNOWN_USERS,
    requesterId
  }
}

/**
 * Dispatch when board page is opened.
 * @param {String} id board id.
 */
export const _pageOpened = (id) => {
  return {
    type: PAGE_OPENED,
    id
  }
}

/**
 * Dispatch when board page is change(e.g. switch board using board switcher).
 * @param {String} id board id.
 * @param {String} previousBoardId Privious opened board id.
 */
export const _pageChanged = (id, previousBoardId) => {
  return {
    type: PAGE_CHANGED,
    id,
    previousBoardId
  }
}

/**
 * Dispatch when board page is closed.
 * @param {String} id board id.
 */
export const _pageClosed = (id) => {
  return {
    type: PAGE_CLOSED,
    id
  }
}

/**
 * Loads current board data.
 */
export const loadCurrentBoard = () => {
  return {
    type: LOAD_CURRENT_BOARD
  }
}

/**
 * Loads board's basic details.
 * @param {String} requesterId
 * @param {Array} boardIds e.g. [12344, 343434].
 */
export const loadBoardsBasicDetails = (requesterId, boardIds) => {
  return {
    type: BOARDS_LOAD_BASIC_DETAILS,
    requesterId,
    boardIds
  }
}

/**
 * Loads board members from firestore.
 * @param {String} boardId Board Id.
 */
export const subscribeMembers = (boardId) => {
  return {
    type: SUBSCRIBE_MEMBERS,
    boardId
  }
}

/**
 * Unsubscribes board members from firestore.
 * @param {String} boardId Board Id.
 */
export const unsubscribeMembers = (boardId) => {
  return {
    type: UNSUBSCRIBE_MEMBERS,
    boardId
  }
}

/**
 * Loads account members from firestore.
 * @param {String} accountId Board Id.
 */
export const subscribeAccountMembers = (accountId) => {
  return {
    type: SUBSCRIBE_ACCOUNT_MEMBERS,
    accountId
  }
}

/**
 * Unsubscribes account members from firestore.
 * @param {String} accountId Board Id.
 */
export const unsubscribeAccountMembers = (accountId) => {
  return {
    type: UNSUBSCRIBE_ACCOUNT_MEMBERS,
    accountId
  }
}

/**
 * Disconnects boards details.
 * @param {String} requesterId
 */
export const disconnectBoardsBasicDetails = (requesterId) => {
  return {
    type: BOARDS_BASIC_DETAILS_DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to mark use template welcome dialog as presented.
 */
export const markUseTemplateWelcomeDialogPresented = () => {
  return {
    type: MARK_USE_TEMPLATE_WELCOME_DIALOG_PRESENTED
  }
}

/**
 * Dispatch action to mark taskboard participant video watched.
 */
export const markTaskboardParticipantVideoWatched = () => {
  return {
    type: MARK_TASKBOARD_PARTICIPANT_VIDEO_WATCHED
  }
}

/**
 * Dispatch action to mark taskboard non participant video watched.
 */
export const markTaskboardNonParticipantVideoWatched = () => {
  return {
    type: MARK_TASKBOARD_NON_PARTICIPANT_VIDEO_WATCHED
  }
}

/**
 * Dispatch action to mark whiteboard participant video watched.
 */
export const markWhiteboardParticipantVideoWatched = () => {
  return {
    type: MARK_WHITEBOARD_PARTICIPANT_VIDEO_WATCHED
  }
}

/**
 * Dispatch action to mark whiteboard non participant video watched.
 */
export const markWhiteboardNonParticipantVideoWatched = () => {
  return {
    type: MARK_WHITEBOARD_NON_PARTICIPANT_VIDEO_WATCHED
  }
}

/**
 * Resend invitation to given members for given board.
 * @param {Number} boardId
 * @param {Array} members
 */
export const resendInvitation = (boardId, members) => {
  return {
    type: BOARD_TEAM_RESEND_INVITATION,
    boardId,
    members
  }
}

export const _resendInvitationFailed = (boardId, members, error) => {
  return {
    type: BOARD_TEAM_RESEND_INVITATION_FAILED,
    boardId,
    members,
    error
  }
}

export const _resendInvitationSuccess = (boardId, members) => {
  return {
    type: BOARD_TEAM_RESEND_INVITATION_SUCCESS,
    boardId,
    members
  }
}