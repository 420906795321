import startsWith from 'lodash-es/startsWith';
import isArray from 'lodash-es/isArray';
import { show as showSnackBar } from './components/kerika-snackbar.js';
import i18next from '@dw/i18next-esm';
import { retry } from '@lifeomic/attempt';
import { store } from './store';
import * as app from './redux/app';
import * as device from './redux/device';
import { getCookieVal } from './utils.js';

const NETWORK_ERROR_RETRY_TIME = 2000; //In milliseconds.

/**
 *
 * @param {String} url - API Url. If it is start with `/` then add app base url as postfix.
 * @param {Object} oParams - Request data
 *  - `mode` - By default its value is `cors`
 *  - `credentials` - By defalt its value is `include`
 *  - `headers` - By default it sets `Content-Type": "application/json`
 *  - `body` - Set request body as stringify json. If it is JSON then it strigify the request body data.
 *  - `excludeErrors`: Array of HTTP status code which should not logged as error when API is failed with this status code.
 *  - `log401AsError` {Boolean}: If true then also log 401 as an error.
 * @return Promise -
 *  - Resolved only when API response is OK with response body data only, otherwise reject promise with state and error code and message
 *
 * NOTE: If Any API is failed with `401` status code then it fires `authentication-denied` event on window and does not log as error log to logout from applition to user.
 * Note: Fetch method documentation:  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */
export const requestApi = async (url, oParams) => {
  const underMaintenance = app.selectors.isUnderMaintenance(store.getState());
  if (underMaintenance) {
    return;
  }
  let params = { ...oParams };

  if (!params) {
    params = {};
  }

  if (startsWith(url, '/')) {
    url = window.K.config.apiBaseUrl + url;
  }

  //Options for CORS request
  if (!params.mode) {
    params.mode = 'cors';
  }

  if (!params.credentials) {
    params.credentials = "include";
  }

  if (!params.headers) {
    params.headers = {
      "Content-Type": "application/json"
    };
  }

  const deviceId = device.selectors.getId();
  if(deviceId) {
    params.headers['Device-Id'] = deviceId;
  }

  if (startsWith(url, window.K.config.apiBaseUrl)) {
    //Set `x-app` header in each request so webapp can identify that request is came from PWA application
    params.headers['X-App'] = 'pwa-' + window.K.config.version;
  }

  if (location.host.includes('localhost')) {
    params.headers['X-Auth-Token'] = getCookieVal('SESSION');
  }

  if (params.body && typeof params.body !== "string" && !(params.body instanceof FormData)) {
    params.body = JSON.stringify(params.body);
  }

  if (params.body instanceof FormData) {
    if (params.headers && params.headers['Content-Type']) {
      delete params.headers['Content-Type'];
    }
  }

  let aExcludeErrors = (params.excludeErrors && isArray(params.excludeErrors)) ? [...params.excludeErrors] : [];
  delete params.excludeErrors;


  let log401AsError = params.log401AsError || false;
  delete params.log401AsError;

  let res;
  let toastActionButton
  if (params.method && params.method.toLowerCase() !== 'get') {
    const reload = () => window.location.reload();
    toastActionButton = { caption: i18next.t('buttons.reload'), callback: reload };
  }
  try {
    res = await _retryFetch(url, params);
  } catch (err) {
    // When server request failed due to network error, Updates connection status to offline.
    if (isNetworkError(err) && !isAbortError(err)) {
      const online = app.selectors.isOnline(store.getState());
      if (online || online === undefined) {
        store.dispatch(app.actions.updateAppConnectionStatus(false));
      }
      res = await _retryOnNetworkError(url, params);
    } else {
      return new Promise((resolve, reject) => {reject(err)});
    }
  }

  if (res.status && res.status === 401) {
    if(log401AsError) {
      console.error(`API failed. url: ${url}, statuscode: ${res.status}, res:`, res);
    }
    // Dispatch 'authentication-denied' event on window when API is failed due to user is not logged in
    window.dispatchEvent(new CustomEvent('authentication-denied', {}));
    //Reject promise when 401 is including in excludes-error code
    if (aExcludeErrors.indexOf(res.status) != -1) {
      //return a Promise which is never resolved.
      return new Promise((resolve, reject) => {reject(res)});
    }
    return new Promise(() => { });
  }

  let responseText;
  try {
    responseText = await res.text();
    responseText = responseText.trim();
  } catch (err) {
    //Logged as an error only if it did not fail due to a network error or abort request.
    if (err && err.code && err.code != err.ABORT_ERR) {
      console.error("Failed to retrieve responseText", err);
    }
  }

  let responseJSON;
  try {
    responseJSON = JSON.parse(responseText);
  } catch (e) {
    //ignore
  }

  if (res.ok) {
    return getResponseObject(responseJSON, responseText, res.status);
  }

  let body = responseText || JSON.stringify(responseJSON || {});

  if(responseJSON && responseJSON.code === 'ACCESS_COOKIE_NOT_AVAILABLE') {
    // Dispatch 'access-cookie-not-available' event on window when API is failed due to access cookie is not available
    window.dispatchEvent(new CustomEvent('access-cookie-not-available', {}));
    return new Promise(() => { });
  }
  
  if(responseJSON && responseJSON.code === 'MALICIOUS_URL_FOUND') {
    showSnackBar({ message: i18next.t('toast.maliciousUrl'), type: 'ERROR' });
  } else if (responseJSON && responseJSON.code === 'ID_IN_USE') {
    let Id = oParams && oParams.body && oParams.body.id;
    showSnackBar({ message: 'Something went wrong. Please try again.', type: 'ERROR', actionButton: toastActionButton });
    console.error(`API Failed as entity-id was already in use. Id: ${Id}, url: ${url}, statuscode: ${res.status}, body:${body}`);
  } else if (aExcludeErrors.indexOf(res.status) == -1 && res.status !== 503) {
    // If it's not expected error by the requester, need to log this as an Sentry error.
    if(res && res.status === 504) {
      showSnackBar({ message: i18next.t('toast.504')});
    } else {
      console.error(`API failed. url: ${url}, statuscode: ${res.status}, body:${body}`);
      showSnackBar({ message: `API failed. status-code: ${res.status}`, type: 'ERROR', actionButton: toastActionButton });
    }
  }
  return getResponseObject(responseJSON, responseText, res.status);
}

let getResponseObject = (json, text, status) => {
  let error = status && status >= 400;
  if (!error) {
    if (json) {
      return json;
    }

    if (!text) {
      return;
    }
  }


  if (json) {
    throw { ...json, status };
  }

  throw {
    status: status,
    code: 'JSON_PARSE_FAILED',
    error: 'Failed to parse resposne as Json.',
    text: text
  };
}

/**
 * @param {String} url - API Url.
 * @param {Object} oParams - Request data
 * @param {Number} maxAttempts - Maximum retry attempt.Default is 5.
 * @param {Number} delay delay between retry.
 * Retry fetch API when it's failed due to network error.
 */
const _retryFetch = async (url, params, maxAttempts=5, delay=200) => {
  let retryOptions = {
    factor: 2,
    maxAttempts,
    delay,
    maxDelay: 1000
  };

  try {
    return await retry(() => {
      return _fetch(url, params)
    }, retryOptions);
  } catch (error) {

    //If api is failed due to network error.
    if(!error.status) {
      throw error;
    }
    return error;
  }
}


/**
 * @param {String} url - API Url.
 * @param {Object} oParams - Request data
 * Wrapper of fetch js api.
 * @returns {Promise} - When api is failed due to network error then promise reject.
 *                    - When api mehtod is `PUT` and error code is 500 then promise reject.
 *                    - Otherwise resolve a promise.
 */
const _fetch = async (url, params) => {
  let res;
  try {
    res = await fetch(url, params);
  } catch (error) {
    throw error;
  }

  return new Promise((resolve, reject) => {
    if(params && params.method == 'PUT' && res && res.status == 500) {
      reject(res);
    } else {
      resolve(res);
    }
  });
}

/**
 * Retries server requests until it resolved.
 */
export const _retryOnNetworkError = async (url, params) => {
  return await retry(async () => {
    try {
      const res = await fetch(url, params);
      if (!app.selectors.isOnline(store.getState())) {
        store.dispatch(app.actions.updateAppConnectionStatus(true));
      }
      return res;
    } catch (error) {
      store.dispatch(app.actions.updateLastConnectionFailTime());
      return new Promise((resolve, reject) => {reject(error)});
    }
  }, { delay: NETWORK_ERROR_RETRY_TIME, maxAttempts: 0 });
}

/**
 * @param {Object} error Error
 * @returns `true` when error or error code is not found.
 */
export const isNetworkError = (error) => {
  return !error ||  isAbortError(error) || (!error.code && !error.status);
}

/**
 * @param {Object} error Error
 * @returns `true` when request is canceled
 */
export const isAbortError = (error) => {
  return error && error.code && error.ABORT_ERR && error.code == error.ABORT_ERR;
}

export default requestApi;