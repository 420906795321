import { css } from 'lit-element';
export const scrollStyles = css`
    /* START: non-webkit Browser scroll css */
    :host([wide-layout]),
    :host([wide-layout]) .mdc-dialog__content,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content,
    .scroll-style,
    :host([type="popover"]),
    :host([wide-layout]) *,
    :host([wide-layout]) .mdc-dialog__content *,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content *,
    .scroll-style *,
    :host([type="popover"]) * {
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.32) transparent;
    }
    /* END: non-webkit Browser scroll css */

    /* START: webkit Browser scroll css */
    :host([wide-layout]) .mdc-dialog__content::-webkit-scrollbar,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content::-webkit-scrollbar,
    :host([wide-layout]) ::-webkit-scrollbar,
    .scroll-style::-webkit-scrollbar,
    :host([type="popover"]) ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    :host([wide-layout]) .mdc-dialog__content::-webkit-scrollbar-track,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content::-webkit-scrollbar-track,
    .scroll-style::-webkit-scrollbar-track,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content::-webkit-scrollbar-track,
    :host([type="popover"]) ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    :host([wide-layout]) .mdc-dialog__content::-webkit-scrollbar-thumb,
    :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content::-webkit-scrollbar-thumb,
    :host([wide-layout]) ::-webkit-scrollbar-thumb,
    .scroll-style::-webkit-scrollbar-thumb,
    :host([type="popover"]) ::-webkit-scrollbar-thumb {
      background-color : rgba(0, 0, 0, 0.32);
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: calc(12px / 2);
    }
    /* END: webkit Browser scroll css */
`;