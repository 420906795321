import forEach from 'lodash-es/forEach.js';

/**
 * Provides a way to gets all drop files or folder all files using drag and drop.
 * This module export a `getFiles` async functions, In this function you pass the drop event, and gets all the files.
 */

const readEntriesAsync = (rootEntry) => {
  const reader = rootEntry.createReader(), entriesArr = [];
  const readRecursive = (resolve, reject) => {
    reader.readEntries(entries => {
      if (entries.length === 0) {
        return resolve(entriesArr);
      }
      entries.forEach(entry => entriesArr.push(entry));
      readRecursive(resolve, reject);
    }, reject);
  };

  return new Promise(readRecursive);
};

const __readFiles = async (item) => {
  let itemEntry = item.webkitGetAsEntry();
  if (itemEntry && itemEntry.isDirectory) {
    const entriesList = await readEntriesAsync(itemEntry);
    return Promise.all(entriesList.map(entry => {
      if(entry.isFile) {
        return new Promise((resolve, reject) => {
          entry.file((file) => resolve(file), reject);
        });
      }
    }));
  }

  return Promise.resolve([item.getAsFile && item.getAsFile() || null]);
};

const readFilesAsync = async (items) => {
  let files = [];
  forEach(items, (item) => {
    files.push(item);
  });

  items = await Promise.all(files.map(item => {
    return __readFiles(item);
  }));

  files = [];
  forEach(items, (value) => {
    forEach(value, (file) => {
      if(file && file.name) {
        files.push(file);
      }
    });
  });
  return Promise.resolve(files);
}

/**
 * @param {Event} event passed drop event.
 * @returns {Array} all the drop files.
 */
export const getFiles = async (ev) => {
  if (!ev.dataTransfer.items) {
    return Promise.resolve(ev.dataTransfer.files || []);
  }

  let files = await readFilesAsync(ev.dataTransfer.items);
  return Promise.resolve(files || []);
}

export default getFiles;