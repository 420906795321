import { isFullBoardUrl } from './compute-full-url';
import URI from '@dw/urijs-esm';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';
import firestoreRedux from '@dreamworld/firestore-redux';
import { from64 } from './router-utils.js';

/**
 * This promise function is resolved with parse page data if currently accessed url is a board page otherwise reject promise.
 */
export const boardPageRouter = (data) => {
  return new Promise(async (resolve, reject) => {
    if(!isFullBoardUrl(data.path)) {
      reject(data);
      return;
    }

    const uri = new URI(data.path);
    const accountId = uri.segment()[0];
    const boardId = uri.segment()[2];
    const innerPageId = uri.segment()[3] || '';
    const cpageView = uri.segment()[1] === 'c';
    let cardId;
    let canvasRefId;
    if(innerPageId && innerPageId.startsWith('cnvs_')) {
      canvasRefId = innerPageId.split('cnvs_')[1] || '';
      canvasRefId = from64(canvasRefId);
    } else {
      if(innerPageId && innerPageId.startsWith('crd_')) {
        cardId = innerPageId;
      }
    }


    data.accountId = accountId;

    // When board exists in accessible board.
    if(get(data, `accessibleBoards.${accountId}.${boardId}`)) {
      data.dialog= {};
      const boardType = (data.accessibleBoards[accountId][boardId] === 'WHITEBOARD') ? 'WHITEBOARD' : 'TASKBOARD';
      data.page = _getPageData({ boardId, type: boardType, cpageView, canvasRefId });
      data.dialog = _getDialogData({ boardId, cardId });
      resolve(data);
      return;
    }


    try {
      const query = firestoreRedux.getDocById(`boards`, boardId, { once: true });
      const doc = await query.result;
      // For public board.
      if (!isEmpty(doc)) {
        let hashAccess = doc.privacy === 'PUBLIC';
        if(hashAccess) {
          data.page = _getPageData({ boardId, type: doc.type, cpageView, canvasRefId });
          data.dialog = _getDialogData({ boardId, cardId });
          resolve(data);
          return;
        } else {
          throw new Error('permission-denied');
        }
      }
    } catch (error) {
      // For Private board.
      const errorString = error && error.toString && error.toString() || '';
      if (error && ((error.includes && error.includes('permission-denied')) || (errorString && errorString.includes('permission-denied')))) {
        data.page = _getPageData({ boardId, cpageView, privatePage: true, canvasRefId });
        data.dialog = _getDialogData({ boardId, cardId });
        resolve(data);
        return;
      }
    }

    // When board doesn't exists.
    data.dialog = {};
    data.page = {
      name: 'NOT_FOUND'
    };
    resolve(data);
    return;
  });
}

/**
 *
 * @param {*} param0
 *  @property {Object} board Board detail. e.g. {}
 * @returns
 */
const _getPageData = ({boardId, type, cpageView, canvasId, privatePage, canvasRefId}) => {
  //Board type
  let boardType = (type === 'WHITEBOARD' || type === 'RECTANGLE' || type === 'ELLIPSE' || type === 'RHOMBUS') ? 'WHITEBOARD' : 'TASKBOARD';
  let pageType = !!canvasRefId ? 'WHITEBOARD': boardType;
  let params = _getQueryParams();
  return {
    name: 'BOARD',
    params: {
      type: boardType || 'TASKBOARD',
      boardId,
      pageType,
      canvasId: (boardType === 'WHITEBOARD') ? canvasId : '',
      canvasRefId,
      private: privatePage || false,
      cpageView,
      ...params
    }
  };

};


const _getDialogData = ({ boardId, cardId }) => {
  if (!cardId) {
    return {};
  }

  //Board card detail dialog
  const params = _getQueryParams();
  let tab = 'description';
  const queryTabParam = new URI().query(true).tab;
  if (queryTabParam && queryTabParam.match("^(description|tasks|comments|attachments|history)$")) {
    tab = queryTabParam;
  }
  params.tab = tab;

  return {
    name: 'CARD',
    params: {
      boardId,
      cardId,
      ...params
    }
  }
}

/**
 * @return { Object } - All query parameters hash of current url.
 */
const _getQueryParams = () => {
  return new URI().query(true);
}