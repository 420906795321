export * as reselct from 'reselect';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash-es/isEqual';

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, { resultEqualityCheck: isEqual });

export default (options) => {
  const maxSize = options.maxSize || 1;
  const resultEqualityCheck = options.resultEqualityCheck || isEqual;
  const equalityCheck = options.equalityCheck || function defaultEqualityCheck(a, b) { return a === b; };
  return createSelectorCreator(defaultMemoize, {resultEqualityCheck, maxSize, equalityCheck});
};