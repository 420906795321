import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';
import forEach from 'lodash-es/forEach';
import dropRight from 'lodash-es/dropRight';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual.js';
import filter from 'lodash-es/filter';
import uniqWith from 'lodash-es/uniqWith';
import values from 'lodash-es/values';
import * as utils from '../../utils';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as boardUtils from '../board/utils.js';
import { createSelector } from 'reselect';

/**
 * Maximum how many number of board shows in the list of board switcher.
 */
const boardSwitcherMaxItems = 12;

export const _boardSwitcherItemList = createSelector(
  (state) => firestoreRedux.selectors.collection(state, 'board-switcher'),
  (switcherList) => {
    if(isEmpty(switcherList)) {
      return [];
    }

    let list = values(switcherList);
    list = filter(list, doc => doc && doc.type === 'BOARD');

    //Sort data based on `openedAt`.
    list = sortBy(list, ['openedAt']).reverse();
    list = uniqWith(list, (item1, item2) => {
      return item1.id == item2.id;
    });

    //Removes item after `boardSwitcherMaxItems`.
    if (list.length > boardSwitcherMaxItems) {
      let drop = list.length - boardSwitcherMaxItems;
      list = dropRight(list, drop);
    }

    return list;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  });

/**
 * @param {Object} state Redux state
 * @returns List of Boards which are accessible to user & exists in quick switcher list
 * 	 - `boardSwitcherMaxItems` boards maximum in the list.
 * 	 - Include `boardSwitcherMaxItems` boards shown will be the one that was most recently visited by the user.
 *   - Board list group by account and account and board wise alphabetically sort.
 *  e.g: [
 *          {
 *           id: 1234,
 *           name: "arun's accouns",
 *           boards: [{id: 1233, name: 'board1'}]
 *          },
 *          {
 *           id: 5678,
 *           name: "Chirag's accouns",
 *           boards: [{id: 99999, name: 'board2'}]
 *          }
 *        ]
 */

export const list = createSelector(
  (state) => firestoreRedux.selectors.collection(state, 'board-switcher'),
  (state) => firestoreRedux.selectors.collection(state, 'boards'),
  (state) => firestoreRedux.selectors.collection(state, 'board-move-requests'),
  (state) => firestoreRedux.selectors.collection(state, 'accounts'),
  (state) => firestoreRedux.selectors.collection(state, 'board-unread-user-details'),
  (state) => auth.selectors.currentUserId(state),
  auth.selectors.accessibleBoards,
  router.selectors.pageBoardId,
  (state) => get(state, `router.page.params.viewId`),
  app.selectors.wideLayout,
  (switcherList, boards, boardMoveRequest, accounts, boardUnreadUserDetails, userId, accessibleBoards, currentBoardId, currentViewId, wideLayout) => {
    let list = [];
    let viewsList = [];
    const userIdWithoutPrefix = userId && utils.getIdWoPrefix({id: userId, prefix: 'usr_'});
    forEach(switcherList, (doc) => {
      if (doc && doc.type === 'VIEWS' && wideLayout) {
        viewsList.push({ id: doc.viewId, selected: currentViewId == doc.viewId, openedAt: doc.openedAt, type: 'VIEWS' });
      } else if (doc && doc.type === 'BOARD' && get(accessibleBoards, `${doc.accountId}.${doc.boardId}`)) {
        let attrs = get(boards, doc.boardId);
        if (!isEmpty(attrs)) {
          const _boardMoveRequest = get(boardMoveRequest, `bmr_${utils.getIdWoPrefix({ id: doc.boardId, prefix: 'brd_' })}`);
          attrs = boardUtils.attrs(attrs, _boardMoveRequest);
          const _boardIsActive = boardUtils.isBoardActive(attrs);
          const unreadDoc = get(boardUnreadUserDetails, `buu_${utils.getIdWoPrefix({ id: doc.boardId, prefix: 'brd_' })}_${userIdWithoutPrefix}`) || {};
          const unread = _boardIsActive && unreadDoc && unreadDoc.anyUnread && unreadDoc.unread && (unreadDoc.unread.name || unreadDoc.unread.columns || unreadDoc.unread.attachments || unreadDoc.unread.chats) || false;
          list.push({
            id: attrs.id,
            accountId: attrs.accountId,
            name: attrs.name,
            type: attrs.type,
            canvasId: attrs.type === 'WHITEBOARD' ? attrs.canvasId : 0,
            status: attrs.status === 'TRASHED' ? 'TRASHED' : attrs.status === 'ARCHIVED' ? 'ARCHIVED' : 'ACTIVE',
            template: attrs.template,
            selected: attrs.id == currentBoardId,
            openedAt: doc.openedAt || 0,
            unread
          });
        }
      }
    });

    //Sort data based on `openedAt`.
    list = sortBy(list, ['openedAt']).reverse();
    list = uniqWith(list, (item1, item2) => {
      return item1.id == item2.id;
    });

    //Removes item after `boardSwitcherMaxItems`.
    if (list.length > boardSwitcherMaxItems) {
      let drop = list.length - boardSwitcherMaxItems;
      list = dropRight(list, drop);
    }

    //Sort data based on boards name.
    list = sortBy(list, (o) => o.name && o.name.toLocaleLowerCase());

    //Group by accountId
    list = groupBy(list, 'accountId');

    let boardList = [];
    forEach(list, (boards, accountId) => {
      const account = get(accounts, accountId);
      boardList.push({ id: accountId, name: get(account, 'name', ''), boards });
    });

    //Sort data based account name.
    boardList = sortBy(boardList, (o) => o.name && o.name.toLocaleLowerCase());

    return [...viewsList, ...boardList];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * board switcher list has unread board or not.
 */
export const unread = createSelector(list, (switcherList) => {
  let hasUnread = false;
  forEach(switcherList, (value) => {
    if (value.type === 'VIEWS') {
      return true;
    } else {
      forEach(value.boards, (board) => {
        if(board && board.unread) {
          hasUnread = true;
          return false;
        }
      });
    }

    if(hasUnread) {
      return false;
    }
  });

  return hasUnread;
});

/**
 * Purpose: hide or show board switcher to user.
 * @returns {Number} How many items are in the quick switcher list.
 */
export const boardSwitcherItemsCount = createSelector(list, (switcherList) => {
  let listLength = 0;
  forEach(switcherList, (value) => {
    if (value.type === 'VIEWS') {
      listLength += 1;
    } else {
      forEach(value.boards, () => {
        listLength += 1;
      });
    }
  });

  return listLength;
});

/**
 * @returns {Number} Count of accounts into switcher list.
 */
export const accountsCount = createSelector(list, (switcherList) => {
  const accountsList = filter(switcherList, (item) => item.type !== 'VIEWS');
  return accountsList.length;
});

/**
 * @returns {Boolean} true when board switcher is accessible.
 */
export const isBoardSwitcherAccessible = createSelector(router.selectors.pageName, app.selectors.wideLayout, (pageName, wideLayout) => {
  return wideLayout || pageName === 'BOARD' || pageName === 'VIEWS' || pageName === 'BOARD_EXPLORER';
});
