export const MARK_AS_READ = 'UNREAD_HIGHLIHTS_MARK_AS_READ';
export const MARK_ALL_AS_READ = 'UNREAD_HIGHLIHTS_MARK_ALL_AS_READ';

/**
 * Marks unread highlights of the entity attributes as read.
 * @param {String} entityId Entity Id which will be marked as read.
 * @param {Array} attributes List of attributes which would be marked as read. e.g. ['title', 'dueDate', 'description'] etc...
 */
export const markAsRead = (entityId, attributes) => {
  return {
    type: MARK_AS_READ,
    entityId,
    attributes
  }
}

/**
 * Marks all unread highlights of the provided entity ids as read.
 * @param {Array} entityIds List of entity ids. e.g. ['crd_j4fieie4x7rfjsf', ...]
 */
export const markAllAsRead = (entityIds, actionPerform, localWritesData = {}) => {
  return {
    type: MARK_ALL_AS_READ,
    entityIds,
    actionPerform,
    localWritesData
  }
}