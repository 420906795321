import { sagaMiddleware, store } from '../../store';
import saga from './saga';

import * as _actions from './actions';
import * as _selectors from './selectors';
import * as _reducers from './reducers.js';

export const actions = _actions
export const selectors = _selectors;

sagaMiddleware.run(saga);
store.addReducers({
  'manage-account': _reducers.manageAccount
});
