import isDeviceReady from './device-ready.js';
import isFullScreen from './fullscreen.js';
import * as app from './redux/app';
import { store } from './store.js';

/**
 * Lock orientation mode as a `portrait`.
 */
export const lockPortrait = async () => {
  await isDeviceReady();
  if(isFullScreen() || app.selectors.isInstalledApp(store.getState())) {
    lock('portrait');
  }
}

/**
 * Lock orientation mode as a `landscape`.
 */
export const lockLandscape = async () => {
  await isDeviceReady();
  if(isFullScreen() || app.selectors.isInstalledApp(store.getState())) {
    lock('landscape');
  }
}

/**
 * @param {String} mode 
 * Lock orientation mode as given `mode`.
 */
const lock = (mode) => {
  window.screen && window.screen.orientation && window.screen.orientation.lock && window.screen.orientation.lock(mode).catch((error)=> {
    //If orientation lock is not supported for current device.
    if(error && error.code === 9) {
      return;
    }
    console.error(error)
  });
}