import { css } from 'lit-element';
import * as typographyLiterals from '@dreamworld/material-styles/typography-literals.js';
export const typography = css`
  .paper-font-display4, h1,
  .paper-font-display3, h2,
  .paper-font-title, h6,
  .paper-font-caption,
  .paper-font-overline,
  .paper-font-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .paper-font-display4, h1 {
    ${typographyLiterals.headline1};
  }
  .paper-font-display3, h2 {
    ${typographyLiterals.headline2};
  }
  .paper-font-display2, h3 {
    ${typographyLiterals.headline3};
  }
  .paper-font-display1, h4 {
    ${typographyLiterals.headline4};
  }
  .paper-font-headline, h5 {
    ${typographyLiterals.headline5};
  }
  .paper-font-title, h6 {
    ${typographyLiterals.headline6};
  }
  .paper-font-subtitle1 {
    ${typographyLiterals.subtitle1};
  }
  .paper-font-subtitle2 {
    ${typographyLiterals.subtitle2};
  }
  .paper-font-body1 {
    ${typographyLiterals.body1};
  }
  .paper-font-body2 {
    ${typographyLiterals.body2};
  }
  .paper-font-button {
    ${typographyLiterals.button};
  }
  .paper-font-caption {
    ${typographyLiterals.caption};
  }
  .paper-font-overline {
    ${typographyLiterals.overline};
  }
`;