export const LOAD_DATA = 'BOARD_SUMMARY_LOAD_DATA';
export const LOAD_BOARD_SUMMARY_DONE = 'BOARD_SUMMARY_LOAD_DATA_DONE';

export const LOAD_COLUMN_SUMMARY = 'LOAD_COLUMN_SUMMARY';
export const LOAD_COLUMN_SUMMARY_DONE = 'LOAD_COLUMN_SUMMARY_DONE';

/**
 * load given board summary data.
 */
export const load = (boardId) => {
  return {
    type: LOAD_DATA,
    boardId
  }
}

export const loadColumnSummary = (boardId) => {
  return {
    type: LOAD_COLUMN_SUMMARY,
    boardId
  }
}