import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import uniq from 'lodash-es/uniq';
import isEqual from 'lodash-es/isEqual.js';
import isEmpty from 'lodash-es/isEmpty.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { createSelector } from 'reselect';
import * as searchSelectors from '../search/selectors.js';

/**
 * @property {Object} state Redux state
 * @property {String} cardId - Card Id
 * @return { Array } List of card chats sorted by createdAt.
 */
export const cardChats = createSelector(
  ({ state, cardId }) => firestoreRedux.selectors.queryStatus(state, `card-chats-${cardId}`),
  ({ state, cardId }) => firestoreRedux.selectors.docsByQueryResult(state, `card-chats-${cardId}`, 'card-chat-messages'),
  (queryStatus, chats) => {
    if (!queryStatus || (isEmpty(chats) && queryStatus === 'PENDING')) {
      return;
    }
    return sortBy(chats, ['createdAt']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

/**
 * @property {Object} state Redux state
 * @property {String} boardId - Board Id.
 * @return { Array } List of board chats sorted by createdAt.
 */
export const boardChats = createSelector(
  ({ boardId }) => boardId,
  ({ state, boardId }) => firestoreRedux.selectors.queryStatus(state, `board-chats-${boardId}`),
  ({ state, boardId }) => firestoreRedux.selectors.queryStatus(state, `board-chats-${boardId}_top`),
  ({ state, boardId }) => firestoreRedux.selectors.queryStatus(state, `board-chats-${boardId}_bottom`),
  ({ state, boardId }) => firestoreRedux.selectors.docsByQueryResult(state, `board-chats-${boardId}`, 'board-chat-messages'),
  ({ state }) => firestoreRedux.selectors.collection(state, 'board-chat-messages'),
  ({ state }) => searchSelectors.highlightedChats(state),
  (boardId, queryStatus, queryStatusTop, queryStatusBottom, currentBoardChats, allBoardChats, highlightedChats) => {
    const chats = isEmpty(highlightedChats) ? currentBoardChats : filter(allBoardChats, { boardId });
    queryStatus = isEmpty(highlightedChats) ? queryStatus: queryStatusTop === 'PENDING' || queryStatusBottom === 'PENDING' ? 'PENDING': 'LIVE';
    if (!queryStatus || (isEmpty(chats) && queryStatus === 'PENDING')) {
      return;
    }

    return sortBy(chats, ['createdAt']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns `true` when all bottom board chats are loaded.
 */
export const allTopBoardChatsLoaded = (state) => get(state, `chat.allTopBoardChatsLoaded`);

/**
 * @returns `true` when all top board chats are loaded.
 */
export const allBottomBoardChatsLoaded = (state) => get(state, `chat.allBottomBoardChatsLoaded`);

/**
 * @returns {Object} Hash of referenced user ids. e.g. {`usr_2unuf848gjif`: true, ...}
 */
export const getReferencedUsers = createSelector(
  ({ message }) => message,
  (message) => {
    let users = {};
    const userRegex = /\B\@(usr)\_[A-Za-z0-9]+\b/gi;
    const userMatchWords = (message && message.match(userRegex)) || [];
    forEach(uniq(userMatchWords), (word) => {
      let itemId = word && word.replace('@usr_', 'usr_');
      if (itemId) {
        users[itemId] = true;
      }
    });
    return users;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @returns {Boolean} `true` when `@all` is referenced in chat message.
 */
export const getReferencedUsersAll = createSelector(
  ({ message }) => message,
  (message) => {
    const allRegex = /\B\@(All)\b/gi;
    const allMatchWords = (message && message.match(allRegex)) || [];
    return (allMatchWords && allMatchWords.length > 0) || false;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} chatId Chat ID passed to give chat item model.
 * @return { Object } Card chat model.
 */
export const cardChatItem = (state, chatId) => {
  return firestoreRedux.selectors.doc(state, 'card-chat-messages', chatId);
};

/**
 * @param {Object} state Redux state.
 * @param {String} chatId Chat ID passed to give chat item model.
 * @return { Object } Selector which return chat model.
 */
export const boardChatItem = (state, chatId) => {
  return firestoreRedux.selectors.doc(state, 'board-chat-messages', chatId);
};

/**
 * given chat board id.
 */
export const chatBoardId = (state, chatId) => {
  const chat = chatId && chatId.startsWith('ccm_') ? cardChatItem(state, chatId): boardChatItem(state, chatId);
  return chat && chat.boardId;
}

/**
 * @param {Object} state 
 * @param {String} containerId 
 * @returns {String} given container add message status.
 */
export const addNewChatStatus = (state, containerId) => get(state, `chat.addNewChat.${containerId}.status`);
