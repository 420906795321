import { takeEvery, call, select, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as auth from '../../redux/auth';
import * as amplitude from '../../analytics/amplitude.js';
import { config }  from './config';
import firestoreRedux from '@dreamworld/firestore-redux';
import entityIdProvider from '../../entity-id-provider';


/**
 * Loads all knownFeatures from firestore when auth loaded.
 * Note: If user is already logged-in, loads it immediately. Otherwise, loads when user authentication completes.
 * Firestore path:  /users/$userId/known-features
 */
function* load() {
  const state = yield select();
  let userId = auth.selectors.currentUserId(state);
  if (userId) {
    //Timeout required because firebase initialization has not been finished yet.
    setTimeout(() => {
      firestoreRedux.query('known-features', { id: 'known-features', where: [['userId', '==', userId]], requesterId: `known-features-${userId}` });
    });
  }
}

/**
 * Marks feature as known feature when it's not shown every time.
 * @param {Object} action action payload e.g {type: 'MARK_AS_KNOWN', name: 'SHARE_BOARD'}
 */
function* markAsKnown({name, extraDetails}) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const dataLoaded = selectors.isLoaded(state);
  if (!dataLoaded && userId) {
    return;
  }

  if(auth.selectors.impersonatedUser(state)) {
    return;
  }

  let doc = selectors.knownFeature({ state, name });
  const id = doc ? doc.id : `kf_${entityIdProvider.getId()}`
  try {
    const duration = extraDetails && extraDetails.duration || 0;
    const acknowledged = extraDetails && extraDetails.acknowledged || 'AUTO';
    const skipped = extraDetails && extraDetails.skipped || false;
    amplitude.logEvent('tip acknowledged', {name, duration, acknowledged, skipped});

    //If tip is skip mark as read
    if (config[name].skipMarkAsRead) {
      return;
    }

    if(userId) {
      doc = { id, userId, name, known: true };
      firestoreRedux.save(`users/${userId}/known-features`, doc);
    } else {
      const key = `knownFeatures.${name}`;
      localStorage && localStorage.setItem && localStorage.setItem(key, true);
    }
  } catch (error) {
    if(userId) {
      firestoreRedux.delete(`users/${userId}/known-features`, id, { localWrite: true });
    }
  }
}


/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(load),
    takeEvery(auth.actions.UPDATE_AUTH, load),
    takeEvery(actions.MARK_AS_KNOWN, markAsKnown)
  ]);
}

export default saga;