import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { store } from '../../store.js';
import * as app from '../app';
import * as auth from '../auth';

/**
 * Converting/return the appropriate page URL based on given router page data.
 * @param {Object} page It's the page data as per the router page data.
 * @return {String} page url based on given page data.
 */
export const buildURL = (page) => {
  if(isEmpty(page)) {
    throw new Error("Invalid arguments passed.");
  }

  if (page.dialog && page.dialog.name === 'CARD') {
    return getCardDialogUrl(page);
  }

  if(page.name === 'BOARD') {
    return getBoardPageUrl(page);
  }

  if(page.name === 'JOIN_BOARD') {
    return getJoinBoardPageUrl(page);
  }

  if(page.name === 'BOARD_EXPLORER') {
    return getBoardExplorerUrl(page);
  }

  if (page.name === 'VIEWS') {
    return getViewsUrl(page);
  }

  if(page.name === 'NOT_FOUND') {
    return page.url ? `/not-found?url=${encodeURIComponent(page.url)}`: `/not-found`;
  }

  return '';
}

const getCardDialogUrl = (page) => {
  const accountId = page.accountId;
  const boardId = get(page, 'dialog.params.boardId');
  const cardId = get(page, 'dialog.params.cardId');
  const tab = get(page, 'dialog.params.tab');
  if(!accountId || !boardId || !cardId) {
    throw new Error("Invalid arguments passed.");
  }

  return tab ? `/${accountId}/board/${boardId}/${cardId}`: `/${accountId}/board/${boardId}/${cardId}?tab=${tab}`;
}

/**
 * Converting/return the board page URL based on given router page data.
 * @param {Object} page It's the page data as per the router page data.
 * @return {String} board url based on given page data.
 */
const getBoardPageUrl = (page) => {
  let accountId = page.accountId;
  let boardId = page.params && page.params.boardId;
  if(!accountId || !boardId) {
    throw new Error("Invalid arguments passed.");
  }

  return `/${accountId}/board/${boardId}`;
}

const getJoinBoardPageUrl = (page) => {
  const accountId = page.accountId;
  const boardId = page.params && page.params.boardId;
  const secret = page.params && page.params.secret;
  if(!accountId || !boardId || !secret) {
    console.error("getJoinBoardPageUrl > Invalid arguments passed.", {accountId, boardId, secret});
    return;
  }
  return `/${accountId}/board/${boardId}/join?secret=${secret}`;
}

/**
 * Converting/return the board explorer page URL based on given router page data.
 * @param {Object} page It's the page data as per the router page data.
 * @return {String} board explorer url based on given page data.
 */
const getBoardExplorerUrl = (page) => {
  let accountId = page.accountId;
  const state = store.getState();
  if(!accountId) {
    accountId = auth.selectors.lastUsedAccount(state);
  }


  const wideLayout = app.selectors.wideLayout(state);
  let filters = page.params && page.params.filters || {};
  let url = wideLayout ? `/home` : `/${accountId}/home`;
  url = url + (filters.favorite ? '/favorite' : '/other');
  url = url + (filters.status == 'ARCHIVED' ? '/archive' : '');
  url = url + (filters.status == 'TRASHED' ? '/trash' : '');
  url = url + (filters.status == 'ACTIVE' && filters.template ? '/templates' : '');
  url = url + (filters.status == 'ACTIVE' && !filters.template ? '/boards' : '');
  url = url + (filters.currentLang ? '/current-lang': '');
  return url;
}

/**
 * @param {String} id View Id.
 * @returns {String} Url path segment for provided system view id.
 */
 export const viewIdToUrlPath = (id) => {
  const hash = {
    'UPDATES': 'new',
    'ASSIGNED_TO_ME': 'assigned-to-me',
    'ATTENTION': 'attention',
    'GOT_DONE': 'done',
    'WHATS_DUE': 'due'
  };
  return hash[id];
}

/**
 * @param {Object} page Page data as per the router page data.
 * @return {String} view page url based on given page data.
 */
const getViewsUrl = (page) => {
  return `/views/${viewIdToUrlPath(page.params.viewId)}`;
}

export default buildURL;