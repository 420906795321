

import { html, css } from 'lit-element/lit-element.js';
import { LitElement } from "@dreamworld/pwa-helpers/lit-element.js";
import { connect } from '@dreamworld/pwa-helpers/connect-mixin.js';
import { store } from '../../store.js';
import i18next from '@dw/i18next-esm/index.js';
import localize from '@dw/pwa-helpers/localize.js';
import URI from '@dw/urijs-esm';
import { sharedStyles } from '../../theme/shared-styles.js';
import { typography } from '@dreamworld/material-styles/typography.js';
import { validateEmail } from '../../utils.js';
import * as typographyLiterals from '@dreamworld/material-styles/typography-literals.js';
import * as app from '../../redux/app/index.js';
import * as auth from '../../redux/auth/index.js';
import * as router from '../../redux/router/index.js';
import * as amplitude from '../../analytics/amplitude.js';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import '../common/kerika-button.js';
import '../kerika-input.js';
import '../kerika-loader.js';

/**
 * This element provides way to login into application with direct email.
 * It's connected element.
 *
 * Behavior:
 *  - Rendering:
 *    - Shows `Email`, `Password`, `Forgot Password?` link and `LOGIN` button
 *      - install app `Forgot Password?` link is not shown.
 *    - On choose 'LOGIN'
 *      - If email and password is valid then send request to login on auth server.
 *      - Shows loader instead of login and forgot password buttons.
 */

class KerikaSigninWithDirectEmail extends connect(store)(localize(i18next)(LitElement)) {
  static get styles() {
    return [
      sharedStyles,
      typography,
      css`
        :host {
          ${typographyLiterals.body1};
          display: block;
          --kerika-button-height: 44px;
        }

        .buttons kerika-button {
          width: 100%;
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .buttons kerika-button.forgot-password {
          text-transform: none;
        }

        .loding-cotainer {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 44px;
          margin-top: 12px;
        }

        kerika-button {
          margin-top: 24px;
        }

        kerika-input {
          width: 100%;
        }
      `
    ];
  }

  constructor() {
    super();
    this.i18nextNameSpaces = ['login-and-signup'];

    /**
     * Auth base url.
     * use for redirect on forgot password page.
     */
    this._authUserUIBaseUrl;
  }

  static get properties() {
    return {
      /**
       * Read data from redux
       * Current app is installed app or not.
       */
      _installedApp: { type: Boolean, reflect: true, attribute: 'installed-app' },

      /**
       * User email type on email input.
       */
      _email: { type: String },

      /**
       * User password type on password input.
       */
      _password: { type: String },

      /**
       * Read data from redux
       * Login process is running or not.
       */
      _inProgress: { type: Boolean },

      /**
       * Read data from redux
       * Login last error.
       */
      _loginLastError: { type: String },

      /**
       * Read data from redux
       * signup service.
       */
      _signupService: { type: String },

      /**
       * Read data from redux
       * login service.
       */
      _loginService: { type: String },
    }
  }

  render() {
    return html`
      <kerika-input
        id="email"
        required
        type="email"
        showasfilled
        .dense=${true}
        errorMessage=${this.__getEmailErrorMessage()}
        .value=${this._email}
        .disabled=${this._inProgress}
        .placeholder=${i18next.t('login-and-signup:login.loginForm.email.label')}
        @value-changed=${this.__onEmailChanged}
        @enter=${this._login}>
      </kerika-input>
      <kerika-input
        id="password"
        required
        type="password"
        showasfilled
        .dense=${true}
        errorMessage=${this.__getPasswordErrorMessage()}
        .value=${this._password}
        .disabled=${this._inProgress}
        .placeholder=${i18next.t('login-and-signup:login.loginForm.password.label')}
        @value-changed=${this.__onPasswordChange}
        @enter=${this._login}>
      </kerika-input>

      <div class="buttons">
        ${this._inProgress? html`
          <div class="loding-cotainer">
            <kerika-loader></kerika-loader>
          </div>
        `: html`
          <kerika-button large outlined .disabled=${this._inProgress} @click=${this._login}>${i18next.t('login-and-signup:login.buttons.login')}</kerika-button>
          <kerika-button text-inherit class="forgot-password" @click=${this.__onForgotPassword} .disabled=${this._inProgress}>${i18next.t('login-and-signup:login.loginForm.buttons.forgotPassword')}</kerika-button>
        `}
      </div>
    `
  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('_loginLastError') && this._loginLastError) {
      if(this._loginLastError == 'USER_NOT_FOUND') {
        let emailEL = this.shadowRoot.querySelector('#email');
        emailEL.invalid = true;
      }

      if(this._loginLastError == 'INVALID_PASSWORD') {
        let passwordEl = this.shadowRoot.querySelector('#password');
        passwordEl.invalid = true;
      }
    }
  }

  /**
   * Sets _email
   * @param {Object} e Event
   * @private
   */
  __onEmailChanged(e) {
    this._loginLastError = undefined;
    this._email = e.detail.value;
  }

  /**
   * Sets _password
   * @param {Object} e Event
   * @private
   */
  __onPasswordChange(e) {
    this._loginLastError = undefined;
    this._password = e.detail.value;
  }

  /**
  * @returns `true` when one of value is changed, it's not blank and valid.
  * @protected
  */
  __validateAllInput() {
    const elements = this.renderRoot.querySelectorAll('kerika-input');
    let focused = false;
    let valid = true;
    forEach(elements, (element)=>{
      let invalid = element && element.validate && element.validate() === false;
      if(invalid && valid) {
        valid = false;
      }

      if(element && element.type === 'email' && !invalid && !validateEmail(element.value)) {
        element.invalid = true;
        invalid = true;
        valid = false;
      }

      if(!focused && invalid) {
        setTimeout(() => {
          element && element.focus && element.focus();
        }, 0);
        focused =  true;
      }
    });

    return valid;
  }

  /**
   * @return {String} error message for email input
   * @private
   */
  __getEmailErrorMessage() {
    if(this._loginLastError == 'USER_NOT_FOUND') {
      return i18next.t('login-and-signup:error.USER_NOT_FOUND');
    }
    return i18next.t('login-and-signup:login.loginForm.email.errorMessage');
  }

  /**
   * @return {String} error message for password input
   * @private
   */
  __getPasswordErrorMessage() {
    if(this._loginLastError == 'INVALID_PASSWORD') {
      return i18next.t('login-and-signup:error.INVALID_PASSWORD');
    }
    return i18next.t('login-and-signup:login.loginForm.password.errorMessage');
  }

  /**
   * Invoked when user click on login button.
   * Disaptch auth `login` action with service as kerika, email and password.
   * @protected.
   */
  _login() {
    if(this._inProgress) {
      return;
    }

    if(!this.__validateAllInput()) {
      return;
    }

    amplitude.logEvent('auth initiated', {service: 'KERIKA', trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
    store.dispatch(auth.actions.login({service: 'kerika', email: this._email, password: this._password}));
  }

  /**
   * Invoked when user click on forgot password.
   * Redirect on auth forgot passwrord page.
   * @private
   */
  __onForgotPassword() {
    const uri = new URI();
    uri.path(`/forgot-password`);
    if(this._email) {
      uri.setQuery('email', this._email);
    }
    uri.removeQuery('embed-login-signup');
    uri.removeQuery('action');
    uri.fragment('');

    if(this._embedLoginSignup) {
      const opener = window.top || window.opener;
      if(opener) {
        opener.postMessage({
          type: 'FORGOT_PASSWORD_PAGE_OPEN',
          url: uri.toString()
        }, "*");
      }
      return;
    }
    router.actions.navigate(uri.toString());
  }

  /**
   * Invoked whenever redux state is changed
   * @param {Object} state - Redux store
   */
  stateChanged(state) {
    this._installedApp = app.selectors.isInstalledApp(state);
    let _config = app.selectors.config(state);
    this._authUserUIBaseUrl = _config && _config.auth && _config.auth.userUIBaseUrl;
    const loginStatus = auth.selectors.loginStatus(state);
    const signupStatus = auth.selectors.signupStatus(state);
    this._signupService = auth.selectors.signupService(state);
    this._loginService = auth.selectors.loginService(state);
    this._joinSecretInfo =  auth.selectors.joinSecretInfo(state);
    this._hasInvitedUser = !isEmpty(this._joinSecretInfo);
    this._inProgress = loginStatus === 'IN_PROGRESS' || loginStatus === 'SUCCESS' || signupStatus === 'IN_PROGRESS' || (signupStatus === 'SUCCESS' && this._hasInvitedUser) || (this._signupService && this._signupService !== 'kerika' && signupStatus === 'SUCCESS');
    this._loginLastError = auth.selectors.loginError(state);
    this._embedLoginSignup = router.selectors.embedLoginSignup(state);
  }
}

window.customElements.define('kerika-signin-with-direct-email', KerikaSigninWithDirectEmail);
