import * as auth from '../auth/index.js';
import * as app from '../app/index.js';
import {
  signupInSameSession,
  signupUsingJoinSecret,
} from '../signup/selectors.js'; // TODO: change import statement after signup story.
import get from 'lodash-es/get.js';
import { createSelector } from 'reselect/es/index.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../utils';

/**
 * @param {Object} state Redux state
 * @return {Boolean} `true` when download app dialog is presented already to the user.
 */
const downloadDialogPresented = (state) => {
  const userId = auth.selectors.currentUserId(state);
  const doc = firestoreRedux.selectors.doc(state, 'user-ui', `dad_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`);
  if (doc && doc.presented) {
    return true;
  }
  const query = get(
    firestoreRedux.selectors.queriesByRequester(state,`user-ui-${userId}`),
    0
  );
  return !query || query.status === 'PENDING' ? undefined : false;
};
/**
 * Download app dialog is opened or not for current user based on
 *  - current app is installed app?
 *  - user already present this dialog?
 *  - user complete a signup in this session?
 *  - user complete a signup using a join-secret?
 * @returns {Boolean} `true` when current app is installed app and (user can't complete a sign up in this session OR user complete a signup using a join-secret) and user can't present this dialog earlier.
 */
export const downloadAppDialogOpen = createSelector(
  downloadDialogPresented,
  app.selectors.isInstalledApp,
  app.selectors.wideLayout,
  signupInSameSession,
  signupUsingJoinSecret,
  (
    presented,
    isInstalledApp,
    wideLayout,
    signupInSameSession,
    signupUsingJoinSecret
  ) => {
    if (isInstalledApp || wideLayout || presented === undefined) {
      return false;
    }
    return !presented && (!signupInSameSession || signupUsingJoinSecret);
  }
);
