export const PAGE_OPENED = 'FORGOT_PASSWORD_PAGE_OPENED';
export const PAGE_CLOSED = 'FORGOT_PASSWORD_PAGE_CLOSED';

export const REQUEST_OTP = 'FORGOT_PASSWORD_REQUEST_OTP';
export const REQUEST_OTP_DONE = 'FORGOT_PASSWORD_REQUEST_OTP_DONE';
export const REQUEST_OTP_FAILED = 'FORGOT_PASSWORD_REQUEST_OTP_FAILED';

export const RESEND_OTP = 'FORGOT_PASSWORD_RESEND_OTP';
export const RESEND_OTP_DONE = 'FORGOT_PASSWORD_RESEND_OTP_DONE';
export const RESEND_OTP_FAILED = 'FORGOT_PASSWORD_RESEND_OTP_FAILED';

export const VERIFY_OTP = 'FORGOT_PASSWORD_VERIFY_OTP';
export const VERIFY_OTP_DONE = 'FORGOT_PASSWORD_VERIFY_OTP_DONE';
export const VERIFY_OTP_FAILED = 'FORGOT_PASSWORD_VERIFY_OTP_FAILED';

export const RESET = 'FORGOT_PASSWORD_RESET';
export const RESET_DONE = 'FORGOT_PASSWORD_RESET_DONE';
export const RESET_FAILED = 'FORGOT_PASSWORD_RESET_FAILED';

/**
 * Send otp on given `email`.
 * @param {String} email
 */
export const requestOtp = (email) => {
  return {
    type: REQUEST_OTP,
    email
  }
}

/**
 * Re-send otp on given `email`.
 * @param {String} id
 */
export const resendOtp = (id) => {
  return {
    type: RESEND_OTP,
    id
  }
}

/**
 * Verify given `otp` for given `id`.
 * @param {String} id
 * @param {Number} otp
 */
export const verifyOtp = (id, otp, email) => {
  return {
    type: VERIFY_OTP,
    id,
    otp,
    email
  }
}

/**
 * Reset given `password` for given `id`.
 * @param {String} email
 * @param {String} id
 * @param {Number} otp
 * @param {String} password 
 */
export const reset = (email, id, otp, password) => {
  return {
    type: RESET,
    email, 
    id,
    otp,
    password
  }
}