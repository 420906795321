import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as actions from './actions';
import get from 'lodash-es/get';

const initialState = {
  participatedBoards: { loadInProgress: false },
  view: {
    'UPDATES': { loadInProgress: true, initDataLoadInProgress: true },
    'ASSIGNED_TO_ME': { loadInProgress: true, initDataLoadInProgress: true  },
    'ATTENTION': { loadInProgress: true, initDataLoadInProgress: true  },
    'GOT_DONE': { loadInProgress: true, initDataLoadInProgress: true  },
    'WHATS_DUE': { loadInProgress: true, initDataLoadInProgress: true  },
  },
  selectedCards: [],
  viewIds: ['UPDATES', 'ASSIGNED_TO_ME', 'ATTENTION', 'GOT_DONE', 'WHATS_DUE']
};
/**
 * view Reducer
 */
export const viewReducer = (state = initialState, action) => {
  let id;
  let oState = {...state};
  switch (action.type) {
    case actions.PAGE_OPENED:
    case actions.PAGE_CHANGED:
      return ReduxUtils.replace(oState, `currentId`, action.viewId);
    
    case actions.UPDATE_PREFERENCES:
    case actions.UPDATE_SETTINGS:
    case actions.REFRESH:
      id = action.id || get(oState, `currentId`);
      oState = ReduxUtils.replace(oState, `view.${id}.initDataLoadInProgress`, true);
      return ReduxUtils.replace(oState, `view.${id}.loadInProgress`, true);
    case actions.REFRESH_DONE:
      id = action.id || get(oState, `currentId`);
      return ReduxUtils.replace(oState, `view.${id}.data`, action.data);

    case actions.INIT_DATA_LOADED:
      return ReduxUtils.replace(oState, `view.${action.id}.initDataLoadInProgress`, false);

    case actions.LOAD_VIEW_DATA_DONE:
      oState = ReduxUtils.replace(oState, `view.${action.id}.initDataLoadInProgress`, false);
      return ReduxUtils.replace(oState, `view.${action.id}.loadInProgress`, false);
    case actions.LOAD_VIEW_DATA_FAILED:
      oState = ReduxUtils.replace(oState, `view.${action.id}.initDataLoadInProgress`, false);
      return ReduxUtils.replace(oState, `view.${action.id}.loadInProgress`, false);

    case actions.LOAD_PARTICIPATED_BOARDS:
      return ReduxUtils.replace(oState, `participatedBoards.loadInProgress`, true);
    case actions.LOAD_PARTICIPATED_BOARDS_DONE:
      return ReduxUtils.replace(oState, `participatedBoards`, { loadInProgress: false, data: action.data });
    case actions.LOAD_PARTICIPATED_BOARDS_FAILED:
      return ReduxUtils.replace(oState, `participatedBoards.loadInProgress`, false);

    case actions.UPDATE_SELECTED_CARD:
      return {
        ...state,
        selectedCards: action.cardIds,
        selectedColumn: action.columnId
      };

    case actions.CLEAR_SELECTED_CARD:
      return {
        ...state,
        selectedCards: [],
        selectedColumn: null
      };

    default:
      return state;
  }
};