import { store } from '../store.js';
import * as app from '../redux/app';
import * as auth from '../redux/auth';
import * as router from '../redux/router';
import * as multipleLanguage from '../redux/multiple-language';

/**
 * Opens external link.
 * For installed app it opens it by using `<a>` tag while in regular browser it opens by `window.open`
 * Why use `<a>` tag?
 *  - In installed app, all domain except PWA are not in whitelist urls,
 *    so whenever user opens external link by `window.open` it does not works in it.
 *    Due to that restriction, this hack has been applied for installed app.
 * @param {Object} attrs Attributes of anchor tag. `{ href, target, download }`
 */
export const openExternalLink = (attrs = { href, target: '_blank', download }, entityId, maliciousUrlCheck = true) => {
  if (!attrs.href) {
    console.error('openExternalLink: Mandatory arguments are not provided');
    return;
  }

  attrs.href = getUrl(attrs.href, entityId, maliciousUrlCheck);
  if(app.selectors.isInstalledApp(store.getState())) {
    let a = document.createElement('a');
    for (let attr in attrs) {
      a.setAttribute(attr, attrs[attr]);
    }

    a.click();
    return;
  }

  window.open(attrs.href, attrs.target);
}

/**
 * Generates a URL with additional context parameters based on the current application state.
 * 
 * @param {string} url - The original URL to be modified.
 * @param {string} [entityId] - An optional entity ID to include in the context parameters.
 * @returns {string} The modified URL with the added context parameters.
 */
const getUrl = (url, entityId, maliciousUrlCheck) => {
  if(!maliciousUrlCheck) {
    return url;
  }

  const state = store.getState();
  const redirectEndpoint = app.selectors.redirectEndpoint(state);
  
  if (!redirectEndpoint) {
    return url;
  }

  const removeEmptyValues = (obj) => 
    Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => 
        v !== '' && v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0)
      )
  );

  const canvasId = router.selectors.canvasId(state);
  const withPrefixCanvasId = canvasId ? `cnvs_${canvasId}` : '';

  const contextParams = removeEmptyValues({
    a: router.selectors.accountId(state) || '',
    b: router.selectors.dialogBoardId(state) || router.selectors.pageBoardId(state) || '',
    c: router.selectors.cardId(state) || '',
    cv: withPrefixCanvasId,
    u: auth.selectors.currentUserId(state) || '',
    e: entityId || ''
  });

  const queryParams = {
    url: encodeURIComponent(url),
    referrer: encodeURIComponent(window.location.href),
    lang: multipleLanguage.selectors.currentLanguage(state),
    context: btoa(new URLSearchParams(contextParams).toString())
  };

  url = Object.entries(queryParams).reduce(
    (endpoint, [key, value]) => endpoint.replace(`{${key}}`, value),
    redirectEndpoint
  );

  url = `${app.selectors.apiBaseUrl(state)}${url}`;

  if(!app.selectors.isInstalledApp(state)) {
    return url;
  }
  
  url = `${app.selectors.webAppBaseUrl(state)}/en/open-external-link.html?redirect=${encodeURIComponent(url)}`
  return url;
};