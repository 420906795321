import * as actions from './actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

export const boardSummary = (state = {}, action) => {
  switch (action.type) {
    case actions.LOAD_BOARD_SUMMARY_DONE:
      return ReduxUtils.replace(state, `${action.id}`, action.summary);
    case actions.LOAD_COLUMN_SUMMARY_DONE:
      return ReduxUtils.replace(state, `${action.id}.column-summary`, action.summary);
    default:
      return state;
  }
}
