const TO64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
const URL_SAFE_DECODABET = [
    -9,-9,-9,-9,-9,-9,-9,-9,-9,                 // Decimal  0 -  8
    -5,-5,                                      // Whitespace: Tab and Linefeed
    -9,-9,                                      // Decimal 11 - 12
    -5,                                         // Whitespace: Carriage Return
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 14 - 26
    -9,-9,-9,-9,-9,                             // Decimal 27 - 31
    -5,                                         // Whitespace: Space
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,              // Decimal 33 - 42
    -9,                                         // Plus sign at decimal 43
    -9,                                         // Decimal 44
    62,                                         // Minus sign at decimal 45
    -9,                                         // Decimal 46
    -9,                                         // Slash at decimal 47
    52,53,54,55,56,57,58,59,60,61,              // Numbers zero through nine
    -9,-9,-9,                                   // Decimal 58 - 60
    -1,                                         // Equals sign at decimal 61
    -9,-9,-9,                                   // Decimal 62 - 64
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,            // Letters 'A' through 'N'
    14,15,16,17,18,19,20,21,22,23,24,25,        // Letters 'O' through 'Z'
    -9,-9,-9,-9,                                // Decimal 91 - 94
    63,                                         // Underscore at decimal 95
    -9,                                         // Decimal 96
    26,27,28,29,30,31,32,33,34,35,36,37,38,     // Letters 'a' through 'm'
    39,40,41,42,43,44,45,46,47,48,49,50,51,     // Letters 'n' through 'z'
    -9,-9,-9,-9,-9                              // Decimal 123 - 127
    ,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 128 - 139
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 140 - 152
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 153 - 165
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 166 - 178
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 179 - 191
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 192 - 204
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 205 - 217
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 218 - 230
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,     // Decimal 231 - 243
    -9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9,-9         // Decimal 244 - 255 
];
    


export const to64 = (n) => {
    let s = '';
    n = parseInt(n);
    if(typeof n !== 'number' || isNaN(n) || !n || n <= 0) {
        return s;
    }
    
    while (n)
    {
        s = TO64.charAt(n & 63) + s;
        n = n >> 6;
    }
    
    return s;
};
    
/**
 * @param hex - String which contains ALPHANUMERIC and '-'.
 * @return Number - Conver string (hex) into Number using base 64
 */
export const from64 = (hex) => {
    if(typeof hex !== 'string') {
        console.error("String is invalid. Input is : " + hex);
        return hex;
    }
    
    let len = hex.length;
    let v = 0;
    let c, d;
    
    for (let i = 0; i < len; i++)
    {
        c = hex.charCodeAt(i);
        if (c < 0)
            break;
        d = URL_SAFE_DECODABET[c];
        v = (v << 6) + d;
    }
    return v;
};