import { DwRipple } from '@dreamworld/dw-ripple/dw-ripple.js';
import { css } from 'lit-element';


/**
 * Extended version of [dw-ripple](https://github.com/DreamworldSolutions/dw-ripple). 
 */
export class KerikaRipple extends DwRipple {
  static get styles() {
    return [
      super.styles,
      css`
        :host .mdc-ripple-surface {
          border-radius: var(--kerika-ripple-border-radius, 0px);
        }
      `
    ]
  }
}

customElements.define('kerika-ripple', KerikaRipple);