import * as app from './redux/app';
import { store } from './store.js';

//Dispatch deviceReady action.
document.addEventListener('deviceready', () => {
  store.dispatch(app.actions.deviceReady());
}, false);

/**
 * Current device is ready or not.
 * @returns {Promise} resolve promise when device is ready.
 */
export const isDeviceReady = () => {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  if(app.selectors.isInstalledApp(store.getState())) {
    document.addEventListener("deviceready", function() {
      resolve(true);
    }.bind(this), false);
  } else {
    resolve(false);
  }
  return promise;
}

export default isDeviceReady;