export const ADD = 'BOARD_COLUMN_ADD';
export const EDIT = 'BOARD_COLUMN_EDIT';
export const UPDATE_ORDER = 'BOARD_COLUMN_UPDATE_ORDER';
export const REMOVE = 'BOARD_COLUMN_REMOVE';
export const UPDATE_VISIBILITY = 'BOARD_COLUMN_UPDATE_VISIBILITY';
export const MOVE_TO_ANOTHER_BOARD = 'COLUMN_MOVE_TO_ANOTHER_BOARD';
export const UNDO_MOVE = 'BOARD_COLUMN_UNDO_MOVE';
export const UPDATE_DND_TIP_SHOWN_COUNT = 'BOARD_COLUMN_UPDATE_DND_TIP_SHOWN_COUNT';

/**
 * Dispatch redux action to add new column.
 * @param {Number} boardId Board Id
 * @param {String} name Column Name
 * @param {Number} wipLimit Wip limit for column.
 * @param {Number} before add new column before this given colunm
 */
export const add = ({boardId, name, wipLimit, before}) => {
  return {
    type: ADD,
    boardId,
    name,
    wipLimit,
    before
  }
}

/**
 * Dispatches redux action to edit column.
 * @param {String} boardId Board Id
 * @param {String} columnId Column Id
 * @param {String} name Column Name
 * @param {Number} wipLimit Wip limit for column.
 */
export const edit = (boardId, columnId, name, wipLimit) => {
  return {
    type: EDIT,
    boardId,
    columnId,
    name,
    wipLimit
  }
}

/**
 * Dispatch redux action to update column order.
 * @param {*}
 *  @property {String} boardId Borad Id.
 *  @property {String} columnId Column Id
 *  @property {String} newIndex New index of the column.
 *  @property {String} before move column before this given colunm
 */
export const updateOrder = ({boardId, columnId, newIndex, before}) => {
  return {
    type: UPDATE_ORDER,
    boardId,
    columnId,
    newIndex,
    before
  }
}

export const undoMove = ({ columns, actionId }) => {
  return {
    type: UNDO_MOVE,
    columns,
    actionId
  }
}

export const updateDndTipShownCount = () => {
  return {
    type: UPDATE_DND_TIP_SHOWN_COUNT
  }
}

/**
 * Dispatch redux action to delete column.
 * @param {Number} columnId Column Id
 */
export const remove = (boardId, columnId) => {
  return {
    type: REMOVE,
    boardId,
    columnId
  }
}

/**
 * Dispatch redux action to update column visibility.
 * @param {Number} boardId Borad Id.
 * @param {Number} columnId Column Id
 * @param {Boolean} visibility Column new visibility
 */
 export const updateVisibility = ({boardId, columnId, visibility}) => {
  return {
    type: UPDATE_VISIBILITY,
    boardId,
    columnId,
    visibility
  }
 }

/**
 * Moves whole column to another board.
 * @param {*} 
 *  @property {String} columnId Id of the column which is being moved.
 *  @property {String} destBoardId Board Id in which column will be moved.
 *  @property {String} destBoardNextColumnId Id of the column in destination board, before which this column will be moved.
 */
export const moveToAnotherBoard = ({ columnId, destBoardId, destBoardNextColumnId }) => {
  return {
    type: MOVE_TO_ANOTHER_BOARD,
    columnId,
    destBoardId,
    destBoardNextColumnId
   }
 }