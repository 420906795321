export const CREATE_BOARD_DIALOG_OPENED = 'CREATE_BOARD_DIALOG_OPENED';
export const CREATE_BOARD_DIALOG_CLOSED = 'CREATE_BOARD_DIALOG_CLOSED';
export const CREATE_BOARD_DIALOG_LOAD_DATA = 'CREATE_BOARD_DIALOG_LOAD_DATA';
export const CREATE_BOARD_DIALOG_LOAD_DATA_DONE = 'CREATE_BOARD_DIALOG_LOAD_DATA_DONE';
export const CREATE_TEMPLATE_DIALOG_LOAD_DATA = 'CREATE_TEMPLATE_DIALOG_LOAD_DATA';
export const CREATE_TEMPLATE_DIALOG_LOAD_DATA_DONE = 'CREATE_TEMPLATE_DIALOG_LOAD_DATA_DONE';
export const CREATE_BOARD = 'CREATE_BOARD';
export const CREATE_BOARD_DONE = 'CREATE_BOARD_DONE';
export const CREATE_BOARD_FAILED = 'CREATE_BOARD_FAILED';

/**
 * Loads create board dialog data from firestore.
 */
export const loadCreateBoardDialogData = (accountId) => {
  return {
    type: CREATE_BOARD_DIALOG_LOAD_DATA,
    accountId
  };
}

/**
 * Loads create template dialog data from firestore for given accountId..
 */
 export const loadCreateTemplateDialogData = (accountId) => {
  return {
    type: CREATE_TEMPLATE_DIALOG_LOAD_DATA,
    accountId
  };
}

/**
 * This is used debug issue multiple boards are created.
 * Create board action is called then this veriable is set as a true after 1 seconds this property is reset.
 */
let createBoardActionCalled = false;

/**
 * Create a new board for given account and using given template.
 * @param {Object} params
 *  - @property {String} name new board name
 *  - @property {String} boardType which type board i created like whiteboard or task board
 *  - @property {Number} accountId
 *  - @property {Number} templateId 
 */
export const createBoard = ({name, boardType, accountId, templateId, boardPrivacy}) => {
  if(createBoardActionCalled) {
    throw new Error('create board actions trigger multiple times within a seconds.');
  }

  createBoardActionCalled = true;
  setTimeout(()=> {
    createBoardActionCalled = false;
  }, 1000);
  return {
    type: CREATE_BOARD,
    name,
    boardType,
    accountId,
    templateId,
    boardPrivacy
  }
}