import URI from '@dw/urijs-esm';
import getNewIds from '../../../get-new-ids.js';
import requestApi from '../../../request-api';
import { from64 } from './router-utils';

/**
 * A hash whose key is oldApp's pageId & value is regular expression which parses URL of the old app for that page.
 */
const oldAppUrlsRegex = {
  "PROFILE": "^/profile/?$",
  "PREFERENCES_BOARDS": "^/preferences/?(boards)?/?$",
  "PREFERENCES_NOTIFICATIONS": "^/preferences/notifications/?$",
  "PREFERENCES_CANVASES": "^/preferences/canvases/?$",
  "MANAGE_ACCOUNT_SUMMARY": "^\/(my-account)\/?$",
  "HOME_CONTEXT": "^\/(home)\/?$",
  "HOME_FAVORITE_CONTEXT": "^\/(home)\/(favorite)\/?$",
  "VIEWS_CONTEXT": "^\/(home)\/(views)\/?$",
  "FAVORITES_VIEWS": "^\/(home)\/(favorite)\/(views)\/?$",
  "FAVORITES_BOARDS": "^\/(home)\/(favorite)\/(boards)\/?$",
  "FAVORITES_TEMPLATES": "^\/(home)\/(favorite)\/(templates)\/?$",
  "HOME_SHARED_CONTEXT": "^\/(home)\/(shared)\/?$",
  "HOME_MY_CONTEXT": "^\/(home)\/(my)\/?$",
  "SHARED_BOARDS": "^\/(home)\/(shared)\/(boards)\/?$",
  "SHARED_TEMPLATES": "^\/(home)\/(shared)\/(templates)\/?$",
  "SHARED_ARCHIVED_BOARDS": "^\/(home)\/(shared)\/(archive)\/?$",
  "SHARED_TRASHED_BOARDS": "^\/(home)\/(shared)\/(trash)\/?$",
  "MY_BOARDS": "^\/(home)\/(my)\/(boards)\/?$",
  "MY_TEMPLATES": "^\/(home)\/(my)\/(templates)\/?$",
  "MY_ARCHIVED_BOARDS": "^\/(home)\/(my)\/(archive)\/?$",
  "MY_TRASHED_BOARDS": "^\/(home)\/(my)\/(trash)\/?$",
  "PROJECTS_CONTEXT": "^\/(projects)\/.*",
  "TEMPLATES_CONTEXT": "^\/(templates)\/.*",
  "VIEWS": "^\/(views)\/((assigned-to-me)|(needs-attention)|(whats-due)|(got-done))\/?$",
  "BOARD": "^\/(m)\/([a-zA-Z0-9-_]+)\/?$",
  "CANVAS": "^\/(c)\/([a-zA-Z0-9-_]+)\/?$",
  "BOARD_CARD": "^\/(m)\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)\/?$",
  "VIEWS_CARD": "^\/(views)\/([a-z]+-{1}[a-z]+)+[^-]\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)\/?$"
};

/**
 * Hash which maps old App's pageId to new App's page Id.
 * Note:: If pageId are same in old-app and new-app then they aren't saved here. So, it mainly contains 2 types.
 * of pages: Deleted pages & context pages.
 */
const newPageIds = {
  "HOME_CONTEXT": "FAVORITES_BOARDS",
  "HOME_FAVORITE_CONTEXT": "FAVORITES_BOARDS",
  "VIEWS_CONTEXT": "FAVORITES_VIEWS",
  "HOME_SHARED_CONTEXT": "OTHER_BOARDS",
  "HOME_MY_CONTEXT": "OTHER_BOARDS",
  "PROJECTS_CONTEXT": "OTHER_BOARDS",
  "TEMPLATES_CONTEXT": "OTHER_TEMPLATES",
  "SHARED_BOARDS": "OTHER_BOARDS",
  "SHARED_TEMPLATES": "OTHER_TEMPLATES",
  "SHARED_ARCHIVED_BOARDS": "ARCHIVED_BOARDS",
  "SHARED_TRASHED_BOARDS": "TRASHED_BOARDS",
  "MY_BOARDS": "OTHER_BOARDS",
  "MY_TEMPLATES": "OTHER_TEMPLATES",
  "MY_ARCHIVED_BOARDS": "ARCHIVED_BOARDS",
  "MY_TRASHED_BOARDS": "TRASHED_BOARDS",
  "MANAGE_ACCOUNT_SUMMARY": "MANAGE_ACCOUNT",
  "PREFERENCES_BOARDS": "PREFERENCES"
};

/**
 * A hash whose key is account page ids & value is appropriate url.
 */
const accountContextUrls = {
  "MANAGE_ACCOUNT": "/manage-account/summary",
  "MANAGE_ACCOUNT_USERS": "/manage-account/users",
  "MANAGE_ACCOUNT_TRANSACTIONS": "/manage-account/transactions",
  "MANAGE_ACCOUNT_SETTINGS": "/manage-account/settings",
  "PREFERENCES": "/preferences/general",
  "PREFERENCES_NOTIFICATIONS": "/preferences/notifications",
  "PREFERENCES_CANVASES": "/preferences/canvases",
  "FAVORITES_BOARDS": "/home/favorite/boards",
  "OTHER_BOARDS": "/home/other/boards",
  "FAVORITES_TEMPLATES": "/home/favorite/templates",
  "FAVORITES_TEMPLATES_CURRENT_LANG": "/home/favorite/templates/current-lang",
  "OTHER_TEMPLATES": "/home/other/templates",
  "OTHER_TEMPLATES_CURRENT_LANG": "/home/other/templates/current-lang",
  "ARCHIVED_BOARDS": "/home/other/archive",
  "TRASHED_BOARDS": "/home/other/trash",
  "FAVORITES_VIEWS": "/home/favorite/views"
};

/**
 * This promise function is reject with parse v4  url.
 */
export const convertV1ToV4 = (data) => {
  return new Promise( async(resolve, reject) => {
    if (!data.path.startsWith('/app/v1')) {
      reject(data);
      return;
    }

    const pagePath = data.path.replace('/app/v1', '');
    const pageId = _parsePageId(pagePath);
    if (!pageId) {
      data.redirect = pagePath;
      resolve(data);
      return;
    }

    if(pageId === 'VIEWS') {
      data.convertV1ToV4 = true;
      data.redirect = pagePath;
      resolve(data);
      return;
    }

    const accountId = _getAccountId(data);
    const newPageId = newPageIds[pageId] || pageId;
    if(newPageId === 'PROFILE') {
      data.convertV1ToV4 = true;
      data.redirect = accountId ? `/${accountId}/home/other/boards#profile`: '/signup#profile';
      resolve(data);
      return;
    }

    if(newPageId === 'BOARD' || newPageId === 'CANVAS' || newPageId === 'BOARD_CARD' || newPageId === 'VIEWS_CARD') {
      data.redirect = await _getBoardCardUrl(pagePath, accountId, newPageId, data);
      resolve(data);
      return;
    }

    if(accountContextUrls[newPageId]) {
      data.convertV1ToV4 = true;
      data.redirect = accountId ? `/${accountId}${accountContextUrls[newPageId]}`: accountContextUrls[newPageId];
      resolve(data);
      return;
    }

    data.redirect = accountId ? `/${accountId}${pagePath}`: pagePath;
    resolve(data);
  });
};

const _parsePageId = (path) => {
  for (let pageId in oldAppUrlsRegex) {
    if (path.match(oldAppUrlsRegex[pageId])) {
      return pageId;
    }
  }
  return undefined;
};

/**
 * @return {Number} Return an account from the user's owned or accessible account, otherwise undefined.
 */
const _getAccountId = (data) => {
  if (data.ownedAccounts && data.ownedAccounts[0]) {
    return data.ownedAccounts[0];
  }

  for (let accountId in data.accessibleAccounts) {
    return accountId;
  }
  return undefined;
};

const _getBoardCardUrl = async (path, accountId, pageId, data) => {
  //For boad and card url.
  const uri = new URI(path);
  const segment = uri.segment();
  const entityId = segment[segment.length - 1];
  if(!entityId) {
    return path;
  }

  try {
    let newEntityId;
    if(entityId.startsWith('crd_') || entityId.startsWith('brd_')) {
      newEntityId = entityId;
    } else {
      const ids = await getNewIds([entityId]);
      newEntityId = ids[entityId];
    }
    if(!newEntityId) {
      path = await _getCanvasUrl(entityId, accountId, path, data);
      return path
    }

    let boardId;
    let entityType;
    if(newEntityId.startsWith('crd_')) {
      entityType = 'CARD';
      try {
        const response = await requestApi(`/card/cards/${newEntityId}`, { excludeErrors: [502, 401, 403, 404] });
        boardId = response && response.boardId;
      } catch (error) {}
    }

    if(newEntityId.startsWith('brd_') || boardId) {
      entityType = entityType || 'BOARD';
      boardId = boardId || newEntityId;
      try {
        const response = await requestApi(`/board/boards/${boardId}`, { excludeErrors: [502, 401, 403, 404] });
        accountId = response && response.accountId;
      } catch (error) {}
    }

    if(!accountId || !boardId) {
      return path;
    }
    data.convertV1ToV4 = true;
    return entityType === 'CARD' ? `/${accountId}/board/${boardId}/${newEntityId}${pageId === 'VIEWS_CARD' ? '?referrer-page=VIEWS': ''}`: `/${accountId}/board/${boardId}`;
  } catch (error) {
    console.error(error);
    return path;
  }
}

const _getCanvasUrl = async (entityId, accountId, path, data) => {
  try {
    const response = await requestApi(`/canvas/${from64(entityId)}/page-info`, { excludeErrors: [502, 401, 403, 404] });
    const boardId = response && response.board && response.board.refEntityId;
    accountId = response && response.board && response.board.accountId;
    if(!accountId || !boardId) {
      return path;
    }
    data.convertV1ToV4 = true;
    return `/${accountId}/board/${boardId}/cnvs_${entityId}`;
  } catch (error) {
    return path;
  }
}