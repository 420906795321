import {takeEvery, all } from 'redux-saga/effects';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as actions from './actions.js';

/**
 * Loads all feature loggle data from firestore.
 */
function* load() {
  //Already app saga load this details

  //firestoreRedux.getDocById('beta-users', 'bus_0');
  //firestoreRedux.query('app', { requesterId: 'app-data' });
}

/**
 * Init Saga.
 */
function* featureSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, load)
  ]);
}

export default featureSaga;