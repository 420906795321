import URI from '@dw/urijs-esm';

export const loginPageRouter = (data) => {
  return new Promise(function (resolve, reject) {
    if (data.path.startsWith('/login')) {
      if (!data.user) {
        data.page = {
          name: 'LOGIN_AND_SIGNUP',
          params: data.params,
        };
        resolve(data);
        return;
      }

      if (data.params.redirect) {
        location.href = decodeURIComponent(data.params.redirect);
        return;
      }
    }
    reject(data);
  });
};
