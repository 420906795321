import * as auth from '../auth';
import * as router from '../router';
import * as knownFeatures from '../known-features';
import * as boardTagsSelectors from '../board-tags/selectors.js';
import * as fileStore from '../file-store';
import firestoreRedux from '@dreamworld/firestore-redux';

import get from 'lodash-es/get.js';
import difference from 'lodash-es/difference.js';
import isEmpty from 'lodash-es/isEmpty';
import concat from 'lodash-es/concat';
import split from 'lodash-es/split';
import uniq from 'lodash-es/uniq';
import uniqWith from 'lodash-es/uniqWith';
import forEach from 'lodash-es/forEach';
import intersectionWith  from 'lodash-es/intersectionWith';
import orderBy from 'lodash-es/orderBy';
import values from 'lodash-es/values';
import sortBy from 'lodash-es/sortBy';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';

import htmlTrim from '@dreamworld/web-util/htmlTrim';
import moment from 'moment/src/moment';

import { getIdWoPrefix, isHashNumber } from '../../utils';
import * as utils from './utils.js';
import * as selectorCreator from '../selector-creator.js';

/**
 * Search string typed by the user.
 */
export const query = (state) => get(state, `search.query`);

/**
 * @returns {Object} last search request detail.
 */
export const lastSearchRequest = (state) => get(state, `search.lastSearchRequest`);

/**
 * @returns {String} last search request status.
 */
export const lastSearchRequestStatus = (state) => get(state, `search.lastSearchRequest.request.status`);

/**
 * @param {Boolen} state last search request using reference by card number or not.
 * @returns
 */
export const searchByReferenceNumber = (state) => isHashNumber(query(state)) || false;

 /**
  * @returns {String} Current type of the search. Possible values: 'CARDS', 'ATTACHMENTS' or 'CANVASES'. Default is 'CARDS'
  */
export const currentType = selectorCreator.reselct.createSelector(
  (state) => get(state, `search.lastSearchRequest.selectedType`),
  searchByReferenceNumber,
  (_currentType, _searchByReferenceNumber) => {
    if (_searchByReferenceNumber) {
      return 'CARDS'
    }
    return _currentType || 'CARDS';
  }
);

/**
 * @returns {String} Current selected baords.
 * When current page is not board page, returns 'OTHER' otherwise current selected Boards.
 * Possible values: 'CURRENT' or 'OTHER'
 */
export const currentSelectedBoards = selectorCreator.reselct.createSelector(
  (state) => get(state, `search.lastSearchRequest.selectedBoards`),
  router.selectors.pageBoardId,
  searchByReferenceNumber,
  (_selectedBoards, boardId, _searchByReferenceNumber) => {
    if (!boardId || _searchByReferenceNumber) {
      return 'OTHER'
    }
    return _selectedBoards || 'CURRENT';
  }
);

/**
 * @returns {Array} Search result for cards.
 * When current board is selected, returns result for current board. Otherwise, returns result for other boards.
 */
export const cardSearchResult = selectorCreator.reselct.createSelector(
  currentSelectedBoards,
  (state) => get(state, `search.searchResult.card`),
  (selectedBoards, searchResult) => {
    if(searchResult === undefined) {
      return searchResult;
    }

    const result = selectedBoards === 'CURRENT' ? get(searchResult, `currentBoard`): get(searchResult, `otherBoards`);
    if(isEmpty(result)) {
      return result;
    }
    let newResult = [];
    forEach(result, (item) => {
      item  = cloneDeep(item);
      if(item.type !== 'BOARD_CHAT') {
        newResult.push(item);
      } else {
        let bMatch =  false;
        forEach(newResult, (newItem) => {
          const newItemBoardId = get(newItem, 'board.id');
          const itemBoardId = get(item, 'board.id');
          if(newItem.type === 'BOARD_CHAT' && newItemBoardId === itemBoardId) {
            //Add chats object
            const chats = get(newItem, 'chats', []);
            chats.push(get(item, 'chats.0'));

            //Add snippest
            const snippets = get(newItem, 'snippets', []);
            snippets.push(get(item, 'snippets.0'));

            bMatch = true;
            return false;
          }
        });

        if(!bMatch) {
          const snippetsItem = get(item, 'snippets.0', '');
          item = {...item, ...{snippets: [snippetsItem]}};
          newResult.push(item);
        }
      }
    });

    if(isEqual(result, newResult)) {
      return result;
    }
    return newResult;
  }
);

/**
 * @returns {Array} Search result for attachments.
 * When current board is selected, returns result for current board. Otherwise, returns result for other boards.
 */
export const attachmentSearchResult = selectorCreator.reselct.createSelector(
  currentSelectedBoards,
  (state) => get(state, `search.searchResult.attachment`),
  (selectedBoards, searchResult) => {
    if(searchResult === undefined) {
      return searchResult;
    }

    if (selectedBoards === 'CURRENT') {
      return get(searchResult, `currentBoard`);
    }
    return get(searchResult, `otherBoards`);
  }
)

/**
 * @returns {Number} Board Id of last search request.
 */
export const lastSearchRequestBoardId = (state) => get(state, `search.lastSearchRequest.boardId`);

/**
 * @returns {Boolean} `true` when search result is being loaded.
 * When current page is board page & last search request boardId is different returns `true`
 * Otherwise, when search result is not available & search request is in progress, returns `true`
 */
export const searchInProgress = selectorCreator.reselct.createSelector(
  lastSearchRequestStatus,
  lastSearchRequestBoardId,
  router.selectors.pageBoardId,
  currentType,
  cardSearchResult,
  attachmentSearchResult,
  (requestStatus, lastSearchRequestBoardId, currentBoardId, currentType, cardSearchResult, attachmentSearchResult) => {
    if (currentBoardId && lastSearchRequestBoardId != currentBoardId && requestStatus === 'IN_PROGRESS') {
      return true;
    }
    if (currentType === 'CARDS') {
      return requestStatus === 'IN_PROGRESS' && cardSearchResult == undefined;
    } else if (currentType === 'ATTACHMENTS') {
      return requestStatus === 'IN_PROGRESS' && attachmentSearchResult == undefined;
    }
  }
);

/**
 * @returns {Object} Filters for card search. When no filter set, returns default filters.
 */
export const cardFilters = selectorCreator.reselct.createSelector(
  (state) => get(state, `search.lastSearchRequest.cardFilters`),
  (filters) => {
    if (isEmpty(filters)) {
      return defaultCardFilters();
    }
    return filters;
  }
)

/**
 * @returns {Object} default cards filters value.
 */
export const defaultCardFilters = () => {
  return {
    searchFields: ['TITLE', 'DESCRIPTION', 'CHAT', 'TASK'],
    includeUnparticipatedBoards: false,
    templates: false,
    boardStatus: ['ACTIVE'],
    columnTypes: ['OTHER', 'DONE', 'TRASH'],
    excludeBoardIds: [],
    includeBoardIds: [],
    filter: {
      lastUpdatedFrom: null,
      lastUpdatedTo: null,
      dueDateNotScheduled: false,
      dueDateFrom: null,
      dueDateTo: null,
      includeText: '',
      excludeText: '',
      status: [],
      priority: [],
      tags: [],
      assignments: []
    }
  }
}

export const defaultCardsRefNoFilters = () => {
  return {
    searchFields: ['REF_NO'],
    includeUnparticipatedBoards: true,
    templates: true,
    boardStatus: ['ACTIVE', 'ARCHIVED', 'TRASHED'],
    columnTypes: ['OTHER', 'DONE', 'TRASH'],
    excludeBoardIds: [],
    includeBoardIds: [],
    filter: {
      lastUpdatedFrom: null,
      lastUpdatedTo: null,
      dueDateNotScheduled: false,
      dueDateFrom: null,
      dueDateTo: null,
      includeText: '',
      excludeText: '',
      status: [],
      priority: [],
      tags: [],
      assignments: []
    }
  }
}

/**
 * @returns {Boolean} `true` when card filters is changed. `false` otherwise.
 */
export const isCardFiltersChanged = selectorCreator.reselct.createSelector(cardFilters, defaultCardFilters, (_cardFilters, _defaultFilters) => {
  return !isEqual(_cardFilters, _defaultFilters);
});

/**
 * @returns {Object} Filters for attachments search. When no filter set, returns default filters.
 */
export const attachmentFilters = selectorCreator.reselct.createSelector(
  (state) => get(state, `search.lastSearchRequest.attachmentFilters`),
  (filters) => {
    if (isEmpty(filters)) {
      return defaultAttachmentFilters();
    }
    return filters;
  }
)

/**
 * @returns {Object} default cards filters value.
 */
export const defaultAttachmentFilters = () => {
  return {
    docTypes: ['DOC', 'SPREADSHEET', 'PRESENTATION', 'GOOGLE_FORM', 'BOX_NOTE', /* 'ONE_NOTE',*/ 'WEB', 'IMAGE', 'AUDIO', 'VIDEO', 'PDF', 'OTHER', 'COMPRESS', 'DRAWING'],
    includeUnparticipatedBoards: false,
    templates: false,
    boardStatus: ['ACTIVE'],
    columnTypes: ['OTHER', 'DONE', 'TRASH'],
    filter: {
      lastUpdatedFrom: null,
      lastUpdatedTo: null,
      includeText: '',
      excludeText: ''
    }
  };
}

/**
 * @returns {Boolean} `true` when attachment filters is changed. `false` otherwise.
 */
export const isAttachmentFiltersChanged = selectorCreator.reselct.createSelector(attachmentFilters, defaultAttachmentFilters, (_attachmentFilters, _defaultFilters) => {
  return !isEqual(_attachmentFilters, _defaultFilters);
});

/**
 * @returns {Array} List of tags for card filters. e.g. [{name: 'Bug fixes', color: 'red'}, {name: 'PWA: Sentry error', color: 'blue'}]
 */
export const filterTags = selectorCreator.default({maxSize: 50})(
  (state) => get(state, `search.filterData.tags`),
  (state) => boardTagsSelectors.allowedColors(state),
  (tags, _allowedColors) => {
    if(isEmpty(tags)) {
      return tags;
    }

    let newTags = [];
    forEach(tags, (tag) => {
      let color = '';
      if(tag.color) {
        color = get(_allowedColors, `${tag.color}.value`, '');
      }
      newTags.push(merge({}, tag, {color}));
    });
    return sortBy(newTags, (tag) => tag && tag.name && tag.name.toLowerCase());
  }
);

/**
 * @returns {Array} List of assignments for card filters. e.g. [{id: 578333, name: 'Nirmal Baldaniya', profilePic: ''}, {id: 904636, name: 'Chirag Moradiya', profilePic: ''}]
 */
export const filterAssignments = selectorCreator.reselct.createSelector(
  (state) => get(state, `search.filterData.users`),
  (assignments) => {
    if(isEmpty(assignments)) {
      return assignments;
    }

    return  sortBy(assignments, (user) => {
      let name = user && user.firstName || user.email;
      if(user.lastName)  {
        name = `${name} ${user.lastName}`;
      }
      return name.toLowerCase();
    });
  }
);


/**
 * @returns {Object} Highlights data. e.g. { query, matchedOn: ['TITLE', 'TASK'], chats, tasks, snippets }
 */
export const lastOpenedSearchResult = (state) => get(state, `search.lastOpenedSearchResult`);

/**
 * Matched attachment Id.
 */
export const matchedAttachmentId = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    return get(data, 'attachment.id');
  }
)

/**
 * @returns {Boolean} `true` when search result matched on card title.
 */
export const isCardTitleMatched = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    return get(data, 'type') === 'CARD' && get(data, 'matchedOn', []).includes('TITLE')
  }
)

/**
 * @returns {String} did you mean suggestion.
 */
export const didYouMean = (state) => get(state, 'search.didYouMean.0');

/**
 * @returns {Boolean} `true` when search result matched on card description.
 */
export const isCardDescriptionMatched = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    return get(data, 'type') === 'CARD' && get(data, 'matchedOn', []).includes('DESCRIPTION')
  }
)

/**
 * @returns {Array} List of task ids when search result matched on sub-task.
 */
export const highlightedTasks = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    let taskIds = [];
    forEach(get(data, 'tasks', []), (task) => {
      taskIds.push(task.id);
    })
    return taskIds;
  }
)

/**
 * @returns {Array} List of chat ids when search result matched on chats.
 */
export const highlightedChats = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    let chatIds = [];
    forEach(get(data, 'chats', []), (chat) => {
      chatIds.push(chat.id);
    })
    return chatIds;
  }
)

/**
 * @returns {Array} highlighted search keywords. When search result is matched on card title,
 *                  returns search query keywords otherwise returns html snippets keyword.
 */
const highlightedKeywords = selectorCreator.reselct.createSelector(
  lastOpenedSearchResult,
  (data) => {
    let keywords = [];
    if (get(data, 'matchedOn', []).includes('TITLE')) {
      get(data, 'query') && keywords.push(data.query);
      const queryKeywords = split(data.query, ' ');
      const queryKeywords2 = split(data.query, '-');
      keywords = concat(keywords, queryKeywords, queryKeywords2);
    }
    const snippetsKeyword = utils.keywordsForSnippets(get(data, 'snippets', []));
    return uniq(concat(keywords, snippetsKeyword));
  }
)

/**
 * @returns {String} `htmlString` html string with highlighted searc result keywords.
 */
export const highlightedHtml = selectorCreator.reselct.createSelector(
  (state, htmlString) => htmlString,
  highlightedKeywords,
  (htmlString, keywords) => {
    return utils.highlightedHtml(htmlString, keywords);
  }
)

/**
 * @returns {Array} Current user's search history.
 */
export const searchHistory = selectorCreator.default({maxSize: 100})(
  (state) => firestoreRedux.selectors.collection(state, 'search-history'),
  (history) => {
    return uniqWith(values(history), (item1, item2)=> {
      return item1.query === item2.query;
    });
  }
);

/**
 * Based on user's query, prepares list of suggestions.
 * Algorithm:
 *  1st priority: When exact query matched.
 *  2nd priority: Whose more words are matched, will be first.
 * @returns {Array} Search suggestions. e.g. `['PWA: service worker not updating', 'iOS: fix keyboard issue']`
 */
export const suggestions = selectorCreator.reselct.createSelector(
  searchHistory,
  (state, actualQuery, cursorPosition) => {
    return {actualQuery, cursorPosition};
  },
  (searchHistory, {actualQuery, cursorPosition}) => {
    if (isEmpty(actualQuery) || isEmpty(searchHistory)) {
      return [];
    }
    const partialWord = __getCursorPositionWord(actualQuery, cursorPosition);
    actualQuery = actualQuery.trim() || '';
    actualQuery = actualQuery.toLowerCase();
    const queryWords = split(actualQuery, ' ');
    let suggestions = [];
    forEach(searchHistory, (history) => {
      let historyQuery = history.query && history.query.trim() || '';
      historyQuery = historyQuery.toLowerCase();

      //If query is same as a history query.
      if(actualQuery === historyQuery) {
        return true;
      }

      const historyWords = split(historyQuery, ' ');
      const matchedWords = intersectionWith(historyWords, queryWords, (hw,qw) => {
        hw = hw && hw.toLowerCase() || hw;
        qw = qw && qw.toLowerCase() || qw;
        return hw === qw || (hw.startsWith(qw) && partialWord === qw);
      });

      //If matchwords has found.
      if(!matchedWords || !matchedWords.length) {
        return true;
      }

      //If query words is more than 1 and history query is only one word.
      if(queryWords.length > 1 && historyWords.length === 1) {
        return true;
      }

      let htmlTitle = historyQuery;
      let partial =  false;
      forEach(uniq(matchedWords), (word) => {
        if(!partial) {
          partial = partialWord && word === partialWord;
        }
        htmlTitle = htmlTitle.replace(
          word,
          `<strong>${word}</strong>`
        );
      });
      suggestions.push({ title: historyQuery, order: matchedWords.length, lastUsedAt: history.lastUsedAt, htmlTitle: htmlTrim(htmlTitle), partial});
    });

    suggestions = uniqWith(suggestions, (item1, item2) => {
      return item1.title === item2.title;
    });
    return orderBy(suggestions, ['order', 'partial', 'lastUsedAt'], ['desc', 'desc', 'desc']);
  }
);

/**
 * @returns {String} word from given query for given cursor position.
 */
const __getCursorPositionWord = selectorCreator.default({maxSize: 100})(
  (query, cursorPosition) => query,
  (query, cursorPosition) => cursorPosition,
  (query, cursorPosition) => {
    query = query && query.toLowerCase() || '';
    const queryWords = split(query, ' ');
    let partialWord;
    let wordStartPosition;
    let wordEndPosition;
    forEach(queryWords, (word)=> {
      wordStartPosition = wordStartPosition ? wordStartPosition: 1;
      wordEndPosition = wordStartPosition + (word.length - 1);
      if(wordStartPosition <= cursorPosition && wordEndPosition >= cursorPosition) {
        partialWord = word;
        return false;
      }
      wordStartPosition = wordEndPosition + 2;
    });
    return partialWord;
  }
);

/**
 * @returns {Boolean} `true` when suggestions dialog is opened.
 */
export const suggestionsDialogOpened = state => get(state, `search.suggestionsDialogOpened`);

/**
 * Auto numbering is enabled for current user for one of the board from accessible board.
 */
const autoNumberingEnabled = selectorCreator.reselct.createSelector(
  (state) => firestoreRedux.selectors.collection(state, 'user-accessible-boards'),
  (accessibleBoards) => {
    let enabled = false;
    forEach(accessibleBoards, (board)=> {
      if(board && board.showCardRefNo) {
        enabled = true;
        return false;
      }
    });
    return enabled;
  }
);

/**
 * Search reference number tip is known or not for current user.
 * @returns {Boolean} `true` tip is known for current user. `false` otherwise.
 */
export const refNoTipKnown = selectorCreator.reselct.createSelector(
  autoNumberingEnabled,
  (state) => firestoreRedux.selectors.doc(state, 'user-ui', `srnt_${getIdWoPrefix({ id: auth.selectors.currentUserId(state), prefix: 'usr_' })}`),
  (state) =>  knownFeatures.selectors.isKnown({ state, name: 'SEARCH_REF_NO_TIP' }),
  (_autoNumberingEnabled, searchRefNoTip, _refNoTipKnown) => {
    if(_refNoTipKnown) {
      return true;
    }

    const refNoTipLastSeen = get(searchRefNoTip, 'lastSeen', 0);
    if(refNoTipLastSeen && moment(refNoTipLastSeen).isSame(moment(), 'day')) {
      return true;
    }

    return !_autoNumberingEnabled;
  }
);

/**
 * @returns {Boolean} `true` when filter available.
 */
export const hasFilterAvailable = selectorCreator.default({maxSize: 20})(
  (state) => currentType(state),
  (state) => fileStore.selectors.microsoftAccessibleAccounts(state),
  (state) => auth.selectors.accessibleAccounts(state),
  (state) => currentSelectedBoards(state),
  (state) => router.selectors.accountId(state),
  (currentType, microsoftAccessibleAccounts, accessibleAccounts, currentSelectedBoards, accountId) => {
    if(currentType !== 'ATTACHMENTS') {
      return true;
    }

    if(currentSelectedBoards === 'CURRENT') {
      return !microsoftAccessibleAccounts.includes(accountId);
    }
    
    if(isEmpty(difference(accessibleAccounts, microsoftAccessibleAccounts))) {
      return false;
    }

    return true;
  }
);