import { createSelector } from 'reselect';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual.js';
import sortBy from 'lodash-es/sortBy';
import get from 'lodash-es/get';
import find from 'lodash-es/find.js';
import filter from 'lodash-es/filter.js';

// redux imports
import * as app from '../app';
import * as router from '../router';
import * as boardSelectors from '../board/selectors.js';
import * as fileStore from '../file-store';
import * as firestoreRedux from '@dreamworld/firestore-redux';

/**
 * @param {String} cardId Card ID passed to give details of card attachments.
 * @return { Array } List of card attachments. e.g. [{id, title, order}, ...]
 */
export const cardAttachments = createSelector(
  ({ state, cardId }) => firestoreRedux.selectors.queryStatus(state, `card-attachments-${cardId}`),
  ({ state, cardId }) => firestoreRedux.selectors.docsByQueryResult(state, `card-attachments-${cardId}`, 'card-attachments'),
  (queryStatus, attachments) => {
    if (!queryStatus || (isEmpty(attachments) && queryStatus === 'PENDING')) {
      return;
    }

    return sortBy(attachments, 'order').reverse();
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 100,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} cardId Card ID passed to get details of card attachments with tips.
 * @return {Boolean} True if there are any card attachments with tips, false otherwise.
 */
export const hashCardAttachmentsTipItem = createSelector(
  ({ state, cardId }) => cardAttachments({ state, cardId }),
  (attachments) => {
    const tipAttachments = filter(attachments, (attachment) => !!attachment.coludPermissionTip);
    return tipAttachments && !!tipAttachments.length || false;
  }
);

/**
 * @param {String} boardId board Id
 * @return { Array } List of board attachments. e.g. [{id, order, title}, ...]
 */
export const boardAttachments = createSelector(
  ({ state, boardId }) => firestoreRedux.selectors.queryStatus(state, `board-attachments-${boardId}`),
  ({ state, boardId }) => firestoreRedux.selectors.docsByQueryResult(state, `board-attachments-${boardId}`, 'board-attachments'),
  (queryStatus, attachments) => {
    if (!queryStatus || (isEmpty(attachments) && queryStatus === 'PENDING')) {
      return;
    }
    return sortBy(attachments, 'order').reverse();
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} boardId Board ID passed to get details of board attachments with tips.
 * @return {Boolean} True if there are any board attachments with tips, false otherwise.
 */
export const hashBoardAttachmentsTipItem = createSelector(
  ({ state, boardId }) => boardAttachments({ state, boardId }),
  (attachments) => {
    const tipAttachments = filter(attachments, (attachment) => !!attachment.coludPermissionTip);
    return tipAttachments && !!tipAttachments.length || false;
  }
);

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId Attachment ID passed to give attachment item model.
 * @return { Object } Card attachment model.
 */
export const cardAttachmentItem = (state, attachmentId) => {
  return firestoreRedux.selectors.doc(state, 'card-attachments', attachmentId);
};

/**
 * @param {Object} state Redux state.
 * @param {String} attachmentId Attachment ID passed to give attachment item model.
 * @return { Object } Selector which return attachment model.
 */
export const boardAttachmentItem = (state, attachmentId) => {
  return firestoreRedux.selectors.doc(state, 'board-attachments', attachmentId);
};

/**
 * @param {String} fileId give file id to get file info.
 * @returns {Object} file information to given fileId.
 */
export const getCloudFileInfo = createSelector(
  (state, fileId) => `${fileStore.selectors.preferredStorage(state)}-${fileId}`,
  (state, fileId) => firestoreRedux.selectors.queryStatus(state, `cloud-files-${fileStore.selectors.preferredStorage(state)}-${fileId}`),
  (state, fileId) => firestoreRedux.selectors.docsByQueryResult(state, `cloud-files-${fileStore.selectors.preferredStorage(state)}-${fileId}`, 'cloud-files'),
  (docId, queryStatus, docs) => {
    const doc = find(docs, {id: docId}) || find(docs, {shortLink: docId}) || (docs && docs[0]);
    return doc || (queryStatus === 'CLOSED' ? {} : undefined);
  },
  {
    memoizeOptions: {
      maxSize: 500,
      resultEqualityCheck: isEqual,
    }
  }
);

/**
 * Based on current account's preferred store & current user's emails checks that current board folder is shared with current user or not.
 * @returns {Boolean} `true` When current or given board's folder is shared with current user.
 */
export const isBoardFolderShared = createSelector(
  fileStore.selectors.preferredStorage,
  fileStore.selectors.currentUserEmails,
  fileStore.selectors.cloudAccountType,
  (preferredStorage, emails, cloudAccountType) => {
    if (isEmpty(preferredStorage) || isEmpty(emails)) {
      return false;
    }

    if (preferredStorage === 'GOOGLE' || preferredStorage === 'GOOGLE_MARKET_PLACE' || preferredStorage === 'KERIKA') {
      return !isEmpty(get(emails, 'googleEmails'));
    }

    if(preferredStorage === 'MICROSOFT') {
      if(cloudAccountType === 'PERSONAL') {
        return !isEmpty(get(emails, 'microsoftPersonalEmails'));
      }
      return !isEmpty(get(emails, 'microsoftPersonalEmails')) || !isEmpty(get(emails, 'microsoftWorkEmails'));
    }

    if(preferredStorage === 'BOX') {
      return !isEmpty(get(emails, 'boxEmails'));
    }

    return false;
  }
);

/**
 * @param {Object} state Redux state
 * @returns Attachment id for which file preview should be shown.
 */
export const filePreviewId = (state) => get(state, `router.page.params.file-preview`, '');

/**
 * Based on Router data (attachment id, board id, container id), computes file preview url.
 * @returns {String} File preview URL
 */
export const filePreviewUrl = createSelector(
  app.selectors.config,
  router.selectors.accountId,
  router.selectors.cardId,
  filePreviewId,
  (config, accountId, cardId, attachmentId) => {
    if (!accountId || !attachmentId) {
      return;
    }
    return `${config.apiBaseUrl}/file-store/${
      cardId ? 'card-attachments' : 'board-attachments'
    }/${attachmentId}/preview?accountId=${accountId}`;
  },
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * given attachment board id.
 */
export const attachmentBoardId = (state, attachmentId) => {
  const attachment = attachmentId && attachmentId.startsWith('ca_') ? cardAttachmentItem(state, attachmentId): boardAttachmentItem(state, attachmentId);
  return attachment && attachment.boardId;
}

/**
 * given attachment board details.
 */
export const attachmentBoardAttrs = (state, attachmentId) => {
  const boardId = attachmentBoardId(state, attachmentId)
  return boardId && boardSelectors.attrs(state, boardId);
}