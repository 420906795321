export const ACCESS_REVOKED_DETECTED = 'FILE_STORE_ACCESS_REVOKED_DETECTED';
export const REQUEST_AUTHORIZATON_TO_AO = 'FILE_STORE_REQUEST_AUTHORIZATON_TO_AO';
export const REQUEST_AUTHORIZATON_TO_AO_DONE = 'FILE_STORE_REQUEST_AUTHORIZATON_TO_AO_DONE';
export const REQUEST_AUTHORIZATON_TO_AO_FAILED = 'FILE_STORE_REQUEST_AUTHORIZATON_TO_AO_FAILED';
export const REAUTHORIZE = 'FILE_STORE_REAUTHORIZE';
export const REAUTHORIZE_DONE = 'FILE_STORE_REAUTHORIZE_DONE';
export const REAUTHORIZE_FAILED = 'FILE_STORE_REAUTHORIZE_FAILED';
export const LOAD_DATA = 'FILE_STORE_LOAD_DATA';

export const PREFERRED_STORE_SELECTION_PENDING = 'FILE_STORE_PREFERRED_STORE_SELECTION_PENDING';

export const PREFERRED_STORE_SELECTION = 'FILE_STORE_PREFERRED_STORE_SELECTION';
export const PREFERRED_STORE_SELECTION_DONE = 'FILE_STORE_PREFERRED_STORE_SELECTION_DONE';
export const PREFERRED_STORE_SELECTION_FAILED = 'FILE_STORE_PREFERRED_STORE_SELECTION_FAILED';

export const CHANGE_PREFERRED_STORE = 'FILE_STORE_CHANGE_PREFERRED_STORE';
export const CHANGE_PREFERRED_STORE_DONE = 'FILE_STORE_CHANGE_PREFERRED_STORE_DONE';
export const CHANGE_PREFERRED_STORE_FAILED = 'FILE_STORE_CHANGE_PREFERRED_STORE _FAILED';

export const CHECK_PREFERRED_STORE_SELECTION = 'FILE_STORE_CHECK_PREFERRED_STORE_SELECTION';
export const CHECK_PREFERRED_STORE_SELECTION_DONE = 'FILE_STORE_CHECK_PREFERRED_STORE_SELECTION_DONE';
export const CHECK_PREFERRED_STORE_SELECTION_FAILED = 'FILE_STORE_CHECK_PREFERRED_STORE_SELECTION_FAILED';


/**
 * Loads account cloud stores & current user's cloud-store emails.
 * @param {String} requesterId Requester Id
 * @param {String} accountId Optional. if not given consider current account.
 */
export const load = (requesterId, accountId) => {
  return {
    type: LOAD_DATA,
    requesterId,
    accountId
  }
}


/**
 * Send request to AO for re-authorization.
 */
export const requestAuthorizationToAO = () => {
  return {
    type: REQUEST_AUTHORIZATON_TO_AO
  }
}

/**
 * re-authorization for given service.
 */
export const reAuthorize = (service, email) => {
  return {
    type: REAUTHORIZE,
    service,
    email
  }
}

/**
 * Dispatch this action when account preferred store selection is pending.
 */
export const preferredStoreSelectionPending = ({callback}) => {
  return {
    type: PREFERRED_STORE_SELECTION_PENDING,
    callback
  }
}

/**
 * Dispatch this action when select a preferred stroe for given account.
 * @param {Number} accountId account Id
 * @param {String} storage new storage name. Possible values: KERIKa, GOOGLE.
 * @param {String} token change preferred store selection using this token. It's optional when user is login.
 */
export const preferredStoreSelect = ({accountId, token, storage, inSameWindow = false, reAuthorizationAlreadyCompleted = false, email, authEmail}) => {
  return {
    type: PREFERRED_STORE_SELECTION,
    accountId,
    token,
    storage,
    inSameWindow,
    reAuthorizationAlreadyCompleted,
    email,
    authEmail
  }
}

export const _preferredStoreSelectFailed = ({accountId, error, storage}) => {
  return {
    type: PREFERRED_STORE_SELECTION_FAILED,
    accountId,
    storage,
    error
  }
}

export const _preferredStoreSelectDone = ({accountId, storage, status = 'SUCCESS', email, authEmail}) => {
  return {
    type: PREFERRED_STORE_SELECTION_DONE,
    accountId,
    storage,
    status,
    email,
    authEmail
  }
}

/**
 * Dispatch this action when check the user has already select preffered store or not.
 * @param {Number} accountId account Id
 * @param {String} storage new storage name. Possible values: KERIKa, GOOGLE.
 * @param {String} token change preferred store selection using this token. It's optional when user is login.
 */
export const checkPrefferedStoreSelection = ({accountId, token, storage}) => {
  return {
    type: CHECK_PREFERRED_STORE_SELECTION,
    accountId,
    token,
    storage
  }
}

export const _checkPrefferedStoreSelectionDone = ({accountId, token, storage, data}) => {
  return {
    type: CHECK_PREFERRED_STORE_SELECTION_DONE,
    accountId,
    token,
    storage,
    data,
  }
}

export const _checkPrefferedStoreSelectionFailed = ({accountId, token, storage, error}) => {
  return {
    type: CHECK_PREFERRED_STORE_SELECTION_FAILED,
    accountId,
    token,
    storage,
    error
  }
}

/**
 * Dispatch this action when change preferred stroe for given account.
 * @param {Number} accountId account Id
 * @param {String} storage new storage name. Possible values: KERIKA, GOOGLE, MICROSOFT.
 * @param {String} token change preferred store selection using this token. It's optional when user is login.
 */
export const changePrefferedStore = ({accountId, token, storage, inSameWindow = false, reAuthorizationAlreadyCompleted = false, email, authEmail}) => {
  return {
    type: CHANGE_PREFERRED_STORE,
    accountId,
    token,
    storage,
    inSameWindow,
    reAuthorizationAlreadyCompleted,
    email,
    authEmail
  }
}

export const _changePrefferedStoreFailed = ({accountId, error, storage}) => {
  return {
    type: CHANGE_PREFERRED_STORE_FAILED,
    accountId,
    storage,
    error
  }
}

export const _changePrefferedStoreDone = ({accountId, storage, status = 'SUCCESS', email, authEmail}) => {
  return {
    type: CHANGE_PREFERRED_STORE_DONE,
    accountId,
    storage,
    status,
    email,
    authEmail
  }
}