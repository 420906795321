import get from 'lodash-es/get';
import { createSelector } from 'reselect';
import * as router from '../router/index.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix } from '../../utils';


/**
 * @param {Object} state Redux state
 * @returns {String} currently opened board privacy.
 */
export const privacy = (state) => {
  const boardId = router.selectors.pageBoardId(state);
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'privacy', '');
};

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened board WIP is enabled or not.
 */
export const enabledWIP = (state) => {
  const boardId = router.selectors.pageBoardId(state);
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'wipLimitEnabled', false);
};

/**
 * @param {Object} state Redux state
 * @param {String} boardId Board Id
 * @returns {Boolean} currently opened board autoNumbering is enabled or not.
 */
export const enabledAutoNumbering = (state) => {
  const boardId = router.selectors.pageBoardId(state);
  const doc = firestoreRedux.selectors.doc(state, 'boards', boardId);
  return get(doc, 'showCardRefNo', false);
};

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened board tagsEnabled is enabled or not.
 */
export const tagsEnabled = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId || router.selectors.pageBoardId(state)),
  (doc) => {
    if(!doc) {
      return false;
    }

    const tagsEnabled = get(doc, 'tagsEnabled');
    if(tagsEnabled === null) {
      return true;
    }
    return tagsEnabled;
  }
);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} currently opened board CardAdded is enabled or not.
 */
export const visitorCardAddedEnabled = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'board-visitor-notification-preferences', `bvnp_${getIdWoPrefix({prefix: 'brd_', id: boardId || router.selectors.pageBoardId(state)})}`),
  (doc) => {
    if(!doc) {
      return false;
    }

    return get(doc, 'cardAdded') || false;
  }
);