export const PAGE_OPENED = 'VIEW_PAGE_OPENED';
export const PAGE_CLOSED = 'VIEW_PAGE_CLOSED';
export const PAGE_CHANGED = 'VIEW_PAGE_CHANGED';

export const SETTINGS_DIALOG_OPENED = 'VIEW_SETTINGS_DIALOG_OPENED';
export const UPDATE_SETTINGS = 'VIEW_UPDATE_SETTINGS';

export const UPDATE_PREFERENCES = 'VIEW_UPDATE_PREFERENCES';

export const UPDATE_SCROLL_POSITION = 'VIEW_UPDATE_SCROLL_POSITION';

export const LOAD_VIEW_DATA = 'LOAD_VIEW_DATA';
export const LOAD_VIEW_DATA_DONE = 'LOAD_VIEW_DATA_DONE';
export const LOAD_VIEW_DATA_FAILED = 'LOAD_VIEW_DATA_FAILED';

export const LOAD_PARTICIPATED_BOARDS = 'LOAD_PARTICIPATED_BOARDS';
export const LOAD_PARTICIPATED_BOARDS_DONE = 'LOAD_PARTICIPATED_BOARDS_DONE';
export const LOAD_PARTICIPATED_BOARDS_FAILED = 'LOAD_PARTICIPATED_BOARDS_FAILED';

export const MARK_COLUMN_AS_READ = 'VIEW_MARK_COLUMN_AS_READ';
export const MARK_COLUMN_AS_READ_DONE = 'VIEW_MARK_COLUMN_AS_READ_DONE';

export const UPDATE_SELECTED_CARD = 'VIEW_UPDATE_SELECTED_CARD';
export const CLEAR_SELECTED_CARD = 'VIEW_CLEAR_SELECTED_CARD';
export const TOGGLE_CARD_SELECTION = 'VIEW_CARD_TOGGLE_SELECTION';
export const SELECTION_UPTO_CARD = 'VIEW_CARD_SELECTION_UPTO_CARD';

export const REFRESH = 'VIEW_REFRESH';
export const UPDATE_DATA = 'VIEW_UPDATE_DATA';
export const REFRESH_DONE = 'VIEW_REFRESH_DONE';
export const REFRESH_FAILED = 'VIEW_REFRESH_FAILED';

export const INIT_DATA_LOADED = 'VIEW_INIT_DATA_LOADED';

export const UPDATE_COLUMN_VISIBILITY = 'VIEW_UPDATE_COLUMN_VISIBILITY';

/**
 * Dispatch redux action to update column visibility.
 * @param {Number} columnId Column Id whose visibility needed to be changed.
 * @param {Boolean} visibility Visibility of column. `true` or `false`
 */
 export const updateVisibility = (columnId, visibility) => {
  return {
    type: UPDATE_COLUMN_VISIBILITY,
    columnId,
    visibility
  }
 }

/**
 * Requests server to refresh current view.
 * @param {String} id  View Id.
 */
export const refresh = (id) => {
  return {
    type: REFRESH,
    id
  }
}

/**
 * refresh done.
 * @param {String} id  View Id.
 * @param {String} data  View data.
 */
export const _refreshDone = (id, data) => {
  return {
    type: REFRESH_DONE,
    id,
    data
  }
}

/**
 * refresh done.
 * @param {String} id  View Id.
 * @param {String} error
 */
export const _refreshFailed = (id, error) => {
  return {
    type: REFRESH_FAILED,
    id,
    error
  }
}

/**
 * Dispatch when init data is loaded.
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const _initDataLoaded = (id, data) => {
  return {
    type: INIT_DATA_LOADED,
    id,
    data
  }
}

export const LOAD_SUMMARY  = 'VIEW_LOAD_SUMMARY';
export const DISCONNECT_SUMMARY = 'VIEW_DISCONNECT_SUMMARY';

/**
 * Requests to update groupBy or assignedTo me criteria of current view.
 * @param {*} param0 
 *  @property {String} groupBy Group By of current view. Possible values: 'BOARD', 'STATUS', 'DUE_DATE'....
 *  @property {Boolean} assignedToMe `true` when assignedToMe filter is applied.
 */
export const updatePreferences = ({groupBy, assignedToMe}) => {
  return {
    type: UPDATE_PREFERENCES,
    groupBy,
    assignedToMe
  }
}

/**
 * Requests to update view settings of current user.
 * @param {*} param0 
 *  @property {String} includeBoards Possible values: 'FAVORITE', 'ALL' or 'SELECTED'
 *  @property {Array} includeBoardIds List of selected participated board ids.
 */
export const updateSettings = ({ includeBoards, includeBoardIds }) => {
  return {
    type: UPDATE_SETTINGS,
    includeBoards,
    includeBoardIds
  }
}

/**
 * Dispatches MARK_COLUMN_AS_READ action.
 * @param {String} columnId Column Id of active column.
 */
export const markColumnAsRead = (columnId) => {
  return {
    type: MARK_COLUMN_AS_READ,
    columnId
  }
}

/**
 * Dispatches UPDATE_SCROLL_POSITION action.
 * @param {String} columnIndex Index of column
 */
export const updateScrollPosition = (columnIndex) => {
  return {
    type: UPDATE_SCROLL_POSITION,
    columnIndex, 
 } 
}


/**
 * Set card seection for currently open view.
 * @param { Array } cardIds selected card Ids for current board.
 * @param { String } columnId Column Id in which card is selected.
 */
export const setSelection = (cardIds, columnId) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_SELECTED_CARD,
    cardIds,
    columnId
  });
}

/**
 * Clear card selection for currently open view.
 */
export const clearSelection = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_CARD
  });
}

/**
 * When card is already selected, removes it's selection otherwise adds it into selection.
 * @param { Number } cardId card Id for toggle selection.
 * @param { String } columnId View Column Id in which card is selected.
 */
export const toggleSelection = (cardId, columnId) => {
  return {
    type: TOGGLE_CARD_SELECTION,
    cardId,
    columnId
  }
}

/**
 * Selects cards upto given cardId
 * @param { Number } cardId card Id
 * @param { String } columnId View column Id in which selection is udated.
 */
export const selectUpto = (cardId, columnId) => {
  return {
    type: SELECTION_UPTO_CARD,
    cardId,
    columnId
  }
}

/*
 * Load views summary
 */
export const loadSummary = () => {
  return {
    type: LOAD_SUMMARY
  } 
}

/**
 * Disconnect views summary.
 */
 export const disconnectSummary = () => {
  return {
    type: DISCONNECT_SUMMARY
  } 
}