export const CHANGE_LANGUAGE = 'MULTIPLE_LANGUAGE_CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'MULTIPLE_LANGUAGE_CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAILED = 'MULTIPLE_LANGUAGE_CHANGE_LANGUAGE_FAILED';

export const LOAD_DATA = 'MULTIPLE_LANGUAGE_LOAD_DATA';

/**
 * Loads multiple-language data from firestore.
 */
export const load = () => {
  return {
    type: LOAD_DATA
  }
}

/**
 * Change to new language.
 * @param {String} lang new language.
 */
export const changeLanguage = (lang) => {
  return {
    type: CHANGE_LANGUAGE,
    lang
  }
};

export const _changeLanguageSuccess = (lang) => {
  return {
    type: CHANGE_LANGUAGE_SUCCESS,
    lang
  }
};

export const _changeLanguageFailed = (lang, error) => {
  return {
    type: CHANGE_LANGUAGE_FAILED,
    lang,
    error
  }
};