export const PAGE_OPENED = 'ACCOUNT_PREFERENCE_PAGE_OPENED';
export const PAGE_CLOSED = 'ACCOUNT_PREFERENCE_PAGE_CLOSED';
export const PREFERENCES = 'PREFERENCES_CHANGE';
export const PREFERENCES_DONE = 'PREFERENCES_CHANGE_DONE';
export const PREFERENCES_FAILED = 'PREFERENCES_CHANGE_FAILED';


/**
 * Dispatch action to update account settings.
 * @param {Object} preferences Account preferences
 */
export const updatePreferences = (preferences) => {
  return {
    type: PREFERENCES,
    preferences
  }
}