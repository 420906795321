import get from 'lodash-es/get';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';

import * as authSelectors from '../auth/selectors.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as utils from '../../utils.js';
import { createSelector } from 'reselect';

/**
 * @returns {Object} Given user basic details except last-seen details.
 *                   e.g `{ id, email, firstName, lastName, image, service, pending, accountId, signupTime, auths }`
 */
export const attrs = createSelector(
  (state, userId) => firestoreRedux.selectors.doc(state, 'users', userId || authSelectors.currentUserId(state)),
  (attrs) => {
    if (!attrs) {
      return attrs;
    }
    const primaryAuth = find(get(attrs, `auths`), {
      id: attrs.primaryAuthId,
    });

    const newAttrs = {
      id: get(attrs, 'id'),
      email: get(attrs, 'email'),
      firstName: get(attrs, 'firstName', ''),
      lastName: get(attrs, 'lastName', ''),
      profileImage: get(attrs, 'profileImage', ''),
      service: get(primaryAuth, `authType`, ''),
      pending: get(attrs, 'pending', false),
      signupTime: get(attrs, 'signupTime'),
      auths: get(attrs, 'auths'),
    };

    return newAttrs;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200
    }
  }
)

/**
 * @returns {String} given user primary auth type.
 */
export const primaryAuthType = createSelector(
  (state, userId) => attrs(state, userId || authSelectors.currentUserId(state)),
  (attrs) => {
    return get(attrs, 'service', '') || '';
  }
);

/**
 * @returns {Object} Given user last-seen details.
 *                   e.g `{ timezoneId, timezoneOffset, lastLogin, lastSeen, ipAddress }`
 */
export const lastSeenDetails = createSelector(
  (state, userId) => firestoreRedux.selectors.doc(state, 'users', userId || authSelectors.currentUserId(state)),
  (state, userId) => firestoreRedux.selectors.doc(state, `lastseen-details`, `ulsd_${utils.getIdWoPrefix({id: userId || authSelectors.currentUserId(state), prefix: 'usr_'})}`),
  (attrs, userLastSeenDetails) => {
    if (!attrs) {
      return attrs;
    }

    const newLastSeenDetails = {
      timezoneId: get(attrs, 'timezone'),
      timezoneOffset: get(attrs, 'timezoneOffset'),
      lastLogin: get(attrs, 'lastLoginTime'),
      lastSeen: get(userLastSeenDetails, 'time', get(attrs, 'lastSeen')),
      ipAddress: get(userLastSeenDetails, 'ip', get(attrs, 'lastLoginIp')),
      countryCode: get(userLastSeenDetails, 'country.code', ''),
    };
    return newLastSeenDetails;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200
    }
  }
);

/**
 * @returns {Object} Given user properties.
 */
export const userProperties = createSelector(
  (state) => {
    const userId = get(state, 'auth.user.id') || authSelectors.currentUserId(state);
    const id = `ups_${utils.getIdWoPrefix({ id: userId, prefix: 'usr_' })}`;
    return firestoreRedux.selectors.doc(state, `user-properties`, id) || {};
  },
  (userProperties) => {
    return userProperties;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 20
    }
  }
);

/**
 * User first session is end or not.
 */
export const firstSessionEnd = createSelector(
  (state) =>  userProperties(state),
  (userProperties) => {
    return isEmpty(userProperties) || !userProperties.firstSession || !userProperties.firstSession.startTime || userProperties.firstSession.sessionEnd;
  }
);

/**
 * User has taskboard aware or not.
 */
export const taskBoardAware = createSelector(
  (state) =>  userProperties(state),
  (userProperties) => {
    return userProperties ? userProperties.taskBoardAware ? true: userProperties.taskBoardAware === false ? false: null: null;
  }
);

/**
 * User jobRole
 */
export const jobRole = createSelector(
  (state) =>  userProperties(state),
  (userProperties) => {
    return userProperties && userProperties.jobRole || '';
  }
);