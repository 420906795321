import merge from 'lodash-es/merge';
import isEmpty from 'lodash-es/isEmpty';

// Check google tag manager event is enabled or not.
let isGoogleTagManagerEnabled = window.K.config.enableGTM === true || window.K.config.enableGTM === 'true';

let GTMAPIKey;

/**
 * Initialize google tag manager.
 * @param {String} APIKey
 */
export const init = (APIKey, extraParams) => {
  if(!isGoogleTagManagerEnabled) {
    return;
  }

  if(!APIKey) {
    isGoogleTagManagerEnabled = false;
    return;
  }

  GTMAPIKey = APIKey;
  (function(w,d,s,l,i, extraParams){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ extraParams;f.parentNode.insertBefore(j,f);

    let noscript = document.createElement("noscript");
    let iframe = document.createElement("iframe");iframe.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl+ extraParams;
    iframe.height = "0";iframe.width = "0";iframe.style.display = "none";iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    let body = document.body;
    body.parentNode.insertBefore(noscript, body);
  })(window,document,'script','dataLayer', GTMAPIKey, extraParams);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
  window.gtag('config', GTMAPIKey);
}

/**
 * Track google tag manager event.
 * @param {String} name event name
 * @param {Object} properties event properties
 */
 export const logEvent = (name, properties = {}) => {
  if(!isGoogleTagManagerEnabled) {
    return;
  }

  const GTMDefaultDetails = {
    'send_to': [GTMAPIKey]
  };

  window.gtag('event', name, merge({}, GTMDefaultDetails, properties || {}));
}

/**
 * Set GA4 user properties.
 * @param {Object} properties
 */
export const setUserProperties = (userId, properties) => {
  if(!isGoogleTagManagerEnabled) {
    return;
  }

  properties = properties || {};
  if(userId) {
    window.gtag('config', 'MEASUREMENT_ID', {
      'user_id': userId
    });
    properties = merge({'kerika_user_id': userId}, properties);
  }

  if(!isEmpty(properties)) {
    window.gtag('set', 'user_properties', properties);
  }
}