
/**
 * Open new tab using
 * @param {String} url
 * @param {String} target
 * @param {String} options
 * @returns {Promise} resolve wit reference.
 * Please see details here: https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-inappbrowser/
 */
export const open = (url, target = "_blank", options = "location=no") => {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  document.addEventListener("deviceready", function () {
    if (!window.cordova || !window.cordova.InAppBrowser) {
      console.warn('InAppBrowser plugin is not available.');
      reject();
      return;
    }

    const reference = window.cordova.InAppBrowser.open(url, target, options);
    resolve(reference);
  }, false);
  return promise;
}