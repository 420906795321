import { isAccountHomePage, isDesktopHomePage, isMobileHomePage, isNotSupportedPage, isNotFoundPage } from './validate-url';
import URI from '@dw/urijs-esm';

/**
 * This promise function is resolved with parse page data if currently accessed url is an account home page otherwise reject promise.
 */
export const boardExplorerRouter = (data) => {
  return new Promise((resolve, reject) => {
    if(!isAccountHomePage(data.path) || isNotSupportedPage(data.path) || isNotFoundPage(data.path)) {
      reject(data);
      return;
    }

    if(data.wideLayout) {
      //If current url is not dektop url OR archive or trash url
      if(!isDesktopHomePage(data.path) || /\/(archive|trash)\/?/.test(data.path)) {
        data.redirect = getDesktopValidPath(data.path);
        resolve(data);
        return;
      }
    }

    if(!data.wideLayout && !isMobileHomePage(data.path)) {
      data.redirect = getMobileValidPath(data.path, data.lastAccessedAccount, data.ownedAccounts, data.accessibleAccounts);
      resolve(data);
      return;
    }

    let uri = new URI(data.path);
    data.accountId = data.wideLayout ? undefined: uri.segment()[0];
    const segment2 = data.wideLayout ? uri.segment()[1]: uri.segment()[2];
    const segment3 = data.wideLayout ? uri.segment()[2]: uri.segment()[3];
    const segment4 = data.wideLayout ? uri.segment()[3]: uri.segment()[4];
    let filter1 = segment2;
    let filter2 = segment3;
    let filter3 = segment4;
    let filters = {

    };

    if (!['favorite', 'other'].includes(segment2)) {
      filter1 = segment3;
      filter2 = segment2;
    }

    if (filter1 === 'favorite') {
      filters.favorite = true;
    } else {
      filters.favorite = false;
    }

    if(filter3 === 'current-lang') {
      filters.currentLang = true;
    } else {
      filters.currentLang = false;
    }

    filters.status = 'ACTIVE';
    if (filter2 === 'boards') {
      filters.template = false;
    } else if (filter2 === 'templates') {
      filters.template = true;
    } else if (filter2 === 'archive') {
      filters.template = false;
      filters.status = 'ARCHIVED';
    } else if (filter2 === 'trash') {
      filters.template = true;
      filters.status = 'TRASHED';
    } else if (filter2 === 'views') {
      filters.views = true;
    }
    let params = _getQueryParams();
    data.page = {
      name: 'BOARD_EXPLORER',
      params: {
        filters,
        action: params.action,
        ...params
      }
    };

    resolve(data);
  });
}

/**
 * @return { Object } - All query parameters hash of current url.
 */
const _getQueryParams = () => {
  return new URI().query(true);
}

const getDesktopValidPath = (path) => {
  const uri = new URI(path);
  const accountId = uri.segment()[0];
  let url = uri.toString().replace(`/${accountId}`, '');
  if(/\/(archive|trash)\/?/.test(url)) {
    return '/home/favorite/boards';
  }
  return url;
}

const getMobileValidPath = (path, lastAccessedAccount, ownedAccounts, accessibleAccounts) => {
  ownedAccounts = ownedAccounts || [];
  accessibleAccounts = accessibleAccounts || {};
  lastAccessedAccount = lastAccessedAccount || '';

  if (!accessibleAccounts[lastAccessedAccount]) {
    lastAccessedAccount = (ownedAccounts && ownedAccounts[0]) || keys(accessibleAccounts)[0];
  }

  return `/${lastAccessedAccount}${path}`;
}