import * as actions from './actions';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import get from 'lodash-es/get';

const INITIAL_STATE = {};

export const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_QUERY:
      return ReduxUtils.replace(state, `query`, action.query);
    case actions.SUGGESTIONS_DIALOG_OPENED_CHANGED:
      return ReduxUtils.replace(state, `suggestionsDialogOpened`, action.opened);
    case actions.UPDATE_TYPE:
      return ReduxUtils.replace(state, `lastSearchRequest.selectedType`, action.value);
    case actions.UPDATE_SELECTED_BOARDS:
      return ReduxUtils.replace(state, `lastSearchRequest.selectedBoards`, action.value);
    case actions.RESET_RESULTS:
      return ReduxUtils.replace(state, `searchResult`, undefined);
    case actions.SEARCH:
      if (!get(state, `query`)) {
        return state;
      }
      return ReduxUtils.replace(state, `lastSearchRequest.request`, { status: 'IN_PROGRESS' });
    case actions.SEARCH_SUCCESS:
      state = ReduxUtils.replace(state, `lastSearchRequest.request`, { status: 'SUCCESS' });
      state = ReduxUtils.replace(state, `lastSearchRequest.boardId`, action.currentBoardId);
      state = ReduxUtils.replace(state, `didYouMean`, action.didYouMean);
      state = ReduxUtils.replace(state, `lastSearchRequest.query`, action.query);

      let resultPath = `searchResult.card.currentBoard`;
      if (action.currentType === 'CARDS' && action.selectedBoards === 'OTHER') {
        resultPath = `searchResult.card.otherBoards`;
      } else if (action.currentType === 'ATTACHMENTS' && action.selectedBoards === 'CURRENT') {
        resultPath = `searchResult.attachment.currentBoard`;
      } else if (action.currentType === 'ATTACHMENTS' && action.selectedBoards === 'OTHER') {
        resultPath = `searchResult.attachment.otherBoards`;
      }
      return ReduxUtils.replace(state, resultPath, action.results);
    case actions.SEARCH_FAILED:
      return ReduxUtils.replace(state, `lastSearchRequest.request`, { status: 'FAILED', error: action.error, message: action.message });
    case actions.RESET:
      return INITIAL_STATE;
    case actions.SET_HIGHLIGHTS:
      return ReduxUtils.replace(state, `lastOpenedSearchResult`, action.data);
    case actions.RESET_HIGHLIGHTS:
      return ReduxUtils.replace(state, `lastOpenedSearchResult`, null);
    case actions.UPDATE_CARD_FILTERS:
      return ReduxUtils.replace(state, `lastSearchRequest.cardFilters`, action.filters);
    case actions.UPDATE_ATTACHMENT_FILTERS:
      return ReduxUtils.replace(state, `lastSearchRequest.attachmentFilters`, action.filters);
    case actions.LOAD_CARDS_FILTER_DATA_SUCCESS:
      return ReduxUtils.replace(state, `filterData`, action.data);
    default:
      return state;
  }
};