import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { CREATE_BOARD_DIALOG_OPENED, CREATE_BOARD_DIALOG_CLOSED, CREATE_BOARD_DIALOG_LOAD_DATA, CREATE_BOARD_DIALOG_LOAD_DATA_DONE, CREATE_TEMPLATE_DIALOG_LOAD_DATA, CREATE_TEMPLATE_DIALOG_LOAD_DATA_DONE, CREATE_BOARD, CREATE_BOARD_FAILED } from './create-board/actions.js';
import {
  UPDATE_ARCHIVE_FILTERS_DONE, UPDATE_TRASH_FILTERS_DONE, UPDATE_ACCOUNTS_FILTERS_DONE, UPDATE_CURRENT_LANGUAGE_FILTERS_DONE,
  LOAD_BOARDS_DETAILS, LOAD_BOARDS_DETAILS_DONE, STORE_LAST_VISITED_PAGE_PARAMS, CREATE_TEMPLATE, CREATE_TEMPLATE_FAILED, PAGE_OPENED, PAGE_CLOSED
} from './actions';
import isEmpty from 'lodash-es/isEmpty';

export const FILTERS_LOCAL_STORAGE_KEY = 'browsefilters';

//Get browsefilters values from local storage.
let browsefiltersLocalState;
try {
    const value = localStorage && localStorage.getItem && localStorage.getItem('browsefilters') || null;
    browsefiltersLocalState = JSON.parse(value);
} catch (e) { console.error(e); }

browsefiltersLocalState = isEmpty(browsefiltersLocalState) ? {}: browsefiltersLocalState;

const INITIAL_STATE = {
  loading: true,
  createBoard: {
    createInProgress: false,
    lastLoadedAc: undefined,
    dataLoading: false,
  },
  'action-in-progress': {},
  browsefilters: browsefiltersLocalState
}

export const boardExplorer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGE_OPENED:
    case PAGE_CLOSED:
    case CREATE_TEMPLATE_FAILED:
      return ReduxUtils.replace(state, `createTemplateInProgress`, false);
    case CREATE_TEMPLATE:
      return ReduxUtils.replace(state, `createTemplateInProgress`, true);
    case LOAD_BOARDS_DETAILS:
      return ReduxUtils.replace(state, `loading`, true);
    case LOAD_BOARDS_DETAILS_DONE:
      return ReduxUtils.replace(state, `loading`, false);
    case CREATE_BOARD_DIALOG_LOAD_DATA:
      return ReduxUtils.replace(state, `createBoard.dataLoading`, true);
    case CREATE_TEMPLATE_DIALOG_LOAD_DATA:
      return ReduxUtils.replace(state, `createTemplate.dataLoading`, true);
    case CREATE_TEMPLATE_DIALOG_LOAD_DATA_DONE:
      return ReduxUtils.replace(state, `createTemplate.dataLoading`, false);
    case CREATE_BOARD_DIALOG_LOAD_DATA_DONE:
      state = ReduxUtils.replace(state, `createBoard.lastLoadedAc`, action.accountId);
      return ReduxUtils.replace(state, `createBoard.dataLoading`, false);
    case CREATE_BOARD:
      return ReduxUtils.replace(state, `createBoard.createInProgress`, true);
    case CREATE_BOARD_FAILED:
      return ReduxUtils.replace(state, `createBoard.createInProgress`, false);
    case CREATE_BOARD_DIALOG_OPENED:
    case CREATE_BOARD_DIALOG_CLOSED:
      return ReduxUtils.replace(state, `createBoard.createInProgress`, false);
    case STORE_LAST_VISITED_PAGE_PARAMS:
      return ReduxUtils.replace(state, `lastVisitedPageParams`, action.params);
    case UPDATE_ARCHIVE_FILTERS_DONE:
    case UPDATE_TRASH_FILTERS_DONE:
    case UPDATE_ACCOUNTS_FILTERS_DONE:
    case UPDATE_CURRENT_LANGUAGE_FILTERS_DONE:
      return ReduxUtils.replace(state, `browsefilters`, action.value);
    default:
      return state;
  }
}