import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as board from '../board';
import * as boardSummary from '../board-summary';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import sortBy from 'lodash-es/sortBy';
import findIndex from 'lodash-es/findIndex';
import isEqual from 'lodash-es/isEqual.js';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { createSelector } from 'reselect';
import { getIdWoPrefix } from '../../utils';

/**
 * Default column visibility value based on column type.
 */
export const defaultColumnVisibility = (columnType, wideLayout) => {
  const columnVisibility = {
    OTHER: true,
    DONE: true,
    TRASH: false,
  };
  const mobileColumnVisibility = {
    OTHER: true,
    DONE: true,
    TRASH: true,
  };

  if (wideLayout) {
    return columnVisibility[columnType];
  }
  return mobileColumnVisibility[columnType];
};

/**
 * @param {Number} boardId Board Id
 * @returns columns e.g [
 *                        {"id":10859145,"name":"To Do","order":-10000,"type":"OTHER","wipLimit":0, "cardsCount": 4, "iconNumber": 1},
 *                        {"id":10859146,"name":"In Progress","order":100,"type":"OTHER","wipLimit":0, "cardsCount": 7, "iconNumber": 2},
 *                        {"id":10859151,"name":"Done","order":9999,"type":"DONE","wipLimit":0, "cardsCount": 6},
 *                        {"id":10859152,"name":"Trash","order":10000,"type":"TRASH","wipLimit":0, "cardsCount": 5}
 *                      ]
 */
export const list = createSelector(
  ({ state, boardId }) => board.selectors.columns(state, boardId),
  ({ state, boardId }) => boardSummary.selectors.columnSummary(state, boardId),
  (columns, columnSummary) => {
    columns = sortBy(columns, ['order', 'name']);
    const list = [];
    forEach(columns, (column, index) => {
      const obj = {
        id: column.id,
        boardId: column.boardId,
        description: column.description,
        name: column.name,
        order: column.order,
        type: column.type,
        wipLimit: column.wipLimit,
        cardsCount: get(columnSummary, column.id, 0),
        iconNumber: index + 1,
      };
      obj.iconNumber = index + 1;
      list.push(obj);
    });
    return list;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

/**
 * @param {Object} state Redux state
 * @param {String} boardId Board Id
 * @returns {Boolean} `true` when wipLimit is enabled for board.
 */
export const wipLimitEnabled = createSelector(
  (state, boardId) => firestoreRedux.selectors.doc(state, 'boards', boardId),
  (doc) => get(doc, 'wipLimitEnabled', false),
  {
    memoizeOptions: {
      maxSize: 50,
    },
  }
);

/**
 * @returns {Object} column details.
 * @param {Object} state State
 * @param {String} columnId Column Id
 */
export const column = createSelector(
  (state, columnId) => firestoreRedux.selectors.doc(state, 'columns', columnId),
  (column) => {
    return column || {};
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);


/**
 * @returns {String} Column Id of "DONE" column.
 */
export const currentBoardDoneColumnId = createSelector(
  (state) => board.selectors.columns(state, router.selectors.pageBoardId(state)),
  (columns) => get(find(columns, { type: 'DONE' }), 'id')
);

/**
 * @returns {String} Column Id of "TRASH" column.
 */
export const currentBoardTrashColumnId = createSelector(
  (state) => board.selectors.columns(state, router.selectors.pageBoardId(state)),
  (columns) => get(find(columns, { type: 'TRASH' }), 'id')
);

/**
 * @param {*}
 *  @property {Object} state State
 *  @property {String} boardId Board Id
 *  @property {String} columnId Column Id
 * @returns {Object} next column of given columnId in given board.
 *
 */
export const nextColumn = createSelector(
  ({ columnId }) => columnId,
  ({ state, boardId }) => board.selectors.columns(state, boardId),
  (columnId, columns) => {
    const columnIndex = findIndex(columns, { id: columnId });
    const nextColumnIndex = columnIndex >= 0 ? columnIndex + 1 : undefined;
    return get(columns, nextColumnIndex);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

/**
 * @param {*}
 *  @property {Object} state State
 *  @property {String} boardId Board Id
 *  @property {String} columnId Column Id
 * @returns {Object} provious column of given columnId in given board.
 */
export const previousColumn = createSelector(
  ({ columnId }) => columnId,
  ({ state, boardId }) => board.selectors.columns(state, boardId),
  (columnId, columns) => {
    const columnIndex = findIndex(columns, { id: columnId });
    const previousColumnIndex = columnIndex > 0 ? columnIndex - 1 : undefined;
    return get(columns, previousColumnIndex);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

export const hiddenColumns = createSelector(
  (state, boardId) => firestoreRedux.selectors.docsByQueryResult(state, `hidden-columns-${boardId || router.selectors.pageBoardId(state)}`, 'hidden-columns'),
  (columns) => {
    return columns || [];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

/**
 * @param {*}
 *  @property {Object} state State
 *  @property {String} boardId Board Id
 * @returns {Number} The number of columns that are currently hidden for the given board.
 */
export const hiddenColumnsCount = (state, boardId) => {
  const columns = list({state, boardId});
  let count = 0;
  forEach(columns, (column) => {
    const visibility = columnVisibility({state, columnId: column.id});
    if (!visibility) {
      count += 1;
    }
  });
  return count;
};

/**
 * @param {*}
 *  @property {String} state State
 *  @property {String} columnId Column Id
 * @returns {Boolean} given column is visible or not.
 */
export const columnVisibility = createSelector(
  ({ state, columnId }) => app.selectors.wideLayout(state),
  ({ state, columnId }) => auth.selectors.currentUserId(state),
  ({ state, columnId }) => columnId,
  ({ state, columnId }) => column(state, columnId),
  ({ state, columnId }) => hiddenColumns(state),
  (wideLayout, userId, columnId, columnDetails, allHiddenColumns) => {
    if (!userId) {
      return true;
    }
    const doc = find(allHiddenColumns, { columnId });
    if (!doc) {
      const columnType = get(columnDetails, 'type', 'OTHER');
      return defaultColumnVisibility(columnType, wideLayout);
    }
    return !get(doc, 'hidden');
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 200,
    },
  }
);

/**
 * @returns {Number} given column cards count.
 */
export const cardsCount = createSelector(
  (state, columnId) => firestoreRedux.selectors.doc(state, 'columns-summary', `cos_${getIdWoPrefix({ id: columnId, prefix: 'col_' })}`),
  (doc) => {
    return (doc && doc.cards) || 0;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

export const columnBoardId = (state, columnId) => {
  const _doc = column(state, columnId);
  return _doc && _doc.boardId;
}

export const dndTipShownCount = createSelector(
  (state) => firestoreRedux.selectors.doc(state, `user-ui`, `cdtsc_${getIdWoPrefix({id: auth.selectors.currentUserId(state), prefix: 'usr_'})}`),
  (doc) => {
    doc = doc || {};
    return doc.count || 0;
  }
);