import get from 'lodash-es/get';
import * as app from '../app';
import * as routerUtils from './routers/router-utils'
import * as board from '../board';
import * as zoomOutView from '../zoomout-view';
import URI from '@dw/urijs-esm';


/**
 * @param { Object } state - Redux state object
 * @return { String } - Id of account id from router redux state
 */
export const accountId = (state) => get(state, 'router.accountId');

/**
 * @param { Object } state - Redux state object
 * @return { Object } - page detail object from router redux state
 */
export const page = (state) => get(state, 'router.page');

/**
 * @param { Object } state - Redux state object
 * @return { Object } - page params detail object from router redux state
 */
export const pageParams = (state) => get(state, 'router.page.params');

/**
 * @param { Object } state - Redux state object
 * @return { Object } - page filters object from router redux state
 */
export const pageFilters = (state) => get(state, 'router.page.params.filters');

/**
 * @param { Object } state - Redux state object
 * @return { Object } - dialog detail object from router redux state
 */
export const dialog = (state) => get(state, 'router.dialog');

/**
 * @param { Object } state - Redux state object
 * @return { Object } - page params detail object from router redux state
 */
export const dialogParams = (state) => get(state, 'router.dialog.params');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Name of page from router page redux state
 */
export const pageName = (state) => get(state, 'router.page.name');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Type of page from router page redux state
 */
 export const pageType = (state) => get(state, 'router.page.params.pageType');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Id of board id from router page redux state
 */
export const pageBoardId = (state) => get(state, 'router.page.params.boardId');

/**
 * @param { Object } state - Redux state object
 * @return { Boolean } page is private from router page redux state.
 */
export const privatePage = (state) => get(state, 'router.page.params.private');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Name of dialog from router dialog redux state
 */
export const dialogName = (state) => get(state, 'router.dialog.name');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Id of board id from router dialog redux state
 */
export const dialogBoardId = (state) => get(state, 'router.dialog.params.boardId');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Id of card id from router dialog redux state
 */
export const cardId = (state) => get(state, 'router.dialog.params.cardId');

/**
 * @param { Object } state - Redux state object
 * @return { String } - Name of action dialog from router page redux state
 */
export const cardTab = (state) => {
  if(dialogName(state) !== 'CARD') {
    return '';
  }
  return get(state, 'router.dialog.params.tab');
};

/**
 * @param { Object } state - Redux state object
 * @return { String } - Name of action dialog from router page redux state
 */
export const actionName = (state) => get(state, 'router.page.params.action', '');

/**
 * @param { Object } state - Redux state object
 * @return { Boolean } - `true` when zoomout mode `false` otherwise.
 */
export const zoomOutMode = (state) => {
  const pageType = pageName(state) === 'VIEWS' ? 'VIEW': 'BOARD';
  const pageId = pageType === 'VIEW' ? get(state, 'router.page.params.viewId'): pageBoardId(state);
  return zoomOutView.selectors.zoomout({state, pageId, pageType});
}

/**
 * @param { Object } state - Redux state object
 * @returns {String} - template id from url.
 */
export const templateId = (state) => {
  try {
    let templateId = get(state, 'router.page.params.template');
    if(!templateId) {
      templateId = get(state, 'router.dialog.params.template');
    }
    return templateId || '';
  } catch (error) {
    return '';
  }
}

/**
 *
 * @param {Object} state Redux state
 * @returns {Number} current page canvas reference id.
 */
export const canvasRefId = (state) => {
  let _canvasRefId = get(state, 'router.page.params.canvasRefId') || get(state, 'router.dialog.params.canvasRefId');
  if(!_canvasRefId) {
    _canvasRefId = board.selectors.canvasId({state});
  }
  return _canvasRefId ? +_canvasRefId: 0;
}

/**
 * @returns {String} canvas id as a base64 encoded string.
 */
export const canvasId = (state) => routerUtils.to64(canvasRefId(state));

/**
 * @param {Object} state Redux state
 * @param {Number} id canvas Id.
 * @param {Number} accountId Optional. If don't give then consider currently opened account.
 * @param {Number} boardId Optional. If don't give then consider currently opened board.
 * @returns {String} canvas c-page(view as webpage) link for given canvas.
 */
export const getCanvasCPageLink = (state, id, nAccountId, boardId) => {
  nAccountId = nAccountId || accountId(state);
  boardId = boardId || pageBoardId(state);
  return `${nAccountId}/c/${boardId}/cnvs_${routerUtils.to64(id)}`;
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when currently opened page is c-page. `false` otherwise.
 */
export const isCpageView = (state) => {
  return get(state, 'router.page.params.cpageView') && get(state, 'router.page.params.pageType') === 'WHITEBOARD';
}

/**
 * @param {Object} state Redux state
 * @param {Number} id
 * @param {Number} nAccountId Optional. If don't give then will consider currently opened account.
 * @param {Number} boardId Optional. If don't give then will consider currently opened board.
 * @returns {String} canvas page link for given canvas.
 */
 export const getCanvasPageLink = (state, id, nAccountId, boardId) => {
   nAccountId = nAccountId || accountId(state);
   boardId = boardId || pageBoardId(state);
   let queryParam = '';
   const useCaseBoard = board.selectors.isUseCaseBoard(state);
   if (useCaseBoard) {
    queryParam = '?use-case-board=true';
   }
  return `/${nAccountId}/board/${boardId}/cnvs_${routerUtils.to64(id)}${queryParam}`;
}

/**
 * @returns {String} Where does the signup-login-page open?
 */
export const signupLoginPageOpenTrigger = (state) => {
  const _dialogName = dialogName(state);
  const template = templateId(state);
  const _source = source(state);
  if(_dialogName === 'AUTH' && template) {
    return 'USE_TEMPLATE';
  }

  if(_source === 'website-header') {
    return 'HEADER';
  }

  return 'UNKNOWN';
}

/**
 * @returns {String} referrer page name.
 */
export const referrerPage = (state) => get(state, 'router.page.params.referrer-page', '');

/**
 * @returns {String} referrer page trigger.
 */
export const referrerPageTrigger = (state) => {
  let pageTrigger = get(state, 'router.page.params.referrer-page-trigger', '');
  pageTrigger = pageTrigger === 'preview-image' ? 'PREVIEW_IMAGE_CLICK' : pageTrigger;
  return pageTrigger;
}

/**
 * @returns {Boolean} `true` when signup mode is found in url.
 */
export const signupMode = (state) => {
  const _page = page(state);
  const _dialog = dialog(state);

  let _dialogMode = get(_dialog, 'params.mode');
  let _pageMode = get(_page, 'params.mode');

  const _signupMode = _dialogMode || _pageMode || 'login';
  if(typeof _signupMode === 'string') {
    return _signupMode === 'signup';
  }

  return _signupMode && _signupMode.length && _signupMode.includes && _signupMode.includes('signup') || false;
}

/**
 * @returns {Boolean} `source` params value.
 */
export const source = (state) => {
  const _page = page(state);
  const _dialog = dialog(state);

  let _dialogSource = get(_dialog, 'params.source');
  let _pageSource = get(_page, 'params.source');
  return _dialogSource || _pageSource;
}

/**
 * @returns {Boolean} `secret` params value.
 */
export const secret = (state) => {
  const _page = page(state);
  const _dialog = dialog(state);

  let _dialogSecret = get(_dialog, 'params.secret');
  let _pageSecret = get(_page, 'params.secret');
  return _dialogSecret || _pageSecret;
}

/**
 * @returns {Boolean} `force` params value.
 */
export const force = (state) => {
  const _page = page(state);
  const _dialog = dialog(state);

  let _dialogSource = get(_dialog, 'params.force');
  let _pageSource = get(_page, 'params.force');
  return _dialogSource === true || _pageSource === true || _dialogSource === 'true' || _pageSource === 'true' || false;
}

/**
 * @returns {String} analytics event track id.
 */
 export const analyticsEventId = (state) => {
  const _page = page(state);
  const _dialog = dialog(state);
  let _dialogEventId = get(_dialog, 'params.evt');
  let _pageEventId = get(_page, 'params.evt');
  return _dialogEventId || _pageEventId;
}

/**
 * @returns {Boolean} embed login ans signup page is opened or not.
 */
export const embedLoginSignup = (state) => {
  let _embedLoginSignup= get(state, 'router.page.params.embed-login-signup');
  if(_embedLoginSignup === 'true' || _embedLoginSignup === true){
    return true;
  }
  return window.kerikaPWA && window.kerikaPWA.embedLoginSignup || false;
}

/**
 * @returns {Boolean} embed import trello board is opened or not.
 */
export const embedTrelloImport = (state) => {
 let _embedTrelloImport= get(state, 'router.page.params.embed-trello-import');
 if(_embedTrelloImport === 'true' || _embedTrelloImport === true){
   return true;
 }
 return window.kerikaPWA && window.kerikaPWA.embedTrelloImport || false;
}

/**
 * @returns {Boolean} embed use case boards is opened or not.
 */
export const embedUseCaseBoards = (state) => {
  let _embedUseCaseBoards= get(state, 'router.page.params.embed-use-case-boards');
  if(_embedUseCaseBoards === 'true' || _embedUseCaseBoards === true){
    return true;
  }
  return window.kerikaPWA && window.kerikaPWA.embedUseCaseBoards || false;
}

/**
 * @returns {Boolean} embed view is opened or not.
 */
export const embedView = (state) => {
  return embedLoginSignup(state) || embedTrelloImport(state) || embedUseCaseBoards(state);
}

/**
 * @returns {String} current video id.
 */
export const videoId = (state) => {
  const _dialog = dialog(state);
  if(!_dialog || _dialog.name !== 'VIDEOS') {
    return;
  }

  const videoId = get(_dialog, 'params.id');

  if(!videoId) {
    return;
  }

  return videoId;
}

/**
 * @returns {String} current video trigger.
 */
export const videoTrigger = (state) => {
  const _dialog = dialog(state);
  if(!_dialog || _dialog.name !== 'VIDEOS') {
    return 'UNKNOWN';
  }

  const trigger = get(_dialog, 'params.trigger');
  if(!trigger) {
    return 'UNKNOWN';
  }

  return trigger == 'tip' ? 'TIP': trigger == 'dialog' ? 'WELCOME_DIALOG': 'UNKNOWN';
}

/**
 * @returns {String} current video url.
 */
export const videoUrl = (state) => {
  const _videoId = videoId(state);

  if(!_videoId) {
    return;
  }

  return app.selectors.videoUrl(state, _videoId);
}

/**
 * @param {Object} state redux state
 * @returns {Boolean} current tab is favorite or not.
 */
export const isFavoritesTab = (state) => {
  const _page = page(state);
  const _filters = get(_page, 'params.filters');
  return _filters && _filters.favorite || false;
}

/**
 * @returns {Array} legacy URLs(regex of individual URL).
 */
export const legacyUrlsRegex = (state) => {
  return [
    '^/(app/.*|app)$',
    '^/m/.*',
    '^/c/.*',
    '^/views/.*',
    '^/projects/.*',
    '^/templates/.*',
    '^/profile$',
    '^/preferences$',
    '^/preferences/boards$',
    '^/preferences/notifications$',
    '^/preferences/canvases$',
    '^/my-account$',
    '^/home$',
    '^/home/favorite$',
    '^/home/views$',
    '^/home/favorite/views$',
    '^/home/favorite/boards$',
    '^/home/favorite/templates$',
    '^/home/shared$',
    '^/home/shared/boards$',
    '^/home/shared/templates$',
    '^/home/shared/archive$',
    '^/home/shared/trash$',
    '^/home/my$',
    '^/home/my/boards$',
    '^/home/my/templates$',
    '^/home/my/archive$',
    '^/home/my/trash$',
  ];
}