export const LOAD = 'MESSAGES_LOAD';
export const MARK_AS_DISMISSED = 'MESSAGES_MARK_AS_DISSMISSED';
export const DISCONNECT = 'MESSAGES_DISCONNECT';

/**
 * Loads messages data.
 * Note: At present messages are loaded already by app. So no need to load anything for now.
 */
export const load = (userId) => {
  return {
    type: LOAD,
    userId
  }
}

/**
 * Disconnects messages data.
 * Note: At present messages are loaded already by app. So no need to disconnect from here.
 */
export const disconnect = (userId) => {
  return {
    type: DISCONNECT,
    userId
  }
}

/**
 * Marks message as dismissed.
 * @param {Number} id Message Id
 */
export const markAsDismissed = (id) => {
  return {
    type: MARK_AS_DISMISSED,
    id
  }
}
