export const DIALOG_OPENED = 'BOARD_TRANSLATIONS_DIALOG_OPENED';
export const DIALOG_CLOSED = 'BOARD_TRANSLATIONS_DIALOG_CLOSED';

export const LOAD = 'BOARD_TRANSLATIONS_LOAD_DATA';
export const LOAD_DONE = 'BOARD_TRANSLATIONS_LOAD_DATA_DONE';
export const DISCONNECT = 'BOARD_TRANSLATIONS_DISCONNECT';

export const TRANSLATE_BOARD = 'TRANSLATE_BOARD';
export const TRANSLATE_BOARD_DONE = 'TRANSLATE_BOARD_DONE';
export const TRANSLATE_BOARD_FAILED = 'TRANSLATE_BOARD_FAILED';

export const RETRANSLATE_BOARD = 'RETRANSLATE_BOARD';
export const RETRANSLATE_BOARD_DONE = 'RETRANSLATE_BOARD_DONE';
export const RETRANSLATE_BOARD_FAILED = 'RETRANSLATE_BOARD_FAILED';

/**
 * Dispatch this action to load data.
 */
export const load = (requesterId, boardId) => {
  return {
    type: LOAD,
    requesterId,
    boardId
  }
}

export const _loadDone = (boardId, response) => {
  return {
    type: LOAD_DONE,
    boardId,
    response
  }
}

/**
 * Dispatch this action to stop live listning.
 */
export const disconnect = (requesterId, boardId) => {
  return {
    type: DISCONNECT,
    requesterId,
    boardId
  }
}

/**
 * Dipatch action to translate a given boards on given languages.
 */
export const translateBoard = (boardId, languages) => {
  return {
    type: TRANSLATE_BOARD,
    boardId,
    languages
  }
}

export const _translateBoardDone = (boardId, languages, response) => {
  return {
    type: TRANSLATE_BOARD_DONE,
    boardId,
    languages,
    response
  }
}

export const _translateBoardFailed = (boardId, languages, error) => {
  return {
    type: TRANSLATE_BOARD_FAILED,
    boardId,
    languages,
    error
  }
}

/**
 * Dipatch action to re-translate a given boards on given languages.
 */
export const retranslateBoard = (boardId) => {
  return {
    type: RETRANSLATE_BOARD,
    boardId,
  }
}

export const _retranslateBoardDone = (boardId) => {
  return {
    type: RETRANSLATE_BOARD_DONE,
    boardId,
  }
}

export const _retranslateBoardFailed = (boardId, error) => {
  return {
    type: RETRANSLATE_BOARD_FAILED,
    boardId,
    error
  }
}