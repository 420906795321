import * as actions from './actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

export const manageAccount = (state = {}, action) => {
  switch (action.type) {
    case actions.TEAM_DATA_LOADED:
      return ReduxUtils.replace(state, `team.${action.accountId}`, action.data);
    case actions.PARTICIPATED_BOARDS_DATA_LOADED: 
      return ReduxUtils.replace(state, `participatedBoards.${action.accountId}.${action.memberId}`, action.data)
    default:
      return state;
  }
}