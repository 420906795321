import { takeEvery, takeLatest, select, all, put, call } from 'redux-saga/effects';
import { getLocaleString } from '../../utils.js';
import { getUserName } from '../../components/utils.js';
import * as firebaseRedux from '@dw/firebase-redux';
import { requestApi, isNetworkError } from '../../request-api';
import { show as showToast } from '../../components/kerika-snackbar.js';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as board from '../board';
import * as manageAccount from '../manage-account';
import * as amplitude from '../../analytics/amplitude.js';
import i18next from '@dw/i18next-esm';
import log from '@dw/loglevel';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';


const _log = log.getLogger('subscription');

/**
 * Loads Subscriptions details from firebase.
 */
function* load(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.read(action.requesterId, [`/subscriptions/${accountId}/subscription`]);
}

/**
 * Stop live listening from firebase for subscriptions details.
 */
function* disconnect(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.fbReader.disconnect(action.requesterId, [`/subscriptions/${accountId}/subscription`]);
}

/**
 * Loads Subscriptions invoices from firebase.
 */
function* loadInvoices(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.read(action.requesterId, [`/subscriptions/${accountId}/invoices`]);
}

/**
 * Stop live listening from firebase for subscriptions invoices.
 */
function* disconnectInvoices(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.fbReader.disconnect(action.requesterId, [`/subscriptions/${accountId}/invoices`]);
}

/**
 * Loads Subscriptions transactions from firebase.
 */
function* loadTransactions(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.read(action.requesterId, [`/subscriptions/${accountId}/transactions`]);
}

/**
 * Stop live listening from firebase for subscriptions transactions.
 */
function* disconnectTransactions(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.fbReader.disconnect(action.requesterId, [`/subscriptions/${accountId}/transactions`]);
}

/**
 * Loads Subscriptions billing address from firebase.
 */
function* loadBillingAddress(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.read(action.requesterId, [`/subscriptions/${accountId}/billing-address`]);
}

/**
 * Stop live listening from firebase for subscriptions billing address.
 */
function* disconnectBillingAddress(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firebaseRedux.fbReader.disconnect(action.requesterId, [`/subscriptions/${accountId}/billing-address`]);
}

/**
 * Initiate refund request.
 */
let lastRefundRequestAbortController;
function* refundRequest() {
  const state = yield select();
  const currentUserName = getUserName(auth.selectors.currentUser(state));
  const currentUserId = auth.selectors.currentUserId(state);
  const currentAccountId = router.selectors.accountId(state);
  const config = app.selectors.config(state);
  const adminUIBaseUrl = config && config.adminUIBaseUrl;
  try {
    if (selectors.creditBalance(state) <= 0) {
      const error = new Error('Credit balance is not available.');
      error.code = 'NOT_FOUND';
      throw error;
    }
    const creditBalance = getLocaleString(selectors.creditBalance(state) / 100);
    //Abort last request
    lastRefundRequestAbortController && lastRefundRequestAbortController.abort && lastRefundRequestAbortController.abort();
    lastRefundRequestAbortController = new AbortController();

    const subject = i18next.t("subscription:creditBalance.email.subject", { balance: creditBalance });
    const refundUrl = `${adminUIBaseUrl}/accounts#manage-account/${currentAccountId}/summary?subscriptionAction=change-credit-balance&type=refund`;
    const body = i18next.t("subscription:creditBalance.email.message", { name: currentUserName, id: currentUserId, balance: creditBalance, refundUrl });

    yield call(requestApi, `/subscription/subscriptions/${currentAccountId}/request-refund`, { method: 'POST', body: { subject, body, signal: lastRefundRequestAbortController.signal } });
    yield put(actions._refundRequestSuccess());
    showToast({ message: i18next.t('subscription:creditBalance.toast.success') });
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._refundRequestFailed());
      return;
    }

    yield put(actions._refundRequestFailed(error.code === 'SUBSCRIPTION_NOT_FOUND' ? 'SUBSCRIPTION_NOT_FOUND' : 'UNKNOWN'));
    if(code === 'UNKNOWN') {
      _log.error('Refund request initiate is failed due to this error: ', error);
    }
  }
}

/**
 * Resend given invoice to billing contacts of current account.
 */
function* resendInvoice(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const invoiceId = action.invoiceId;

  try {
    yield call(requestApi, `/subscription/invoices/${invoiceId}/resend`, { method: 'POST', body: { accountId }, excludeErrors: [404] });
    showToast({ message: i18next.t('subscription:billingHistory.item.toast.resend.success') });
    yield put(actions._resendInvoiceSuccess(invoiceId));
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._resendInvoiceFailed(invoiceId));
      return;
    }

    yield put(actions._resendInvoiceFailed(invoiceId, error.code === 'INVOICE_NOT_FOUND' ? 'NOT_FOUND' : 'UNKNOWN'));
    showToast({ message: error.message || i18next.t('subscription:billingHistory.item.toast.resend.failed'), type: 'ERROR' });
    if(code === 'UNKNOWN') {
      _log.error('Resend invoice is failed due to this error: ', error);
    }
  }
}

/**
 * Online pay given a pending invoice.
 */
function* payOnlineInvoice(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const invoiceId = action.invoiceId;
  const stripeToken = action.token;
  const amount = getLocaleString(action.amount / 100);
  try {
    yield call(requestApi, `/subscription/invoices/${invoiceId}/pay`, { method: 'POST', body: { accountId, stripeToken, amount }, excludeErrors: [404, 400] });
    showToast({ message: i18next.t('subscription:billingHistory.item.toast.payOnline.success', { amount }) });
    yield put(actions._payOnlineInvoiceSuccess(invoiceId));
  } catch (error) {
    //If failed due to network error.
    if (isNetworkError(error)) {
      yield put(actions._payOnlineInvoiceFailed(invoiceId));
      return;
    }

    const code = error.code === 'INVOICE_NOT_FOUND' ? 'NOT_FOUND' : error.code === 'STRIPE_TOKEN_NOT_FOUND' ? 'TOKEN_NOT_FOUND' : error.code === 'BAD_REQUEST' ? 'ALREADY_PAID' : 'UNKNOWN';
    showToast({ message: i18next.t('subscription:billingHistory.item.toast.payOnline.failed', { amount }), type: 'ERROR' });
    yield put(actions._payOnlineInvoiceFailed(invoiceId, code));
    _log.error('Pay online invoice is failed due to this error: ', error);
  }
}

/**
 * Update billing-address.
 */
function* updateBillingAddress({billingAddress}) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  billingAddress = {...billingAddress, accountId};
  const localWritePath = `subscriptions.${accountId}.billing-address`;
  try {
    firebaseRedux.fbReader.localWrite(localWritePath, billingAddress);
    yield call(requestApi, `/subscription/billings/${accountId}`, { method: 'PUT', body: billingAddress });
    yield put(actions._updateBillingAddressSuccess());
  } catch (error) {
    yield put({ type: firebaseRedux.actions.REMOVE_LOCAL_WRITE, path: localWritePath });
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._updateBillingAddressFailed());
      return;
    }

    yield put(actions._updateBillingAddressFailed(error.code === 'BAD_REQUEST' ? 'BAD_REQUEST' : 'UNKNOWN'));
    if(code === 'UNKNOWN') {
      _log.error('Update billing address is failed due to this error: ', error);
    }
  }
}


/**
 * Change plan.
 */
 function* changePlan(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const plan = action.plan;
  const oldPlan = selectors.plan(state);
  const subscriptions = action.subscriptions;
  const token = action.token;
  const requestInvoice = action.requestInvoice;
  const autoRenew = action.autoRenew;
  const body = {
    plan,
    subscriptions: plan === 'PROFESSIONAL' ? subscriptions: undefined,
    stripeToken: requestInvoice ? undefined: token,
    requestInvoice,
    amount: action.amount || 0
  }
  try {
    const response = yield call(requestApi, `/subscription/subscriptions/${accountId}/change-plan`, { method: 'POST', body, excludeErrors: [400, 404] });
    yield put(actions._changePlanSuccess({ accountId, oldPlan, plan, offlineInvoicePaymentDuedate: response && response.dueDate }));
    if(plan === 'PROFESSIONAL') {
      yield put(actions.changeAutoRenew(autoRenew));
    }
  } catch (error) {
    //If failed due to network error.
    const message = error && error.message || 'Unknown error';
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._changePlanFailed({plan, subscriptions, message}));
      return;
    }

    const code = error.code === 'INVALID_PLAN' ? 'INVALID_PLAN' : error.code === 'SUBSCRIPTION_NOT_FOUND' ? 'SUBSCRIPTION_NOT_FOUND' : error.code === 'BAD_REQUEST'? 'BAD_REQUEST': error.code === 'TOKEN_NOT_PROVIDED' ? 'TOKEN_NOT_FOUND': 'UNKNOWN';
    yield put(actions._changePlanFailed({plan, error: code, subscriptions, message}));
    if(code === 'UNKNOWN') {
      _log.error('Change plan is failed due to this error: ', error);
    }
  }
}

function* changePlanSuccess(action) {
  const state = yield select();
  const pendingActionData = selectors.pendingActionsData(state);
  if(action.plan !== 'PROFESSIONAL' || isEmpty(pendingActionData)) {
    return;
  }

  if(pendingActionData.action === 'add') {
    if(pendingActionData.type === 'board-team') {
      yield put(board.actions.addMember({requestId: pendingActionData.requestId, boardId: pendingActionData.boardId, emails: pendingActionData.emails, role: pendingActionData.role}));
      return;
    }

    yield put(manageAccount.actions.addMembers(pendingActionData.accountId, pendingActionData.emails, pendingActionData.role));
    return;
  }

  if(pendingActionData.type === 'board-team') {
    yield put({ type: board.actions.BOARD_TEAM_CHANGE_MEMBER_ROLE, boardId: pendingActionData.boardId, userId: pendingActionData.userId, role: pendingActionData.role, email: pendingActionData.email, userName: pendingActionData.userName });
    return;
  }

  yield put(manageAccount.actions.upgradeRole(pendingActionData.accountId, pendingActionData.memberId));
}

/**
 * Track `subscription plan changed` event in amplitude.
 */
function* trackPlanChangeEvent(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const fromPlan = action.oldPlan;
  const toPlan = action.plan;
  amplitude.logEvent('subscription plan changed', {'account_id': accountId, 'from': fromPlan, 'to': toPlan});
}

function* removeSubscriptions(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const subscriptions = action.subscriptions;
  const autoRenew = action.autoRenew;
  const subscriptionsDetails = selectors.subscription(state);
  const currentPlanAutoRenew = subscriptionsDetails && subscriptionsDetails.autoRenewal;
  try {
    yield call(requestApi, `/subscription/subscriptions/${accountId}/downgrade-subscription`, { method: 'POST', body: { subscriptions }, excludeErrors: [400, 404]});
    yield put(actions._removeSubscriptionsSuccess());

    if(currentPlanAutoRenew !== autoRenew) {
      yield put(actions.changeAutoRenew(autoRenew));
    }
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._removeSubscriptionsFailed());
      return;
    }

    const code = error.code === 'SUBCRIPTION_COUNT_NOT_PROVIDED' ? 'SUBSCRIPTION_NOT_FOUND' :'UNKNOWN';
    yield put(actions._removeSubscriptionsFailed(code));
    if(code === 'UNKNOWN') {
      _log.error('Remove subscriptions is failed due to this error: ', error);
    }
  }
}

function* addSubscriptions(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const subscriptions = action.subscriptions;
  const token = action.token;
  const requestInvoice = action.requestInvoice;
  const autoRenew = action.autoRenew;
  const subscriptionsDetails = selectors.subscription(state);
  const currentPlanAutoRenew = subscriptionsDetails && subscriptionsDetails.autoRenewal;
  const body = {
    subscriptions: subscriptions,
    stripeToken: requestInvoice ? undefined: token,
    requestInvoice,
    amount: action.amount || 0
  }
  try {
    const response = yield call(requestApi, `/subscription/subscriptions/${accountId}/buy-subscription`, { method: 'POST', body, excludeErrors: [400, 404] });
    yield put(actions._addSubscriptionsSuccess(response && response.dueDate));
    if(currentPlanAutoRenew !== autoRenew) {
      yield put(actions.changeAutoRenew(autoRenew));
    }
  } catch (error) {
    //If failed due to network error.
    const message = error && error.message || 'Unknown error';
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._addSubscriptionsFailed({subscriptions, message}));
      return;
    }

    const code = error.code === 'SUBSCRIPTION_NOT_FOUND' ? 'SUBSCRIPTION_NOT_FOUND' : error.code === 'BAD_REQUEST'? 'BAD_REQUEST': error.code === 'TOKEN_NOT_PROVIDED' ? 'TOKEN_NOT_FOUND': 'UNKNOWN';
    yield put(actions._addSubscriptionsFailed({error: code, subscriptions, message}));
    if(code === 'UNKNOWN') {
      _log.error('Add subscriptions is failed due to this error: ', error);
    }
  }
}

function* renew(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const subscriptions = action.subscriptions;
  const token = action.token;
  const requestInvoice = action.requestInvoice;
  const autoRenew = action.autoRenew;
  const subscriptionsDetails = selectors.subscription(state);
  const currentPlanAutoRenew = subscriptionsDetails && subscriptionsDetails.autoRenewal;
  const body = {
    subscriptions: subscriptions,
    stripeToken: requestInvoice ? undefined: token,
    requestInvoice
  }
  try {
    const response = yield call(requestApi, `/subscription/subscriptions/${accountId}/renew`, { method: 'POST', body, excludeErrors: [400, 404] });
    yield put(actions._renewSuccess(response && response.dueDate));
    if(currentPlanAutoRenew !== autoRenew) {
      yield put(actions.changeAutoRenew(autoRenew));
    }
  } catch (error) {
    //If failed due to network error.
    const message = error && error.message || 'Unknown error';
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._renewFailed({subscriptions, message}));
      return;
    }

    const code = error.code === 'SUBSCRIPTION_NOT_FOUND' ? 'SUBSCRIPTION_NOT_FOUND' : error.code === 'BAD_REQUEST'? 'BAD_REQUEST': error.code === 'TOKEN_NOT_PROVIDED' ? 'TOKEN_NOT_FOUND': 'UNKNOWN';
    yield put(actions._renewFailed({error: code, subscriptions, message}));
    if(code === 'UNKNOWN') {
      _log.error('renew plan is failed due to this error: ', error);
    }
  }
}

/**
 * Change auto renew value for current plan.
 */
function* autoRenew(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const autoRenew = action.autoRenew;
  try {
    yield call(requestApi, `/subscription/subscriptions/${accountId}/auto-renew`, { method: 'PUT', body: { autoRenew }});
    yield put(actions._changeAutoRenewSuccess(autoRenew));
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._changeAutoRenewFailed(autoRenew));
      return;
    }

    const code = error.code || 'UNKNOWN';
    yield put(actions._changeAutoRenewFailed(autoRenew, code));
    if(code === 'UNKNOWN') {
      _log.error('Auto renew is failed due to this error: ', error);
    }
  }
}

/**
 * request to non profit plan.
 */
 function* requestNonprofitPlan() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const oldPlan = selectors.plan(state);
  const plan = 'NON_PROFIT';
  try {
    yield call(requestApi, `/subscription/subscriptions/${accountId}/request-non-profit-plan`, { method: 'POST', excludeErrors: [409] });
    yield put(actions._requestNonprofitPlanSuccess({oldPlan, plan, accountId}));
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._requestNonprofitPlanFailed());
      return;
    }

    const code = error.code === 'TEAM_SIZE_OVER' ? 'TEAM_SIZE_OVER' : error.code === 'PUBLIC_DOMAIN_NOT_ALLOWED' ? 'PUBLIC_DOMAIN_NOT_ALLOWED' : 'UNKNOWN';
    yield put(actions._requestNonprofitPlanFailed(code));

    if(code === 'UNKNOWN') {
      _log.error('Request non-profit plan is failed due to this error: ', error);
    }
  }
}

/**
 * compute charges for given subscriptions.
 */
 function* computeCharges(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const subscriptions = action.subscriptions;
  const subscriptionsType = action.subscriptionsType;
  const downgrade = action.downgrade || false;
  const renew = action.renew || false;
  try {
    const charges = selectors.charges(state);
    let response;
    if (subscriptions !== charges.subscriptions) {
      response = yield call(requestApi, `/subscription/subscriptions/${accountId}/compute-change-plan-charges`, { method: 'POST', body: { plan: 'PROFESSIONAL', subscriptions, downgrade, renew }, excludeErrors: [400, 404] });
    }
    const taxAmount = get(response, 'taxAmount', charges.taxAmount);
    const paymentAmount = get(response, 'paymentAmount', charges.paymentAmount);
    const expirationDate = get(response, 'expirationDate', charges.expirationDate);

    yield put(actions._computeChargesSuccess({subscriptionsType, subscriptions, taxAmount, paymentAmount, expirationDate}));
  } catch (error) {
    //If failed due to network error.
    if (!error || !error.code || error.code == error.ABORT_ERR) {
      yield put(actions._computeChargesFailed({subscriptionsType, subscriptions}));
      return;
    }

    const code = error.code === 'INVALID_PLAN' ? 'INVALID_PLAN' : error.code === 'SUBSCRIPTION_NOT_FOUND' ? 'SUBSCRIPTION_NOT_FOUND' : 'UNKNOWN';
    yield put(actions._computeChargesFailed({subscriptionsType, subscriptions, error: code}));
    if(code === 'UNKNOWN') {
      console.error('compute plan charges is failed due to this error: ', error);
    }
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.DISCONNECT, disconnect),
    takeEvery(actions.INVOICES_LOAD, loadInvoices),
    takeEvery(actions.INVOICES_DISCONNECT, disconnectInvoices),
    takeEvery(actions.TRANSACTIONS_LOAD, loadTransactions),
    takeEvery(actions.TRANSACTIONS_DISCONNECT, disconnectTransactions),
    takeEvery(actions.BILLING_ADDRESS_LOAD, loadBillingAddress),
    takeEvery(actions.BILLING_ADDRESS_DISCONNECT, disconnectBillingAddress),
    takeEvery(actions.REFUND_REQUEST, refundRequest),
    takeEvery(actions.RESEND_INVOICE, resendInvoice),
    takeEvery(actions.PAY_ONLINE_INVOICE, payOnlineInvoice),
    takeEvery(actions.UPDATE_BILLING_ADDRESS, updateBillingAddress),
    takeEvery(actions.CHANGE_PLAN, changePlan),
    takeEvery(actions.REQUEST_NON_PROFIT_PLAN, requestNonprofitPlan),
    takeLatest(actions.COMPUTE_CHARGES, computeCharges),
    takeEvery(actions.CHANGE_AUTO_RENEW, autoRenew),
    takeEvery(actions.CHANGE_PLAN_SUCCESS, changePlanSuccess),
    takeEvery(actions.REMOVE_SUBSCRIPTIONS, removeSubscriptions),
    takeEvery(actions.ADD_SUBSCRIPTIONS, addSubscriptions),
    takeEvery(actions.RENEW, renew),
    takeEvery([actions.REQUEST_NON_PROFIT_PLAN_SUCCESS, actions.CHANGE_PLAN_SUCCESS], trackPlanChangeEvent)
  ]);
}

export default saga;