import { RESEND_INVOICE } from "../subscription/actions";

export const PAGE_OPENED = 'MANAGE_ACCOUNT_PAGE_OPENED';
export const PAGE_CLOSED = 'MANAGE_ACCOUNT_PAGE_CLOSED';
export const TAB_CHANGED = 'MANAGE_ACCOUNT_TAB_CHANGED';
export const UPDATE_SETTINGS = 'MANAGE_ACCOUNT_UPDATE_SETTINGS';

export const RENAME = 'MANAGE_ACCOUNT_RENAME';
export const RENAME_DONE = 'MANAGE_ACCOUNT_RENAME_DONE';
export const RENAME_FAILED = 'MANAGE_ACCOUNT_RENAME_FAILED';

export const PARTICIPATED_BOARDS_DATA_LOAD = 'MANAGE_ACCOUNT_PARTICIPATED_BOARDS_DATA_LOAD';
export const PARTICIPATED_BOARDS_DATA_LOADED = 'MANAGE_ACCOUNT_PARTICIPATED_BOARDS_DATA_LOADED';

export const TEAM_DATA_LOAD = 'MANAGE_ACCOUNT_TEAM_DATA_LOAD';
export const TEAM_DATA_LOADED = 'MANAGE_ACCOUNT_TEAM_DATA_LOADED';

export const TEAM_ADD_MEMBERS = 'MANAGE_ACCOUNT_TEAM_ADD_MEMBERS';
export const TEAM_ADD_MEMBERS_DONE = 'MANAGE_ACCOUNT_TEAM_ADD_MEMBERS_DONE';
export const TEAM_ADD_MEMBERS_FAILED = 'MANAGE_ACCOUNT_TEAM_ADD_MEMBERS_FAILED';

export const UPGRADE_ROLE = 'MANAGE_ACCOUNT_TEAM_UPGRADE_ROLE';
export const UPGRADE_ROLE_DONE = 'MANAGE_ACCOUNT_TEAM_UPGRADE_ROLE_DONE';
export const UPGRADE_ROLE_FAILED = 'MANAGE_ACCOUNT_TEAM_UPGRADE_ROLE_FAILED';

export const DOWNGRADE_ROLE = 'MANAGE_ACCOUNT_TEAM_DOWNGRADE_ROLE';
export const DOWNGRADE_ROLE_DONE = 'MANAGE_ACCOUNT_TEAM_DOWNGRADE_ROLE_DONE';
export const DOWNGRADE_ROLE_FAILED = 'MANAGE_ACCOUNT_TEAM_DOWNGRADE_ROLE_FAILED';

export const MEMBER_REMOVE = 'MANAGE_ACCOUNT_TEAM_MEMBER_REMOVE';
export const MEMBER_REMOVE_DONE = 'MANAGE_ACCOUNT_TEAM_MEMBER_REMOVE_DONE';
export const MEMBER_REMOVE_FAILED = 'MANAGE_ACCOUNT_TEAM_MEMBER_REMOVE_FAILED';

export const MAKE_ADMIN = 'MANAGE_ACCOUNT_TEAM_MAKE_ADMIN';
export const MAKE_ADMIN_DONE = 'MANAGE_ACCOUNT_TEAM_MAKE_ADMIN_DONE';
export const MAKE_ADMIN_FAILED = 'MANAGE_ACCOUNT_TEAM_MAKE_ADMIN_FAILED';

export const REMOVE_ADMIN = 'MANAGE_ACCOUNT_TEAM_REMOVE_ADMIN';
export const REMOVE_ADMIN_DONE = 'MANAGE_ACCOUNT_TEAM_REMOVE_ADMIN_DONE';
export const REMOVE_ADMIN_FAILED = 'MANAGE_ACCOUNT_TEAM_REMOVE_ADMIN_FAILED';

export const TEAM_LOAD = 'MANAGE_ACCOUNT_TEAM_LOAD';
export const TEAM_DISCONNECT = 'MANAGE_ACCOUNT_TEAM_DISCONNECT';

export const INVITE_MEMBERS = 'MANAGE_ACCOUNT_INVITE_MEMBERS';
export const INVITE_MEMBERS_DONE = 'MANAGE_ACCOUNT_INVITE_MEMBERS_DONE';
export const INVITE_MEMBERS_FAILED = 'MANAGE_ACCOUNT_INVITE_MEMBERS_FAILED';

export const RESEND_INVITATION = 'MANAGE_ACCOUNT_RESEND_INVITATION';
export const RESEND_INVITATION_SUCCESS = 'MANAGE_ACCOUNT_RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILED = 'MANAGE_ACCOUNT_RESEND_INVITATION_FAILED';

/**
 * This is trigger when current opened account team is changed.
 */
export const TEAM_CHANGED = 'MANAGE_ACCOUNT_TEAM_CHANGED';

/**
 * Dispatch action to update account settings.
 * @param {Object} settings Account Settings
 */
export const updateSettings = (settings) => {
  return {
    type: UPDATE_SETTINGS,
    settings
  }
}

/**
 * Change given `name` for given `accountId` in the arguments.
 * @param {Number} accountId
 * @param {String} name
 */
export const rename = (accountId, name) => {
  return {
    type: RENAME,
    accountId,
    name
  }
}

/**
 * Upgrade role to given member for given account.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const upgradeRole = (accountId, memberId) => {
  return {
    type: UPGRADE_ROLE,
    accountId,
    memberId
  }
}

/**
 * Downgrade role to given member for given account.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const downgradeRole = (accountId, memberId) => {
  return {
    type: DOWNGRADE_ROLE,
    accountId,
    memberId
  }
}

/**
 * Remove member for given account.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const removeMember = (accountId, memberId) => {
  return {
    type: MEMBER_REMOVE,
    accountId,
    memberId
  }
}

/**
 * Make admin to given member for given account.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const makeAdmin = (accountId, memberId) => {
  return {
    type: MAKE_ADMIN,
    accountId,
    memberId
  }
}

/**
 * remove as an admin to given member for given account.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const removeAdmin = (accountId, memberId) => {
  return {
    type: REMOVE_ADMIN,
    accountId,
    memberId
  }
}

/**
 * Dispatch action to load account team from firebase.
 */
export const loadAccountTeam = (requesterId) => {
  return {
    type: TEAM_LOAD,
    requesterId
  }
}

/**
 * Dispatch action to stop live listening account team from firebase.
 */
export const disconnectAccountTeam = (requesterId) => {
  return {
    type: TEAM_DISCONNECT,
    requesterId
  }
}

/**
 * Dispatch action to invite members into given account.
 */
export const inviteMembers = (accountId, members) => {
  return {
    type: INVITE_MEMBERS,
    accountId,
    members
  }
}

export const _inviteMembersDone = (accountId, members) => {
  return {
    type: INVITE_MEMBERS_DONE,
    accountId,
    members
  }
}

export const _inviteMembersFailed = (accountId, members, error) => {
  return {
    type: INVITE_MEMBERS_DONE,
    accountId,
    members,
    error
  }
}

/**
 * Resend invitation for given members into given account.
 */
 export const resendInvitation = (accountId, members) => {
  return {
    type: RESEND_INVITATION,
    accountId,
    members
  }
}

export const _resendInvitationSuccess = (accountId, members) => {
  return {
    type: RESEND_INVITATION_SUCCESS,
    accountId,
    members
  }
}

export const _resendInvitationFailed = (accountId, members, error) => {
  return {
    type: RESEND_INVITATION_FAILES,
    accountId,
    members,
    error
  }
}

/**
 * Add new members into given account.
 * @param {Number} accountId
 * @param {Array} emails
 * @param {String} role
 */
export const addMembers = (accountId, emails, role) => {
  return {
    type: TEAM_ADD_MEMBERS,
    accountId,
    emails,
    role
  }
}