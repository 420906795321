import * as app from '../app';
import * as router from '../router';
import { store } from '../../store.js';
import get from 'lodash-es/get';
import { getHistoryList } from '@dreamworld/web-util/history';

/** 
 * Handle DeepLinks for installed app using `IonicDeeplink` plugin.
 */
export const init = () => {
  document.addEventListener("deviceready", function () {
    if (!window.IonicDeeplink) {
      console.warn('IonicDeeplink plugin is not available.');
      return;
    }

    IonicDeeplink.route({}, () => { }, (data) => {
      // Navigates to direct url by replaceState only when last history URL is root URL.
      const historyList = getHistoryList();
      const replace = historyList && historyList.length && historyList[historyList.length - 1] === '/' || false;
      router.actions.navigate(getUrl(data), replace);
    });
  }, false);
}

/**
 * Computes navigate url based on `data` arguments and application baseUrl.
 * It can handle all types of URLs: v1(legacy), v2(old desktop app) and this (pwa).
 * Read [router](../../wiki/router.md) for more detail.
 * 
 * @param {Object} data IonicDeeplink plugin nomatch data
 *  - e.g. { 
 *            "$link": {
 *              "url": "https://kerika-test2.dreamworld.solutions/m/rmxs",
 *   	          "path": "/m/rmxs",
 *   	          "scheme": "https",
 *   	          "host": "kerika-test2.dreamworld.solutions",
 *   	          "extra": {}
 *            }
 *         }
 * @returns {String} navigate url.
 */
const getUrl = (data) => {
  const path = get(data, '$link.path');
  const host = get(data, '$link.host');
  const queryString = get(data, '$link.queryString');
  const protocol = get(data, '$link.scheme');
  const config = app.selectors.config(store.getState());
  const baseUrl = get(config, 'baseUrl');

  if(`${protocol}://${host}`.startsWith(baseUrl) || path.startsWith('/app')) {
    return queryString ? `${path}?${queryString}`: path;
  }

  return queryString ? `/app/v1${path}?${queryString}`: `/app/v1${path}`;
}